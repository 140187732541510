import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import {phone, postcode, requiredHelpDesk} from '../../../validations';
import {Button} from '../..';
import {api, isValidForm} from "../../../services";

import './customer-general-information.css'

export default class CustomerGeneralInformation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  setDefaultPropsInState(generalInformation) {
    this.setState({
      firstName: generalInformation.firstName,
      lastName: generalInformation.lastName,
      postcode: generalInformation.postcode,
      companyName: generalInformation.companyName,
      roleType: generalInformation.roleType,
      email: generalInformation.email,
      phone: generalInformation.phone,
      isUpdateInProgress: false
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setDefaultPropsInState(nextProps.generalInformation);
      this.setState({roleTypes: nextProps.roleTypes});
    }
  }

  showOptions(options) {
    let result = [];
    let value;

    if (options.length > 0) {
      options.forEach(option => {
        value = option.toLowerCase()
        .replace(/[^A-Za-z0-9\s]/g, '')
        .replace(/\s+/g, '-');
        result.push(<option key={value} value={option}>{option}</option>)
      })
    }
    return result;
  }

  filterInput(e, key, pattern) {
    this.setState({
      [key]: e.target.value.replace(pattern, '')
    })
  }

  getCustomerDetails() {
    return api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}`).then(
        res => {
          if (res.status === 200) {
            res.json().then(responseBody => {
              this.setState({generalInformation: responseBody.details});
              this.setDefaultPropsInState(this.state.generalInformation)
            });
          }
        });
  }

  restoreValues() {
    this.getCustomerDetails();
  }

  updateCustomerAccount(form) {
    if (!isValidForm(form)) {
      return Promise.reject(new Error("Not valid"));
    }

    this.setState({isUpdateInProgress: true});
    const values = form.getValues();
    const body = {
      "firstName": values["firstName"],
      "lastName": values["lastName"],
      "companyName": values["companyName"],
      "phone": values["phone"],
      "postcode": values["postcode"],
      "roleType": values["roleType"]
    };

    return api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}`, 'PUT',
        body);
  }

  render() {
    let isNotVerified = this.props.statusType === 'Unverified';

    return <React.Fragment>
      <h4>General Information</h4>
      <Form
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
      >
        <div>
          <label>First Name:</label>
          <Input
              name={isNotVerified ? 'email' : 'firstName'}
              value={this.state.firstName || ''}
              onChange={(e) => this.filterInput(e, 'firstName',
                  /[^A-Za-z\s-']/g)}
              maxLength={200}
              onBlur={() => this.form.validate('firstName')}
              validations={[requiredHelpDesk]}
              readOnly={isNotVerified}
          />
        </div>
        <div>
          <label>Last Name:</label>
          <Input
              name={isNotVerified ? 'email' : 'lastName'}
              value={this.state.lastName || ''}
              onChange={(e) => this.filterInput(e, 'lastName',
                  /[^A-Za-z\s-']/g)}
              maxLength={200}
              onBlur={() => this.form.validate('lastName')}
              validations={[requiredHelpDesk]}
              readOnly={isNotVerified}
          />
        </div>
        <div>
          <label>Postcode:</label>
          <Input
              name={isNotVerified ? 'email' : 'postcode'}
              value={this.state.postcode || ''}
              minLength={3}
              maxLength={50}
              validations={[requiredHelpDesk, postcode]}
              onChange={e => this.filterInput(e, 'postcode',
                  /[^A-Za-z\d\s-]/g)}
              readOnly={isNotVerified}
          />
        </div>
        <div>
          <label>Company:</label>
          <Input
              name={isNotVerified ? 'email' : 'companyName'}
              value={this.state.companyName || ''}
              maxLength={200}
              onChange={e => this.filterInput(e, 'companyName',
                  /[^A-Za-z\s\d_"'~`!@#$%*^&()={}[\]\\/:;,.<>+?-]/g)}
              readOnly={isNotVerified}
          />
        </div>
        <div>
          <label>Role in Company:</label>
          <Select
              name={isNotVerified ? 'email' : 'roleType'}
              value={this.state.roleType || ''}
              validations={[requiredHelpDesk]}
              onChange={e => this.setState({roleType: e.target.value})}
              disabled={isNotVerified}
          >
            {this.showOptions(this.state.roleTypes || [])}
          </Select>
        </div>
        <div>
          <label>Email / Username:</label>
          <Input
              name="email"
              value={this.state.email || ''}
              readOnly={true}
          />
        </div>
        <div>
          <label>Phone:</label>
          <Input
              name={isNotVerified ? 'email' : 'phone'}
              value={this.state.phone || ''}
              validations={[requiredHelpDesk, phone]}
              minLength={6}
              maxLength={20}
              onChange={e => this.filterInput(e, 'phone', /[^\d]/g)}
              readOnly={isNotVerified}
          />
        </div>
        <div>
          <div className="button_general">
            <div className="buttonDiv">
              <div className="buttonCancel">
                <React.Fragment>
                  <Button
                      disabled={isNotVerified}
                      color="blue"
                      onClick={() => {
                        this.restoreValues();
                      }}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              </div>
              <div className="buttonUpdate">
                  <Button
                      disabled={isNotVerified || this.state.isUpdateInProgress}
                      isFormValidationRequired={true}
                      color="pink"
                      icon="arrow"
                      onClick={() => {
                        this.updateCustomerAccount(this.form)
                        .then(res => {
                          if (res.status === 200) {
                            this.props.onCustomerUpdate();
                            this.getCustomerDetails();
                          } else {
                            this.state({isUpdateInProgress: false});
                            this.props.setConnectionProblems();
                          }
                        })
                        .catch(() => this.setState({isUpdateInProgress: false}));
                      }}
                  >
                    Update
                  </Button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>;
  }
}