import React from 'react';
import ReactModal from 'react-modal';
import { Redirect } from 'react-router-dom';
import { Button } from '..';
import './registration-confirmation.css';

export default class RegistrationConfirmation extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      redirectTo: ''
    };
  }

  closeModal(){
    this.setState({
      redirectTo: '/login'
    });
  }

  render(){
    const root = document.querySelector('react-app');

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    }

    return <ReactModal
      appElement={root}
      isOpen={this.props.isOpen}
      className='app-modal'
    >
      <Button id="close-modal" onClick={() => this.closeModal()}>×</Button>
      <div className='registration-confirmation'>
        <h4>We are sending you an email to confirm your email address. Please follow the instructions contained within that email.</h4>
        <Button color='blue' onClick={() => this.closeModal()}>Continue</Button>
      </div>
    </ReactModal>
  }
}
