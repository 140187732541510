import React from 'react';
import ReactModal from 'react-modal';
import {Button} from '..';
import {Redirect} from 'react-router-dom';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import Select from 'react-validation/build/select';
import {LayoutContext} from "../app/context/layout-context";
import Form from 'react-validation/build/form';
import {
  required,
  requiredAtLeastOneContainerNumber,
  numberOfContainers,
  searchContainerFormat,
  listNameAlreadyExists
} from '../../validations';
import {api, filterInput, dataValidationPatterns, splitContainerNumbers} from '../../services';
import _ from 'lodash';
import {isValidForm, areArraysEqual} from '../../services/index';
import CollapsiblePageHeader from "../collapsible-page-header/collapsible-page-header";

import './saved-lists.css';
import './saved-lists.mobile.css';

export class SavedLists extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: '',
      savedLists: [],
      selectedSavedList: undefined,
      newListName: '',
      editedListName: '',
      isCreateNewListModalOpen: false,
      isEditSavedListModalOpen: false,
      isSaveEditedListAvailable: false
    };
  };

  componentDidMount() {
    this.getSavedListsAndSetSelected();
  }

  getSavedListsAndSetSelected(selectedListId) {
    api(this.props.layoutContext, '/api/customers/container-numbers')
    .then(res => {
      if (res.status === 200) {
        res.json().then(response => {
          this.setState({savedLists: response});
          selectedListId && this.selectSavedList(selectedListId)
        })
      }
    });
  }

  showOptions(options, defaultValue) {
    let result = [<option key='default' value="">{defaultValue}</option>];

    if (options.length > 0) {
      options.forEach(option => {
        result.push(<option key={option.customerContainerNumberListId}
                            value={option.customerContainerNumberListId}>{option.name}</option>);
      })
    }
    return result;
  }

  selectSavedList(value) {
    this.setState({
      selectedSavedList: _.find(this.state.savedLists,
          {customerContainerNumberListId: Number(value)})
    });
  }

  getSelectedListContent() {
    if (this.state.selectedSavedList) {
      if(this.props.layoutContext.state.isMobile){
        return this.state.selectedSavedList.containerNumbers.join('\n');
      } else {
        return this.state.selectedSavedList.containerNumbers.map(
            (container, index, source) => {
              if ((index + 1) % 5 === 0) {
                return container;
              } else if (index % 5 === 0 && index !== 0) {
                return '\n' + container.concat(index === source.length - 1 ? '' : '   ')
              } else return container.concat(index === source.length - 1 ? '' : '   ');
            }).join('');
      }
    } else {
      return '';
    }
  }

  createANewList() {
    this.createANewListForm.validateAll();
    if(isValidForm(this.createANewListForm)){
      const values = this.createANewListForm.getValues();

       api(this.props.layoutContext
           , '/api/customers/container-numbers'
           , 'POST'
           , {name: values['new-list-name'], containerNumbers: splitContainerNumbers(values['new-saved-list-content']).map(container => container.toUpperCase())})
      .then(res => {
        if (res.status === 200) {
          res.json().then(response => {
            this.setState({newListName: '', isCreateNewListModalOpen: false});
            this.getSavedListsAndSetSelected(response);
          });
        }
      });
    }
  }

  deleteSelectedSavedList() {
    if(this.state.selectedSavedList !== undefined) {
      api(this.props.layoutContext
          , '/api/customers/container-numbers/' + this.state.selectedSavedList.customerContainerNumberListId
          , 'DELETE')
      .then(res => {
        if (res.status === 200) {
          this.setState({selectedSavedList: undefined});
          this.getSavedListsAndSetSelected();
        }
      });
    }
  }

  updateSelectedSavedList() {
    this.editSavedListForm.validateAll();
    if(isValidForm(this.editSavedListForm)) {
      if (this.state.isSaveEditedListAvailable && this.state.selectedSavedList !== undefined) {
        const values = this.editSavedListForm.getValues();

        api(this.props.layoutContext
            , '/api/customers/container-numbers/'
            , 'PUT'
            , {
              name: values['new-list-name']
              ,containerNumbers: splitContainerNumbers(
                  values['new-saved-list-content']).map(
                  container => container.toUpperCase())
              ,customerContainerNumberListId: this.state.selectedSavedList.customerContainerNumberListId
            })
        .then(res => {
          if (res.status === 200) {
            this.setState(
                {isEditSavedListModalOpen: false, editedListName: ''});
            this.getSavedListsAndSetSelected(
                this.state.selectedSavedList.customerContainerNumberListId);
          }
        });
      } else {
        this.setState({isEditSavedListModalOpen: false})
      }
    }
  }

  isEditContainerHasChanges(event) {
    if(this.state.selectedSavedList && this.editSavedListForm){
      switch (event.target.name) {
        case 'new-list-name':
          this.setState({isSaveEditedListAvailable: event.target.value !== this.state.selectedSavedList.name})
          break;
        case 'new-saved-list-content':
          const newContainerNumbers = splitContainerNumbers(event.target.value);
          this.setState({isSaveEditedListAvailable: !areArraysEqual(this.state.selectedSavedList.containerNumbers, newContainerNumbers)})
          break;
        default:
            return;
      }
    }
  }

  searchContainers() {
    if (isValidForm(this.form)) {
      const values = this.form.getValues();
      const numbers = encodeURIComponent(values['current-saved-list-content']);
      this.setState({redirectTo: `/containers?numbers=${numbers}`});
    }
  }

  render() {

    if(this.state.redirectTo){
      return <Redirect to={this.state.redirectTo}/>
    }

    const editSavedListModalHeader = 'Edit List';
    const createANewListModalHeader = 'Create a New List';
    const createANewListModalText = <p>Create a new list by typing the name of your list in the List Name box.<br/>
      Put container numbers into the display box underneath then click Save.</p>;
    const savedListsPageHeader = 'Saved Lists';
    const savedListsPageHeaderText = <>
      <h4>You can save up to 10 lists</h4>
      <table className='saved-lists-table-of-instructions'>
        <tbody>
        <tr>
          <td>Create a list:</td>
          <td>Select ‘Create New List’</td>
        </tr>
        <tr>
          <td>View a list:</td>
          <td>Select list from List Name drop down</td>
        </tr>
        <tr>
          <td>Edit a list:</td>
          <td>Select list then click ‘Edit List’</td>
        </tr>
        <tr>
          <td>Delete a list:</td>
          <td>Select list then click ‘Delete List’</td>
        </tr>
        <tr>
          <td>Search a list:</td>
          <td>Select list then click 'Search'</td>
        </tr>
        </tbody>
      </table>
    </>;
    const createNewListButton = <Button
      className='create-saved-list-button'
      buttonType='button'
      disabled={this.state.savedLists.length >= 10}
      color='pink'
      onClick={(e) => {
        e.preventDefault();
        this.setState({isCreateNewListModalOpen: true});
      }}>
      Create New List
    </Button>;

      const savedListViewForm =<Form
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}>
        <div className='saved-lists-name'>
          <Select
              disabled={this.state.savedLists.length === 0}
              name="saved-lists-name"
              validations={[required]}
              value={(this.state.selectedSavedList && this.state.selectedSavedList.customerContainerNumberListId) || ''}
              onBlur={() => this.form.validate('saved-lists-name')}
              onChange={e => {
                this.selectSavedList(e.target.value);
              }}>
            {this.showOptions(this.state.savedLists, 'Select List Name')}
          </Select>
        </div>
        <div className='saved-lists-search-area'>
             <Textarea
                 disabled={true}
                 value={this.getSelectedListContent()}
                 name="current-saved-list-content"
                 className='current-saved-list-content'
                 validations={[requiredAtLeastOneContainerNumber,
                   numberOfContainers, searchContainerFormat]}
                 maxcontainernumbers={20}
                 containernumberformat={dataValidationPatterns.containerNumber}
             />
          <span className='search-area-controls'>
            {this.props.layoutContext.state.isMobile && createNewListButton}
            <Button
                buttonType='button'
                color='pink'
                isFormValidationRequired={true}
                icon="arrow"
                onClick={() => this.searchContainers()}>
              Search
            </Button>
          </span>
        </div>
        <div className='saved-lists-controls'>
          {!this.props.layoutContext.state.isMobile && createNewListButton}
          <Button
              buttonType='button'
              disabled={this.state.selectedSavedList === undefined}
              color='pink'
              onClick={(e) => {
                e.preventDefault();
                this.setState({editedListName: this.state.selectedSavedList.name
                  , isSaveEditedListAvailable: false
                  , isEditSavedListModalOpen: true});
              }}>
            Edit List
          </Button>
          <Button
              buttonType='button'
              disabled={this.state.selectedSavedList === undefined}
              color='pink'
              isFormValidationRequired={true}
              onClick={(e) => {
                e.preventDefault();
                this.deleteSelectedSavedList();
              }
              }>
            Delete List
          </Button>
        </div>
      </Form>;


    return <div className="main_wrap page_id_saved-lists">

      <div className='page_id_saved-lists-header'>
      {this.props.layoutContext.state.isMobile ? <CollapsiblePageHeader
              id='alert-template-page-header-id'
              pageHeader={savedListsPageHeader}
              collapsibleContent={savedListsPageHeaderText}/>
          : <React.Fragment><h2>{savedListsPageHeader}</h2>
              <span className='page_id_saved-lists-content'>
                {savedListsPageHeaderText}
                {savedListViewForm}
              </span>
          </React.Fragment>
      }
      </div>

      {this.props.layoutContext.state.isMobile && savedListViewForm}



      <ReactModal
          appElement={document.querySelector('react-app')}
          isOpen={this.state.isCreateNewListModalOpen} className="app-modal">
        <div className="create-a-new-list-modal">

          {this.props.layoutContext.state.isMobile ? <CollapsiblePageHeader
                  id='alert-template-page-header-id'
                  pageHeader={createANewListModalHeader}
                  collapsibleContent={createANewListModalText}/>
              : <React.Fragment><h2>{createANewListModalHeader}</h2> {createANewListModalText}
              </React.Fragment>
          }

          <Form
              ref={node => {
                this.createANewListForm = node;
              }}
              onSubmit={event => {
                event.preventDefault();
              }}>
            <Input
                name="new-list-name"
                value={this.state.newListName || ''}
                validations={[required, listNameAlreadyExists]}
                existingListNames={this.state.savedLists.map(item => item.name)}
                minLength={1}
                maxLength={20}
                placeholder='List Name'
                onBlur={() => this.createANewListForm.validate('new-list-name')}
                onChange={(e) => filterInput.call(this, e, 'newListName', dataValidationPatterns.listName)}
            />
            <Textarea
                name="new-saved-list-content"
                placeholder='Input up to 20 container numbers for your saved list here, then click Save'
                className='new-saved-list-content'
                validations={[requiredAtLeastOneContainerNumber,
                  numberOfContainers, searchContainerFormat]}
                maxcontainernumbers={20}
                containernumberformat={dataValidationPatterns.containerNumber}
                onChange={()=> this.createANewListForm.validate('new-saved-list-content')}
            />

          <div className='create-a-new-list-modal-controls'>
            <Button
                buttonType='button'
                color='blue'
                onClick={() => this.setState({newListName: '', isCreateNewListModalOpen: false })}>Cancel</Button>
            <Button
                buttonType='button'
                color='pink'
                onClick={() => this.createANewList()}>Save</Button>
          </div>
          </Form>
        </div>
      </ReactModal>

      <ReactModal
          appElement={document.querySelector('react-app')}
          isOpen={this.state.isEditSavedListModalOpen} className="app-modal">
        <div className="edit-saved-list-modal">

          {this.props.layoutContext.state.isMobile ? <CollapsiblePageHeader
                  id='alert-template-page-header-id'
                  pageHeader={editSavedListModalHeader}/>
              : <h2>{editSavedListModalHeader}</h2>
          }
          <Form
              ref={node => {
                this.editSavedListForm = node;
              }}
              onChange={(e)=> this.isEditContainerHasChanges(e)}
              onSubmit={event => {
                event.preventDefault();
              }}>
            <Input
                name="new-list-name"
                value={this.state.editedListName}
                validations={[required]}
                minLength={1}
                maxLength={20}
                onBlur={() => this.editSavedListForm.validate('new-list-name')}
                onChange={(e) => filterInput.call(this, e, 'editedListName', dataValidationPatterns.listName)}
            />
            <Textarea
                name="new-saved-list-content"
                value={this.getSelectedListContent() || ''}
                className='new-saved-list-content'
                validations={[requiredAtLeastOneContainerNumber,
                  numberOfContainers, searchContainerFormat]}
                maxcontainernumbers={20}
                containernumberformat={dataValidationPatterns.containerNumber}
            />

            <div className='edit-saved-list-modal-controls'>
              <Button
                  buttonType='button'
                  color='blue'
                  onClick={() => this.setState({isEditSavedListModalOpen: false })}>Cancel</Button>
              <Button
                  buttonType='button'
                  color='pink'
                  onClick={() => this.updateSelectedSavedList()}>Save</Button>
            </div>
          </Form>
        </div>
      </ReactModal>
    </div>
  }
};

export default props => (
    <LayoutContext.Consumer>
      {(layoutContext) =>
          <SavedLists {...props} layoutContext={layoutContext}/>
      }
    </LayoutContext.Consumer>

);