import React from 'react';
import Header from '../header/header'
import Footer from "../footer/footer";

export default class LoggedOutLayout extends React.Component {

  render() {
    return <React.Fragment>
      <Header isMobile={this.props.layoutContext.state.isMobile}/>
      {this.props.children}
      <Footer isMobile={this.props.layoutContext.state.isMobile}/>
    </React.Fragment>;
  }
};