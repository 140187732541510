import React from "react";
import { Link } from "react-router-dom";
import Form from "react-validation/build/form";
import ReactModal from "react-modal";
import { AccountDetails, ChangePassword, DeleteAccount, Button } from "..";
import {
  api,
  toggleFreeServiceTypeMode,
  formatShortDate,
  formatTimeAndFullDate
} from "../../services";
import "./my-account.css";
import "./my-account.mobile.css";
import FrameSection from "../frame-section/frame-section";
import ChangeAlertContacts from "./../change-alert-contacts/change-alert-contacts";
import PaymentModal from "./payment-modal";
import { Redirect, withRouter } from "react-router-dom";
import ReactGA from "react-ga";

const subscriptionInfo = {
  "Single payment": "30 day subscription",
  "Recurring payment": "30 day subscription recurring"
};

class MyAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: "",
      accountInfo: {},
      isReadOnlyMode: true,
      changePasswordOpen: this.props.isChangePasswordOpen || false,
      deleteAccountOpen: false,
      isChangeAlertContactPopUpOpen: false,
      isCancelSubscriptionOpen: false
    };

    this.getAccountInfo = this.getAccountInfo.bind(this);
  }

  componentDidMount() {
    this.getAccountInfo();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.layoutContext.state.isFreeAdvancedUser !==
      this.props.layoutContext.state.isFreeAdvancedUser
    ) {
      this.getAccountInfo(this);
    }
  }

  showChangePassword(e) {
    e.preventDefault();
    this.setState({ changePasswordOpen: true });
  }

  hideChangePassword() {
    this.setState({ changePasswordOpen: false });
  }

  hideSuccessfulChangePassword() {
    this.setState({ passwordChanged: true });
    this.props.onChangeTemporaryPassword();
    this.hideChangePassword();
    document.getElementById("password-changed-massage-id").scrollIntoView();
  }

  showDeleteAccount(e) {
    e.preventDefault();
    this.setState({ deleteAccountOpen: true });
  }

  hideDeleteAccount() {
    this.setState({ deleteAccountOpen: false });
  }

  getAccountInfo() {
    var crypt = window.location.href.split("=")[1];
    if (!crypt)
      api(
        this.props.layoutContext,
        this.props.layoutContext.state.isFreeAdvancedUser ||
          this.props.layoutContext.state.isPaidUser
          ? "/api/customers/account-details/advanced"
          : "/api/customers/account-details"
      ).then(res => {
        if (res.status === 200) {
          res.json().then(res => this.setState({ accountInfo: res }));
        }
      });
  }
  cancelSubscription() {
    api(this.props.layoutContext, "/api/payment/recurring", "PUT").then(res => {
      if (res.status === 200) {
        this.getAccountInfo();
      }
      this.setState({ isCancelSubscriptionOpen: false });
    });
  }

  switchReadOnlyMode() {
    this.setState(prevState => ({ isReadOnlyMode: !prevState.isReadOnlyMode }));
  }

  toggleFreeServiceType(layoutContext) {
    if (!this.props.layoutContext.state.isPaidUser) {
      api(
        this.props.layoutContext,
        "/api/customers/account-details/toggle-free-service-type",
        "PUT"
      ).then(res => {
        if (res.status === 200) {
          res.json().then(response => {
            const onSuccess = () => {
              toggleFreeServiceTypeMode(layoutContext, response);
            };
            this.props.refreshToken(onSuccess);
          });
        }
      });
    }
  }
  trialAdvancedFeatureClick(){
   if(!this.props.layoutContext.state.isFreeAdvancedUser){
     ReactGA.event({
       category: 'MyAccount',
       action: 'Clicked Trial Advanced Features',
       label :"Viewed Advanced Features Package Details"
     })
     this.linkedInClickTrialAdvancedFeature();
   }
  }

  purchaseAdvancedFeatureClick(){
    if(!this.props.layoutContext.state.isPaidUser){
      ReactGA.event({
        category: 'MyAccount',
        action: 'Clicked Purchase Advanced Features',
        label :"Started Purchase of Advanced Features"
      })
      this.linkedInClickPurchaseAdvancedFeature();
    }
  }
  linkedInClickTrialAdvancedFeature(){
    var img = document.createElement("img");
    img.setAttribute("src","https://px.ads.linkedin.com/collect/?pid=3333801&conversionId=4679745&fmt=gif");
    document.body.appendChild(img);
  }
  linkedInClickPurchaseAdvancedFeature(){
    var img = document.createElement("img");
    img.setAttribute("src","https://px.ads.linkedin.com/collect/?pid=3333801&conversionId=4679753&fmt=gif");
    document.body.appendChild(img);
  }
  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    const advancedFeatureBoxHeader = (
      <div className="advanced-features-section-header">
        <div>
          {this.props.layoutContext.state.isFreeAdvancedUser ||
          !this.state.accountInfo.isTrialModeAllowed
            ? "NEW features:"
            : "NEW advanced features:"}
        </div>
        <div>
          {this.props.layoutContext.state.isFreeAdvancedUser ||
          !this.state.accountInfo.isTrialModeAllowed
            ? "Upgrade for £15 per 30 day period"
            : "Why not try them now!"}
        </div>

        <div />
      </div>
    );
    const advancedFeatureBoxContent = (
      <>
        <div className="advanced-features-section-sub-header">
          Upgrade for real time alerts direct to your inbox or phone and
          valuable historical data to track and verify your cargo.
        </div>
        <div>
          <p>
            {this.props.layoutContext.state.isFreeAdvancedUser ||
            !this.state.accountInfo.isTrialModeAllowed
              ? "For just £15 plus VAT, you "
              : "You "}
            will have access to more detailed container journey information, the
            ability to set alerts on containers so that you know when they have
            reached a particular status, history of the container in our two
            ports and more...
          </p>
          <p>See our Advanced Features page for information</p>
        </div>
        <Button
          color="light-blue"
          icon="arrow"
          onClick={() => {
            if (
              this.props.layoutContext.state.isFreeAdvancedUser ||
              !this.state.accountInfo.isTrialModeAllowed
            )
              this.setState({ redirectTo: "/paid-features" });
            else this.setState({ redirectTo: "/advanced-features" });
          }}
        >
          Learn More
        </Button>
      </>
    );

    const { accountInfo } = this.state;
    const switchFreeTrialModeButton = (
      <div
        className={
          this.props.layoutContext.state.isPaidUser
            ? "my-account-trial-advanced-wrapper-paid "
            : this.props.layoutContext.state.isFreeAdvancedUser
            ? "my-account-trial-advanced-wrapper-advanced "
            : "my-account-trial-advanced-wrapper-free"
        }
      >
        {!this.props.layoutContext.state.isInternalUser &&
          (this.props.layoutContext.state.isFreeAdvancedUser ||
            this.props.layoutContext.state.isFreeUser) &&
          this.state.accountInfo.isTrialModeAllowed && (
            <Button
              onClick={e => {
                e.preventDefault();
                this.toggleFreeServiceType(this.props.layoutContext);
                this.trialAdvancedFeatureClick();
              }}
              color={
                this.props.layoutContext.state.isFreeAdvancedUser
                  ? "blue"
                  : "yellow"
              }
            >
              {this.props.layoutContext.state.isFreeAdvancedUser
                ? "Return to Standard\nFeatures only"
                : `Trial Advanced\nFeatures`}
            </Button>
          )}
        {this.state.accountInfo.invoiceHistoryDto &&
          this.state.accountInfo.invoiceHistoryDto.paymentRate ===
            "Recurring payment" && (
            <Button
              color="blue"
              className="page_id_account_cancel_subscription"
              onClick={() => {
                this.setState({ isCancelSubscriptionOpen: true });
              }}
            >
              {"Cancel\nSubscription"}
            </Button>
          )}
        <Button
          onClick={() => {
            this.props.history.push("/payment");
            this.purchaseAdvancedFeatureClick();}}
          color={"pink"}
          disabled={this.props.layoutContext.state.isInternalUser}
        >
          {this.props.layoutContext.state.isPaidUser
            ? "Renew\nSubscription"
            : "Purchase Advanced\nFeatures"}
        </Button>
        <ReactModal
          appElement={document.querySelector("react-app")}
          isOpen={this.state.isCancelSubscriptionOpen}
          className="app-modal"
        >
          <h2 className="page_id_account-popup-header">Cancel Subscription</h2>
          <p>
            You have requested to cancel your recurring subscription for
            advanced features.
          </p>
          <p>
            If you decide to cancel today, the cancellation will be effective at
            the end of your currently billed period which is{" "}
            {accountInfo.packageInfo &&
              formatShortDate(accountInfo.packageInfo.periodEndDate)}
          </p>
          <p>We do not provide refunds or credit for any partial-periods.</p>
          <p>
            You can re-subscribe to access our advanced features at any time.
          </p>
          <p>
            Once you cancel your subscription, your access to <b>Where’s my
            container?</b> will revert to free 'standard' access.
          </p>
          <p>
            You will still be able to view your invoice-receipts in your account
            settings
          </p>
          <div className="changed-activity-modal-controls page_id_account-cancel-popup-buttons">
            <Button
              color="blue"
              onClick={() => {
                this.setState({ isCancelSubscriptionOpen: false });
              }}
            >
              Go Back
            </Button>
            <Button
              color="pink"
              onClick={() => {
                this.cancelSubscription();
              }}
            >
              Cancel{this.props.layoutContext.state.isMobile && <br />} My
              Subscription
            </Button>
          </div>
        </ReactModal>
      </div>
    );
    const isFreeUser =
      !this.props.layoutContext.state.isPaidUser &&
      !this.props.layoutContext.state.isFreeAdvancedUser;

    return (
      <div className="main_wrap page_id_account">
        <h2>
          My Account Preferences
          {this.state.passwordChanged && (
            <h4
              className="password-changed-massage"
              id="password-changed-massage-id"
            >
              Your Password has been changed successfully
            </h4>
          )}
        </h2>

        {(isFreeUser || this.props.layoutContext.state.isFreeAdvancedUser) &&
          this.props.layoutContext.state.isMobile && (
            <FrameSection
              className="page_id_account-advanced-features-section"
              title={advancedFeatureBoxHeader}
              titleColor="white"
            >
              {advancedFeatureBoxContent}
            </FrameSection>
          )}

        <FrameSection
          className={
            isFreeUser
              ? "page_id_account-general-information-section-free"
              : "page_id_account-general-information-section"
          }
          title="GENERAL INFORMATION"
          titleColor="blue"
        >
          <AccountDetails
            setConnectionProblems={() => this.props.setConnectionProblems()}
            accountInfo={accountInfo}
            layoutContext={this.props.layoutContext}
          />
        </FrameSection>

        <FrameSection
          className={
            isFreeUser
              ? "page_id_account-access-information-section-free"
              : "page_id_account-access-information-section"
          }
          title="ACCESS INFORMATION"
          titleColor="blue"
        >
          <Form
            className="access-information-form"
            onSubmit={e => this.showChangePassword(e)}
          >
            <div>
              <label>Username:</label>
              <div className="username">{accountInfo.email}</div>
            </div>
            <span className="edit-button-wrapper">
              <Button color="light-blue">Change Password</Button>
            </span>
          </Form>

          <ChangePassword
            isOpen={this.state.changePasswordOpen}
            onSuccessfullClose={() => this.hideSuccessfulChangePassword()}
            onClose={() => this.hideChangePassword()}
            layoutContext={this.props.layoutContext}
          />
        </FrameSection>

        {(this.props.layoutContext.state.isFreeAdvancedUser ||
          this.props.layoutContext.state.isPaidUser) && (
          <FrameSection
            className="page_id_account-package-details-section"
            title="PACKAGE DETAILS"
            titleColor="dark-blue"
          >
            <table className="page_id_account-package-details-section-content-table">
              <tbody>
                <tr>
                  <td colSpan="2">
                    as at{" "}
                    {accountInfo.packageInfo &&
                      formatTimeAndFullDate(
                        accountInfo.packageInfo.currentDate
                      )}
                  </td>
                </tr>
                <tr>
                  <td>
                    Current period
                    <br />
                  </td>
                  <td>
                    {accountInfo.packageInfo && (
                      <>
                        <span>Start date: </span>
                        {formatShortDate(
                          accountInfo.packageInfo.periodStartDate
                        )}
                        <br />
                        <span>End date: </span>
                        {formatShortDate(accountInfo.packageInfo.periodEndDate)}
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Available SMS Alerts:</td>
                  <td>
                    {accountInfo.packageInfo &&
                      accountInfo.packageInfo.availableSms}
                  </td>
                </tr>
                <tr>
                  <td>Pending SMS Alerts:</td>
                  <td>
                    {accountInfo.packageInfo &&
                      accountInfo.packageInfo.pendingSms}
                  </td>
                </tr>
                <tr>
                  <td>Available Email Alerts:</td>
                  <td>
                    {accountInfo.packageInfo &&
                      accountInfo.packageInfo.availableEmail}
                  </td>
                </tr>
                <tr>
                  <td>Pending Email Alerts:</td>
                  <td>
                    {accountInfo.packageInfo &&
                      accountInfo.packageInfo.pendingEmail}
                  </td>
                </tr>
                <tr>
                  <td>Your 30-day SMS alert limit:</td>
                  <td>
                    {accountInfo.packageInfo &&
                      accountInfo.packageInfo.smsLimit}
                  </td>
                </tr>
                <tr>
                  <td>Your 30-day Email alert limit:</td>
                  <td>
                    {accountInfo.packageInfo &&
                      accountInfo.packageInfo.emailLimit}
                  </td>
                </tr>
              </tbody>
            </table>
            <p  className={this.props.layoutContext.state.isMobile ? "page_id_account-invoice_container" : ""}>
              <Link to="pending-alerts" className="page_id_account-invoice_link">View pending alerts</Link>
            </p>
          </FrameSection>
        )}

        {(isFreeUser || this.props.layoutContext.state.isFreeAdvancedUser) &&
          !this.props.layoutContext.state.isMobile && (
            <FrameSection
              className={
                isFreeUser
                  ? "page_id_account-advanced-features-section"
                  : "page_id_account-advanced-features-section-advanced"
              }
              title={advancedFeatureBoxHeader}
              titleColor="white"
            >
              {advancedFeatureBoxContent}
            </FrameSection>
          )}

        {(this.props.layoutContext.state.isFreeAdvancedUser ||
          this.props.layoutContext.state.isPaidUser) && (
          <FrameSection
            className="page_id_account-alert-preferences-section"
            title="ALERT PREFERENCES"
            titleColor="dark-blue"
          >
            <div className="page_id_account-alert-preferences-section-content">
              <div>
                <div>Your current preferences are:</div>
              </div>
              <br />
              <div>
                <div>Email Alerts:</div>
                <div className="break-word-text">
                  {(accountInfo.contactDetail &&
                    accountInfo.contactDetail.email) ||
                    "Not set"}
                </div>
              </div>
              <br />
              <div>
                <div>SMS Alerts:</div>
                <div>
                  {(accountInfo.contactDetail &&
                    accountInfo.contactDetail.phone) ||
                    "Not set"}
                </div>
              </div>
              <br />
            </div>
            <div className="page_id_account-package-details-section-controls">
              <Button
                color="light-blue"
                onClick={() =>
                  this.setState({ isChangeAlertContactPopUpOpen: true })
                }
              >
                Edit
              </Button>
              <ChangeAlertContacts
                isOpen={this.state.isChangeAlertContactPopUpOpen}
                onClose={() =>
                  this.setState({ isChangeAlertContactPopUpOpen: false })
                }
                onSuccessfullUpdate={this.getAccountInfo.bind(this)}
                layoutContext={this.props.layoutContext}
              />
            </div>
          </FrameSection>
        )}

        {accountInfo.invoiceHistoryDto &&
          accountInfo.invoiceHistoryDto.hasInvoiceHistory && (
            <FrameSection
              className={
                !isFreeUser
                  ? "page_id_account-payment-details-section-paid"
                  : "page_id_account-payment-details-section-free"
              }
              title="PAYMENT DETAILS"
              titleColor="dark-blue"
            >
              <span>
                {
                  subscriptionInfo[
                    this.state.accountInfo.invoiceHistoryDto.paymentRate
                  ] || "No current subscription"
                }
              </span>
              <br />

              <p>
                Next payment due before:{" "}
                {accountInfo.invoiceHistoryDto.payedPeriodEndDate &&
                  formatShortDate(
                    accountInfo.invoiceHistoryDto.payedPeriodEndDate
                  )}
              </p>

              <Link to="receipts" className="page_id_account-invoice_link">
                My Invoice-Receipts
              </Link>
            </FrameSection>
          )}

        {switchFreeTrialModeButton}

        <section
          className={
            !isFreeUser
              ? "my-account-delete-account-section-paid"
              : "my-account-delete-account-section-free"
          }
        >
          <i>
            I would like to{" "}
            <Button type="link" onClick={e => this.showDeleteAccount(e)}>
              delete
            </Button>{" "}
            my account
          </i>

          <DeleteAccount
            isOpen={this.state.deleteAccountOpen}
            onClose={() => this.hideDeleteAccount()}
            setAccountSuspended={() => this.props.setAccountSuspended()}
            invoices={
              accountInfo.invoiceHistoryDto &&
              accountInfo.invoiceHistoryDto.hasInvoiceHistory
            }
            {...this.props}
          />
        </section>
        <PaymentModal layoutContext={this.props.layoutContext} />
      </div>
    );
  }
}

export default withRouter(MyAccount);
