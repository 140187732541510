import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import ReactModal from 'react-modal';
import validator from 'validator';
import { api, setRequestForEmailExistance, isValidForm } from '../../services';
import { required, email, emailExist } from '../../validations';
import { Button } from '..';
import './password-reset.css';
import './password-reset.mobile.css';

export default class PasswordReset extends React.Component {
  getFailedAttemptError(){
    return <div className="password-reset-error">Your answer is incorrect. You have one attempt left</div>;
  };

  constructor(props){
    super(props);

    this.state = {
      securityQustions: [],
      isEmailExist: '',
      isConnectionProblem: false,
      isSuccess: false,
      tempPasswordAttemptsExceed: false,
      securityQuestionFailed : false,
      isAccountSuspended: false,
      attemptFailed: false
    }
  }

  setDefaultState() {
    this.setState({
      securityQustions: [],
      isConnectionProblem: false,
      isSuccess: false,
      tempPasswordAttemptsExceed: false,
      securityQuestionFailed: false,
      isAccountSuspended: false,
      attemptFailed: false
    });
  }

  checkEmailExistance(){
    const email = this.form.getValues()["email-address"];

    if (email && validator.isEmail(email)) {
      setRequestForEmailExistance(this.props.layoutContext, email)
        .then(res => {
          if (res.status === 200){
            res.json().then(response => this.setState({ isEmailExist: response ? 'exist' : null }))
          }
        });
    }
  }

  setRequestForQuestion(){
    this.form.validateAll();
    if(!isValidForm(this.form)) return false;
    const email = this.form.getValues()["email-address"];
    const url = '/api/customers/secret-questions?email=' + encodeURIComponent(email);

    api(this.props.layoutContext, url)
      .then(res => {
        res.status === 200
          ? res.json().then(question => this.setState({ securityQustions: question }))
          : this.setState({ isConnectionProblem: true })
      })
  }

  setPasswordChanged(){
    this.setState({ isSuccess: true });
  }

  sendAnswer(){
    this.form.validateAll();
    if(!isValidForm(this.form)) return false;

    const values = this.form.getValues();
    const email = values["email-address"];
    const answer = values["security-answer"];
    const url = '/api/customers/account-details/password/reset?email=' + encodeURIComponent(email);
    const body = {
      secretQuestionType: this.state.securityQustions[0],
      answer: answer
    };

    api(this.props.layoutContext, url, 'PUT', body)
    .then(res => {
      res.status === 200
          ? res.json().then(response => response === -1 ? this.setState({tempPasswordAttemptsExceed : true}) : this.setPasswordChanged())
          : res.json().then(response =>
              response.code === 'CSC_02' ?
                  response.description.indexOf('Incorrect answer. Attempts left:') >= 0 ?
                    this.setState({attemptFailed: true}) :
                    this.setState({securityQuestionFailed: true})
                    : response.code === 'CSC_03' ?
                        this.setState({isAccountSuspended: true}) :
                        this.setState({isConnectionProblem: true}))
    })
  }

  showSuccess() {
    return <div className="password-reset_success">
      <h2>Please check your email as a temporary password has now been sent to you. Check your spam folder if this email does not appear in your inbox. For further support please contact WMC Support.</h2>
        <Button color='blue' onClick={() =>{
          this.setDefaultState();
          this.props.onClose()}}>Continue</Button>
    </div>
  }

  showSecurityAnwerFailed() {
    return <div className="password-reset_success">
      <h2>You appear to have a problem, please call us on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a> or email us at WMCsupport@dpworld.com</h2>
      <Button color='blue' onClick={() => {
        this.setDefaultState();
        this.props.onClose()
      }}>OK</Button>
    </div>
  }

/*  showAccountSuspended() {
    return <div className="password-reset_success">
      <h2>Your account has been suspended.  Please contact our Helpdesk on 08000 469 648 for assistance.</h2>
      <Button color='blue' onClick={() => {
        this.setDefaultState();
        this.props.onClose()}}>OK</Button>
    </div>
  }*/

  showTempPasswordAttemtsExceeded() {
    return <div className="password-reset_success temp-password-attempts-exceeded-popup">
      <h2>You have requested a password re-set multiple times for Where's my
        container?</h2>
      <h2>In line with our security requirements we have suspended your
        account.</h2>
      <h2>Please contact us by calling <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a> or emailing
        WMCsupport@dpworld.com so that we can assist you.</h2>
      <Button color='blue' onClick={() => {
        this.setDefaultState();
        this.props.onClose()
      }}>OK</Button>
    </div>
  }

  showSecurityQustion(){
    const questions = this.state.securityQustions;
    if (questions.length > 0) {
      return <div className='security-questions'>
        {
          questions.map((question, i) => {
            return <div key={i}>
                <label>Security question: {question}</label>
                <Input id='password-reset-modal_security-answer-input'
                  className={this.state.attemptFailed ? "is-invalid-input" : undefined}
                  placeholder="Answer"
                  name="security-answer"
                  validations={[required]}
                />
              {this.state.attemptFailed && this.props.layoutContext.state.isMobile &&  this.getFailedAttemptError()}
            </div>
          })
        }
        <div className="security-questions__button_send">
          <Button id='password-reset-modal_send-password-button'
            color="pink"
            icon="arrow"
            onClick={() => {
              this.setState({ isConnectionProblem: false }, () => this.sendAnswer())
            }}
          >
            Send password
          </Button>
        </div>
      </div>
    }
  }

  showConnectionPropblem() {
    if (this.state.isConnectionProblem) return <div className="connection-error">
      You appear to have a problem, please call us on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a> or email us at WMCsupport@dpworld.com
    </div>
  }

  showForm() {
    const close = e => {
      e.preventDefault();
      this.setState({securityQustions:[], attemptFailed: false});
      this.props.onClose();
    };

    return <div className='password-reset'>
      <Button id="close-modal" onClick={close}>×</Button>
      <h4>Password Re-Set</h4>
      <p>Enter your email address below and answer the security question that appears. You will have up to 2 attempts to answer this question. When you have correctly answered, we will send you an email with a temporary password.</p>
      <Form
        ref={node => {
          this.form = node;
        }}
        onSubmit={event => {
          event.preventDefault();
        }}
      >
        <label>Email address</label>
        <Input id='password-reset-modal_email-input'
          name="email-address"
          validations={[required, email, emailExist]}
          isemailexist={this.state.isEmailExist}
          onKeyUp={() => this.checkEmailExistance(this.props.layoutContext)}
          maxLength={200}
          minLength={5}
        />
        <Button id='password-reset-modal_continue-link'
          type='link'
          onClick={() => {
            this.setState({ isConnectionProblem: false }, () => this.setRequestForQuestion())
          }}
        >
          Continue
        </Button>
        {this.showSecurityQustion()}
      </Form>
      {this.showConnectionPropblem()}
      {this.state.attemptFailed && !this.props.layoutContext.state.isMobile && this.getFailedAttemptError()}
    </div>
  }


  render() {
    const root = document.querySelector('react-app');
    const isOpen = this.props.isOpen;


      return <ReactModal appElement={root} isOpen={isOpen}
                         className="app-modal" >
        {
          this.state.isSuccess ? this.showSuccess()
              : this.state.tempPasswordAttemptsExceed
              ? this.showTempPasswordAttemtsExceeded()
              : this.state.securityQuestionFailed
                  ? this.showSecurityAnwerFailed()
                  : this.state.isAccountSuspended
                      ? this.showSecurityAnwerFailed() : this.showForm()
        }
      </ReactModal>;
  }
};
