import React from 'react';

export default () => <svg version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="40"
                          height="44"
                          stroke="#004070"
                          strokeWidth="3"
                          viewBox="0 0 100 100">
  <path fill="none" d="M11.3,7.7H3.9v53.6h25.9c3.6,0,6,1.8,7.4,3.7H39V18.8c0-6-3.6-11.1-11.1-11.1h-5.5"/>
  <path fill="none" d="M74.2,13.2V7.7h-24C42.7,7.7,39,12.8,39,18.8V65h1.8c1.4-2.7,3.8-3.7,7.4-3.7h25.9v-7.4"/>
  <polygon fill="none" points="11.3,26.2 16.9,22.5 22.4,26.2 22.4,4 11.3,4"/>
  <circle fill="none" cx="64.9" cy="33.6" r="16.6"/>
  <polyline fill="none" points="70.5,41 64.9,41 64.9,29.9 61.2,29.9"/>
  <line fill="none" x1="59.4" y1="41" x2="64.9" y2="41"/>
  <line fill="none" x1="64.9" y1="26.2" x2="64.9" y2="22.5"/>
</svg>;