import React from 'react';
import ReactModal from 'react-modal';
import { Redirect } from 'react-router-dom';
import {Button} from '../..';

import './delete-account-button.css'
import {api} from "../../../services";
import NotAllowedOperationModal from "../not-allowed-operation-modal/not-allowed-operation-modal";

export default class DeleteAccountButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteAccountOpen: false,
      accountIsDeletedOpen: false,
      notAllowedOperationOpen: false,
      redirectTo: ''
    };

    this.setRequestForDeleteAccount = this.setRequestForDeleteAccount.bind(this);
  }

  componentDidMount() {
    // console.log(this.props.layoutContext.state);
  }


  setRequestForDeleteAccount(){
    api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}`, "DELETE")
    .then((res) => {
      if (res.status === 200) {
        this.setState({
          deleteAccountOpen: false,
          accountIsDeletedOpen: true
        });
      } else {
        this.setState({ isConnectionProblem: true })
      }
    })
  }

  showConnectionProblem() {
    if (this.state.isConnectionProblem) return <div className="connection-error">
      Oops, something went wrong.  Account was not deleted.  Try again later.
    </div>
  }

  closeModalNotAllowedOperation() {
    this.setState({notAllowedOperationOpen: false});
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }

    return <React.Fragment>
      <Button color="pink"
              onClick={() => {
                this.setState({
                  notAllowedOperationOpen: this.props.isDataOwner,
                  deleteAccountOpen: !this.props.isDataOwner
                });
              }}
      >Delete Account</Button>

      <NotAllowedOperationModal isOpen={this.state.notAllowedOperationOpen} closeModal={this.closeModalNotAllowedOperation.bind(this)}/>

      <ReactModal appElement={document.querySelector('react-app')} isOpen={this.state.deleteAccountOpen} className="app-modal">
        <div className="delete-account-button">
       

          <h4>Are you sure you want to delete this account?</h4>


          {this.props.invoices ? (
            <React.Fragment>
              <p>
                By cancelling this account, you will no longer be able to access
                our information on your containers in our Southampton and London
                Gateway ports.
              </p>
              <p>
                As you have previous invoices with us, we are required to keep
                this information available to you for 7 years.
              </p>
              <p>
                So that we can identify you if you contact us regarding an
                invoice, we will retain enough information to identify you.
                After 7 years have passed, we will delete all information.
              </p>
            </React.Fragment>
          ) : this.props.serviceType==='Free-advanced' && (
            <React.Fragment>
              <p>
                If you delete your account now, your access to all standard and
                advanced Where’s my container features will be removed. Any
                alerts still pending on this account will be deleted and
                therefore not sent.
              </p>
              <p>
                If you are wanting instead to only use our free standard
                features, simply wait until the end of this current period and
                you will revert back to free user status.
              </p>
              
            </React.Fragment>)}

            <p>
            If you still wish to leave, simply click on the Ok button
            below.
          </p>
          <p>If you have decided to stay, just click on Cancel</p>

          <p>
            <Button color="blue"  onClick={() => {
             this.setState({
               deleteAccountOpen: false
             });
            }}>Cancel</Button>
            <Button
                color="pink"
                onClick={() => this.setState({ isConnectionProblem: false }, () => {
                  this.setRequestForDeleteAccount()
                })}
            >
              Ok
            </Button>
          </p>
        </div>
        {this.showConnectionProblem()}
      </ReactModal>

      <ReactModal appElement={document.querySelector('react-app')} isOpen={this.state.accountIsDeletedOpen} className="app-modal">
        <div className="delete-account-button">
          <h4>Account is deleted</h4>
          <p>
            <Button color="blue"  onClick={() => {
              this.setState({
                accountIsDeletedOpen: false,
                redirectTo: '/administration-search'
              });
            }}>Close</Button>
          </p>
        </div>
        {this.showConnectionProblem()}
      </ReactModal>

    </React.Fragment>
  }

}