import React from 'react';
import Actions from '../container-table__actions.jsx';
import {
  getEstimatedDischargeTimePeriod,
  formatDateTime,
} from '../../../services';

export function getContainerAdditionalActions(isCurrentStatus, isPaidUser, props){
  const containerActivity = props.entry.containerStatuses ? props.entry.containerStatuses[0].activity : null;
  let additionalActions = [];
  if(isCurrentStatus && isPaidUser && props.isAvailableForSettingAlerts && containerActivity && containerActivity!=='Storage') {
    additionalActions.push({label:'Set Alert', action: () => props.setContainerNumberForSingleAlertSelection(props.entry)})
  }
  return additionalActions;
}

function getAlertStatusClass(alertStatusName) {
  switch (alertStatusName){
    case 'SENT':
      return 'container-table__status-cell_gray-alert-cell';
    case 'ACTIVE':
    case 'PROCESSING':
    case 'SUPPRESSED':
    case 'SUSPENDED':
    case 'NOT_SUBMITTED':
    case 'INACTIVE':
      return 'container-table__status-cell_blue-alert-cell';
    default:
      return '';
  }
}

function getAlertStatusSign(alertStatusName) {
  switch (alertStatusName){
    case 'SENT':
    case 'ACTIVE':
    case 'PROCESSING':
    case 'NOT_SUBMITTED':
      return  <span
      className='container-table__status-cell_hint'
      data-alerts-small="Alert set"
      data-alerts-small-at="top"
    >
      Y
    </span>;
   
    case 'SUPPRESSED':
    case 'SUSPENDED':
      return <span
      className='container-table__status-cell_hint'
      data-alerts="Alert suspended"
      data-alerts-at="top"
    >
      S
    </span> ;
    case 'INACTIVE':
      return '';
    default:
      return '';
  }
}

export function getContainerAdditionalInfo(containerStatuses, isMobile, maxLengthOfContainerStatusName, allStatusesMode, isCurrentJourney, isPaidUser) {
  const status = containerStatuses.status;
  const advancedStatusCellStyle = {width: maxLengthOfContainerStatusName * 7 +'px'};
  const getAdvancedViewAlertInfo = (item)=> <div className='container-table__status-cell_advanced-view_alert-info'>
    <span className={getAlertStatusClass(item.emailAlertStatus)}>{getAlertStatusSign(item.emailAlertStatus)}</span>
    <span className={getAlertStatusClass(item.smsAlertStatus)}>{getAlertStatusSign(item.smsAlertStatus)}</span>
  </div>;

  let currentStatusClassName = status.statusType === 'On Hold' 
      ? 'container-table__status-cell_all-status-table-current-status current-status-on-hold' : status.statusType === 'On Hold - Processed' ? 'container-table__status-cell_all-status-table-current-status current-status-on-hold-processed'
      : (!isCurrentJourney && allStatusesMode) ? 'container-table__status-cell_all-status-table-past-status' : 'container-table__status-cell_all-status-table-current-status';


  const currentStatusCommonInfoAdvancedView = () => {
  return <React.Fragment>
    {status.estimateArrivalTime && <tr className={currentStatusClassName}>
      <td style={advancedStatusCellStyle}>Estimated Arrival Time:</td>
      <td>{formatDateTime(status.estimateArrivalTime)}</td>
    </tr>}
    {status.estimateDepartureTime && <tr className={currentStatusClassName}>
      <td style={advancedStatusCellStyle}>Estimated Departure Time:</td>
      <td>{formatDateTime(status.estimateDepartureTime)}</td>
    </tr>}
    {status.estimateDischargeTime && <tr className={currentStatusClassName}>
      <td style={advancedStatusCellStyle}>Estimated Discharge Time:</td>
      <td>{getEstimatedDischargeTimePeriod(status.estimateDischargeTime)}</td>
    </tr>}
    {status.dischargedTime && <tr className={currentStatusClassName}>
      <td style={advancedStatusCellStyle}>Discharged:</td>
      <td>{formatDateTime(status.dischargedTime)}</td>
    </tr>}
    {status.arrivedAtPortTime && <tr className={currentStatusClassName}>
      <td style={advancedStatusCellStyle}>Arrived at Port:</td>
      <td>{formatDateTime(status.arrivedAtPortTime)}</td>
    </tr>}
  </React.Fragment>};

  if(isPaidUser === true && !isMobile){
  //desktop paid user view
    return <table className="container-table__status-cell_all-status-table">
      <tbody>
      {allStatusesMode && containerStatuses.futureStatuses && containerStatuses.futureStatuses.slice().reverse().map(
          item =>
              <tr key={item.statusType + item.statusTime}
                  className={item.statusText === 'Yes' ? 'container-table__status-cell_all-status-table-grayed-out' : 'container-table__status-cell_all-status-table-future-status'}>
                <td style={advancedStatusCellStyle}>{item.statusType + ':'}</td>
                <td>{(item.statusTime && formatDateTime(item.statusTime)) || (item.statusText)}</td>
                <td className='container-table__status-cell_all-status-table-alert-cell'>
                  <span className={getAlertStatusClass(item.emailAlertStatus)}>{getAlertStatusSign(item.emailAlertStatus)}</span>
                  <span className={getAlertStatusClass(item.smsAlertStatus)}>{getAlertStatusSign(item.smsAlertStatus)}</span>
                </td>
              </tr>
      )}
      <tr className={currentStatusClassName}>
        <td style={advancedStatusCellStyle}>
          {status.statusType + ':'}
        </td>
        <td>{status.statusTime && formatDateTime(status.statusTime)}</td>
        <td className='container-table__status-cell_all-status-table-alert-cell'>
          <span className={getAlertStatusClass(status.emailAlertStatus)}>{getAlertStatusSign(status.emailAlertStatus)}</span>
          <span className={getAlertStatusClass(status.smsAlertStatus)}>{getAlertStatusSign(status.smsAlertStatus)}</span>
        </td>
      </tr>

      {status.location && <tr className={currentStatusClassName}>
        <td style={advancedStatusCellStyle}>Location:</td>
        <td>{status.location}</td>
      </tr>}

      {currentStatusCommonInfoAdvancedView()}
      {allStatusesMode && containerStatuses.pastStatuses && containerStatuses.pastStatuses.map(
          item =>
              <tr key={item.statusType + item.statusTime}
                  className="container-table__status-cell_all-status-table-past-status">
                <td style={advancedStatusCellStyle}>{item.statusType + ':'}</td>
                <td>{(item.statusTime && formatDateTime(item.statusTime)) || (item.statusText)}</td>
                <td className='container-table__status-cell_all-status-table-alert-cell'>
                  <span className={getAlertStatusClass(item.emailAlertStatus)}>{getAlertStatusSign(item.emailAlertStatus)}</span>
                  <span className={getAlertStatusClass(item.smsAlertStatus)}>{getAlertStatusSign(item.smsAlertStatus)}</span>
                </td>
              </tr>)
      }
      </tbody>
    </table>
  } else {
    const commonInfo = <React.Fragment>
      <div><span className={isPaidUser && 'container-table__status-cell_advanced-view_status-value'}>{status.statusType + ':'} {status.statusTime
      && formatDateTime(status.statusTime)}</span>{getAdvancedViewAlertInfo(status)}</div>
        <span className={isPaidUser && 'container-table__status-cell_advanced-view_additional-info'}>
          <React.Fragment>
            {status.location && <div>Location: {status.location}<br/>
            </div>}
            {status.estimateArrivalTime && <div>Estimated Arrival
              Time: {formatDateTime(status.estimateArrivalTime)}</div>}
            {status.estimateDepartureTime && <div>Estimated Departure
              Time: {formatDateTime(status.estimateDepartureTime)}</div>}
            {status.estimateDischargeTime && <div>Estimated Discharge
              Time: {getEstimatedDischargeTimePeriod(
                  status.estimateDischargeTime)}</div>}
          </React.Fragment>

          {status.dischargedTime && <div>
            Discharged: {formatDateTime(status.dischargedTime)} <br/>
          </div>}
          {status.arrivedAtPortTime && <div>
            Arrived at Port: {formatDateTime(status.arrivedAtPortTime)} <br/>
          </div>}
        </span>
    </React.Fragment>;


   if (isMobile && isPaidUser) {
    return <React.Fragment>

      <div className='container-table__status-cell_advanced-mode-column-header'>
        <span className='container-table__status-cell_advanced-mode-alert-header'>
          <span>Alerts</span>
          <span>Email</span>
          <span>SMS</span>
        </span>
      </div>

      <div className="container-card_advanced-view_container-statuses-container">
        {allStatusesMode && containerStatuses.futureStatuses && containerStatuses.futureStatuses.slice().reverse().map(
            item =>
                <div key={item.statusType + item.statusTime}
                     className={item.statusText === 'Yes' ? 'container-table__status-cell_all-status-table-grayed-out' : 'container-table__status-cell_all-status-table-future-status'}>
                  <div><span className='container-table__status-cell_advanced-view_status-value'>{item.statusType + ':'} {(item.statusTime && formatDateTime(item.statusTime)) || (item.statusText)}</span>{getAdvancedViewAlertInfo(item)}</div>
                </div>)
        }
        <div className={currentStatusClassName}>
          {commonInfo}
        </div>
          {allStatusesMode && containerStatuses.pastStatuses && containerStatuses.pastStatuses.map(
              item =>
                  <div key={item.statusType + item.statusTime}
                      className="container-table__status-cell_all-status-table-past-status">
                    <div><span className='container-table__status-cell_advanced-view_status-value'>{item.statusType + ':'} {(item.statusTime && formatDateTime(item.statusTime)) || (item.statusText)}</span>{getAdvancedViewAlertInfo(item)}</div>
                  </div>)
          }
      </div>
    </React.Fragment>
  } else {
    //desktop/mobile normal view
    return {...commonInfo}
  }}
}

export function getContainerActions(actionsList, additionalActionsList, portName, onAction) {
  return (actionsList || additionalActionsList.length > 0) &&
      <Actions
          onToggle={action => onAction(action)}
          actions={actionsList}
          additionalActions={additionalActionsList}
          port={portName}
      />
}

export default (props, maxLengthOfContainerStatusName, iconFileName, actionsList, additionalActionsList, portName, allStatusesMode, isPaidUser) => <td colSpan={isPaidUser ? 2 : 1}
    className={'container-table__status-cell'}>
  <div className='container-table__status-cell-icon'>
    <img src={require('./../icon/' + iconFileName)} alt=''/>
  </div>
  {props.entry && props.entry.status && <React.Fragment>
    <div className="container-table__status-cell_info">
      {getContainerAdditionalInfo(props.entry, false, maxLengthOfContainerStatusName, allStatusesMode, props.isCurrentJourney, isPaidUser)}
    </div>
    {getContainerActions(actionsList, additionalActionsList, portName, props.onAction)}
  </React.Fragment>}
  {props.entry === null && <div className="container-table__status-cell_info"><p>Unknown container</p></div>}
</td>;