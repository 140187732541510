import React, { Component } from 'react';
import { Button } from '..';
import ReactModal from 'react-modal';
import './notification.css';

export default class Notification extends Component {

  isCrossDisplay() {
    return (
      this.props.notClosable !== undefined
        ? false
        : true
    )
  }

  render() {
    const root = document.querySelector('react-app');
    const isCross =this.isCrossDisplay();
    return <ReactModal
      appElement={root}
      isOpen={this.props.isOpen}
      className='app-modal'
    >
      {isCross && <Button id="close-modal" onClick={() => this.props.onClose()}>×</Button> }
      
      <div className='notification'>
        {this.props.text && 
        <React.Fragment>
          <h4>{this.props.text}</h4>
        </React.Fragment>}
        {this.props.children}
      </div>
    </ReactModal>
  }
}
