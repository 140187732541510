import {signInSection} from './sign-in_section';
import {dashboardPortNotificationsSection} from "./dashboard-port_notifications_section";
import {dashboardComingSoonSection} from "./dashboard-coming_soon_section";
import {dashboardCriticalAlertSection} from "./dashboard-critical_alert_section";
import {getDefaultCmsComponentByLocation} from './get-default-cms-component';
import {faqDashboardSection} from './faq-dashboard-section';
import sanitizeHtml from 'sanitize-html';
import {faqPageItemSection} from "./faq-page-item-section";

export function getCmsComponentByLocation(componentLocation, componentContent){

  //if componentContent is null - return default component
  if(!componentContent) {
    return getDefaultCmsComponentByLocation(componentLocation);
  }

  //sanitize content from CMS
  if(componentContent.content){
    componentContent.content = sanitizeHtml(componentContent.content, {
      allowedTags: ['strong', 'em', 'u', 's', 'sub', 'sup', 'ol', 'li','ul', 'a', 'p', 'br'],
      allowedAttributes: {
        'a': [ 'href', 'id', 'name', 'target' ]
      },
    });
  }

  let componentObjectToInsert;
  switch (componentLocation) {
    case 'Sign In':
      componentObjectToInsert = signInSection(componentContent);
      break;
    case 'Coming Soon':
      componentObjectToInsert = dashboardComingSoonSection(componentContent);
      break;
    case 'Port Status':
      componentObjectToInsert = dashboardPortNotificationsSection(componentContent);
      break;
    case 'Critical Alerts (LGPL)':
    case 'Critical Alerts (DPWS)':
      componentObjectToInsert = dashboardCriticalAlertSection(componentContent);
      break;
    case 'FAQ Item':
      componentObjectToInsert = faqPageItemSection(componentContent);
      break;
    case 'FAQ Dashboard':
      componentObjectToInsert = faqDashboardSection(componentContent);
      break;
    default:
      componentObjectToInsert = [];
  }

  return {regionName: componentContent.region.name,
    regionItemLimit: componentContent.region.itemLimit,
    displayOrder: componentContent.displayOrder,
    published: componentContent.published,
    content: componentObjectToInsert
  }
}