import React from 'react';
import {formatDateTime} from "../../services";

export function dashboardCriticalAlertSection(componentContent) {
  if (componentContent !== '' && componentContent.title !== '') {
    let port = getPort(componentContent.region.name);
    let date = formatDateTime(new Date(componentContent.published));
    return <span><b>{port}</b>{componentContent.title + ' ' + date}</span>;
  }
}

function getPort(regionName) {
  switch (regionName) {
    case 'Critical Alerts (LGPL)':
      return 'London Gateway: ';
    case 'Critical Alerts (DPWS)':
      return 'Southampton: ';
    default:
      return ''
  }
}