import React from 'react';
import Cookies from 'browser-cookies';
import { Redirect } from 'react-router-dom';
import {SERVICE_TYPE} from '../../services/index'

export default class AuthorizationWrapper extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      authorities: JSON.parse(Cookies.get('authorities')),
      serviceType: JSON.parse(Cookies.get(SERVICE_TYPE))
    }
  }

  hasAuthorities(authorities) {
    if (this.props.allowedAuthorities) {
      return authorities !== null && this.props.allowedAuthorities.some(
          e => authorities.includes(e));
    }
    return true;
  }

  hasServiceType(serviceType) {
    if(this.props.allowedServiceTypes){
      return serviceType !== null && this.props.allowedServiceTypes.some(
          e => serviceType === e);
    }
    return true;
  }

  render() {
    const {authorities, serviceType} = this.state;
    return this.hasAuthorities(authorities) && this.hasServiceType(serviceType) ? this.props.children : <Redirect to='/login'/>;
  }
}