import React, { Component } from "react";
import styles from "./Styles.module.scss";
import PropTypes from "prop-types";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";

import { api, isValidForm } from "../../services/index";
import { required, email, phoneCheck } from "../../validations";

import FrameSection from "../frame-section/frame-section";
import Button from "../button/button";
import {Context} from '../app/context/context';
import { LayoutContext } from "../app/context/layout-context";
import requiredValidationMessages from "../../validations/required-validation-messages";

import logoVisa from "../../static/images/logo-visa.png";
import logoMastercard from "../../static/images/logo-mastercard.png";
import logoMaestro from "../../static/images/logo-maestro.jpg";
import logoAmex from "../../static/images/logo-amex.png";
import logoJCB from "../../static/images/logo-jcb.png";
import ReactGA from "react-ga";

const PaymentNames = {
  "Single payment": "1 X 30 day period",
  "Recurring payment": "1 X 30 day period recurring"
};

class Payment extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);

    this.state = {
      postCodeValidation: true,
      paymentRates: [{ name: "", amount: 0 }],
      countries: [],
      sageUrl: null,
      firstName: null,
      lastName: null,
      company: null,
      address1: null,
      address2: null,
      city: null,
      country: null,
      state: null,
      postCode: "",
      emailAddress: null,
      phone: null,
      paymentRate: null,
      orderNumber: null,
      policy: false,
      isOrder: false,
      VPSProtocol: "3.00",
      TxType: "PAYMENT",
      Vendor: null,
      Crypt: null,
      currentPayment: {}
    };
  }

  checked = () => {
    if (!this.state.policy) {
      return " ";
    }
  };

  postCodeValidation = (value, props) => {
    if (
      this.state.postCodeValidation &&
      (!value ||
        (props.isUsed && !value) ||
        (props.isCheckable && !props.checked))
    ) {
      return (
        <span className="form-error is-visible">
          {requiredValidationMessages[props.name]}
        </span>
      );
    }
  };

  filterInput(e, key, pattern) {
    this.setState({
      [key]: e.target.value.replace(pattern, "")
    });
  }

  handleInput = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
    if (name === "country") {
      this.state.countries.map(item => {
        if (item.country === value) {
          this.setState({ postCodeValidation: item.hasPostcode });
        }
        return null;
      });
    }
  };

  toOrder = event => {
    event.preventDefault();
    this.form.validateAll();
    if (isValidForm(this.form)) {
      this.setState({
        isOrder: true
      });
      this.props.refreshToken();
      window.scrollTo(0, 0);
      api(LayoutContext, "/api/payment", "POST", {
        address1: this.state.address1,
        address2: this.state.address2 === "" ? null : this.state.address2,
        city: this.state.city,
        company: this.state.company === "" ? null : this.state.company,
        country: this.state.country,
        email: this.state.emailAddress,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        paymentRate: this.state.paymentRate,
        phone: this.state.phone,
        postcode: this.state.postCode === "" ? null : this.state.postCode,
        purchaseOrderNumber:
          this.state.orderNumber === "" ? null : this.state.orderNumber,
        state: this.state.state
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.setState({
            Crypt: data.crypt,
            Vendor: data.vendor,
            sageUrl: data.registrationUrl,
            currentPayment: data.paymentRate
          });
        });
      ReactGA.event({
          category: 'Payment',
          action: 'Clicked On Next ',
          label :"Navigated to Payment Section"
        })
   }
  };

  componentDidMount() {
    api(LayoutContext, "/api/payment")
      .then(response => {
        return response.json();
      })
      .then(data => {
        this.setState({
          countries: data.countries,
          paymentRates: data.paymentRates
        });
      });
  }

  render() {
    const CheckBox = (
      <div
        className={styles.frame + ' ' + styles.checkboxContainer }
      >
        <Input
          type="checkbox"
          onBlur={() => this.form2.validate("policy")}
          name="policy"
          onChange={e => this.setState({ policy: e.target.checked })}
          checked={this.state.policy}
          validations={[this.checked]}
        />
        <span>
          &nbsp;&nbsp;I agree to the website <a href="/terms-of-use-policy" target="_blank">Terms of Use</a> and{" "}
          <a href="/privacy-policy" target="_blank">privacy</a> policies
        </span>
        <Input
          type="hidden"
          name="VPSProtocol"
          value={this.state.VPSProtocol}
          validations={[required]}
        />
        <Input
          type="hidden"
          name="TxType"
          value={this.state.TxType}
          validations={[required]}
        />
        <Input
          type="hidden"
          name="Vendor"
          value={this.state.Vendor}
          validations={[required]}
        />
        <Input
          type="hidden"
          name="Crypt"
          value={this.state.Crypt}
          validations={[required]}
        />
      </div>
    );
    const BillngDetails = (
      <FrameSection
        className={styles.frame + " " + styles.leftFrame}
        title={"BILLING DETAILS"}
        titleColor={"blue"}
        uncollpsible
      >
        <div className={styles.leftFrameContent}>
          <div className={styles.formRow}>
            <span className={styles.formName}>First Name:</span>
            {this.state.isOrder ? (
              <span>{this.state.firstName}</span>
            ) : (
              <Input
                onBlur={() => this.form.validate("firstName")}
                className={styles.input}
                name="firstName"
                value={this.state.firstName || ""}
                onChange={e => {
                  this.filterInput(
                    e,
                    "firstName",
                    /[^A-Za-zÀ-ÖØ-öø-ÿ\s\d'&\\/,.-]/g
                  );
                }}
                validations={[required]}
                maxLength={20}
                minLength={1}
              />
            )}
          </div>
          <div className={styles.formRow}>
            <span className={styles.formName}>Last Name:</span>
            {this.state.isOrder ? (
              <span>{this.state.lastName}</span>
            ) : (
              <Input
                onBlur={() => this.form.validate("lastName")}
                className={styles.input}
                name="lastName"
                value={this.state.lastName || ""}
                onChange={e => {
                  this.filterInput(
                    e,
                    "lastName",
                    /[^A-Za-zÀ-ÖØ-öø-ÿ\s\d'&\\/,.-]/g
                  );
                }}
                validations={[required]}
                maxLength={20}
                minLength={1}
              />
            )}
          </div>

          {(!this.state.isOrder || this.state.company) && (
            <div className={styles.formRow}>
              <span className={styles.formName}>Company:</span>
              {this.state.isOrder ? (
                <span>{this.state.company}</span>
              ) : (
                <Input
                  className={styles.input}
                  name="company"
                  placeholder="Optional"
                  value={this.state.company || ""}
                  onChange={e => {
                    this.filterInput(
                      e,
                      "company",
                      /[^A-Za-z\s\d_"‘'~`!@#$£%*^&()={}[\]\\/:;,.<>+?-]/g
                    );
                  }}
                  maxLength={200}
                />
              )}
            </div>
          )}
          <div className={styles.formRow}>
            <span className={styles.formName}>Address 1:</span>
            {this.state.isOrder ? (
              <span>{this.state.address1}</span>
            ) : (
              <Input
                onBlur={() => this.form.validate("address1")}
                className={styles.input}
                name="address1"
                value={this.state.address1 || ""}
                onChange={e => {
                  this.filterInput(
                    e,
                    "address1",
                    /[^A-Za-zÀ-ÖØ-öø-ÿ\s\d‘'&()\\/:,.+-]/g
                  );
                }}
                validations={[required]}
                maxLength={100}
                minLength={1}
              />
            )}
          </div>
          {(!this.state.isOrder || this.state.address2) && (
            <div className={styles.formRow}>
              <span className={styles.formName}>Address 2:</span>
              {this.state.isOrder ? (
                <span>{this.state.address2}</span>
              ) : (
                <Input
                  className={styles.input}
                  name="address2"
                  placeholder="Optional"
                  value={this.state.address2 || ""}
                  onChange={e => {
                    this.filterInput(
                      e,
                      "address2",
                      /[^A-Za-zÀ-ÖØ-öø-ÿ\s\d'&()\\/:,.+-]/g
                    );
                  }}
                  maxLength={100}
                  minLength={1}
                />
              )}
            </div>
          )}
          <div className={styles.formRow}>
            <span className={styles.formName}>City:</span>
            {this.state.isOrder ? (
              <span>{this.state.city}</span>
            ) : (
              <Input
                onBlur={() => this.form.validate("city")}
                className={styles.input}
                name="city"
                value={this.state.city || ""}
                onChange={e => {
                  this.filterInput(
                    e,
                    "city",
                    /[^A-Za-zÀ-ÖØ-öø-ÿ\s\d'‘&()\\/:,.+-]/g
                  );
                }}
                validations={[required]}
                maxLength={40}
                minLength={1}
              />
            )}
          </div>
          <div className={styles.formRow}>
            <span className={styles.formName}>Country:</span>
            {this.state.isOrder ? (
              <span>{this.state.country}</span>
            ) : (
              <Select
                onBlur={() => this.form.validate("country")}
                name="country"
                className={styles.input}
                value={this.state.country || ""}
                validations={[required]}
                onChange={this.handleInput}
              >
                <option value="">Please select your country</option>
                {this.state.countries.map((item, i) => {
                  if (item.country === "United Kingdom") {
                    return (
                      <React.Fragment key={'contry_fragment-'+i}>
                        <option key={i} value={item.country}>
                          {item.country}
                        </option>
                        <option disabled>
                          ──────────────────────────────────
                        </option>
                      </React.Fragment>
                    );
                  } else {
                    return (
                      <option key={i} value={item.country}>
                        {item.country}
                      </option>
                    );
                  }
                })}
              </Select>
            )}
          </div>
          {this.state.country === "United States" && (
            <div className={styles.formRow}>
              <span className={styles.formName}>State:</span>
              {this.state.isOrder ? (
                <span>{this.state.state}</span>
              ) : (
                <Select
                  onBlur={() => this.form.validate("state")}
                  name="state"
                  className={styles.input}
                  value={this.state.state || ""}
                  validations={[required]}
                  onChange={this.handleInput}
                >
                  <option value="">Please select your state</option>
                  {this.state.countries.map(item => {
                    if (item.country === this.state.country) {
                      return item.states.map((state, i) => {
                        return (
                          <option key={i} value={state}>
                            {state}
                          </option>
                        );
                      });
                    } else return null;
                  })}
                </Select>
              )}
            </div>
          )}
          {(this.state.isOrder && this.state.postCode === "") || (
            <div className={styles.formRow}>
              <span className={styles.formName}>Postcode:</span>
              {this.state.isOrder ? (
                <span>{this.state.postCode}</span>
              ) : (
                <Input
                  onBlur={() => this.form.validate("postCode")}
                  className={styles.input}
                  name="postCode"
                  value={this.state.postCode || ""}
                  onChange={e => {
                    this.filterInput(e, "postCode", /[^A-Za-z\s\d-]/g);
                  }}
                  validations={[this.postCodeValidation]}
                  maxLength={10}
                  minLength={1}
                />
              )}
            </div>
          )}
          <div className={styles.formRow}>
            <span className={styles.formName}>Email Address:</span>
            {this.state.isOrder ? (
              <span>{this.state.emailAddress}</span>
            ) : (
              <Input
                onBlur={() => this.form.validate("emailAddress")}
                className={styles.input}
                name="emailAddress"
                value={this.state.emailAddress || ""}
                onChange={e => {
                  this.filterInput(e, "emailAddress", /[^\w@\-.]/g);
                }}
                validations={[required, email]}
                maxLength={200}
                minLength={1}
              />
            )}
          </div>
          <div className={styles.formRow}>
            <span className={styles.formName}>Phone:</span>
            {this.state.isOrder ? (
              <span>{this.state.phone}</span>
            ) : (
              <Input
                onBlur={() => this.form.validate("phone")}
                className={styles.input}
                name="phone"
                value={this.state.phone || ""}
                onChange={e => {
                  this.filterInput(e, "phone", /[^\d()+]/g);
                }}
                validations={[required, phoneCheck]}
                maxLength={20}
              />
            )}
          </div>
        </div>
      </FrameSection>
    );
    return (
      <div className={styles.mainframe}>
        <h2 className={styles.header}>
          {this.state.isOrder ? "Order Summary" : "Choose Your Subscription"}
        </h2>
        <Form
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) this.toOrder(event);
          }}
        >
          {!this.state.isOrder && (
            <div>
              <div className={styles.flex}>
                <div className={styles.col}>{BillngDetails}</div>
                <div className={styles.col + " " + styles.rightCol}>
                  {/* If you need a description of the subscription types  */}
                  <div className={styles.frame + " " + styles.info}>
                  <p style={{ fontSize: "20px", fontWeight: "800" }}>
                    There are two types of subscription you can choose from.
                  </p>
                  <p>
                    You can purchase one 30-day period and before that expires
                    you need to make your next payment or revert back to the
                    free standard website access
                  </p>
                  <p style={{ textAlign: "center" }}>OR</p>
                  <p>
                    You can purchase a recurring subscription that will continue
                    to charge your card every 30 days until you notify us
                    otherwise
                  </p>
                </div>
                  <FrameSection
                    className={styles.frame}
                    title={"SUBSCRIPTION TYPE"}
                    titleColor={"yellow"}
                    uncollpsible
                  >
                    <p style={{ paddingBottom: "15px" }}>
                      You are purchasing access to our advanced features for -
                    </p>
                    <table style={{ width: "100%" }}>
                      <tbody>
                        {this.state.paymentRates.map((item, i) => {
                          return (
                            <tr key={i}>
                              <td>
                                <label htmlFor="item.name">
                                  {PaymentNames[item.name]}
                                </label>
                              </td>
                              <td>
                                <input
                                  type="radio"
                                  id="item.name"
                                  name="paymentRate"
                                  value={item.name}
                                  onChange={this.handleInput}
                                  checked={this.state.paymentRate === item.name}
                                />
                                <label
                                  style={{ marginLeft: "10px" }}
                                  htmlFor="type1"
                                >
                                  £{item.amount}
                                  {item.name==="recurring.30days.payment.amount" && " per period"}
                                </label>
                              </td>
                              <td />
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </FrameSection>
                  <div className={styles.frame + " " + styles.addinfo}>
                    <div className={styles.formRow}>
                      <span style={{ fontWeight: "100" }}>
                        Purchase Order Number:
                      </span>
                      <input
                        className={styles.input}
                        placeholder="Optional"
                        name="orderNumber"
                        value={this.state.orderNumber || ""}
                        onChange={e => {
                          this.filterInput(
                            e,
                            "orderNumber",
                            /[^A-Za-z\d()\\/-]/g
                          );
                        }}
                        maxLength={20}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.buttonRow}>
                <div style={{ fontSize: "14px" }} className={styles.frame}>
                  <b>NOTE:</b> Payments for Where’s my container are collected
                  by CNS on behalf of DP World ports in the UK
                </div>

                <div className={styles.frame + " " + styles.buttonContainer}>
                  <Button
                    className={styles.button}
                    color={"blue"}
                    onClick={() => this.context.router.history.goBack()}
                  >
                    Cancel
                  </Button>
                  <Button
                    isFormValidationRequired
                    disabled={!this.state.paymentRate}
                    className={styles.button}
                    color={"pink"}
                    onClick={this.toOrder}
                    icon="arrow"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Form>

        <Form
          action={this.state.sageUrl}
          method="POST"
          ref={node1 => {
            this.form2 = node1;
          }}
          onKeyDown={event => {
            if (event.keyCode === 13) {
              this.form.validateAll();
              if (!isValidForm(this.form2)) event.preventDefault();
            }
          }}
        >
          {this.state.isOrder && (
            <div>
              <div className={styles.flex}>
                <div className={styles.col}>{BillngDetails}{!this.props.layoutContext.state.isMobile && CheckBox}</div>
                <div className={styles.col}>
                  <FrameSection
                    className={styles.frame}
                    title={"YOU ARE PURCHASING -"}
                    titleColor={"blue"}
                    uncollpsible
                  >
                    <div className={styles.leftFrameContent}>
                      <div className={styles.formRow}>
                        <span className={styles.formName}>Description:</span>
                        <span>Where's My Container Subscription</span>
                      </div>
                      <div className={styles.formRow}>
                        <span className={styles.formName}>Period:</span>
                        <span>
                          {PaymentNames[this.state.currentPayment.name]}
                        </span>
                      </div>
                      <div className={styles.formRow}>
                        <span className={styles.formName}>Item Sub-Total:</span>
                        <span>£{this.state.currentPayment.amount}</span>
                      </div>
                      <div className={styles.formRow}>
                        <span className={styles.formName}>
                          VAT {" "}
                          {this.state.currentPayment.vatPercent}:
                        </span>
                        <span>£{this.state.currentPayment.vatAmount}</span>
                      </div>
                      <div className={styles.formRow}>
                        <span className={styles.formName}>Total:</span>
                        <span>£{this.state.currentPayment.totalAmount}</span>
                      </div>
                      {this.state.orderNumber && (
                        <div className={styles.formRow}>
                          <span className={styles.formName}>
                            Purchase Order Number:
                          </span>
                          <span>{this.state.orderNumber}</span>
                        </div>
                      )}
                      <div className={styles.formRow}>
                        <span className={styles.formName}>We accept:</span>
                        <span>
                          <img
                            className={styles.logo}
                            src={logoVisa}
                            alt="visa"
                          />
                          <img
                            className={styles.logo}
                            src={logoMastercard}
                            alt="mastercard"
                          />
                          <img
                            className={styles.logo}
                            src={logoMaestro}
                            alt="maestro"
                          />
                          <img
                            className={styles.logo}
                            src={logoAmex}
                            alt="American express"
                          />
                          <img
                            className={styles.logo}
                            src={logoJCB}
                            alt="JCB"
                          />
                        </span>
                      </div>
                    </div>
                  </FrameSection>

                  <div className={styles.frame + " " + styles.info}>
                    <p>
                      By clicking “Submit your order” you agree that we will
                      make your purchase available immediately and that you will{" "}
                      <b>not</b> be able to cancel it afterwards and receive a
                      refund. Payments are non-refundable and we do not provide
                      refunds or credit for any partial-periods.
                    </p>
                    <p>
                      You can cancel your subscription at any time via the My
                      Account screen. Once cancellation is requested, your
                      advanced access will cease at the end of that paid for
                      period.
                    </p>
                  </div>
                  {this.props.layoutContext.state.isMobile && CheckBox}
                </div>
              </div>

              <div className={styles.frame + " " + styles.buttonContainer}>
                <Button
                  className={styles.button}
                  color={"blue"}
                  onClick={event => {
                    event.preventDefault();
                    window.scrollTo(0, 0);
                    this.setState({
                      isOrder: false,
                      Vendor: null,
                      Crypt: null,
                      policy: false
                    });
                  }}
                >
                  Back
                </Button>
                <Button
                  isFormValidationRequired
                  className={styles.button}
                  color={"pink"}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default props => (
  <LayoutContext.Consumer>
    {layoutContext => (
      <Context.Consumer>
        {context => (
          <Payment {...props} context={context} layoutContext={layoutContext} />
        )}
      </Context.Consumer>
    )}
  </LayoutContext.Consumer>
);
