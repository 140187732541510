import React from 'react';
import {Button} from '../..';

import './suspend-account.css'
import {api} from "../../../services";
import NotAllowedOperationModal from "../not-allowed-operation-modal/not-allowed-operation-modal";

export default class SuspendAccount extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      notAllowedOperationOpen: false
    };

    this.activateAccount = this.activateAccount.bind(this);
    this.suspendAccount = this.suspendAccount.bind(this);
  }

  activateAccount() {
    return api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/activation`, 'PUT', '');
  }

  suspendAccount() {
    return api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/suspend`, 'PUT', '');
  }

  closeModalNotAllowedOperation() {
    this.setState({notAllowedOperationOpen: false});
  }

  render() {
    let isUnverified = this.props.statusType !== 'Suspended' && this.props.statusType !== 'Live';
    let isSuspended = this.props.statusType === 'Suspended';

    if (isUnverified) {
      return <React.Fragment><div/></React.Fragment>
    } else {
      return <React.Fragment>
            <table className="suspend_account_buttons">
              <tbody>
              <tr>
                <td>
                  <div/>
                </td>
                <td>
                  <div className="buttonAccountActive">
                      {!isSuspended && <div className="button_account_active_disabled">
                        <Button color="green">Account Active</Button>
                      </div>
                      }
                      {isSuspended && <div>
                      <Button color="account_active_enabled"
                              onClick={() => {
                                this.activateAccount()
                                .then(res => {
                                  if (res.status === 200) {
                                    this.props.refreshCustomerDetails();
                                  }
                                })
                                .catch(err => console.warn(err));
                              }}
                      >Account Active
                      </Button></div>
                      }
                  </div>
                  <div className="buttonAccountSuspended">
                    <NotAllowedOperationModal isOpen={this.state.notAllowedOperationOpen} closeModal={this.closeModalNotAllowedOperation.bind(this)}/>

                      {isSuspended && <div className="button_account_suspended_disabled"><Button color="pink">Account Suspended</Button></div> }
                      {!isSuspended && <div>
                      <Button color="account_suspended_enabled"
                              onClick={() => {
                                if (this.props.isDataOwner) {
                                  this.setState({notAllowedOperationOpen: true});
                                } else {
                                  this.suspendAccount()
                                  .then(res => {
                                    if (res.status === 200) {
                                      this.props.refreshCustomerDetails();
                                    }
                                  })
                                  .catch(err => console.warn(err));
                                }
                              }
                              }
                      >Account Suspended
                      </Button></div>
                      }
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
      </React.Fragment>;
    }
  }
}