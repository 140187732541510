import React from 'react';
import './page-not-found.css';

export default class PageNotFound extends React.Component {
  // TODO: Styles
  render() {
    return <div className="page-not-found">
      <p>404</p>
      <p>The page you are looking for does not exist.</p>
    </div>
  }
};
