import React from 'react';
import Collapsible from 'react-collapsible';

import './faqs.css';
import './faqs.mobile.css';

export default class Faqs extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      faqitem: []
    }
  }

  componentDidMount() {
    this.props.layoutContext.getCmsComponent(['FAQ Item'], this);
  }

  getContent(questionAnswers, isCms) {
    let result = [];
    for (let index = 0; index < questionAnswers.length; index++) {
      result.push(
          this.showQuestionAndAnswer(questionAnswers[index], index, isCms));
    }
    return result;
  }

  static getContentForTrigger(question, isOpen) {
    return <React.Fragment>
      <div className='question'>{question}</div>
      <div className={isOpen ? 'is-open-arrow' : 'is-closed-arrow'}/>
    </React.Fragment>
  }

  showQuestionAndAnswer(questionAnswer, index, isCms) {
    let answerContent = isCms
        ? <span dangerouslySetInnerHTML={{__html: questionAnswer.a}}/>
        : <span>{questionAnswer.a}</span>;
    return this.props.layoutContext.state.isMobile
        ? <Collapsible key={index}
                       triggerTagName='div'
                       trigger={Faqs.getContentForTrigger(questionAnswer.q,
                           false)}
                       triggerWhenOpen={Faqs.getContentForTrigger(
                           questionAnswer.q, true)}
        >{answerContent}</Collapsible>
        : <div key={index}>
          <h4>
            <li>{questionAnswer.q}</li>
          </h4>
          {answerContent}
        </div>
  }

  static getSortedItems(items) {
    return items.sort((i1, i2) => {
      if (i1.displayOrder > i2.displayOrder) {
        return 1;
      } else if (i1.displayOrder < i2.displayOrder) {
        return -1;
      }

      if (i1.published > i2.published) {
        return -1;
      } else if (i1.published < i2.published) {
        return 1;
      }

      return 0;
    });
  }

  getCmsContent(items) {
    const sortedItems = Faqs.getSortedItems(items);
    const questionAnswers = sortedItems.map(item => item.content);
    return this.getContent(questionAnswers, true);
  }

  render() {
    const {faqitem} = this.state;

    return <div className="main_wrap page_id_faqs">
      <section title="Frequently Asked Questions">
        <h3>Frequently Asked Questions</h3>
        {faqitem.length >= 0 && this.getCmsContent(faqitem)}
      </section>
    </div>;
  }

}