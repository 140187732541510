import React from "react";
import AlertTemplate from "../activity-alert-template/activity-alert-template";
import { api, areArraysEqual } from "../../services";
import _ from "lodash";
import { Button } from "..";
import {
  IMPORT_ACTIVITY_TYPE,
  EXPORT_ACTIVITY_TYPE,
  TRANSHIPMENT_ACTIVITY_TYPE,
  RETURN_TO_SHIPPER_ACTIVITY_TYPE
} from "../activity-alert-template/templates/activityTemplates";
import { Redirect } from "react-router-dom";
import CollapsiblePageHeader from "../collapsible-page-header/collapsible-page-header";
import ReactModal from "react-modal";
import NavigationPrompt from "react-router-navigation-prompt";
import ChangeAlertContacts from "./../change-alert-contacts/change-alert-contacts";

import "./alert-templates.css";
import "./alert-templates.mobile.css";

export default class AlertTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialAlertTemplates: [],
      alertTemplates: [],
      redirectTo: "",
      currentEmail: null,
      currentSMSNumber: null,
      successfullySaved: false,
      isChangeAlertSMSEmailPopupOpen: false,
      newEmail: "",
      newEmailConfirmation: "",
      newSMSNumber: "",
      newSMSNumberConfirmation: "",
      isSavingInProgress: false
    };
  }

  componentDidMount() {
    api(this.props.layoutContext, "/api/alert-templates").then(res => {
      if (res.status === 200) {
        res.json().then(response => {
          if (response.items) {
            this.setState({
              initialAlertTemplates: [...response.items],
              alertTemplates: [...response.items]
            });
          }
        });
      }
    });
  }

  removeAlertTemplateByActivityType(activityType) {
    this.setState({
      alertTemplates: this.state.alertTemplates.filter(
        item => item.activityType !== activityType
      )
    });
  }

  alertTemplateOnClick(activityType, statusName, alertType, action) {
    const changedAlert = {
      activityType: activityType,
      containerStatusType: statusName,
      alertType: alertType
    };
    action.target.checked
      ? this.setState({
          alertTemplates: [...this.state.alertTemplates, changedAlert]
        })
      : this.removeAlertFromAlertTemplates(changedAlert);
  }

  removeAlertFromAlertTemplates(changedAlert) {
    const indexToRemove = _.findIndex(this.state.alertTemplates, changedAlert);
    return this.setState({
      alertTemplates: [
        ...(this.state.alertTemplates.splice(indexToRemove, 1) &&
          this.state.alertTemplates)
      ]
    });
  }

  saveAlertTemplates() {
    if (this.isDirty()) {
      this.setState({ isSavingInProgress: true });
      api(this.props.layoutContext, "/api/alert-templates", "POST", {
        items: this.state.alertTemplates
      }).then(res => {
        if (res.status === 200) {
          this.setState({
            successfullySaved: true,
            initialAlertTemplates: [...this.state.alertTemplates]
          });
        }
        this.setState({ isSavingInProgress: false });
      });
    }
  }

  isDirty() {
    return (
      this.state.alertTemplates.length !==
        this.state.initialAlertTemplates.length ||
      !areArraysEqual(
        this.state.alertTemplates,
        this.state.initialAlertTemplates
      )
    );
  }

  getConfirmationModal(onConfirm, onCancel) {
    return (
      <ReactModal
        appElement={document.querySelector("react-app")}
        isOpen={true}
        className="app-modal"
      >
        <div className="leaving-confirmation">
          <h4>
            You have not saved these alerts, do you still wish to leave this
            page?
          </h4>
          <div className="leaving-confirmation-buttons">
            <Button color="blue" onClick={onCancel}>
              No
            </Button>
            <Button color="pink" onClick={onConfirm}>
              Yes
            </Button>
          </div>
        </div>
      </ReactModal>
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state || this.props !== nextProps;
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    const alertTemplatesControls = (
      <div className="save-alert-templates-buttons">
        <Button
          className={
            this.props.layoutContext.state.isMobile
              ? "cancel-button-mobile"
              : "cancel-button"
          }
          onClick={e => {
            e.preventDefault();
            this.setState({ redirectTo: "/dashboard" });
          }}
          color="blue"
        >
          {this.props.layoutContext.state.isMobile
            ? "Cancel"
            : "Cancel and Return to Dashboard"}
        </Button>
        <Button
          className={
            this.props.layoutContext.state.isMobile
              ? "save-button-mobile"
              : "save-button"
          }
          disabled={!this.isDirty() || this.state.isSavingInProgress}
          onClick={e => {
            e.preventDefault();
            this.saveAlertTemplates();
            let element = document.getElementById(
              "alert-template-page-header-id"
            );
            if (element != null) {
              element.scrollIntoView();
            }
          }}
          color="pink"
        >
          Save
        </Button>
      </div>
    );

    const alertTemplatesPageHeader = "Alert Templates";
    const alertTemplatesPageHeaderText = (
      <h3 className="alert_templates_instructions">
        Select the statuses that you would regularly set alerts for on{" "}
        <b>Imports, Exports, Transhipments </b>
        and <b>Return to Shipper</b> activities and then Save.
        <br />
        From then on, when in the Container Details screen, you can simply
        select the check box to the left of your container number and click the
        <b> ‘Apply Template Alerts’</b> button at the top of the screen. The
        templates you have set up here will then be applied to your container.
      </h3>
    );

    return (
      <div className="main_wrap page_id_alert_templates">
        {this.props.layoutContext.state.isMobile ? (
          <CollapsiblePageHeader
            id="alert-template-page-header-id"
            className="alert-template__header"
            pageHeader={alertTemplatesPageHeader}
            collapsibleContent={alertTemplatesPageHeaderText}
          />
        ) : (
          <React.Fragment>
            <h2
              id="alert-template-page-header-id"
              className="alert-template__header"
            >
              {alertTemplatesPageHeader}
            </h2>{" "}
            {alertTemplatesPageHeaderText}
          </React.Fragment>
        )}

        <div
          onKeyDown={event => {
            if (event.key === "Enter") {
              event.preventDefault();
              this.saveAlertTemplates();
              let element = document.getElementById(
                "alert-template-page-header-id"
              );
              if (element != null) {
                element.scrollIntoView();
              }
            }
          }}
          className="page_id_alert_templates-content"
        >
          {this.state.successfullySaved && (
            <h4 className="alert-template-saved-message">
              Changes have been saved
            </h4>
          )}

          <NavigationPrompt disableNative={false} when={this.isDirty()}>
            {({ onConfirm, onCancel }) =>
              this.getConfirmationModal(onConfirm, onCancel)
            }
          </NavigationPrompt>

          <div className="page_id_alert_templates-content-row">
            <AlertTemplate
              isMobile={this.props.layoutContext.state.isMobile}
              activityType={IMPORT_ACTIVITY_TYPE}
              currentAlertTemplates={this.state.alertTemplates}
              onclick={this.alertTemplateOnClick.bind(this)}
              removeAlertTemplateByActivityType={this.removeAlertTemplateByActivityType.bind(
                this
              )}
            />
            <AlertTemplate
              isMobile={this.props.layoutContext.state.isMobile}
              activityType={EXPORT_ACTIVITY_TYPE}
              currentAlertTemplates={this.state.alertTemplates}
              onclick={this.alertTemplateOnClick.bind(this)}
              removeAlertTemplateByActivityType={this.removeAlertTemplateByActivityType.bind(
                this
              )}
            />
          </div>

          <div className="page_id_alert_templates-content-row">
            <AlertTemplate
              isMobile={this.props.layoutContext.state.isMobile}
              activityType={TRANSHIPMENT_ACTIVITY_TYPE}
              currentAlertTemplates={this.state.alertTemplates}
              onclick={this.alertTemplateOnClick.bind(this)}
              removeAlertTemplateByActivityType={this.removeAlertTemplateByActivityType.bind(
                this
              )}
            />
            <AlertTemplate
              isMobile={this.props.layoutContext.state.isMobile}
              activityType={RETURN_TO_SHIPPER_ACTIVITY_TYPE}
              currentAlertTemplates={this.state.alertTemplates}
              onclick={this.alertTemplateOnClick.bind(this)}
              removeAlertTemplateByActivityType={this.removeAlertTemplateByActivityType.bind(
                this
              )}
            />
          </div>
        </div>

        <div className="alert-templates__buttonRow">
          <div className="alert-templates__buttonRow_text">
            <h3 className="alert_templates_standard_contact_instructions">
              To set a standard email address and mobile phone number for alerts<br/>
              to be sent to, go to{" "}
              <span
                className="change-alert-email-sms-link"
                onClick={() => {
                  this.setState({ isChangeAlertSMSEmailPopupOpen: true });
                }}
              >
                Change Alert Email and SMS
              </span>
            </h3>
          </div>
          {alertTemplatesControls}
        </div>

        <ChangeAlertContacts
          isOpen={this.state.isChangeAlertSMSEmailPopupOpen}
          onClose={() => {
            this.setState({ isChangeAlertSMSEmailPopupOpen: false });
          }}
          layoutContext={this.props.layoutContext}
        />
      </div>
    );
  }
}
