import React from 'react';
import { Section } from '..';

import './cookie-policy.css';
import './cookie-policy.mobile.css';

export default class CookiePolicy extends React.Component {

  render() {

    return <div className="policy-page cookie_policy_main_wrap page_cookie_policy">
      <h1>COOKIE POLICY</h1>
      <Section color="white">
        <p>
        Our website <a className="site" href="https://www.wheresmycontainer.co.uk">www.wheresmycontainer.co.uk</a> (“<b>Our Website</b>”) uses cookies to help us to improve your experience when using Our Website. We also use cookies on any apps that we provide to you (as applicable). This Cookie Policy provides you with information about what cookies we use and for what purpose.
        </p>

        <h4>What are Cookies?</h4>

        <p>
          Also known as browser cookies or tracking cookies, cookies are small, often encrypted text files, located in browser directories. They are used by web developers to help users navigate their websites or apps efficiently and perform certain functions. Due to their core role of enhancing/enabling usability or site processes, <a className="site" href="http://www.allaboutcookies.org/manage-cookies/">disabling cookies</a> may prevent users from using certain websites or features within the app.
        </p>

        <p>
          Cookies are created when a user's <a className="site not-breakable-link" href="http://www.allaboutcookies.org/faqs/browser.html">browser</a> loads a particular website or app. The website or app sends information to the browser which then creates a text file which is stored on the users’ device in the browser’s directory.
        </p>

        <p>
          You can find more information about cookies at <a className="site" href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a>.
        </p>

        <h4>What Cookies do we use?</h4>

        <p>
          Cookies are categorised into 4 types:
        </p>

        <ul>
          <li><b>Strictly necessary cookies.</b> These are cookies that are required for the operation of Our Website. They include, for example, cookies that enable you to log into secure areas of Our Website. Without these cookies, services you have asked for, like shopping baskets or e-billing, cannot be provided.</li>
          <li><b>Performance cookies.</b> These cookies collect information about how visitors use Our Website, for example which web page you visit and if you get error messages from web pages. These cookies help us to improve the way Our Website works. These cookies don’t collect information that identifies a visitor. All information these cookies collect is aggregated and therefore anonymous.  They are only used to improve how a website works. Please note that third parties (including, for example, providers of external services like web traffic analysis services) may have placed performance cookies on Our Website.</li>
          <li><b>Functionality cookies.</b> These cookies allow Our Website to remember choices you make (such as your user name, language or the region you are in) and provide enhanced, more personal features. For instance, a website may be able to provide you with local weather reports or traffic news by storing in a cookie the region in which you are currently located. These cookies can also be used to remember changes you have made to text size, fonts and other parts of web pages that you can customise. They may also be used to provide services you have asked for such as watching a video or commenting on a blog. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites.</li>
          <li><b>Tracking cookies.</b> These are cookies that collect information about your browsing habits in order to make advertising relevant to you and your interests. They are also used to limit the number of times you see an advertisement as well as help measure the effectiveness of the advertising campaigns. They are usually placed by advertising networks with the website operator’s permission. They remember that you have visited a website and this information is shared with other organisations such as advertisers. </li>
        </ul>


       <h4>Consent for Cookies</h4>

        <p>By law, we are obliged to inform you about the cookies on Our Website and obtain your consent before placing any cookie files that store information on your device (with the exception of strictly necessary cookies).</p>
        <p>In order for Our Website to work properly, we use cookies. If you use Our Website, we assume you agree with our Cookie Policy. </p>

        <h4>Third Party Cookies</h4>

        <p>Third parties may also place cookies on our website or apps. This is because we have allowed them to do so, or we use their software in order to provide the website or app to you.</p>
        <p>We do not control these third parties and are not responsible for their use of your personal data that is collected through cookies on our websites or platforms. We would suggest that you read the third party's privacy policy and cookies policy to understand how they may process your personal data and what cookies are used. We will take steps to signpost the third party privacy notice or cookies policy where possible.</p>
        <p>We respect your privacy. If you have any other questions, please get in touch with us at <a className="site" href="mailto:privacy@cnsonline.net">privacy@cnsonline.net</a>.</p>

        <h4>End of Policy.</h4>

        <p>
          This is policy version is Version 1.0 dated 26th May 2023.
        </p>
        {/*<table className='cookie-policy-table adaptive-table' width="100%">*/}
        {/*  <tbody>*/}
        {/*  <tr>*/}
        {/*    <th width="8%" align="left">Cookie Name</th>*/}
        {/*    <th width="54%" align="left">Description/Purpose</th>*/}
        {/*    <th width="10%" align="left">Category</th>*/}
        {/*    <th width="38%" align="left">Duration/Persistence</th>*/}
        {/*  </tr>*/}
        {/*  <tr>*/}
        {/*    <td>lastActionTime</td>*/}
        {/*    <td>Used to save last user action time by feature of signing-out a user after 8 hours of inactivity.*/}
        {/*      Example of content: “1526646893270”*/}
        {/*    </td>*/}
        {/*    <td>Strictly Necessary</td>*/}
        {/*    <td>Expires when the browsing session ends</td>*/}
        {/*  </tr>*/}
        {/*  <tr>*/}
        {/*    <td>access_token</td>*/}
        {/*    <td>Encoded OAuth 2.0/JWT access token. Used by security feature to provide an access to secured API points.*/}
        {/*    </td>*/}
        {/*    <td>Strictly Necessary</td>*/}
        {/*    <td>Expires after 1 hour and is replaced with a new access token value</td>*/}
        {/*  </tr>*/}
        {/*  <tr>*/}
        {/*    <td>refresh_token</td>*/}
        {/*    <td>Encoded OAuth 2.0/JWT refresh token. Used by security feature to refresh an access token for a user.*/}
        {/*    </td>*/}
        {/*    <td>Strictly Necessary</td>*/}
        {/*    <td>Expires when the browsing session ends</td>*/}
        {/*  </tr>*/}
        {/*  </tbody>*/}
        {/*</table>*/}

        <div className='ie_gap'/>

      </Section>
    </div>;
  }
};
