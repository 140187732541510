import Cookies from 'browser-cookies';
import React from 'react';
import moment from 'moment';
import ReactGA from 'react-ga';
import _ from 'lodash';

const ALLOWED_IDLE_TIME = 28800000;
const SESSION_STORAGE_LOGOUT_TIMER = 'logoutTimer';
const COOKIES_LAST_ACTION_TIME='lastActionTime';
const COOKIES_EXPIRATION_DATE='cookieExpirationDate';
export const SERVICE_TYPE='service_type';

export const setUserTokens = (response) => {
  const currentTime = new Date().getTime();
  const expirationDate = currentTime + Number(response.expires_in) * 1000;
  localStorage.setItem(COOKIES_EXPIRATION_DATE, expirationDate);
  Cookies.set('access_token', response.access_token, {expires: new Date(expirationDate)});
  Cookies.set('refresh_token', response.refresh_token, {expires: new Date(expirationDate)});
  Cookies.set('authorities', JSON.stringify(response.authorities), {expires: new Date(expirationDate)});
  Cookies.set(SERVICE_TYPE, JSON.stringify(response.service_type), {expires: new Date(expirationDate)});
};

export const toggleFreeServiceTypeMode = (layoutContext, serviceTypeToSet) => {
  const currentServiceType = JSON.parse(Cookies.get(SERVICE_TYPE));
  const cookieExpirationDate = localStorage.getItem(COOKIES_EXPIRATION_DATE);
  if('Free' === currentServiceType || 'Free-advanced' === currentServiceType) {
    Cookies.set(SERVICE_TYPE, JSON.stringify(serviceTypeToSet), {expires: new Date(Number(cookieExpirationDate))});
    layoutContext.checkServiceType();
  }
};

export const toggleServiceTypeMode = (layoutContext, serviceTypeToSet) => {
  const cookieExpirationDate = localStorage.getItem(COOKIES_EXPIRATION_DATE);
  Cookies.set(SERVICE_TYPE, JSON.stringify(serviceTypeToSet), {expires: new Date(Number(cookieExpirationDate))});
  layoutContext.checkServiceType();
};

export const removeCookies = () => {
  Cookies.erase("refresh_token");
  Cookies.erase("access_token");
  Cookies.erase("authorities");
  Cookies.erase(COOKIES_LAST_ACTION_TIME);
};

export const logout = () => {
  removeCookies();
  window.location.replace("/login");
};

export const cleanForm = (form) => {
  const labels = Object.keys(form.state["byName"]);
  labels.forEach(element => {
    delete form.state["byId"][form.state["byName"][element]];
    form.state["byName"][element] = [];
  });
};

export const api = (layoutContext, url = '/api', method = 'GET', body = {}) => {
  const isLoginRequest = url.includes('/api/oauth/token');
  return fetch(url, {
      method: method,
      headers: {
        'Accept': '*/*',
        'Content-Type': isLoginRequest ? 'application/x-www-form-urlencoded' : 'application/json',
        'X-Requested-With':'XMLHttpRequest',
        'Authorization': authorizationHeader(url, method)
      },
      body: method === 'GET' ? undefined :  isLoginRequest ? body.toString() : JSON.stringify(body)
    })
    .then(response => {
      if(response.status === 401 && !isLoginRequest){
        logout();
      }
      else if (response.status >= 500) {
        if(response.url.includes('oauth/token?grant_type=refresh_token')) {
          logout();
        }
        layoutContext.setConnectionProblems(true);
        return Promise.reject('Internal error');
      }
      else return response;
    })
    .catch();
};

export const authorizationHeader = (url, method) => {
  if (url.startsWith('/api/oauth/token')) {
    return 'Basic YXBpOg==';
  } else if (
      url.startsWith('/api/type/') ||
      url.startsWith('/api/emails/') ||
      (url === '/api/customers' && method === 'POST') ||
      url.startsWith('/api/customers/secret-questions') ||
      url.startsWith('/api/customers/account-details/password/reset') ||
      url.startsWith('/api/contact-us') ||
      url.startsWith('/api/captcha/site-key') ||
      url.startsWith('/content')
    ) {
    return null;
  } else {
    checkCookiesLastActionTime();
    createOrUpdateLogoutTimer();
    return 'Bearer ' + Cookies.get('access_token');
  }
};

const checkCookiesLastActionTime = () => {
  let lastActionTime = Cookies.get(COOKIES_LAST_ACTION_TIME);
  let idleTime = Date.now() - (lastActionTime === null ? Date.now() : new Date(Number(lastActionTime)));
  if(idleTime > ALLOWED_IDLE_TIME){
    logout();
  }
  else Cookies.set(COOKIES_LAST_ACTION_TIME, Date.now().toString(), {expires: new Date(Date.now() + ALLOWED_IDLE_TIME)});
};

const createOrUpdateLogoutTimer = () => {
    let lastTimerId = sessionStorage.getItem(SESSION_STORAGE_LOGOUT_TIMER);
    if (lastTimerId !== null) {
      clearTimeout(lastTimerId)
    }
    let autoLogoutTimeoutId = setTimeout(logout, ALLOWED_IDLE_TIME);
    sessionStorage.setItem(SESSION_STORAGE_LOGOUT_TIMER, autoLogoutTimeoutId);
};

export const setRequestForEmailExistance = (layoutContext, email) => {
  if(!email) return false;
  return api(layoutContext, '/api/emails/existence?email=' + encodeURIComponent(email))
    .then(res => res);
};

export const isAuthorized = () => {
  return (Cookies.get("access_token") || Cookies.get("refresh_token")) ? true : false;
};

//\xa0 - not breakable space
export const formatTimeAndFullDate = (dateTime) => {
  return moment(dateTime).format('HH:mm\xa0DD\xa0MMMM\xa0YYYY');
};

export const formatDateAsFullMonthAndYear = (dateTime) => {
  return moment(dateTime).format('MMM\xa0YYYY');
};

//\xa0 - not breakable space
export const formatDateTime = (dateTime) => {
  return moment(dateTime).format('HH:mm\xa0DD/MM/YYYY');
};

//\xa0 - not breakable space
export const getEstimatedDischargeTimePeriod = (dateTime) => {
  return 'between ' + moment(dateTime).format('HH:mm\xa0DD/MM/YYYY') + ' and ' + moment(dateTime).add('hours', 2).format('HH:mm\xa0DD/MM/YYYY');
};

//\xa0 - not breakable space
export const formatDate = (date) => {
  return moment(date).format('DD\xa0MMM\xa0YYYY');
};

export const formatFullDate = (date) => {
  return moment(date).format('DD\xa0MMMM\xa0YYYY');
};

export const formatShortDate = (date) => {
  return moment(date).format('DD/MM/YYYY');
};

export const formatTime = (date) => {
  return moment(date).format('HH:mm');
};

export const splitContainerNumbers = (containerNumbersString) => {
  return containerNumbersString.trim().split(/[^a-zA-Z0-9]+/).filter(x => x);
};

export const isValidForm = (form) => {
  return form.getChildContext()._errors.length === 0;
};

export const goBack = (context) => {
  if(context.props.history !== undefined){
    context.props.history.goBack();
  }
};

export const trackLocation = () => {
  ReactGA.set({ location: window.location.href});
  ReactGA.set({ page: window.location.pathname});
  ReactGA.pageview(window.location.pathname);
  return null;
};

export const showIfHasAuthorities = (content, allowedAuthorities) => {
  const authorities = JSON.parse(Cookies.get('authorities'));
  return authorities !== null && allowedAuthorities.some(e => authorities.includes(e)) ? content : '';
};

export const hasAnyServiceType = (allowedServiceType) => {
  const serviceTypes = JSON.parse(Cookies.get(SERVICE_TYPE));
  return serviceTypes !== null && allowedServiceType.some(e => serviceTypes.includes(e));
};

export function filterInput(e, key, pattern){
  this.setState({
    [key]: e.target.value.replace(pattern, '')
  })
};

export const dataValidationPatterns = {
  'email': new RegExp(/[^\w@\-.]/g),
  'phone': new RegExp(/[^\d]/g),
  'postcode': new RegExp(/[^A-Za-z\d\s-]/g),
  'companyName': new RegExp(/[^A-Za-z\s\d_"'~`!@#$%*^&()={}[\]\\/:;,.<>+?-]/g),
  'containerNumber': new RegExp(/^[a-zA-Z0-9]{7,11}$/),
  'listName': new RegExp(/[^A-Za-z\d .,;:£$%()/*'-]/g)
};

export const splitContainersByModalColumns = (containersToSplit) => {
  let result = [];
  for (let i=0; i < containersToSplit.length; i += 10 ){
    result.push(<div key={i} className={i < 1 ? 'modal-container-list-first-column' : 'modal-container-list-second-column'}>
      {containersToSplit.slice(i, i + 10).map(
          (item) => <React.Fragment key={item}>{item}<br/></React.Fragment>)}
    </div>)
  }
  return result;
};

export const areArraysEqual = (first, second) => {
  if(first.length !== second.length){
    return false;
  }
  const predicate =  "object" === typeof first[0] ? (item) => _.find(second, item) : (item) => second.includes(item);
  let BreakException = {};
  try {
    first.forEach((a) => {
      if (!predicate(a)) {
        throw BreakException;
      }
    });
  } catch (e) {
    if (e === BreakException) {
      return false;
    }
  }
  return true;
};
