import React from 'react';
import { Section} from '..';

export function dashboardPortNotificationsSection(componentContent){
  return <Section
            color={componentContent.customField1}
            title={componentContent.title}
            backgroundColorCode={componentContent.customField1}
            textColorCode={componentContent.customField2}
            titleMode={componentContent.customField3}
            imagePosition={componentContent.customField5}
            imageFileName={componentContent.customField4}
            opacity={componentContent.customField8 || 1}
            displayMode={componentContent.contentType.name}
            link={componentContent.customField6}
            size={6}
            className="dashboard_port-status-notifications-section"
            mediaTitle={componentContent.region.name !== 'Text' && componentContent.customField3}
            innerContent={componentContent.content !== 'null' && <span dangerouslySetInnerHTML={{__html: componentContent.content}}/>}/>
}