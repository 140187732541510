import React from "react";
import "./frame-section.css";
import "./frame-section.mobile.css";
import {LayoutContext} from "../app/context/layout-context";
import ArrowUp from '../collapsible-page-header/img/arrow-up';
import ArrowDown from '../collapsible-page-header/img/arrow-down';
import Collapsible from 'react-collapsible';

export class FrameSection extends React.Component {

  title(isMobile, isOpen) {
    if (!this.props.title) { return; }

    return <div className={this.titleClassName()}>{this.props.title}{isMobile ? isOpen ? <ArrowDown/> : <ArrowUp/> : ''}</div>;
  }

  getTitle() {
    if(this.props.layoutContext.state.isMobile && !this.props.uncollpsible) {
      const collapsibleTrigger = (isOpen) => <div>{this.title(this.props.layoutContext.state.isMobile, isOpen)}</div>;

      return <Collapsible
          trigger={collapsibleTrigger(false)}
          triggerWhenOpen={collapsibleTrigger(true)}
          triggerTagName='div'
          triggerClassName='frame-section-trigger'
          triggerOpenedClassName=''>
        {this.getContent()}
      </Collapsible>
    }
    else return this.title();
  }

  titleClassName() {
    const classNames = ["frame-section_title"];

    if (this.props.titleColor) {
      classNames.push(`frame-section_title-color_${this.props.titleColor}`);
    }

    return classNames.join(' ');
  }

  className() {
    const classNames = ["frame-section"];

    if(this.props.className) {
      classNames.push(this.props.className)
    }
    if(this.props.borderless) {
      classNames.push('frame-section_borderless');
    }


    return classNames.join(' ');
  }

  getContent(){
    return <span className='frame-section_content'>
          {this.props.children}
        </span>;
  }

  render() {
    return <section className={this.className()}>

      {this.getTitle()}

      {(!this.props.layoutContext.state.isMobile || this.props.uncollpsible) && this.getContent()}
    </section>
  }
}

export default props => (
    <LayoutContext.Consumer>
      {layoutContext =>
          <FrameSection {...props}
                          layoutContext={layoutContext}/>}
    </LayoutContext.Consumer>
);