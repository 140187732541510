import React from "react";
import moment from "moment";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";
import DatePicker from "react-datepicker";
import { default as MobileDatePicker } from "react-mobile-datepicker";

import { Button } from "..";
import {
  numberOfContainers,
  required,
  requiredAtLeastOneContainerNumber,
  searchContainerFormat
} from "../../validations";
import {
  api,
  splitContainerNumbers,
  dataValidationPatterns,
  formatShortDate,
  isValidForm
} from "../../services";
import "react-datepicker/dist/react-datepicker.css";
import "./history-search.css";
import "./history-search.mobile.css";

export default class HistorySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      portTypes: [],
      activityTypes: [],
      containersToSearch: "",
      isMobileDateFromPickerOpen: false,
      isMobileDateToPickerOpen: false,
      dateFromInvalid: false,
      dateToInvalid: false,
      dateFrom: null,
      dateTo: null,
      isSpecificContainerJourneyMode: true,
      selectedActivity: "",
      selectedPort: ""
    };
  }

  componentDidMount() {
    api(this.props.layoutContext, "/api/type/activity-types").then(res => {
      if (res.status === 200) {
        res.json().then(response => {
          this.setState({ activityTypes: response });
        });
      }
    });
    api(this.props.layoutContext, "/api/type/port-types").then(res => {
      if (res.status === 200) {
        res.json().then(response => {
          this.setState({ portTypes: response });
        });
      }
    });

    if (this.props.searchParams) {
      const urlSearchParams = new URLSearchParams(this.props.searchParams);
      const containerNumbers = urlSearchParams.get(
        "history-search-container-numbers"
      )
        ? splitContainerNumbers(
            urlSearchParams.get("history-search-container-numbers")
          ).join(", ")
        : "";
      const isSpecificContainerJourneyMode =
        urlSearchParams.get("specific-container-journey-mode") === "true";
      this.setState({
        selectedActivity: urlSearchParams.get("history-search-activity") || "",
        containersToSearch: containerNumbers,
        dateFrom: urlSearchParams.get("date-from"),
        isSpecificContainerJourneyMode: isSpecificContainerJourneyMode,
        selectedPort: urlSearchParams.get("history-search-port") || "",
        dateTo:
          (!isSpecificContainerJourneyMode &&
            urlSearchParams.get("date-to") &&
            urlSearchParams.get("date-to")) ||
          null
      });
    }
  }

  showOptions(options, defaultValue) {
    let result = [
      <option key="default" value="">
        {defaultValue}
      </option>
    ];
    let value;

    if (options.length > 0) {
      options.forEach(option => {
        value = option;
        result.push(
          <option key={value} value={option}>
            {option}
          </option>
        );
      });
    }

    return result;
  }

  encodeFormValuesToURI(values) {
    if (values) {
      return Object.keys(values)
        .map(key => `${key}=${encodeURIComponent(values[key])}`)
        .join("&");
    }
  }

  searchContainerHistory() {
    if (isValidForm(this.searchForm)) {
      const searchValues = this.encodeFormValuesToURI({
        "specific-container-journey-mode": !this.state.dateTo,
        "date-from": this.state.dateFrom,
        "date-to": this.state.dateTo,
        ...this.searchForm.getValues()
      });
      console.log(searchValues);
      this.props.history.push(`/containers-history?${searchValues}`);
    }
  }

  getHistoryMinDate() {
    let now = new Date();
    now.setMonth(now.getMonth() - 6);
    now.setDate(this.props.layoutContext.state.isMobile ? 0 : 1);
    return now;
  }

  getHistoryMinToDate() {
    const historyMinDate = this.getHistoryMinDate();
    const dateFrom = moment(this.state.dateFrom, "DD/MM/YYYY").toDate();
    return dateFrom > historyMinDate ? dateFrom : historyMinDate;
  }

  getHistoryMaxFromDate() {
    const now = new Date();
    const dateTo = moment(this.state.dateTo, "DD/MM/YYYY").toDate();
    return now > dateTo ? dateTo : now;
  }

  render() {
    return (
      <div className="main_wrap page_id_history">
        <h2>History Search</h2>
        <div className="page_id_history-content">
          <span className="history-search-header-instructions">
            History information on a container will only be viewable 14 days
            after the final status. Prior to this, the container can be viewed
            on the Container Details page.
          </span>
          <Form
            className="history-search-form"
            ref={node => {
              this.searchForm = node;
            }}
            onSubmit={event => {
              event.preventDefault();
            }}
          >
            <div className="page_id_history-textarea-column">
              <span className="page_id_history-box-header">
                Container Numbers
              </span>
              <Textarea
                id="history-search-page_container-numbers-text-area"
                value={this.state.containersToSearch || ""}
                onChange={e => {
                  this.setState({ containersToSearch: e.target.value });
                }}
                name="history-search-container-numbers"
                className="history-search-containers-text-area"
                placeholder="Enter container number(s) here"
                validations={[
                  required,
                  requiredAtLeastOneContainerNumber,
                  numberOfContainers,
                  searchContainerFormat
                ]}
                maxcontainernumbers={20}
                containernumberformat={dataValidationPatterns.containerNumber}
              />
            </div>
            <div className="page_id_history-date-column">
              <div className="page_id_history-datepicker">
                <div className="page_id_history-datepicker-item">
                  <span className="page_id_history-box-header">
                    {this.state.dateTo !== null ? "Date From" : "Journey Date"}
                    &nbsp;
                  </span>
                  <DatePicker
                    selected={
                      this.state.dateFrom === null
                        ? undefined
                        : moment(this.state.dateFrom, "DD/MM/YYYY").toDate()
                    }
                    minDate={this.getHistoryMinDate()}
                    maxDate={this.getHistoryMaxFromDate()}
                    calendarClassName="blue_calendar"
                    customInput={
                      <div>
                        <div
                          className="page_id_history-datepicker-area"
                          onClick={e => {
                            if (this.props.layoutContext.state.isMobile) {
                              this.setState({
                                isMobileDateFromPickerOpen: true
                              });
                              e.stopPropagation();
                            }
                          }}
                        >
                          {this.state.dateFrom !== null ? (
                            <React.Fragment>
                              <div>
                                {moment(
                                  this.state.dateFrom,
                                  "DD/MM/YYYY"
                                ).format("Do")}
                              </div>
                              <div>
                                {moment(
                                  this.state.dateFrom,
                                  "DD/MM/YYYY"
                                ).format("MMMM")}
                              </div>
                              <div>
                                {moment(
                                  this.state.dateFrom,
                                  "DD/MM/YYYY"
                                ).format("YYYY")}
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="page_id_history-plus-icon">+</div>
                              <div>Start Date</div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    }
                    onChange={date => {
                      this.setState({
                        dateFormatInvalid: false,
                        dateFrom: formatShortDate(date)
                      });
                    }}
                  />
                  <MobileDatePicker
                    value={
                      this.state.dateFrom === null
                        ? new Date()
                        : moment(this.state.dateFrom, "DD/MM/YYYY").toDate()
                    }
                    min={this.getHistoryMinDate()}
                    max={this.getHistoryMaxFromDate()}
                    isOpen={this.state.isMobileDateFromPickerOpen}
                    onSelect={date =>
                      this.setState({
                        dateFromInvalid: false,
                        isMobileDateFromPickerOpen: false,
                        dateFrom: formatShortDate(date)
                      })
                    }
                    onCancel={() => {
                      this.setState({ isMobileDateFromPickerOpen: false });
                    }}
                    confirmText="Select"
                    cancelText="Cancel"
                  />
                </div>
                <div className="page_id_history-datepicker-item">
                  <span className="page_id_history-box-header">
                    {this.state.dateTo !== null ? "Date To" : ""}&nbsp;
                  </span>
                  <DatePicker
                    selected={
                      this.state.dateTo === null
                        ? undefined
                        : moment(this.state.dateTo, "DD/MM/YYYY").toDate()
                    }
                    minDate={this.getHistoryMinToDate()}
                    maxDate={new Date()}
                    calendarClassName="blue_calendar"
                    customInput={
                      <div>
                        <div
                          className="page_id_history-datepicker-area"
                          onClick={e => {
                            if (this.props.layoutContext.state.isMobile) {
                              this.setState({
                                isMobileDateToPickerOpen: true
                              });
                              e.stopPropagation();
                            }
                          }}
                        >
                          {this.state.dateTo !== null ? (
                            <React.Fragment>
                              <div>
                                {moment(this.state.dateTo, "DD/MM/YYYY").format(
                                  "Do"
                                )}
                              </div>
                              <div>
                                {moment(this.state.dateTo, "DD/MM/YYYY").format(
                                  "MMMM"
                                )}
                              </div>
                              <div>
                                {moment(this.state.dateTo, "DD/MM/YYYY").format(
                                  "YYYY"
                                )}
                              </div>
                              <div
                                className="page_id_history-clear-icon-background"
                                onClick={e => {
                                  this.setState({
                                    dateTo: null
                                  });
                                  e.stopPropagation();
                                }}
                              ></div>
                              <div
                                className="page_id_history-clear-icon"
                                onClick={e => {
                                  this.setState({
                                    dateTo: null
                                  });
                                  e.stopPropagation();
                                }}
                              >
                                X
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="page_id_history-plus-icon">+</div>
                              <div>End Date</div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    }
                    onChange={date => {
                      this.setState({
                        dateToInvalid: false,
                        dateTo: formatShortDate(date)
                      });
                    }}
                  />
                  <MobileDatePicker
                    value={
                      this.state.dateTo === null
                        ? new Date()
                        : moment(this.state.dateTo, "DD/MM/YYYY").toDate()
                    }
                    min={this.getHistoryMinToDate()}
                    max={new Date()}
                    isOpen={this.state.isMobileDateToPickerOpen}
                    onSelect={date =>
                      this.setState({
                        dateToInvalid: false,
                        isMobileDateToPickerOpen: false,
                        dateTo: formatShortDate(date)
                      })
                    }
                    onCancel={() => {
                      this.setState({ isMobileDateToPickerOpen: false });
                    }}
                    confirmText="Select"
                    cancelText="Cancel"
                  />
                </div>
              </div>
              <span className="page_id_history-datepicker-description">
                Optionally add an end date to perform a search between both
                dates
              </span>
            </div>

            <div className="page_id_history-filter-column">
              <span className="page_id_history-box-header">Port</span>
              <Select
                className={"history-search-select-control"}
                value={this.state.selectedPort}
                name="history-search-port"
              >
                {this.showOptions(this.state.portTypes, "All")}
              </Select>
              <span className="page_id_history-box-header">Activity</span>
              <Select
                className={"history-search-select-control"}
                value={this.state.selectedActivity}
                name="history-search-activity"
              >
                {this.showOptions(this.state.activityTypes, "All")}
              </Select>
              <Button
                className="history-search-button"
                isFormValidationRequired
                disabled={!this.state.dateFrom}
                onClick={e => {
                  e.preventDefault();
                  this.searchForm.validateAll();
                  this.searchContainerHistory();
                }}
                color="pink"
                icon="arrow"
              >
                Search
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
