import React from 'react';
import {Link} from 'react-router-dom';
import {Arrow, Reload, Sad} from './icon';
import {button} from 'react-validation';
import './button.css';

const customButton = ({hasErrors, ...props}) => {
  return <button {...props} disabled={hasErrors || props.disabled}/>
};
const ValidationButton = button(customButton);

export default class Button extends React.Component {
  className() {
    const classNames = ['button'];
    classNames.push(this.props.className);

    if (this.props.color) {
      classNames.push(`button_color_${this.props.color}`);
    }

    if (this.props.icon) {
      classNames.push('button_icon');
    }

    if (this.props.type === 'icon-only') {
      classNames.push('button_icon-only');
    }

    if (this.props.type === 'link') {
      classNames.push('button_link');
    }

    if (this.props.disabled === true) {
      classNames.push('button-disabled');
    }

    return classNames.join(' ');
  }

  children() {
    const icons = {
      arrow: Arrow,
      reload: Reload,
      sad: Sad
    };

    if (this.props.icon) {
      return <React.Fragment>
        {this.props.children}
        {icons[this.props.icon]()}
      </React.Fragment>
    }

    return this.props.children;
  }

  renderContent() {
    if (this.props.link) {
      return <Link to={this.props.link}>{this.children()}</Link>
    }

    return this.children();
  }

  render() {
    const onClick = this.props.onClick || function () {};

    if (this.props.isFormValidationRequired) {
      return <ValidationButton className={this.className()}
                               onClick={(e) => {
                                 if (this.props.disabled !== true) {
                                   onClick(e);
                                 }
                               }}
                               id={this.props.id}>
        {this.renderContent()}
      </ValidationButton>
    } else {
      return <button type={this.props.buttonType} className={this.className()}
                     onClick={(e) => {
                       if (this.props.disabled !== true) {
                         onClick(e);
                       }
                     }}
                     id={this.props.id}>
        {this.renderContent()}
      </button>
    }
  };
}
