import React from 'react';
import { Section } from '..';

import './acceptable-use-policy.css';

export default class AcceptableUsePolicy extends React.Component {

  render() {

    return <div className="policy-page acceptable_use_policy_main_wrap page_acceptable_use_policy">
      <h1>Acceptable Use Policy</h1>
      <Section color="white">

        <ol className="withoutPoint">
          <h3 className="marginMainPoints"><li className="withoutPoint">INTRODUCTION AND SCOPE</li></h3>

          <li>
            This Acceptable Use Policy sets out the terms between you and us under which you may access our website <a className="site" href="https://www.wheresmycontainer.co.uk">www.wheresmycontainer.co.uk</a> (“<b>Our Website</b>”). This Acceptable Use Policy applies to all users of, and visitors to, Our Website.
          </li>

          <li>
            Your use of Our Website means that you accept, and agree to abide by all the policies in this Acceptable Use Policy, which supplement our <a className="site" href="/terms-of-use-policy">Website Terms of Use</a>.
          </li>

          <li>
            <a className="site" href="https://www.wheresmycontainer.co.uk">www.wheresmycontainer.co.uk</a> is a website operated by Community Network Services Ltd, a company registered in England No: 2084279 and we have our registered office located at 16 Palace Street, London SW1E 5JQ (the “<b>Company</b>” or “<b>we</b>” or “<b>us</b>”). Our main trading address is Western Docks, Southampton, SO15 1DA, VAT registration number 458998463.
          </li>

          <li>
            Community Network Services Limited (CNS) is a wholly owned subsidiary of DP World Southampton.
          </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">PROHIBITED USES</li></h3>

          <li>
            You may use Our Website only for lawful purposes.  You may not use Our Website:
          </li>
          <ul className='internal-ul'>
            <li>In any way that breaches any applicable local, national or international law or regulation.</li>
            <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
            <li>For the purpose of harming or attempting to harm minors in any way.</li>
            <li>To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards as detailed in Section 4 of this document.</li>
            <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
            <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
          </ul>

          <li>You also agree:</li>
          <ul className='internal-ul'>
            <li>Not to reproduce, duplicate, copy or re-sell any part of Our Website in contravention of the provisions of our <a className="site" href="/terms-of-use-policy">Website Terms of Use</a>.</li>
            <li>Not to access without authority, interfere with, damage or disrupt:
              <ul className="circle">
                <li>any part of Our Website;</li>
                <li>any equipment or network on which Our Website is stored;</li>
                <li>any software used in the provision of Our Website; or</li>
                <li>any equipment or network or software owned or used by any third party.</li>
              </ul>
            </li>
          </ul>

          <h3 className="marginMainPoints"><li className="withoutPoint">INTERACTIVE SERVICES</li></h3>

          <li>We provide interactive services on Our Website including:</li>
          <ul>
            <li>Container Tracking</li>
          </ul>
          <li>
            Where we do provide any Interactive Service, we will provide clear information to you about the kind of service offered, if it is moderated and what form of moderation is used (including whether it is human or technical).
          </li>
          <li>
            We will do our best to assess any possible risks for users (and in particular, for children) from third parties when they use any Interactive Service provided on Our Website, and we will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are under no obligation to oversee, monitor or moderate any Interactive Service we provide on Our Website, and we expressly exclude our liability for any loss or damage arising from the use of any Interactive Service by a user in contravention of our content standards, whether the service is moderated or not.
          </li>
          <li>
            The use of any of our Interactive Services by a minor is subject to the consent of their parent or guardian. We advise parents who permit their children to use an Interactive Service that it is important that they communicate with their children about their safety online, as moderation is not fool proof. Minors who are using any Interactive Service should be made aware of the potential risks to them.
          </li>
          <li>
            Where we do moderate an Interactive Service, we will normally provide you with a means of contacting the moderator, should a concern or difficulty arise.
          </li>
          <li>
            The user of an  interactive service acknowledges and agrees that whilst this information and service is provided in good faith, no representation or warranty, express or implied, is or will be <b>made and no responsibility or liability (subject to paragraph 13.1 of our <a className="site" href="/terms-of-use-policy">Website Terms of Use Policy</a> is or will be accepted by us, or a member of our Group, or by our or by any of our Group's respective officers, employees or agents in relation to the accuracy or completeness of this information</b> or any other written or oral information made available to any interested party or its advisers and any such liability is expressly disclaimed.
          </li>
          <li>
            For the purposes of this document, "<b>Group</b>" means the Company, any subsidiary or any holding company of the Company and any other subsidiary from time to time of a holding company of the Company.  A reference to a "holding company" or a "subsidiary" means a holding company or a subsidiary (as the case may be) as defined in section 1159 of the Companies Act 2006.
          </li>
          <li>
            Where you provide us with any personal information as part of using an Interactive Service, such information is provided and used in accordance with our <a className="site" href="/privacy-policy">Privacy Policy</a>.
          </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">CONTENT STANDARDS</li></h3>
          <li>
            These content standards apply to any and all material which you contribute to Our Website ("<b>Contributions</b>"), and to any Interactive Services associated with it.
          </li>
          <li>
            You must comply with the spirit and the letter of the following standards. The standards apply to each part of any Contribution as well as to its whole.
          </li>
          <li>
            Contributions must:
          </li>
          <ul className='internal-ul'>
            <li>Be accurate (where they state facts).</li>
            <li>Be genuinely held (where they state opinions).</li>
            <li>Comply with applicable law in the UK and in any country from which they are posted.</li>
          </ul>
          <li>
            Contributions must not:
          </li>
          <ul className='internal-ul'>
            <li>Contain any material which is defamatory of any person.</li>
            <li>Contain any material which is obscene, offensive, hateful or inflammatory.</li>
            <li>Promote sexually explicit material.</li>
            <li>Promote violence.</li>
            <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
            <li>Infringe any copyright, database right or trade mark of any other person.</li>
            <li>Be likely to deceive any person.</li>
            <li>Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
            <li>Promote any illegal activity.</li>
            <li>Be threatening, abuse or invade another’s privacy, or cause annoyance, inconvenience or needless anxiety.</li>
            <li>Be likely to harass, upset, embarrass, alarm or annoy any other person.</li>
            <li>Be used to impersonate any person, or to misrepresent your identity or affiliation with any person.</li>
            <li>Give the impression that they emanate from us, if this is not the case.</li>
            <li>Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer misuse.</li>
          </ul>

          <h3 className="marginMainPoints"><li className="withoutPoint">SUSPENSION AND TERMINATION</li></h3>
          <li>
            We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of Our Website.  When a breach of this policy has occurred, we may take such action as we deem appropriate.
          </li>
          <li>
            Failure to comply with this Acceptable Use Policy constitutes a material breach of the <a className="site" href="/terms-of-use-policy">Terms of Use</a> upon which you are permitted to use Our Website, and may result in our taking all or any of the following actions:
          </li>
          <ul className='internal-ul'>
            <li>Immediate, temporary or permanent withdrawal of your right to use Our Website.</li>
            <li>Immediate, temporary or permanent removal of any posting or material uploaded by you to Our Website.</li>
            <li>Issue of a warning to you.</li>
            <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
            <li>Further legal action against you.</li>
            <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.</li>
          </ul>
          <li>
            We exclude liability for actions taken in response to breaches of this Acceptable Use Policy.  The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.
          </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">CHANGES TO THE ACCEPTABLE USE POLICY</li></h3>
          <li>
            We may revise this Acceptable Use Policy at any time by amending this page. You are expected to check this page from time to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in this Acceptable Use Policy may also be superseded by provisions or notices published elsewhere on Our Website.
          </li>

          <li><b>End of Policy.</b></li>

        </ol>

        <div align="right">
          Last Updated: 23/05/2018
        </div>
        
        <div className='ie_gap'/>

      </Section>
    </div>;
  }
};
