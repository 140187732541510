import React from 'react';
import {NavLink, Redirect} from 'react-router-dom';
import {
  AdministrationSearch,
  Dashboard,
  Document,
  Play,
  Settings,
  Alert,
  History,
  UserGuide
} from "./icon";
import Header from '../header/header'
import {showIfHasAuthorities} from '../../services/';
import "./logged-in-layout.css";
import "./logged-in-layout.mobile.css";
import Footer from "../footer/footer";
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css'
import ReactGA from "react-ga";

const ReactHint = ReactHintFactory(React);

export default class LoggedInLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarCollapsed: global.localStorage.getItem('navbarCollapsed') || true,
      redirectTo: '',
      scroll: 0
    };
  }

  componentDidMount(){
    if (navigator.userAgent.indexOf("Trident") > -1) 
      document.querySelector('react-app').addEventListener('scroll', ()=>{
        var y = document.querySelector('react-app').scrollTop;
        this.setState({scroll: y}); 
      }, true)
    else
    window.addEventListener('scroll', ()=>{
      var y = window.pageYOffset;
      this.setState({scroll: y}); 
    }, true)
  }
  
  componentWillUnmount(){
    if (navigator.userAgent.indexOf("Trident") > -1) 
      document.querySelector('react-app').removeEventListener('scroll', ()=>{
        var y = document.querySelector('react-app').scrollTop;
        this.setState({scroll: y}); 
      }, true)
    else
    window.removeEventListener('scroll', ()=>{
      var y = window.pageYOffset;
      this.setState({scroll: y}); 
    }, true)
  }

  toggleNavbar(e) {
    const nextState = !this.state.navbarCollapsed;
    this.setState({navbarCollapsed: nextState});
    global.localStorage.setItem('navbarCollapsed', nextState);

    e.preventDefault();
  }

  navbarClassName() {
    let className = 'logged-in-layout__navbar';

    if (this.state.navbarCollapsed) {
      className += ` ${className}_collapsed`;
    }

    return className;
  }

  isActivePage(location) {
    return location.pathname === "/" || location.pathname === "/dashboard";
  }
  myAccountIconClickEvent(){
    ReactGA.event({
      category: 'MyAccount',
      action: 'Clicked "My Account" Icon',
      label :"Navigated to MyAccount from Left Navigation Bar"
    })
  }
  render() {

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }

    let menu;

    const isPaidUser = this.props.layoutContext.state.isPaidUser;
    const isFreeAdvancedUser = this.props.layoutContext.state.isFreeAdvancedUser;

    if (!this.props.layoutContext.state.isMobile) {
      menu = <nav className={this.navbarClassName()}>
        <ul className='logged-in-layout__navbar-menu' style={{position: 'fixed', top: this.state.scroll > 80 ? '0px' : 80-this.state.scroll+'px' }}>
          <li>
            <NavLink
                to="/dashboard"
                isActive={(match, location) => this.isActivePage(location)}
                className="logged-in-layout__navbar-item"
                activeClassName="logged-in-layout__navbar-item_active"
            >
              <Dashboard/> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/account" className="logged-in-layout__navbar-item"
                     activeClassName="logged-in-layout__navbar-item_active" onClick={() => this.myAccountIconClickEvent()}>
              <Settings/> Account
            </NavLink>
          </li>

          <li>
            <div className='alert-template-feature-hint-wrapper'>
              {!isFreeAdvancedUser && !isPaidUser && <ReactHint persist events={{click: true, hover: true}}/>}
            </div>
            <NavLink to="/alert-templates"
                     data-rh="This is an advanced feature. For access to advanced features, check your My Account page"
                     data-rh-at="right"
                     onClick={(e) => {
                       if (!isPaidUser && !this.props.layoutContext.state.isFreeAdvancedUser) {
                         e.preventDefault();
                       }
                     }}
                     className={isPaidUser || this.props.layoutContext.state.isFreeAdvancedUser  ? "logged-in-layout__navbar-item"
                         : "logged-in-layout__navbar-item logged-in-layout__navbar-item_disabled"}
                     activeClassName="logged-in-layout__navbar-item_active">
              <Alert/>Alerts
            </NavLink>
          </li>
          <li>
            <NavLink to="/history-search"
                     data-rh="This is an advanced feature. For access to advanced features, check your My Account page"
                     data-rh-at="right"
                     className={isPaidUser || isFreeAdvancedUser ? "logged-in-layout__navbar-item"
                         : "logged-in-layout__navbar-item logged-in-layout__navbar-item_disabled"}
                     onClick={(e) => {
                       if (!isPaidUser && !isFreeAdvancedUser) {
                         e.preventDefault();
                       }
                     }}
                     activeClassName="logged-in-layout__navbar-item_active">
              <History/>History
            </NavLink>
          </li>
          <li>
            <NavLink to="/faqs" className="logged-in-layout__navbar-item"
                     activeClassName="logged-in-layout__navbar-item_active">
              <Document/> FAQs
            </NavLink>
          </li>
          {(isFreeAdvancedUser || isPaidUser) && <li>
            <NavLink to="/User_Guide_WMC_V1.4.pdf"
                     target="_blank"
                     className="logged-in-layout__navbar-item-special"
                     activeClassName="logged-in-layout__navbar-item-special_active">
              <UserGuide/> User Guide </NavLink>
          </li>}

          {showIfHasAuthorities(<li>
            <NavLink to="/administration-search"
                     className="logged-in-layout__navbar-item"
                     activeClassName="logged-in-layout__navbar-item_active">
              <AdministrationSearch/>Admin
            </NavLink>
          </li>, ['helpdesk'])}
          <li>
            <button onClick={e => this.toggleNavbar(e)}
                    className="logged-in-layout__navbar-item">
              <Play/> Collapse
            </button>
          </li>
        </ul>
      </nav>
    }

    return <React.Fragment>
      <Header isMobile={this.props.layoutContext.state.isMobile}/>

      <div className="content_footer_wrapper">
        <span className="react_app_wrap">

          {menu}

          {this.props.children}
          <NavLink
              id="sign-out"
              to='/sign-out'
              className="sign-out"
              activeClassName="sign-out">
              Sign Out
            </NavLink>
          </span>
        <Footer isMobile={this.props.layoutContext.state.isMobile}/>
      </div>
    </React.Fragment>;
  }
};
