import React from 'react';
import ArrowUp from './img/arrow-up';
import ArrowDown from './img/arrow-down';
import Collapsible from 'react-collapsible';

import './collapsible-page-header.css';
import './collapsible-page-header.mobile.css';

export default class CollapsiblePageHeader extends React.Component {

  render() {
    const collapsibleTrigger = (isOpen) => <div
        id={this.props.id ? this.props.id : 'collapsible-header-trigger-wrapper-id'}
        className='collapsible-header-trigger-wrapper'>
      <div
          className='collapsible-header-trigger'>{this.props.pageHeader}</div>
      {this.props.collapsibleContent ? isOpen ? <ArrowDown/> : <ArrowUp/> : ''}
    </div>;

    return <span className='noselect'>
      <Collapsible
          trigger={collapsibleTrigger(false)}
          triggerWhenOpen={collapsibleTrigger(true)}
          triggerTagName='div'
          triggerClassName='alert-templates-page-header-trigger'
          triggerOpenedClassName=''>
        {this.props.collapsibleContent && this.props.collapsibleContent}
      </Collapsible>
    </span>
  }
};
