import React from 'react';
import { Section} from '..';

export function signInSection(componentContent){
  return <Section
            color={componentContent.customField1}
            title={componentContent.title}
            backgroundColorCode={componentContent.customField1}
            textColorCode={componentContent.customField2}
            titleMode={componentContent.contentType.name==='Text' && componentContent.customField3}
            imagePosition={componentContent.customField5}
            imageFileName={componentContent.customField4}
            opacity={componentContent.customField8 || 1}
            displayMode={componentContent.contentType.name}
            link={componentContent.customField6}
            mediaTitle={componentContent.region.name !== 'Text' && componentContent.customField3}
            innerContent={componentContent.content !== 'null' && <span dangerouslySetInnerHTML={{__html: componentContent.content}}/>}>
        </Section>
}