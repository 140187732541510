import React from 'react';
import {getVesselString, getContainerStatusName} from './container-table__row';
import {
  getContainerAdditionalInfo,
  getContainerActions,
  getContainerAdditionalActions
} from './status-cell/status-additional-info'
import statusCellIconName from './status-cell/status-icon-info';

function getContainerStatusIconInfo(status) {
  return statusCellIconName[getContainerStatusName(status)]
}

function getContainerInfo(props) {
  if (props.entry.containerStatuses !== null) {
    return props.entry.containerStatuses.map((status, i) => props.isPaidUser && !props.allStatusesMode && i>0 ? null : <React.Fragment key={props.entry.container+i}>
          <div className='container-card_container-actions'>
            {getContainerActions( i===0 ? props.entry.actions : null, getContainerAdditionalActions(i===0, props.isPaidUser, props), status.port,
                props.onAction)}
          </div>
          <div className={props.isPaidUser ? 'container-card_container-info-advanced' : 'container-card_container-info'}>
            <div className='container-card_container-info_title'>Port:</div>
            <div
                className='container-card_container-info_value'>{status.port}</div>
            <div className='container-card_container-info_title'>Activity:</div>
            <div
                className='container-card_container-info_value'>{status.activity}</div>
            <div className='container-card_container-info_title'>Vessel:</div>
            <div
                className='container-card_container-info_value'>{getVesselString(
                status.vessel)}</div>
            <div
                className={props.isPaidUser ? 'container-card_container-info_advanced-view_status-icon' : 'container-card_container-info_status-icon'}>
              {getContainerStatusIconInfo(status) ? <img src={require('./icon/' + getContainerStatusIconInfo(
                  status))} alt='container status icon'/> : ''}
            </div>
            <div
                className={props.isPaidUser ? 'container-card_container-info_advanced-status-details' : 'container-card_container-info_status-details'}>
              {/*status is unavailable for mobiles*/}
              {getContainerStatusIconInfo(status) ? getContainerAdditionalInfo(status, true, 0, props.allStatusesMode, i === 0, props.isPaidUser) :
                  <span className='container-card__status-cell_status_unavailable'>
                    {getContainerStatusName(status)}
                  </span>}
            </div>
          </div>
        </React.Fragment>
    )
  }
  else {
    return <div className={props.isPaidUser ? 'container-card_container-info-advanced' : 'container-card_container-info'}>
      <div
          className={props.allStatusesMode ? 'container-card_container-info_advanced-view_status-icon' : 'container-card_container-info_status-icon'}>
        <img src={require('./icon/' + getContainerStatusIconInfo(null))}
             alt='container status icon'/>
      </div>
      <div
          className={props.allStatusesMode ? 'container-card_container-info_value container-card_container-info_advanced-status-details' : 'container-card_container-info_value container-card_container-info_status-details'}>
        Unknown container
      </div>
    </div>
  }
}

export default function containerCard(props) {
  return <div
      className={props.hasChanges ? 'container-card changed-container-card'
          : 'container-card'}>
    {props.isPaidUser && <div className='container-card-container-selection-box'><input type='checkbox' checked={props.isSelected} onChange={props.onSelect}/></div>}
    <div className={props.isPaidUser ? 'container-card_container-number-advanced' : 'container-card_container-number'}>
      {props.entry.container}
    </div>
    {getContainerInfo(props)}
  </div>
};