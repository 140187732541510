import React from 'react';
import {Context} from '../app/context/context';
import {LayoutContext} from '../app/context/layout-context';
import {splitContainerNumbers, formatDateTime} from '../../services/index';
import './container-history-table.css';
import { getVesselString } from './../container-table/container-table__row';
import Button from '../button/button';
import Collapsible from 'react-collapsible';
import './container-history-table.mobile.css';
import ArrowUp from './../collapsible-page-header/img/arrow-up';
import ArrowDown from './../collapsible-page-header/img/arrow-down';

export class ContainerHistoryTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sortingBy: '',
      sortingOrder: 'desc'
    };
    this.selectSorting = this.selectSorting.bind(this);
  }

  componentDidMount() {
    const urlSearchParams = new URLSearchParams(this.props.searchParams);
    const containerNumbers = splitContainerNumbers(urlSearchParams.get('history-search-container-numbers'));
    const isSpecificContainerJourneyMode = urlSearchParams.get('specific-container-journey-mode') === 'true';
    const searchParams = {
      "activity": urlSearchParams.get('history-search-activity') || null,
      "containerNumbers": containerNumbers,
      "fromPeriodDate": urlSearchParams.get('date-from').replace(/\//g, '-'),
      "isJourneyDate": isSpecificContainerJourneyMode,
      "port": urlSearchParams.get('history-search-port') || null,
      "toPeriodDate": ((!isSpecificContainerJourneyMode && urlSearchParams.get('date-to')) && urlSearchParams.get('date-to').replace(/\//g, '-')) || null
    };

    if (this.props.context.state.foundContainerHistory.length === 0 && containerNumbers) {
      this.props.context.searchContainerHistory(this.props.layoutContext, searchParams)
    }
  }

  selectSorting(name, order) {
    this.setState({
      sortingBy: name,
      sortingOrder: order
    });
  }

  changeSorting(name) {
    let order = 'desc';

    if (this.state.sortingBy === name) {
      order = this.state.sortingOrder === 'asc' ? 'desc' : 'asc';
    }

    this.setState({
      sortingBy: name,
      sortingOrder: order
    });
  }

  getVesselValue(vessel) {
    if(vessel) {
      if (vessel.vessel) {
        return vessel.vessel;
      }
      else if (vessel.outVessel) {
        return vessel.outVessel;
      }
      else if (vessel.inVessel) {
        return vessel.inVessel;
      }
    }
    else {
      return '';
    }
  }

  secondarySorting = (a, b) => {
    const aDate = Date.parse((a.statuses === null ? ''
        : a.statuses[a.statuses.length === 0 ? 0 : a.statuses.length -1] === null ? ''
            : a.statuses[a.statuses.length === 0 ? 0 : a.statuses.length -1]['statusTime']));
    const bDate = Date.parse(b.statuses === null ? ''
        : b.statuses[b.statuses.length === 0 ? 0 : b.statuses.length -1] === null ? ''
            : b.statuses[b.statuses.length === 0 ? 0 : b.statuses.length -1]['statusTime']);
    return (isNaN(aDate) ? 0 : Number(aDate)) - (isNaN(bDate) ? 0 : Number(bDate));
  };

  sortData(entryToSort) {

    const key = this.state.sortingBy;
    let sorted;


    switch (key) {
      case 'port':
        debugger;
        sorted = entryToSort.journeyHistories.sort(
            (a, b) => (a.statuses === null ? ''
                : a.statuses[a.statuses.length - 1] === null ? ''
                    : a.statuses[a.statuses.length - 1][key])
            .localeCompare(
                b.statuses === null ? '' : b.statuses[b.statuses.length - 1]
                === null ? '' : b.statuses[b.statuses.length - 1][key]) || this.secondarySorting(a, b));
        break;
      case 'activity':
        sorted = entryToSort.journeyHistories.sort(
            (a, b) => (a.statuses === null ? ''
                        : a.statuses[0] === null ? ''
                          : a.statuses[0][key])
            .localeCompare(
                b.statuses === null ? '' : b.statuses[0]
                === null ? '' : b.statuses[0][key]) || this.secondarySorting(a, b));
        break;
      case 'vessel':
        sorted = entryToSort.journeyHistories.sort(
            (a, b) => (a.statuses === null ? ''
                : a.statuses[0] === null ? ''
                    : this.getVesselValue(a.statuses[0][key]))
            .localeCompare(
                b.statuses === null ? ''
                    : b.statuses[0] === null ? ''
                      : this.getVesselValue(
                    b.statuses[0][key])) || this.secondarySorting(a, b)
        );
        break;
      default:
        sorted = entryToSort.journeyHistories.sort(
            (a, b) => this.secondarySorting(a, b));
    }

    return this.state.sortingOrder === 'desc'
        ? sorted
        : sorted.reverse();
  }

  className(name) {
    if (this.state.sortingBy === name) {
      return this.state.sortingOrder === 'desc' ? 'sorting-order_desc'
          : 'sorting-order_asc';
    }
  }

  collapsibleTrigger (containerNumber, isOpen) {
    return <div className={'container-history-card-collapsible-trigger ' +  (isOpen ? 'container-history-card-collapsible-trigger-opened' : 'container-history-card-collapsible-trigger-closed')}>
      <span>{containerNumber}</span>
      {isOpen ? <ArrowDown/> : <ArrowUp/>}
    </div>;
  }

  clickOnCollapsibleCard(clickOnExpandedCards) {
    Array.from(document.getElementsByClassName(clickOnExpandedCards
        ? 'container-history-card-collapsible-trigger-closed'
        : 'container-history-card-collapsible-trigger-opened')).forEach(
        item => item.click())
  }

  render() {

    if (this.props.layoutContext.state.isMobile) {
      return <>
      {this.props.context.state.foundContainerHistory.length > 1 && <div className='container-history-card-wrapper-controls'>
          <Button color='blue' onClick={()=> this.clickOnCollapsibleCard(true)}>Expand all</Button>
          <Button color='blue' onClick={()=> this.clickOnCollapsibleCard(false)}>Collapse all</Button>
        </div>}

     <div className='container-history-card-search-result-container'>
       {this.props.context.state.foundContainerHistory.map(
         (entry, entryOrder) =>
           <div key={entry.container}>
             <Collapsible
                 trigger={this.collapsibleTrigger(entry.container, false)}
                 triggerWhenOpen={this.collapsibleTrigger(entry.container, true)}
                 triggerTagName='div'
                 open={this.props.context.state.foundContainerHistory.length === 1}
             >

               <div className='container-history-card-journey-history'>
               {entry.journeyHistories.length === 0 ?
                   <p>NO DETAILS</p>
                   : this.sortData(entry) && entry.journeyHistories.map(
                   (journeyHistory) =>{
                       let activityResult = [];
                       let portResult = [];
                       journeyHistory.statuses.forEach(status => {
                         activityResult.indexOf(status.activity) === -1 && activityResult.push(status.activity);
                         let portValue = getVesselString(status.vessel, status.activity === 'Transhipment');
                         portValue && portResult.indexOf(portValue) === -1 && portResult.push(portValue);
                       });
                       return <div key={journeyHistory.gkey} className='container-history-card-journey'>
                         <div className='container-history-card-status-header'>
                           <div>
                             <span>Port: </span>
                             <span>{journeyHistory.statuses[journeyHistory.statuses.length - 1].port}</span>
                           </div>
                           <div>
                             <span>Activity: </span>
                             <span>{activityResult.join('/')}</span>
                           </div>
                           <div>
                             <span>Vessel: </span>
                             <span>{portResult.join('/')}</span>
                           </div>
                         </div>
                        <div>
                          {journeyHistory.statuses.map((status) => <div className='container-history-card-journey-status'>
                            <div className='container-history-card-status-list-container'>
                              <span className='container-history-card-status-name'>{status.statusType}</span>
                              <span className='container-history-card-status-time'>{formatDateTime(status.statusTime)}</span>
                            </div>

                            <div className='container-history-card-journey-status-alerts'>
                              {status.emailRecipients && status.emailRecipients.sort().map((recpient) => <div>
                                <span>Email </span>
                                <span>{recpient}</span>
                                </div>
                              )}

                              {status.smsRecipients && status.smsRecipients.sort().map((recpient) => <div>
                                  <span>SMS </span>
                                  <span>{recpient}</span>
                                  </div>
                              )}
                            </div>
                          </div>
                          )}
                        </div>
                       </div>
               })}</div>
             </Collapsible>
           </div>)
       }
      </div>
      </>
    }
    else {
      return <div className='container-history-table-wrapper'>

        <table className="container-history-table">
          <thead>
          <tr>
            <th
                className={this.className('container')}>Container
            </th>
            <th
                className={this.className('port') + ' sortable'}
                onClick={() => this.changeSorting('port')}>Port
            </th>
            <th
                className={this.className('activity') + ' sortable'}
                onClick={() => this.changeSorting('activity')}>Activity
            </th>
            <th
                className={this.className('vessel') + ' sortable'}
                onClick={() => this.changeSorting('vessel')}>Vessel
            </th>
            <th
                className={this.className('status')}>Status
            </th>
            <th
                className={this.className('time_date')}>Time/Date
            </th>
            <th
                className={this.className('alerts_sent')}>Alerts Sent
            </th>
            <th
                className={this.className('alert_number_email_address')}>Alert Number/Email Address
            </th>
          </tr>
          </thead>
          {this.props.context.state.foundContainerHistory.map((entry, entryOrder) =>
              <tbody key={entry.container}>
              {entry.journeyHistories.length === 0 ? <tr>
                    <td>{entry.container}</td>
                    <td/>
                    <td/>
                    <td/>
                    <td className='container-history-table_container-status-column'>NO DETAILS</td>
                    <td/>
                    <td/>
                    <td/>
                  </tr>
                  : this.sortData(entry) && entry.journeyHistories.map((journeyHistory, journeyOrder) =>
                      <React.Fragment key={journeyHistory.gkey}>{journeyHistory.statuses.map((status, statusOrder) => {
                        return <tr key={entry.container + status.statusType
                        + statusOrder} className={(statusOrder === journeyHistory.statuses.length - 1) && (journeyOrder !== entry.journeyHistories.length -1) ? statusOrder === 0 ? 'last-status-of-journey next-status-of-journey' : 'last-status-of-journey' : statusOrder === 0 && journeyOrder !== 0 ? 'next-status-of-journey': ''}>
                          <td>{journeyOrder === 0 && statusOrder === 0
                          && entry.container}</td>
                          <td>{statusOrder === 0 && journeyHistory.statuses[journeyHistory.statuses.length - 1].port}</td>
                          <td>{(statusOrder === 0
                              || journeyHistory.statuses[statusOrder - 1].activity
                              !== status.activity) && status.activity}</td>
                          <td className='container-history-table_vessel-column'>
                            {(statusOrder === 0
                                || JSON.stringify(journeyHistory.statuses[statusOrder - 1].vessel) !== JSON.stringify(status.vessel))
                                && getVesselString(journeyHistory.statuses[statusOrder].vessel, status.activity === 'Transhipment')}</td>
                          <td className='container-history-table_container-status-column'>{status.statusType}</td>
                          <td className='container-history-table_container-status-column'>{formatDateTime(status.statusTime, '.')}</td>
                          <td className='container-history-table_container-alert-type-column'>
                            {status.emailRecipients && status.emailRecipients.map(recipient =>
                              <>Email<br/></>)}
                            {status.smsRecipients && status.smsRecipients.map(recipient =>
                                <>SMS<br/></>)}
                          </td>
                          <td className='container-history-table_container-alert-contact-column'>
                            {status.emailRecipients && status.emailRecipients.sort().map(recipient =>
                                <>{recipient}<br/></>)}
                            {status.smsRecipients && status.smsRecipients.sort().map(recipient =>
                                <>{recipient}<br/></>)}
                          </td>
                        </tr>
                      })}</React.Fragment>                  )}
              </tbody>
          )}
        </table>
      </div>
    }
  }
}

export default props => (
    <LayoutContext.Consumer>
      {layoutContext =>
          <Context.Consumer>
            {context => <ContainerHistoryTable {...props} context={context}
                                        layoutContext={layoutContext}/>}
          </Context.Consumer>
      }
    </LayoutContext.Consumer>
);
