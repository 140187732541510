import React from 'react';
import ReactModal from 'react-modal';
import {Button} from '../..';
import './password-reset.css'

import {api} from "../../../services";
import NotAllowedOperationModal from "../not-allowed-operation-modal/not-allowed-operation-modal";

export default class PasswordReset extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isPasswordUpdated: false,
      exceededTimeLimit: false,
      isCustomerSuspended: false,
      notAllowedOperationOpen: false
    };
  }

  resetPasswordForUser(){
    return  api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/password/reset`, 'PUT');
  }

  closeModalNotAllowedOperation() {
    this.setState({notAllowedOperationOpen: false});
  }

  render() {
    if (!this.props.statusType || this.props.statusType === 'Unverified') {
      return <React.Fragment><div/></React.Fragment>
    }
    return <div className="administration-password-reset">
      <label>Password Reset:</label>
      <Button
          color="blue"
          onClick={() => {
            if (this.props.isDataOwner) {
              this.setState({notAllowedOperationOpen: true});
            } else {
              this.resetPasswordForUser()
              .then(res => {
                if (res.status === 200) {
                  res.json().then(response => {
                    if (response === -1) {
                      this.setState({isCustomerSuspended: true})
                    }
                    else {
                      this.setState({isPasswordUpdated: true})
                    }
                  });
                } else {
                  res.json().then(response => {
                    if (response.code === "CSC_02") {
                      this.setState({exceededTimeLimit: true});
                    }
                  });
                }
              })
              .catch(err => console.warn(err));
            }
          }
          }
      >
        Send temporary password
      </Button>

      <NotAllowedOperationModal isOpen={this.state.notAllowedOperationOpen} closeModal={this.closeModalNotAllowedOperation.bind(this)}/>

      <ReactModal
          appElement={document.querySelector('react-app')}
          isOpen={this.state.isPasswordUpdated || this.state.exceededTimeLimit
          || this.state.isCustomerSuspended} className="app-modal">
        <div className="account-suspend-modal">
          <h2>
            {this.state.isPasswordUpdated && 'Temporary password has been sent'}
            {this.state.exceededTimeLimit
            && 'You have already requested a temporary password. 10 minutes must elapse before requesting again.'}
            {this.state.isCustomerSuspended && <span>A temporary password has been requested for the 4<sup>th</sup> time which has suspended this account</span>}
          </h2>
          <Button color='blue'
              onClick={() => this.setState(
                  {
                    isPasswordUpdated: false,
                    exceededTimeLimit: false,
                    isCustomerSuspended: false
                  })}>OK</Button>
        </div>
      </ReactModal>
    </div>
  }
}