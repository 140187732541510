export default {
  'first-name': 'Please enter your first name',
  'last-name': 'Please enter your last name',
  'business-type': 'Please select business type',
  'email': 'Invalid Email',
  'confirm-email': 'Email addresses do not match',
  'role-in-company': 'Please enter your role in the company',
  'password': 'Password must contain at least 8 characters including at least 1 number and 1 uppercase letter.',
  'confirm-password': 'The passwords you have entered do not match',
  'postcode': 'Please enter the postcode',
  'contact-number': 'Please enter your contact phone number',
  'security-question-type': 'Please select a security question',
  'seq-answer-1': 'Please enter the answer to the question',
  'business-subtype': 'Please select business sub type',
  'company':'Please enter your company name',
  'company-name': 'Please enter your company name',
  'current-password': 'Please enter your current password',
  'new-password': 'Please enter your new password',
  'phone': 'Please enter your contact phone number',
  'alert-primary-email': 'Please enter the alert email address',
  'new-list-name': 'Please enter the list name',
  'firstName': 'Please enter your first name',
  'lastName': 'Please enter your last name',
  'address1': 'Please enter your address', 
  'city': 'Please enter your city',
  'country': 'Please select your country',
  'state': 'Please select your state',
  'postCode': 'Please enter your postcode',
  'emailAddress': 'Invalid Email',
  'subject':'Please enter the subject of your message',
  'message':'Please enter your message',
};

