export const importTemplate = [
  {
    statusLabel: 'Arrived',
    statusType: 'Arrived',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Discharged',
    statusType: 'Discharged',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Hold',
    statusType: 'On Hold',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Hold Processed (LGP only)',
    statusType: 'On Hold - Processed',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Available for Collection',
    statusType: 'Available for Collection',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Departed Port for Rail Terminal',
    statusType: 'Departed Port for Rail Terminal',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Departed Rail Terminal (LGP Only)',
    statusType: 'Departed Rail Terminal',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Departed by Road',
    statusType: 'Departed by Road',
    labels: ['Email', 'SMS']
  }
];

export const exportTemplate = [
  {
    statusLabel: 'Arrived at Port',
    statusType: 'Arrived at Port',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Hold',
    statusType: 'On Hold',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Ready for Loading',
    statusType: 'Ready for Loading',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Vessel at Berth',
    statusType: 'On Vessel at Berth',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Vessel at Sea',
    statusType: 'On Vessel at Sea',
    labels: ['Email', 'SMS']
  }
];

export const transhipmentTemplate = [
  {
    statusLabel: 'Arrived',
    statusType: 'Arrived',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Discharged',
    statusType: 'Discharged',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Hold',
    statusType: 'On Hold',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Ready for Loading',
    statusType: 'Ready for Loading',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Vessel at Berth',
    statusType: 'On Vessel at Berth',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'On Vessel at Sea',
    statusType: 'On Vessel at Sea',
    labels: ['Email', 'SMS']
  }
];

export const returnToShipperTemplate = [
  {
    statusLabel: 'Available for Collection',
    statusType: 'Available for Collection',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Departed Port for Rail Terminal',
    statusType: 'Departed Port for Rail Terminal',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Departed Rail Terminal (LGP Only)',
    statusType: 'Departed Rail Terminal',
    labels: ['Email', 'SMS']
  },
  {
    statusLabel: 'Departed by Road',
    statusType: 'Departed by Road',
    labels: ['Email', 'SMS']
  }
];

export const IMPORT_ACTIVITY_TYPE = 'Import';
export const EXPORT_ACTIVITY_TYPE = 'Export';
export const TRANSHIPMENT_ACTIVITY_TYPE = 'Transhipment';
export const RETURN_TO_SHIPPER_ACTIVITY_TYPE = 'Return to Shipper';
