import React from 'react';
import { api } from '../../../services';
import { splitContainerNumbers } from "../../../services/index";

export const Context = React.createContext();

export default class Provider extends React.Component {

  constructor(){
    super();
    this.state = {
      isSmsAlertsSuspended: false,
      isEmailAlertsSuspended: false,
      isLoading: false,
      requestedNumbers: [],
      foundContainers: [],
      foundContainerHistory: [],
      recentFoundContainers: []
    }
  }

  doSearchRequest(layoutContext, url, data){
    this.setState({isLoading: true});
    return api(layoutContext, url, 'POST', data)
  }

  addContainers(layoutContext, isPaid, containerNumbersToAdd){
    if(containerNumbersToAdd.length > 0){
      this.doSearchRequest(layoutContext, isPaid ? '/api/containers/advanced' : '/api/containers', containerNumbersToAdd)
      .then(response => {
            if(response.status === 200) {
              response.json().then(responseBody => {
                this.setState({
                  requestedNumbers: this.state.requestedNumbers.concat(containerNumbersToAdd),
                  foundContainers: responseBody.containers.concat(this.state.foundContainers),
                  isLoading: false
                });
              })
            }
          }
      );
    }
  }


  search(layoutContext, isPaid, data){
    this.doSearchRequest(layoutContext, isPaid ? '/api/containers/advanced' : '/api/containers', data)
      .then(response => {
        if(response.status === 200) {
          response.json().then(responseBody => {
            if(this.state.isLoading){
              this.setState({
                requestedNumbers: data,
                foundContainers: isPaid ? responseBody.containers : responseBody,
                isSmsAlertsSuspended: isPaid ? Number(responseBody.limit.smsLimit) <= 0 : false,
                isEmailAlertsSuspended: isPaid ? Number(responseBody.limit.emailLimit) <= 0 : false,
                isLoading: false
              });
            }
          })
        }
      }
    );
  }

  historySearch(layoutContext, data) {
    this.doSearchRequest(layoutContext, '/api/containers/history', data)
    .then(response => {
      if(response.status === 200) {
        response.json().then(responseBody => {
          if(this.state.isLoading){
            this.setState({
              foundContainerHistory: responseBody,
              isLoading: false
            })
          }
        })
      }
    })
  }

  removeContainers(containersToRemove) {
    this.setState({foundContainers: this.state.foundContainers.filter(container => !containersToRemove.includes(container.container)),
      requestedNumbers: this.state.requestedNumbers.filter(number => !containersToRemove.includes(number.toUpperCase()))})
  }

  render() {
    return (
        <Context.Provider value={{
          state: this.state,
          searchContainer: (layoutContext, isPaid, values) => {
            const data = splitContainerNumbers(values['container-numbers']);
            this.search(layoutContext, isPaid, data);
          },
          searchContainerHistory: (layoutContext, data) => {
            this.historySearch(layoutContext, data)
          },
          refreshSearch: (layoutContext, isPaid) => {
            this.setState({recentFoundContainers: this.state.foundContainers});
            this.search(layoutContext, isPaid, this.state.requestedNumbers)
          },
          cleanSearchResults: ()=>{
            this.setState({
              recentFoundContainers: []
              , foundContainers:[]
              , foundContainerHistory: []
              , isLoading: false});
          },
          removeContainers: (containersToRemove)=> {
            this.removeContainers(containersToRemove);
          },
          addContainers: (layoutContext, isPaid, containerNumbersToAdd) => {
            this.addContainers(layoutContext, isPaid, containerNumbersToAdd)
          }
        }}>
          {this.props.children}
        </Context.Provider>
    )
  }
}
