import React from "react";
import { withRouter } from "react-router-dom";
import Modal from "../notification/notification";
import Button from "../button/button";

import { api } from "../../services";
import ReactGA from "react-ga";

class PaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoaded: true,
      status: "",
      message: ""
    };
  }

  componentDidMount() {
    var crypt = window.location.href.split("=")[1];
    if (crypt) {
      this.setState({ isOpen: true });
      api(this.props.layoutContext, "/api/payment/status", "POST", crypt).then(
        res => {
          if (res.status === 200) {
            this.setState({ isLoaded: false, status: "success" });
          }
          if (res.status === 400) {
            res.json().then(res => {
              this.setState({
                isLoaded: false,
                status: "failed",
                message: res.description
              });
            });
          }
        }
      );
    }
  }

  onClose = () => {
    this.setState({ isOpen: false });
    window.location.href = "/account";
  };
  paymentSuccessfulEvent(){
    ReactGA.event({
      category: 'Payment',
      action: 'Completed the Payment',
      label :"Payment Successful"
    })
  }
  render() {
    return (
      <Modal
        className="paymnet-modal__modal"
        isOpen={this.state.isOpen}
        onClose={this.onClose}
        notClosable
      >
        {this.state.isLoaded && <h4>Loading</h4>}
        {this.state.status === "success" && (
          <div className="payment-modal__success_container" onLoad={() => this.paymentSuccessfulEvent()}>
            <p className="paymnet-modal__header">Payment Successful</p>
            <p className="paymnet-modal__subheader">
              Thank you for purchasing our advanced features
            </p>
            <p className="paymnet-modal__text">
              To see your Invoice-Receipt for this transaction, please go to
              <br /> ‘My Receipts’ on your My Account page
            </p>
            <Button color="blue" onClick={this.onClose}>
              OK
            </Button>
          </div>
        )}
        {this.state.status === "failed" && (
          <div className="payment-modal__failed_container">
            <p className="paymnet-modal__header">Payment Failed</p>
            <p className="paymnet-modal__subheader">{this.state.message}</p>
            <Button color="blue" onClick={this.onClose}>
              OK
            </Button>
          </div>
        )}
      </Modal>
    );
  }
}

export default withRouter(PaymentModal);
