import React from 'react';
import Form from 'react-validation/build/form';
import Textarea from 'react-validation/build/textarea';
import Input from "react-validation/build/input";
import {Link, Redirect} from 'react-router-dom';
import {Button, Section} from '..';
import {Context} from '../app/context/context';
import {LayoutContext} from "../app/context/layout-context";
import Cookies from 'browser-cookies';
import {
  api,
  dataValidationPatterns,
  formatDate,
  formatDateAsFullMonthAndYear,
  isValidForm,
  splitContainersByModalColumns
} from '../../services';
import {
  numberOfContainers,
  requiredAtLeastOneContainerNumber,
  searchContainerFormat
} from '../../validations';
import './dashboard.mobile.css';
import './dashboard.css';
import {Arrow} from '../button/icon'
import ReactHintFactory from 'react-hint';
import ReactModal from 'react-modal';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.scss";
import CriticalAlertComponent from './critical-alert-component'
import ReactGA from "react-ga";
var moment = require('moment');

const ReactHint = ReactHintFactory(React);

export class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: '',
      lastLoginDate: '',
      changedActivityContainers: [],
      notificationIdsOfChangedActivityContainers: [],
      alertSubscriptionsInfo: {},
      expPaidModal: false,
      marketingPreferencesModal: false,
      hasMarketingPreferences: null,
      sendUpdatesLondon:false,
      sendUpdatesSouthampton:false
    };

    this.getCustomerGeneralInfo();
  }

  searchContainers() {
    if (isValidForm(this.form)) {
      const values = this.form.getValues();
      const numbers = encodeURIComponent(values['container-numbers']);
      this.setState({redirectTo: `/containers?numbers=${numbers}`});
    }
  }
  searchContainerClickEvent(){
    ReactGA.event({
      category: 'Search',
      action: 'Clicked Container Search Button ',
      label :"Searched for a Container"
    })
  }
  myAccountLinkClickEvent(){
    ReactGA.event({
      category: 'MyAccount',
      action: 'Clicked > Button  ',
      label :"Navigated to MyAccount from SearchPage"
    })
    this.linkedInClickMyAccount();
  }
  linkedInClickMyAccount(){
    var img = document.createElement("img");
    img.setAttribute("src","https://px.ads.linkedin.com/collect/?pid=3333801&conversionId=4679737&fmt=gif");
    document.body.appendChild(img);
  }
  radioChecked = () => {
    if (this.state.isSendUpdates === undefined) {
      return " ";
    }
  };

  checkboxChecked = () => {
    if (this.state.isSendUpdates === true && (!this.state.sendUpdatesSouthampton && !this.state.sendUpdatesLondon)) {
      return " ";
    }
  };

  getCustomerGeneralInfo() {
    api(this.props.layoutContext, "/api/customers/general-info")
    .then(res => {
      res.status === 200
          ? res.json().then(
          response =>{
              this.setState({
                hasMarketingPreferences: response.hasMarketingPreferences,
                lastLoginDate: response.lastLoginTime,
                changedActivityContainers: response.subscriptionInfo.containerNumbers
                    || [],
                notificationIdsOfChangedActivityContainers: response.subscriptionInfo.subscriptionIds,
                alertSubscriptionsInfo: (({pendingEmail, pendingSms, availableEmail, availableSms, paidSubscriptionExpirationDate}) => ({
                  pendingEmail,
                  pendingSms,
                  availableEmail,
                  availableSms,
                  paidSubscriptionExpirationDate
                }))(response.subscriptionInfo)
              })
              this.didExpPaidModalOpen();
              this.didMarketingPreferencesModalOpen();
            })
          : this.setState({lastLoginDate: ''});
    });
  }

  didExpPaidModalOpen(){
    if(!this.state.alertSubscriptionsInfo.paidSubscriptionExpirationDate)
      return;
    if(localStorage.getItem('lastExpPaidModalShown')===moment().format("YYYY-MM-DD") )
      return;
    if(moment().add(1, 'days').format("YYYY-MM-DD")!==this.state.alertSubscriptionsInfo.paidSubscriptionExpirationDate && moment().format("YYYY-MM-DD")!==this.state.alertSubscriptionsInfo.paidSubscriptionExpirationDate)
      return;
    this.setState({expPaidModal: true});
  }

  expPaidModalClose(){
    this.setState({expPaidModal: false});
    localStorage.setItem('lastExpPaidModalShown', moment().format("YYYY-MM-DD"))
  }

  didMarketingPreferencesModalOpen(){
    if(this.props.layoutContext.state.isInternalUser)
      return;
    const authorities = JSON.parse(Cookies.get('authorities'));
    if(['helpdesk'].some(e => authorities.includes(e)))
      return;
    if(this.state.hasMarketingPreferences)
      return;
    if(localStorage.getItem('didMarketingPreferencesModalShown')==='true')
      return;
    if(this.state.expPaidModal){
      localStorage.setItem('didMarketingPreferencesModalShown', true)
      return;
    }
    if(this.state.changedActivityContainers.length > 0){
      localStorage.setItem('didMarketingPreferencesModalShown', true)
      return;
    }
    this.setState({marketingPreferencesModal: true});
  }

  marketingPreferencesModalSave(){
    api(this.props.layoutContext, '/api/customers/marketing-preferences', "POST", {lgpPortSelected: this.state.isSendUpdates,
      souPortSelected: this.state.isSendUpdates})
      .then(res=>{
        this.setState({marketingPreferencesModal: false});
        localStorage.setItem('didMarketingPreferencesModalShown', true)
      })
      .catch(err=>{console.log(err)})
  }

  marketingPreferencesModalClose(){
    this.setState({marketingPreferencesModal: false});
    localStorage.setItem('didMarketingPreferencesModalShown', true)
  }

  componentDidMount() {
    document.getElementById('react-app-header').scrollIntoView();
    this.props.layoutContext.getCmsComponent(
        ['Critical Alerts (DPWS)', 'Critical Alerts (LGPL)', 'Port Status',
          'Coming Soon', 'FAQ Dashboard'], this);
  }

  getHistoryStartDate() {
    let now = new Date();
    return now.setMonth(now.getMonth() - 6);
  }

  ceaseNotificationAndCloseModal() {
    api(this.props.layoutContext,
        "api/alert-subscription/notification-cessation", 'POST',
        this.state.notificationIdsOfChangedActivityContainers)
    .then(res => {
      res.status === 200 &&
      this.setState({
        changedActivityContainers: [],
        notificationIdsOfChangedActivityContainers: []
      });
    });
  }

  getAlertWarningMessage(available, pending, alertType) {
    if (available > 0) {
      return pending > 0 ? <p>Pending {alertType} alerts - {pending}</p> :
          <p>No {alertType} alerts set</p>;
    } else {
      return pending > 0 ?
          <p>Pending {alertType} alerts - {pending}. Currently suspended</p>
          : <p>No {alertType} alerts set - limit reached</p>;
    }
  }

  getAlertInformation() {
    const {alertSubscriptionsInfo} = this.state;
    if (alertSubscriptionsInfo.pendingEmail <= 0
        && alertSubscriptionsInfo.pendingSms <= 0
        && alertSubscriptionsInfo.availableEmail > 0
        && alertSubscriptionsInfo.availableSms > 0) {
      return <p>There are no alerts currently set</p>;
    } else if (alertSubscriptionsInfo.pendingEmail <= 0
        && alertSubscriptionsInfo.pendingSms <= 0
        && alertSubscriptionsInfo.availableEmail <= 0
        && alertSubscriptionsInfo.availableSms <= 0) {
      return <p>There are no alerts currently set - limits reached</p>;
    }
    return <>
      {this.getAlertWarningMessage(alertSubscriptionsInfo.availableSms,
          alertSubscriptionsInfo.pendingSms, 'SMS')}
      {this.getAlertWarningMessage(alertSubscriptionsInfo.availableEmail,
          alertSubscriptionsInfo.pendingEmail, 'Email')}
    </>
  }

  sortItemsByOrderAscAndPublishedDesc(c1, c2) {
    if (c1.displayOrder > c2.displayOrder) {
      return 1;
    } else if (c1.displayOrder < c2.displayOrder) {
      return -1;
    }

    if (c1.published > c2.published) {
      return -1;
    } else if (c1.published < c2.published) {
      return 1;
    }

    return 0;
  }

  getDashboardBoxHint(boxName){
    if (boxName !== 'history' && boxName !== 'alerts') {
      return ''
    }

    const className = boxName === 'history' ? 'fake-free-history-box' : 'fake-free-alerts-box';
    const isCustomerPaid = this.props.layoutContext.state.isPaidUser;
    const isCustomerFreeAdvanced = this.props.layoutContext.state.isFreeAdvancedUser;
    const hintText = "This is an advanced feature. For access to advanced features, check your My Account page";
    const position = 'top';

    if (isCustomerPaid || isCustomerFreeAdvanced) {
      return '';
    }else if (this.props.layoutContext.state.isMobile){
      return <React.Fragment>
        <ReactHint attribute='free-box-data' persist events={{click: true, hover: true}}/>
        <div className={className} free-box-data={hintText} free-box-data-at={position}/>
      </React.Fragment>
    }

    return <div className={className}><span>{hintText}</span></div>;
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }

    const cmsContentComingSoonSettings = {
      dots: true,
      infinite: true,
      speed: 1500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplaySpeed: 8000
    };

    const isCustomerPaid = this.props.layoutContext.state.isPaidUser;
    const isCustomerFreeAdvanced = this.props.layoutContext.state.isFreeAdvancedUser;
    const searchControlsSection = <Section
        className='dashboard-search-controls-section'>
      <span>
        <div className='dashboard-hint-wrapper'>
          <ReactHint attribute='dashboard-hint-data' persist
                     events={{hover: true, click: true}}/>
        </div>
         <div className="dashboard_saved-list-button-wrapper" search-button="This is an advanced feature. For access to advanced features, check your My Account page" search-button-at="top">
            <Button
                className='saved-list-button'
                disabled={!(isCustomerPaid || isCustomerFreeAdvanced)}
                onClick={e => {
                  e.preventDefault();
                  this.setState({redirectTo: '/saved-lists'})
                }}
                color="pink"
                icon="arrow"
            >
              Saved lists
            </Button>
          </div>
      </span>
      <Button
          id='dashboard-page-search-containers-button'
          onClick={e => {
            e.preventDefault();
            this.form.validateAll();
            this.searchContainers();
            this.searchContainerClickEvent();
          }}
          color="pink"
          icon="arrow"
      >
        Search
      </Button>
    </Section>;

    return <div className="dashboard_wrap">
      <div className="main_wrap page_id_dashboard">
        <div className="header_critical_alert_wrapper">
          <CriticalAlertComponent sou={this.state.criticalalertsdpws}
                                  lgp={this.state.criticalalertslgpl}/>
          <h2 className="dashboard_header">Welcome Back</h2>
          <h4>Start a new container search:</h4>
        </div>
        {!(isCustomerPaid || isCustomerFreeAdvanced) && <ReactHint attribute='search-button' persist events={{hover: true, click: true}}/>}
        <Section className='dashboard_container-search-section' size="6">
          <Form
              className="search-form"
              ref={node => {
                this.form = node;
              }}
              onSubmit={event => {
                event.preventDefault();
              }}
          >

          <Textarea
              id='container-numbers-text-area'
              name="container-numbers"
              className="search-numbers"
              placeholder="Enter container number(s) here"
              validations={[requiredAtLeastOneContainerNumber,
                numberOfContainers, searchContainerFormat]}
              maxcontainernumbers={20}
              containernumberformat={dataValidationPatterns.containerNumber}
          />
            <div className="search-section-bottom">
              <label>
                Enter up to 20 container numbers separated by commas, spaces,
                tabs, hyphens or line breaks.
              </label>
            </div>

            {!this.props.layoutContext.state.isMobile && searchControlsSection}

          </Form>
        </Section>
        {this.props.layoutContext.state.isMobile && searchControlsSection}

        <Section className='dashboard_my-account-section' title="My Account"
                 color="light-blue" size="2" mobileAdaptive>
        <span className='mobile-section-content'>
          <span className='section-info'>
            {this.state.lastLoginDate && <strong>Last log-in {formatDate(
                this.state.lastLoginDate)}</strong>}
            <p>Manage your account preferences and settings</p>
          </span>
          <span className='section-link'>
            <Link className="button button_color_pink button_icon" onClick={() => this.myAccountLinkClickEvent()}
                  to="/account">
                {Arrow()}
            </Link>
          </span>
        </span>
        </Section>

        <span className="dashboard_alerts-section-wrapper">
          {this.getDashboardBoxHint('alerts')}
          <Section
              className={"dashboard_alerts-section" + (isCustomerPaid
              || isCustomerFreeAdvanced ? "" : ' section_color_grey')}
              title="Alerts"
              color="light-blue" mobileAdaptive>
          <span className='mobile-section-content'>
            <span className='section-info'>

              {isCustomerPaid || isCustomerFreeAdvanced ?
                  this.getAlertInformation()
                  : <p>There are no alerts currently set</p>}

            </span>
              <span className='section-link'>
                <Button
                    className={"button button_color_pink button_icon"
                    + (isCustomerPaid || isCustomerFreeAdvanced ? ""
                        : " disabled-button")}
                    disabled={!(isCustomerPaid || isCustomerFreeAdvanced)}
                    onClick={e => {
                      e.preventDefault();
                      this.setState({redirectTo: '/alert-templates'})
                    }}
                    icon="arrow"
                />
              </span>
            </span>
          </Section>
        </span>

        <span className="dashboard_history-section-wrapper">
        {this.getDashboardBoxHint('history')}
        <Section
            className={"dashboard_history-section" + (isCustomerPaid
            || isCustomerFreeAdvanced ? "" : " section_color_grey")}
            title="History"
            color="light-blue" mobileAdaptive>
        <span className='mobile-section-content'>
          <span className='section-info'>
            <p>History from {formatDateAsFullMonthAndYear(
                this.getHistoryStartDate())}</p>
            </span>
            <span className='section-link'>
              <Button
                  className={"button button_color_pink button_icon"
                  + (isCustomerPaid || isCustomerFreeAdvanced ? ""
                      : " disabled-button")}
                  disabled={!(isCustomerPaid || isCustomerFreeAdvanced)}
                  onClick={e => {
                    e.preventDefault();
                    this.setState({redirectTo: '/history-search'})
                  }}
                  icon="arrow"
              />
            </span>
          </span>
        </Section>
        </span>

        {this.state.faqdashboard && this.state.faqdashboard[0].content}

        {this.state.portstatus && this.state.portstatus[0].content}

        {this.state.comingsoon &&
        <div className="dashboard_coming-soon-section section_size_6">
          <Slider {...cmsContentComingSoonSettings}>
            {this.state.comingsoon
            .sort(this.sortItemsByOrderAscAndPublishedDesc)
            .map((item, i) =>
                <React.Fragment key={i}>{item.content}</React.Fragment>)
            }
          </Slider>
        </div>
        }

        <table className="dashboard_contactInfo">
          <colgroup>
            <col width="15%"/>
            <col width="90%"/>
          </colgroup>
          <tbody>
          <tr>
            <td colSpan="2">
              If you are experiencing problems with container information please
              contact:
            </td>
          </tr>
          <tr className='contact-information-row'>
            {this.props.layoutContext.state.isMobile ? <React.Fragment>
                  <td colSpan="2">
                    London Gateway:<br/>
                    <a href="mailto:customer.services@londongateway.com">customer.services@londongateway.com</a>
                  </td>
                </React.Fragment>
                : <React.Fragment>
                  <td colSpan="1">
                    London Gateway:
                  </td>
                  <td colSpan="2">
                    <a href="mailto:customer.services@londongateway.com">customer.services@londongateway.com</a>
                  </td>
                </React.Fragment>
            }
          </tr>

          <tr className='contact-information-row'>
            {this.props.layoutContext.state.isMobile ? <React.Fragment>
                  <td colSpan="2">
                    Southampton:<br/>
                    <a href="mailto:operationsadmin@dpworldsouthampton.com">operationsadmin@dpworldsouthampton.com</a>
                  </td>
                </React.Fragment>
                : <React.Fragment>
                  <td colSpan="1">
                    Southampton:
                  </td>
                  <td colSpan="2">
                    <a href="mailto:operationsadmin@dpworldsouthampton.com">operationsadmin@dpworldsouthampton.com</a>
                  </td>
                </React.Fragment>
            }
          </tr>
          </tbody>
        </table>
        <br/>
      </div>

      <ReactModal appElement={document.querySelector('react-app')}
                  isOpen={this.state.changedActivityContainers.length > 0}
                  className="app-modal">
        <Button id="close-modal" onClick={() => {
          this.setState({
            changedActivityContainers: [],
            notificationIdsOfChangedActivityContainers: []
          })
        }}>×</Button>
        <div className='changed-activity-modal'>
          <h3>The Activity of the following containers has changed
            <div className='changed-activity-modal-container-list'>
              {splitContainersByModalColumns(
                  this.state.changedActivityContainers)}
            </div>
            Please note these container numbers if you wish to check them
            <br/>You will need to re-apply alerts if you still require them.
            <br/><b>Click 'OK' to remove this notice.</b>
          </h3>

          <div className='changed-activity-modal-controls'>
            <Button color="blue"
                    onClick={() => this.ceaseNotificationAndCloseModal()}>OK</Button>
          </div>
        </div>
      </ReactModal>


      <ReactModal appElement={document.querySelector('react-app')}
                  isOpen={this.state.expPaidModal}
                  className="app-modal">
        <Button id="close-modal" onClick={() => {this.expPaidModalClose()}}>×</Button>
        <div className='changed-activity-modal'>
          <h3>Your current subscription to the advanced features will expire at midnight on {moment(this.state.alertSubscriptionsInfo.paidSubscriptionExpirationDate).format('DD/MM/YYYY')}.
            <br/>You can renew your subscription from your My Account page at any time.
          </h3>

          <div className='changed-activity-modal-controls'>
            <Button color="blue"
                    onClick={() => this.expPaidModalClose()}>OK</Button>
          </div>
        </div>
      </ReactModal>

      <ReactModal appElement={document.querySelector('react-app')}
                  isOpen={this.state.marketingPreferencesModal}
                  className="app-modal">
        <Button id="close-modal" onClick={() => {this.marketingPreferencesModalClose()}}>×</Button>
        <div className='changed-activity-modal'>
          <Form>

                <h2 className="register__centered">Can we send you updates?</h2>
                <p>
                If you would like to receive updates about product or service enhancements to <b>Where's my container?</b> and updates from DP World in the UK about supply chain operations please let us know below.
                </p>
                <div className="register__centered register__flex-updates">
                <div className="register__flex-input-item">
                  <Input
                    type="radio"
                    id="sendUpdatesTrue"
                    name="sendUpdates"
                    onChange={e=>{this.setState({isSendUpdates: true})}}
                    value={'true'}
                    validations={[this.radioChecked]}
                    checked={this.state.isSendUpdates === true}
                />
                  <label htmlFor="sendUpdatesTrue">Yes</label></div>
                  <div className="register__flex-input-item" style={{marginLeft:'20px'}}>
                  <Input
                    type="radio"
                    id="sendUpdatesFalse"
                    name="sendUpdates"
                    onChange={e=>{this.setState({isSendUpdates: false})}}
                    value={'false'}
                    validations={[this.radioChecked]}
                    checked={this.state.isSendUpdates === false}
                  />
                  <label htmlFor="sendUpdatesFalse">No</label></div>
                </div>

          <div className='changed-activity-modal-controls'>
            <Button color="blue"
            isFormValidationRequired={true}
                    onClick={(e) =>{e.preventDefault(); this.marketingPreferencesModalSave()}}>OK</Button>
          </div>
          </Form>
        </div>
      </ReactModal>



    </div>
  }
}

export default props => (
    <LayoutContext.Consumer>
      {layoutContext =>
          <Context.Consumer>
            {context => <Dashboard {...props} layoutContext={layoutContext}
                                   context={context}/>}
          </Context.Consumer>
      }
    </LayoutContext.Consumer>

);
