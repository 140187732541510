export default {
  'Inbound at Sea': 'inbound-at-sea.svg',
  'Arrived': 'on-vessel-at-berth.svg',
  'On Vessel at Berth': 'on-vessel-at-berth.svg',
  'On Hold': 'on-hold.svg',
  'On Hold - Processed': 'on-hold.svg',
  'Discharged': 'discharged.svg',
  'Available for Collection': 'available-for-collection.svg',
  'Released for Rail': 'released-for-rail.svg',
  'Departed Port for Rail Terminal': 'departed-port-for-rail-terminal.svg',
  'Departed Rail Terminal': 'at-rail-terminal-on-train.svg',
  'Departed by Road': 'departed-by-road.svg',
  'Inbound by Road': 'inbound-by-road.svg',
  'Ready for Loading': 'ready-for-loading.svg',
  'Planned for Vessel': 'planned-for-vessel.svg',
  'On Vessel at Sea': 'on-vessel-at-sea.svg',
  'Inbound by Rail': 'inbound.svg',
  'In Storage': 'in-storage.svg',
  'Released': 'released.svg',
  'Released but not yet bookable': 'released-but-not-bookable.svg',
  'Departed': 'on-vessel-at-sea.svg',
  null : 'unknown.svg'
};