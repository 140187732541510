import React from "react";
import "./section.css";
import "./section.mobile.css";
import {getCmsMediaFromCacheByName} from "./../../services/cms-integration";

export default class Section extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cmsImage: '',
      height: 0,
      width: 0
    };
  }

  componentWillMount() {
    window.addEventListener('resize', this.setComponentSize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setComponentSize.bind(this));
  }

  componentDidMount() {
    this.setComponentSize();
  }

  setComponentSize = () => {
    if(this.sectionRef){
      this.setState({height: this.sectionRef.clientHeight, width: this.sectionRef.clientWidth});
    }
  };

  title() {
    if (!this.props.title) { return; }

    return <div className='section-title-wrapper'><h2 className={(this.props.displayMode === 'Text' && this.props.titleMode && this.props.titleMode.toLowerCase()) || ''}>{this.props.title}</h2></div>;
  }

  className() {
    const classNames = ["section"];

    if (this.props.color) {
      classNames.push(`section_color_${this.props.color}`);
    }

    if (this.props.size) {
      classNames.push(`section_size_${this.props.size}`);
    }

    if (this.props.mobileAdaptive) {
      classNames.push('section_mobile_adaptive')
    }
    if(this.props.className) {
      classNames.push(this.props.className)
    }

    if(this.props.link) {
      classNames.push('section_cursor_pointer')
    }

    return classNames.join(' ');
  }

  getImageValue(){
    if(this.props.imageFileName && (!this.state.cmsImage || this.props.imageFileName !== this.state.cmsImage.fileName)){
      getCmsMediaFromCacheByName(this.props.imageFileName, this);
      return this.state.cmsImage ? this.state.cmsImage.content : '';
    };
  }

  getSectionStyle(backgroundImageValue) {
    if (backgroundImageValue) {
      return {
        color: this.props.textColorCode,
        backgroundImage: `linear-gradient(rgba(255,255,255,${this.getTransparency()}), rgba(255,255,255,${this.getTransparency()})), url(${backgroundImageValue})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: this.state.height > this.state.width ? 'auto 100%' : '100% auto'
      };
    } else {
      return {
        backgroundColor: this.props.backgroundColorCode,
        color: this.props.textColorCode
      };
    }
  }

  getTransparency() {
    return 1 - this.props.opacity;
  }

  render() {
    this.getImageValue();

    let sectionStyle = this.getSectionStyle(this.props.imageFileName
        && (this.props.imagePosition === 'Background' || this.props.displayMode === 'Image')
            && this.state.cmsImage && this.state.cmsImage.content);

    return <section className={this.className()}
                    ref={(sectionRef) => this.sectionRef = sectionRef}
                    style={sectionStyle}
                    onClick={() => this.props.link && window.open(this.props.link)}>
      {this.props.displayMode!=='Image' && this.title()}
      {(this.props.innerContent || this.state.cmsImage) &&
        <span className={this.props.innerContent && 'section-content-wrapper'}>
          {this.props.imagePosition === 'Right' && <img src={this.state.cmsImage && this.state.cmsImage.content} alt={this.props.mediaTitle} style = {
            {
              float: 'right',
              padding: '10px',
              opacity: this.props.opacity
            }
          }/>}
          {this.props.imagePosition === 'Left' && <img src={this.state.cmsImage && this.state.cmsImage.content} alt={this.props.mediaTitle} style = {
            {
              float: 'left',
              padding: '10px',
              opacity: this.props.opacity
            }
          }/>}
          {this.props.imagePosition === 'Center' && <img src={this.state.cmsImage && this.state.cmsImage.content} alt={this.props.mediaTitle} style = {
            {
              display: 'block',
              margin: 'auto',
              opacity: this.props.opacity
            }
          }/>}
          {this.props.innerContent}
        </span>
      }
      {this.props.children}
    </section>
  }
};

Section.defaultProps = {
  size: 12
}
