import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import { Button } from '../..';
import { api, filterInput, dataValidationPatterns, cleanForm, isValidForm } from '../../../services';
import { required, postcode, phone } from '../../../validations';
import "./account-details.css";

export default class AccountDetails extends React.Component{

  constructor(props) {
    super(props);

    const {accountInfo} = props;

    this.state = {
      validationError: false,
      name: accountInfo.name,
      email: accountInfo.email,
      postcode: accountInfo.postcode,
      companyName: accountInfo.companyName,
      phone: accountInfo.phone,
      roleType: accountInfo.roleType,
      isUpdateInProgress: false
    };
  }

  componentDidMount(){
    this.getRoleTypes();
  }

  componentWillReceiveProps(nextProps){
    if (this.notEqualsPropsAndNextProps(this.props, nextProps)) {
      const {accountInfo} = nextProps;

      this.setState({
        validationError: false,
        name: accountInfo.name,
        email: accountInfo.email,
        postcode: accountInfo.postcode,
        companyName: accountInfo.companyName,
        phone: accountInfo.phone,
        roleType: accountInfo.roleType,
        isUpdateInProgress: false
      })
    }
  }

  notEqualsPropsAndNextProps(props, nextProps){
    return nextProps.accountInfo.postcode !== props.accountInfo.postcode
        || nextProps.accountInfo.companyName !== props.accountInfo.companyName
        || nextProps.accountInfo.phone !== props.accountInfo.phone
        || nextProps.accountInfo.roleType !== props.accountInfo.roleType;
  }

  isChangedReadOnlyMode(state, nextState){
    return state == null || nextState.isReadOnlyMode !== state.isReadOnlyMode || nextState.validationError;
  }

  getRoleTypes(){
    api(this.props.layoutContext, '/api/type/role-types')
    .then(res => {
      if (res.status === 200) {
        res.json().then(roles => this.setState({ roles: roles }));
      }
    })
  }

  isReadOnlyMode(){
    return this.state == null || typeof this.state.isReadOnlyMode === 'undefined' || this.state.isReadOnlyMode;
  }

  updateAccount(){
    if(!isValidForm(this.form)) return Promise.reject(new Error("Not valid"));

   this.setState({isUpdateInProgress: true});
    const values = this.form.getValues();
    const body = {
      "companyName": values["company"],
      "phone": values["phone"],
      "postcode": values["postcode"],
      "roleType": values["role-in-company"]
    };


    api(this.props.layoutContext, '/api/customers/account-details/details', 'PUT', body)
    .then(res => {
      if (res.status === 200) {
        this.setState({
            previousPostcode: this.state.postcode,
            previousCompanyName: this.state.companyName,
            previousPhone: this.state.phone,
            previousRoleType: this.state.roleType,
            isReadOnlyMode: true,
            isUpdateInProgress: false
          });
        cleanForm(this.form);
      }
    })
    .catch(() => {
      this.setState({isUpdateInProgress: false })
    });
  }

  showOptions(options){
    let result = [];
    let value;

    if (options.length > 0) {
      options.forEach(option => {
        value = option.toLowerCase()
          .replace(/[^A-Za-z0-9\s]/g, '')
          .replace(/\s+/g, '-');
        result.push(<option key={value} value={option}>{option}</option>)
      })
    }
    return result;
  }

  render(){
    const isReadOnlyMode = this.isReadOnlyMode();

    const controls = isReadOnlyMode ?
        <Button
                id="edit_button"
                color="light-blue"
                onClick={() =>{
                  this.setState(state =>{
                    return{
                      previousPostcode: state.postcode,
                      previousCompanyName: state.companyName,
                      previousPhone: state.phone,
                      previousRoleType: state.roleType,
                      isReadOnlyMode: false
                    }
                  });
                }}
            >
              Edit
            </Button>
            : <React.Fragment>
              <Button
                  color="blue"
                  onClick={() => {
                    this.setState(state =>{
                      return{
                        postcode: state.previousPostcode,
                        companyName: state.previousCompanyName,
                        phone: state.previousPhone,
                        roleType: state.previousRoleType,
                        isReadOnlyMode: true
                      }
                    });
                    cleanForm(this.form);
                  }}
              >
                Cancel
              </Button>
              <Button
                  isFormValidationRequired
                  disabled={this.state.isUpdateInProgress}
                  color="pink"
                  icon="arrow"
                  onClick={() => {
                    this.updateAccount()
                  }}
              >
                Update
              </Button>
            </React.Fragment>;

    return <React.Fragment>
      <Form
          className='account-details-form'
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
      >
        <div>
          <label>Name:</label>
          <div>
            <div>{this.state.name}</div>
          </div>
        </div>
        <div>
          <label>Postcode:</label>
          {isReadOnlyMode ? <div>
            <div>{this.state.postcode}</div>
          </div>
              : <Input
                  name="postcode"
                  value={this.state.postcode || ''}
                  validations={[required, postcode]}
                  minLength={3}
                  maxLength={50}
                  onChange={(e) => filterInput.call(this, e, 'postcode', dataValidationPatterns.postcode)}
              />}
        </div>
        <div>
          <label>Company:</label>
          {isReadOnlyMode ? <div>
            <div>{this.state.companyName}</div>
          </div>
              :
              <Input
                  name="company"
                  value={this.state.companyName || ''}
                  validations={[required]}
                  minLength={1}
                  maxLength={200}
                  onChange={(e) => filterInput.call(this, e, 'companyName', dataValidationPatterns.companyName)}
              />}
        </div>
        <div>
          <label>Role in Company:</label>
          {isReadOnlyMode ?
          <div>
            <div>{this.state.roleType}</div>
          </div>
              :
              <Select
                  value={this.state.roleType || ''}
                  name="role-in-company"
                  validations={[required]}
                  onChange={e => this.setState({ roleType: e.target.value })}
              >
                {this.showOptions(this.state.roles || [])}
              </Select>}
        </div>
        <div>
          <label>Email:</label>
          <div>
            <div>{this.state.email}</div>
          </div>
        </div>
        <div>
          <label>Phone:</label>
          {isReadOnlyMode ? <div>
            <div>{this.state.phone}</div>
          </div>
              :
              <Input
                  name="phone"
                  value={this.state.phone || ''}
                  validations={[required, phone]}
                  minLength={6}
                  maxLength={20}
                  onChange={e => filterInput.call(this, e, 'phone', dataValidationPatterns.phone)}
              />}
        </div>
        <div>
          {controls}
        </div>
      </Form>
    </React.Fragment>;
  }
};
