import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Select from 'react-validation/build/select';
import {Button} from '../..';
import {api, isValidForm} from "../../../services";

import {requiredHelpDesk} from "../../../validations";

import './customer-secret-question.css'

export default class CustomerSecretQuestion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  setDefaultPropsInState(secretQuestionAnswers) {
    if (typeof secretQuestionAnswers !== 'undefined' && secretQuestionAnswers !== '') {
      this.setState({
        secretQuestionType: secretQuestionAnswers[0].secretQuestionType,
        answer: secretQuestionAnswers[0].answer,
        previousAnswer: secretQuestionAnswers[0].answer,
        secretQuestionAnswerId: secretQuestionAnswers[0].secretQuestionAnswerId
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this.setDefaultPropsInState(nextProps.secretQuestionAnswers);
      this.setState({secretQuestionTypes: nextProps.secretQuestionTypes});
    }
  }

  showOptions(options) {
    let result = [];
    let value;

    if (options.length > 0) {
      options.forEach(option => {
        value = option.toLowerCase()
        .replace(/[^A-Za-z0-9\s]/g, '')
        .replace(/\s+/g, '-');
        result.push(<option key={value} value={option}>{option}</option>)
      })
    }
    return result;
  }

  filterInput(e, key, pattern) {
    this.setState({
      [key]: e.target.value.replace(pattern, '')
    })
  }

  updateSecretQuestion(form) {
    if (!isValidForm(form)) {
      return Promise.reject(new Error("Not valid"));
    }

    const values = form.getValues();
    const body = {
      "secretQuestionAnswerId": this.state.secretQuestionAnswerId,
      "secretQuestionType": values["secretQuestionType"],
      "answer": values["answer"]
    };

    return api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/secret-question-answers`, 'PUT', body);
  }

  getSecretQuestionAnswers() {
    return api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/secret-question-answers`).then(
        res => {
          if (res.status === 200) {
            res.json().then(responseBody => {
                this.setDefaultPropsInState(responseBody);
            });
          }
        });
  }

  restoreValues() {
    this.getSecretQuestionAnswers();
  }


  render() {
    let isNotVerified = this.props.statusType === 'Unverified';

    return <React.Fragment>
      <Form
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
      >
        <div>
        <label>Secret Question:</label>
        <Select
            name='secretQuestionType'
            value={this.state.secretQuestionType || ''}
            onChange={e => {
              this.setState({
                answer: null,
                secretQuestionType: e.target.value
              });
              document.getElementById("answer-input-id").focus();
              this.form.state.byId[this.form.state.byName['answer']].isUsed = false;
            }}
            disabled={isNotVerified}
        >
          {this.showOptions(this.props.secretQuestionTypes || [])}
        </Select>
        </div>
        <div>
          <label>Answer:</label>
          <Input
              id={isNotVerified ? "unverified" : "answer-input-id"}
              name='answer'
              value={this.state.answer || ''}
              validations={[requiredHelpDesk]}
              maxLength={200}
              onChange={e => this.filterInput(e, 'answer',
                  /[^A-Za-z\s\d_"'~`!@#$%*^&()={}[\]\\/:;,.<>+?-]/g)}
              readOnly={isNotVerified}
              onBlur={()=>this.form.validateAll()}
          />
        </div>

        <div>
          <div className="button_generalSecretQuestion">
            <div className="buttonDivSecretQuestion">
              <div className="buttonCancelSecretQuestion">
                <React.Fragment>
                  <Button
                      disabled={isNotVerified}
                      color="blue"
                      onClick={() => {
                        this.restoreValues();
                      }}
                  >
                    Cancel
                  </Button>
                </React.Fragment>
              </div>
              <div className="buttonUpdateSecretQuestion">
                <React.Fragment>
                  <Button
                      disabled={isNotVerified || this.state.answer === null || this.state.answer === '' || this.state.answer === this.state.previousAnswer}
                      color="pink"
                      icon="arrow"
                      onClick={() => {
                        this.updateSecretQuestion(this.form)
                        .then(res => {
                          if (res.status === 200) {
                            this.getSecretQuestionAnswers();
                          } else {
                            this.props.setConnectionProblems();
                          }
                        })
                        .catch(err => console.warn(err));
                      }}
                  >
                    Update
                  </Button>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </React.Fragment>;
  }
}