import React from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";

import Button from "../button/button";
import { Section, RegistrationConfirmation } from "..";
import { Redirect } from "react-router-dom";
import { api, isValidForm } from "../../services";
import {
  required,
  requiredDynamicSubType,
  password,
  passwordsEqual,
  email,
  emailsEqual,
  emailNotExist,
  postcode,
  phone
} from "../../validations";
import { Arrow } from "../button/icon";

import "./registration.css";
import "./registration.mobile.css";

export default class Registration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      businessTypes: [],
      businessSubTypes: [],
      businessSubType: "",
      roleTypes: [],
      secretQuestionTypes: [],
      firstName: "",
      email: "",
      emailConfirmation: "",
      postcode: "",
      phone: "",
      businessType: "",
      isEmailExist: "exist",
      isConfirmationOpened: false,
      redirectTo: "",
      isSubtypeRequired: false,
      isSubmitInProgress: false,
      sendUpdatesLondon:false,
      sendUpdatesSouthampton:false
    };
    this.setRequestForOptions();
  }

  radioChecked = () => {
    if (this.state.isSendUpdates === undefined) {
      return " ";
    }
  };

  checkboxChecked = () => {
    if (this.state.isSendUpdates === true && (!this.state.sendUpdatesSouthampton && !this.state.sendUpdatesLondon)) {
      return " ";
    }
  };

  openRegistrationConfirmation() {
    this.setState({ isConfirmationOpened: true });
  }

  setRequestForOptions() {
    this.getOptionsByType("businessTypes");
    this.getOptionsByType("businessSubTypes");
    this.getOptionsByType("roleTypes");
    this.getOptionsByType("secretQuestionTypes");
  }

  trimValue(data) {
    return data === null ? data : data.trim();
  }

  setRequestForRegistration() {
    const data = this.form.getValues();

    if (!isValidForm(this.form)) return false;

    this.setState({ isSubmitInProgress: true });
    const body = {
      businessSubType:
        (this.state.businessType &&
          this.isBusinessTypeImportExport() &&
          data["business-subtype"]) ||
        null,
      businessType: data["business-type"],
      companyName: this.trimValue(data["company-name"]),
      containersAmount: this.trimValue(data["containers-number"] || null),
      customerCredentialDto: {
        email: this.trimValue(data["email"]),
        password: this.trimValue(data["password"])
      },
      firstName: this.trimValue(data["first-name"]),
      lastName: this.trimValue(data["last-name"]),
      phone: this.trimValue(data["contact-number"]),
      postcode: this.trimValue(data["postcode"]),
      roleType: data["role-in-company"],
      secretQuestionAnswerDtos: [
        {
          answer: this.trimValue(data["seq-answer-1"]),
          secretQuestionType: data["security-question-type"]
        }
      ],
      marketingPreferencesDto: {
        lgpPortSelected: this.state.isSendUpdates,
        souPortSelected: this.state.isSendUpdates
      },
    };

    api(this.props.layoutContext, "/api/customers", "POST", body)
      .then(res => {
        if (res.status === 200) {
          this.openRegistrationConfirmation();
        } else {
          this.props.setConnectionProblems();
          this.setState({ isSubmitInProgress: false });
        }
      })
      .catch(() => {
        this.setState({ isSubmitInProgress: false });
      });
  }

  setRequestForEmailExistance(e) {
    if (!e.target.value) return false;
    api(
      this.props.layoutContext,
      "/api/emails/existence?email=" + encodeURIComponent(e.target.value)
    ).then(res => {
      if (res.status === 200) {
        res
          .json()
          .then(response =>
            this.setState({ isEmailExist: response ? "exist" : null })
          );
      }
    });
  }

  getOptionsByType(type) {
    let url = "";

    switch (type) {
      case "businessTypes":
        url = "/api/type/business-types";
        break;
      case "businessSubTypes":
        url = "/api/type/business-sub-types";
        break;
      case "roleTypes":
        url = "/api/type/role-types";
        break;
      case "secretQuestionTypes":
        url = "/api/type/secret-question-types";
        break;
      default:
        return false;
    }

    api(this.props.layoutContext, url).then(res => {
      res.status === 200
        ? res.json().then(response => this.setState({ [type]: response }))
        : setTimeout(() => this.getOptionsByType(type), 5000);
    });
  }

  showOptions(options, defaultValue) {
    let result = [
      <option key="default" value="">
        {defaultValue}
      </option>
    ];
    let value;

    if (options.length > 0) {
      options.forEach(option => {
        value = option
          .toLowerCase()
          .replace(/[^A-Za-z0-9\s]/g, "")
          .replace(/\s+/g, "-");
        result.push(
          <option key={value} value={option}>
            {option}
          </option>
        );
      });
    }

    return result;
  }

  showSubTypes() {
    const businessType = this.state.businessType;
    const isSubtypeRequired = businessType && this.isBusinessTypeImportExport();
    if (isSubtypeRequired && !this.state.isSubtypeRequired) {
      this.setState({ isSubtypeRequired: true });
    } else if (
      this.form &&
      businessType &&
      !this.isBusinessTypeImportExport() &&
      this.state.isSubtypeRequired
    ) {
      this.setState({ isSubtypeRequired: false });
      this.setState({ businessSubType: "" });
      this.form.state.byId[
        this.form.state.byName["business-subtype"]
      ].isUsed = false;
    }
  }

  isBusinessTypeImportExport() {
    const businessType = this.state.businessType;
    return ["Importer", "Exporter", "Importer and Exporter"].includes(
      businessType
    );
  }

  filterInput(e, key, pattern) {
    this.setState({
      [key]: e.target.value.replace(pattern, "")
    });
  }

  componentDidUpdate(nextProps, nextState) {
    this.showSubTypes();
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    return (
      <div className="reg_main_wrap page_id_register">
        <RegistrationConfirmation isOpen={this.state.isConfirmationOpened} />
        <h1>Register to search container information</h1>
        <Section color="white">
          <Form
            ref={node => {
              this.form = node;
            }}
            onSubmit={event => {
              event.preventDefault();
              this.setRequestForRegistration();
            }}
          >
            <fieldset>
              <div>
                <label className="required">First Name:</label>
                <Input
                  name="first-name"
                  value={this.state.firstName || ""}
                  onChange={e =>
                    this.filterInput(e, "firstName", /[^A-Za-z\s-']/g)
                  }
                  onBlur={() => this.form.validate("first-name")}
                  validations={[required]}
                  maxLength={200}
                />
              </div>

              <div>
                <label className="required">Last Name:</label>
                <Input
                  name="last-name"
                  value={this.state.lastName || ""}
                  onChange={e =>
                    this.filterInput(e, "lastName", /[^A-Za-z\s-']/g)
                  }
                  onBlur={() => this.form.validate("last-name")}
                  validations={[required]}
                  maxLength={200}
                />
              </div>

              <div>
                <label className="required">Email Address:</label>
                <Input
                  name="email"
                  value={this.state.email || ""}
                  onChange={e => this.filterInput(e, "email", /[^\w@\-.]/g)}
                  onBlur={e => {
                    this.form.validate("email");
                    this.setRequestForEmailExistance(e);
                  }}
                  validations={[required, email, emailsEqual, emailNotExist]}
                  maxLength={200}
                  isemailexist={this.state.isEmailExist}
                />
              </div>
              <div>
                <label className="required">Confirm Email:</label>
                <Input
                  name="confirm-email"
                  value={this.state.emailConfirmation || ""}
                  onChange={e =>
                    this.filterInput(e, "emailConfirmation", /[^\w@\-.]/g)
                  }
                  onBlur={() => this.form.validate("confirm-email")}
                  validations={[required, emailsEqual]}
                  maxLength={200}
                />
              </div>
              <div>
                <label className="required" name="password">
                  Password:
                </label>
                <div className="info info-password" onClick={() => {}}>
                  i
                </div>
                <Input
                  type="password"
                  name="password"
                  validations={[required, password, passwordsEqual]}
                  onBlur={() => this.form.validate("password")}
                  maxLength={50}
                  minLength={8}
                />
              </div>
              <div>
                <label className="required">Confirm Password:</label>
                <Input
                  type="password"
                  name="confirm-password"
                  validations={[required, password, passwordsEqual]}
                  onBlur={() => this.form.validate("confirm-password")}
                  maxLength={50}
                  minLength={8}
                />
              </div>
              <div>
                <label className="required">Contact Phone Number:</label>
                <Input
                  name="contact-number"
                  value={this.state.phone || ""}
                  onChange={e => this.filterInput(e, "phone", /[^\d]/g)}
                  validations={[required, phone]}
                  onBlur={() => this.form.validate("contact-number")}
                  maxLength={20}
                  minLength={6}
                />
              </div>
              <div>
                <label className="required">Postcode:</label>
                <Input
                  name="postcode"
                  value={this.state.postcode || ""}
                  onChange={e =>
                    this.filterInput(e, "postcode", /[^A-Za-z\d\-\s]/g)
                  }
                  validations={[required, postcode]}
                  onBlur={() => this.form.validate("postcode")}
                  maxLength={50}
                  minLength={3}
                />
              </div>
              <div className="registration__security-question">
                <label className="required">Security Question:</label>
                <Select
                  name="security-question-type"
                  validations={[required]}
                  onBlur={() => this.form.validate("security-question-type")}
                >
                  {this.showOptions(
                    this.state.secretQuestionTypes,
                    "Select question"
                  )}
                </Select>
              </div>
              <div>
                <label className="required">Answer:</label>
                <Input
                  name="seq-answer-1"
                  value={this.state.answer || ""}
                  onChange={e =>
                    this.filterInput(
                      e,
                      "answer",
                      /[^A-Za-z0-9\s_"'~`!@#$%*^&()={}[\]\\/:;,.<>+?-]/g
                    )
                  }
                  onBlur={() => this.form.validate("seq-answer-1")}
                  validations={[required]}
                  maxLength={200}
                />
              </div>
            </fieldset>
            <fieldset>
              <div>
                <label className="required">Company Name:</label>
                <Input
                  name="company-name"
                  value={this.state.companyName || ""}
                  onChange={e =>
                    this.filterInput(
                      e,
                      "companyName",
                      /[^A-Za-z\d\-\s_"'~`!@#$%*^&()={}[\]\\/:;,.<>+?-]/g
                    )
                  }
                  onBlur={() => this.form.validate("company-name")}
                  validations={[required]}
                  maxLength={200}
                />
              </div>

              <div className="registration__role-in-company">
                <label className="required">Role in Company:</label>
                <Select
                  name="role-in-company"
                  validations={[required]}
                  onBlur={() => this.form.validate("role-in-company")}
                >
                  {this.showOptions(
                    this.state.roleTypes,
                    "Select your role in the company"
                  )}
                </Select>
              </div>
              <div className="registration__business-type">
                <label className="required">Business Type:</label>
                <Select
                  name="business-type"
                  validations={[required]}
                  onChange={e =>
                    this.setState({ businessType: e.target.value })
                  }
                  onBlur={() => {
                    this.form.validate("business-type");
                  }}
                >
                  {this.showOptions(
                    this.state.businessTypes,
                    "Select business type"
                  )}
                </Select>
              </div>
              <div
                className={
                  this.state.isSubtypeRequired ? "visibleDiv" : "invisibleDiv"
                }
              >
                <label className="required">Business Sub Type:</label>
                <Select
                  name="business-subtype"
                  ref={node => {
                    this.subTypeSelect = node;
                  }}
                  value={this.state.businessSubType}
                  validations={[requiredDynamicSubType]}
                  onBlur={() => this.form.validate("business-subtype")}
                  onChange={e =>
                    this.setState({ businessSubType: e.target.value })
                  }
                >
                  {this.showOptions(
                    this.state.businessSubTypes,
                    "Select business sub type"
                  )}
                </Select>
              </div>
              <div
                className={
                  this.state.isSubtypeRequired ? "visibleDiv" : "invisibleDiv"
                }
              >
                <label name="containers-number">
                  Can you tell us the approximate number of all containers you
                  ship through the UK per month?
                </label>
                <Input
                  name="containers-number"
                  value={this.state.containersNumber || ""}
                  onChange={e =>
                    this.filterInput(e, "containersNumber", /[^\d]/g)
                  }
                  maxLength={3}
                />
              </div>
              <div className="register__update_container">
                <p className="register__centered register__header">Can we send you updates?</p>
                <p style={{marginBottom: '10px', marginTop: '5px'}}>
                If you would like to receive updates about product or service enhancements to <b>Where's my container?</b> and updates from DP World in the UK about supply chain operations please let us know below.
                </p>
                <div className="register__centered register__flex-updates">
                <div className="register__flex-input-item">
                  <Input
                    type="radio"
                    id="sendUpdatesTrue"
                    name="sendUpdates"
                    onChange={e=>{this.setState({isSendUpdates: true})}}
                    value={'true'}
                    validations={[this.radioChecked]}
                    checked={this.state.isSendUpdates === true}
                />
                  <label htmlFor="sendUpdatesTrue">Yes</label></div>
                  <div className="register__flex-input-item" style={{marginLeft:'20px'}}>
                  <Input
                    type="radio"
                    id="sendUpdatesFalse"
                    name="sendUpdates"
                    onChange={e=>{this.setState({isSendUpdates: false})}}
                    value={'false'}
                    validations={[this.radioChecked]}
                    checked={this.state.isSendUpdates === false}
                  />
                  <label htmlFor="sendUpdatesFalse">No</label></div>
                </div>
              </div>
            </fieldset>

            <div className="register__button_row">
              <button
                className="button button_color_blue registration_button_back"
                onClick={() => this.setState({ redirectTo: "/login" })}
              >
                Back to sign-in
              </button>
              <Button
                isFormValidationRequired={true}
                disabled={this.state.isSubmitInProgress}
                className="registration__button_submit"
                onClick={() => {
                  this.form.validateAll();
                }}
              >
                Submit
                {Arrow()}
              </Button>
            </div>
          </Form>
        </Section>
      </div>
    );
  }
}
