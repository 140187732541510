import React from 'react';
import { Section } from '..';

import './privacy-policy.css';
import './privacy-policy.mobile.css';

export default class PrivacyPolicy extends React.Component {

  render() {

    return <div className="policy-page privacy_policy_main_wrap page_privacy_policy">
      <h1>Privacy Policy</h1>
      <Section color="white">
        <ol className="withoutPoint" type="1" start={1}>
          <h3 className="marginMainPoints"><li className="withoutPoint">INTRODUCTION AND SCOPE</li></h3>
            <li>
              Community Network Services Ltd is a company registered in England No: 2084279 and we have our registered office located at
              16 Palace Street, London SW1E 5JQ (the “<b>Company</b>” or “<b>we</b>” or “<b>us</b>”). Our main trading address is Western Docks,
              Southampton, SO15 1DA, VAT registration number 458998463.
            </li>
            <li>
              CNS is committed to protecting and respecting personal data that it handles in connection with the services it provides to its users,
              particularly in compliance with data protection law.
            </li>
             <li>
              Community Network Services Limited (CNS) is a wholly owned subsidiary of DP World Southampton.
            </li>
            <li>
              This privacy policy describes how we use your personal data when you use our
              website <a className="site" href="https://www.wheresmycontainer.co.uk">www.wheresmycontainer.co.uk</a> (“<b>Our Website</b>”),
              any apps we provide (as applicable), email or other correspondence or when we provide products or services to you.
            </li>
            <li>
              You have legal rights to access and to know how we use the personal data that we hold about you.
            </li>
            <li>
              You can read, print and save this whole policy.  We use sub-headings to help you find specific information that you may be looking for more easily.
            </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">DATA CONTROLLER</li></h3>
            <li>
              Our Website is operated under the legal jurisdiction of England.  For the purpose of the General Data Protection Regulation
              (Regulation EU 2016/679 of the European Parliament and of the Council) (the “<b>GDPR</b>”), the Company is the data controller.
            </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">FURTHER INFORMATION</li></h3>
            <li>
              For any further information regarding this Privacy Policy, advice or guidance, please
              email <a className="email" href="mailto:cnsprivacy@cnsonline.net">cnsprivacy@cnsonline.net</a>
            </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">INFORMATION WE COLLECT FROM OR ABOUT YOU</li></h3>
            <div>
              <li>
                We collect and process different types of personal data via Our Website, which may include the following:
                <br/>
              </li>
              <ol className="alphaWithBracket internal-ol">
                <li className="alphaWithBracket">
                  <b>Personal identifiers/details</b> - such as name, address, employer, job title, contact details
                  (e.g. email and phone number), which you provide to us through our registration, enquiry or contact forms,
                  direct interactions, or if you correspond with us via email and which we use:
                  <ul type="circle">
                    <li>
                      to fulfil any request you make (e.g. submitting a query on Our Website, subscribing to a communications
                      service, fulfilling requests for information, entering into a competition, promotion, survey etc.);
                    </li>
                    <li>
                      to allow you to participate in any interactive features of our service, when you choose to do so.
                    </li>
                  </ul>
                </li>
                <li className="alphaWithBracket">
                  <b>Marketing data</b> – such as to create a database of people who have expressed interest in our companies
                  (subsidiaries or affiliates, including those within the DP World Group), products or services; including creating
                  and maintaining a mailing list of people who have expressed an interest in receiving electronic marketing communications from us.
                </li>
                <li className="alphaWithBracket">
                  <b>Feedback and opinions</b> - which you provide to us including through our enquiry or contact forms is processed
                  to allow us to answer queries and complaints you may have and to help us evaluate the quality and consistency of our services.
                </li>
                <li className="alphaWithBracket">
                  <b>Website and communication usage</b> - including your IP address, visits to Our Website, browser type and version,
                  time zone setting, browser plug-in types and versions, operating system and platform and information about your visit,
                  including the full Uniform Resource Locators (URL), clickstream to, through and from Our Website (including date and time),
                  products or services you viewed or searched for, page response times, download errors, length of visits to certain pages,
                  page interaction information (such as scrolling, clicks, and mouse-overs), methods used to browse away from the page and
                  any phone number used to call our customer service number which we collect from your browser to enable us to monitor the
                  performance and relevance of Our Website and our ad words campaigns, to ensure that content from Our Website is presented
                  in the most effective manner for you and for your computer, and to keep Our Website safe and secure.
                </li>
                <li className="alphaWithBracket">
                  <b>Cookie usage</b> - Our Website uses cookies to distinguish you from other users.  This helps us to provide you with
                  a good experience when you browse Our Website and also allows us to improve it.  For detailed information on the cookies
                  we use and the purposes for which we use them see our <a className="site" href="\cookie-policy">Cookie Policy</a>.
                </li>
              </ol>
              <li>
              In addition, we use the information we collect to create profiles and to segment our database for analytical and direct marketing
              purposes including sending marketing and promotional materials.  We may combine information we obtain from other sources with
              information we collect from Our Website to improve our business analysis and customer understanding.
              </li>
              <li>
              If you join our communications program we collect information about each email we have sent to you and your interaction with them,
              including, without limitation, the number of times opened, deliverability, which sections you clicked on etc. which we use to
              measure the effectiveness of our email campaigns, to plan marketing campaigns and to segment our database.
              </li>
              <li>
              We may also undertake additional data processing activities but will ensure they are compatible with this Privacy Policy.
              </li>
              <ol className="withPointAndNextNumber" start={4} type="1">
                <b><li className="withPointAndNextNumber">USE OF PERSONAL DATA</li></b>
                We will only use your personal data when the law allows us to.  In the day to day running of our business we may use your personal
                data without asking for your consent because:
                <ol className="alphaWithBracket">
                  <li className="alphaWithBracket">
                      we are entering into and carrying out our obligations under a contract with you;
                  </li>
                  <li className="alphaWithBracket">
                      we need to use your personal data for our own legitimate interest purposes (such as the administration and management of our
                      business and the improvement of our services) which shall comply with GDPR and your interests and fundamental rights do not override those interests
                  </li>
                  <li className="alphaWithBracket">
                      where we need to comply with a legal or regulatory obligation.
                  </li>
                </ol>
                 Other types of legal basis that we rely on to process your personal data are included within this Privacy Policy.
                <b><li className="withPointAndNextNumber">CONSENT</li></b>
                Whilst we always want you to be aware of how we are using your personal data, this does not necessarily mean that we are required
                to ask for your consent before we can use it.
                <p>
                  If we choose to process your personal data for any purpose incompatible with those described in this section, we will provide you
                  with appropriate information at the point where you come across those additional purposes in order to obtain your consent (where required)
                  or are able to satisfy our legal obligations, prior to commencing any such additional processing activities.  You are not required to
                  give consent just because we ask for it.  If you do give consent you can change your mind and withdraw it at a later date by contacting
                  us and requesting to be removed from the mailing list on the following email
                  address: <a className="email" href="mailto:cnsprivacy@cnsonline.net">cnsprivacy@cnsonline.net</a>
                </p>
                <p>
                  You are not under a legal obligation to provide us with any of your personal data but please note that if you elect not to provide us
                  with your personal data we may be unable to provide our products or services to you.
                </p>
              </ol>
            </div>

          <h3 className="marginMainPoints"><li className="withoutPoint">RELATED WEBSITES</li></h3>
            We operate several other websites which can be accessed via Our Website each of which has privacy information informing you how personal
            data are handled through that website.  These other websites include (but are not limited to):
            <ol className="alphaWithBracket">
              <li className="alphaWithBracket">
                  DP World London Gateway Customer Portal used to provide vehicle booking, driver registration and accessed via the “Vehicle Booking System” icon and links.
              </li>
            </ol>
            <li>
              It is important that you read this Privacy Policy together with any other privacy notice (including the Acceptable Use Policy) or fair
              processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are
              fully aware of how and why we are using your data. This Privacy Policy supplements the other notices and is not intended to override them.
            </li>
            <li>
              Our Website may include links to third party websites, plus-ins and applications. Clicking on those links or enabling those connections may
              allow third parties to collect or share data about you.  We do not control these third party websites and are not responsible for their privacy
              statements.  When you leave Our Website, we encourage you to read the privacy notice of every website you visit.
            </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">LEGAL BASIS FOR PROCESSING</li></h3>
          <li>
            The legal basis for processing personal data described above is the pursuit of our legitimate interests of seeking to promote, develop and grow
            our businesses through the sale of products and services and to provide excellent customer services.
          </li>
          <li>
            If you subscribe to our email communications program we may send to you unsolicited direct email marketing materials but only where you have granted
            your consent or if they relate to products and services similar to those you have previously bought from us or expressed interest in.
          </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">DISCLOSING, SHARING AND TRANSFERRING PERSONAL DATA</li></h3>
            <li>
              The table below indicates categories of organisations to whom we may disclose information about you in the day to day running of our business and which
              includes suppliers processing data on our behalf to help us to provide services to you.  We disclose personal data in this manner to procure high quality,
              cost effective services which we believe to be in our legitimate interests.
            </li>
            <table className='privacy-policy-table-7 adaptive-table'>
              <tbody>
                <tr>
                  <th align="left" width="50%">Categories of Organisation</th>
                  <th align="left" width="35%">Purpose</th>
                  <th align="left" width="15%">Location</th>
                </tr>
                <tr>
                  <td>Marketing agencies, database hosting companies, data cleansing companies, and mailing houses.</td>
                  <td>To provide marketing services and fulfil our CRM and email program.</td>
                  <td>UK or European Economic Area</td>
                </tr>
                <tr>
                  <td>Email broadcasters (MailChimp)</td>
                  <td>To fulfil our CRM and email program.</td>
                  <td>USA*</td>
                </tr>
              </tbody>
            </table>
            <li>
            <ins>*United States of America:</ins> The EU has not endorsed the privacy laws of the United States but has approved a framework for the transfer of personal data
            called the EU:US Privacy Shield under which MailChimp has a valid certificate enabling us to lawfully transfer your personal data to the MailChimp
            email platform.  You may review MailChimp’s Privacy Shield certificate here <a className="site" href="https://www.privacyshield.gov">www.privacyshield.gov</a>.
            </li>
            <li>
            We may collect, transfer and store your personal data within any member of our group, which means subsidiaries, our ultimate holding company and its subsidiaries,
              as defined in s1159 of the UK Companies Act 2005 (“<b>Group</b>”) including those outside the European Economic Area (“<b>EEA</b>”), including the United Arab Emirates.
            </li>
            <li>
              We may send you marketing communications by email, telephone, text message or post.
            </li>
            <p>
              You can ask us to only send you marketing communications by particular methods (for example, you may be happy to receive emails from us but not telephone calls),
              about specific subjects or you may ask us not to send you any marketing communications at all.
            </p>
            <li>
              We may ask you to indicate your marketing preferences when you first register an account on Our Website and/or app (as applicable), which you may change later
              on by emailing us on: <a className="email" href="mailto:cnsprivacy@cnsonline.net">cnsprivacy@cnsonline.net</a>.
            </li>
            <li>
              We will only share your personal data with third parties for marketing purposes if you provide us with your consent to do so including by email or by ticking
              a box on a form, or any other method that we use to collect your personal data.
            </li>
            <li>
              We may also share information about you with other companies within the DP World Group such as other terminals where we think you may have an interest in their
              products and services.  We undertake data sharing on the basis of our Group’s legitimate interests in promoting our products and services and undertaking direct marketing.
            </li>
            <li>
              If we share your personal data with any terminals outside of the European Economic Area we will ensure that suitable mechanisms are in place to safeguard
              your rights and your personal data including, without limitation, adequacy decisions made by the European Commission or model contractual clauses between
              terminals in the EU and those not in the EU.
            </li>
            <li>
              We may also disclose your personal information to other third parties from time to time:
            </li>

              <ol className="alphaWithBracket">
                  <li className="alphaWithBracket">
                    if we are under a duty to disclose or share your personal data to comply with any legal obligation or if we sell or buy any business or assets,
                    in which case we will disclose your personal data to the prospective seller or buyer of such business or assets;
                  </li>
                  <li className="alphaWithBracket">
                    to enforce or apply our terms of use and other agreements;
                  </li>
                  <li className="alphaWithBracket">
                    to protect the rights, property, or safety of our company, our customers, or others including exchanging information with other companies
                    and organisations for the purposes of fraud protection and credit risk reduction.
                  </li>
                  <li className="alphaWithBracket">
                    business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you;
                  </li>
                  <li className="alphaWithBracket">
                    analytics and search engine providers that assist us in the improvement and optimisation of Our Website;
                  </li>
                  <li className="alphaWithBracket">
                    credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you.
                  </li>
                  <li className="alphaWithBracket">
                    advertisers and advertising networks that require the data to select and serve relevant adverts to you and others for the purposes of
                    the third parties commercial interests and/or to enable us to comply with our advertisers' wishes by displaying their advertisement to that target audience.
                  </li>
              </ol>
              <li>
                We do not make use of automated decision making or profiling.
              </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">DATA RETENTION</li></h3>
          <li>
            We will hold information about you in our data systems only for as long as we need to hold it which generally is as follows:
          </li>
          <ol className="alphaWithBracket">
            <li className="alphaWithBracket">
              We will retain and process information about you for as long as you continue to use or express an interest in our services, log into Our Website,
              use our interactive services, or for as long as we feel that our business, products and services may be of interest to you where you have not indicated otherwise.
            </li>
            <li className="alphaWithBracket">
              We will hold information about how you interact with our electronic communications for as long as they are relevant to helping us analyse the performance
              of our digital marketing activities or customers and prospects.
            </li>
          </ol>
          <li>
            Please note that we may anonymise your personal data or use it for statistical purposes.  We keep anonymised and statistical data indefinitely but we take care to
            ensure that such data can no longer identify or be connected to any individual.
          </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">YOUR RIGHTS</li></h3>

            <li>
              Data protection law grants you certain rights ("<b>information rights</b>") which provide you with:
            </li>
            <div>
              <table className='privacy-policy-table-9'>
                <colgroup>
                  <col width="13%"/>
                  <col width="87%"/>
                </colgroup>
                <tbody>
                  <tr>
                    <td className="category">Right of access and of data portability.</td>
                    <td>
                      You have the right of access to information we hold about or concerning you and/or to have it transferred to another data controller in some circumstances.
                      If you would like to exercise this right you should contact us as outlined above.
                    </td>
                  </tr>
                  <tr>
                    <td className="category">Right of rectification or erasure.</td>
                    <td>
                        If you feel that any data that we hold about you is inaccurate you have the right to ask us to correct or rectify it.  You also have a right to ask us to
                        erase information about you where you can demonstrate that the data we hold is no longer needed by us, or if you withdraw the consent upon which our
                        processing is based, or if you feel that we are unlawfully processing your data.
                        <br/>
                        <br/>
                        Your right of rectification and erasure extends to anyone we have disclosed your personal information to and we will/shall take all reasonable steps to
                        inform those with whom we have shared your data about your request for erasure.
                    </td>
                  </tr>
                  <tr>
                    <td className="category">Right to restriction of processing.</td>
                    <td>
                      You have a right to request that we refrain from processing your data where you contest its accuracy, or the processing is unlawful and you have opposed its
                      erasure, or where we don’t need to hold your data anymore but you need us to in order to establish, exercise or defend any legal claims, or we are in dispute
                      about the legality of our processing your personal data.
                    </td>
                  </tr>
                  <tr>
                    <td className="category">Right to object.</td>
                    <td>
                      You have a right to object to our processing of your personal data where the basis of the processing is our legitimate interests including but not limited
                      to direct marketing and profiling.
                    </td>
                  </tr>
                  <tr>
                    <td className="category">Right to Withdraw Consent.</td>
                    <td>
                      You have the right to withdraw your consent for the processing of your personal data where the processing is based on consent.  To withdraw consent, please
                      specify what you wish to withdraw consent for, by emailing: <a className="email" href="mailto:cnsprivacy@cnsonline.net">cnsprivacy@cnsonline.net</a>.
                    </td>
                  </tr>
                  <tr>
                    <td className="category">Right of Complaint.</td>
                    <td>
                      If you are unhappy with the way we have used or are handling your personal data you have the right to lodge a complaint with the Information Commissioner’s
                      Office (ICO), the UK supervisory authority for data protection issues.  We would, however, appreciate the chance to deal with your concerns before you approach
                      the ICO so please contact us in the first instance by emailing: <a className="email" href="mailto:cnsprivacy@cnsonline.net">cnsprivacy@cnsonline.net</a>.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              You can find full details of your personal data rights on the Information Commissioner’s Office website
              at <a className="site" href="https://ico.org.uk/">www.ico.org.uk</a>.
            </p>

          <h3 className="marginMainPoints"><li className="withoutPoint">DATA SECURITY</li></h3>
          <li>
            We take every care to ensure that your personal data is kept secure.  We have put in place appropriate security measures to prevent your personal data from being accidentally lost,
            used or accessed in an unauthorised way, altered or disclosed.  In addition, we limit access to your personal data to those employees, agents, contractors and other third parties
            who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.
          </li>
          <li>
            We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.
          </li>
          <li>
            Please remember that you are responsible for keeping your passwords secure. If we have given you (or you have chosen) a password which enables you to access certain parts of Our
            Website or app (as applicable), you are responsible for keeping this password confidential. Please do not to share your passwords with anyone.
          </li>
          <li>
            Unfortunately, sending information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of
            personal data sent to Our Website; you send us personal data at your own risk. Once we have received your personal data, we will use strict procedures and security features
            to try to prevent unauthorised access.
          </li>

          <h3 className="marginMainPoints"><li className="withoutPoint">HOW WE KEEP THIS POLICY UP TO DATE</li></h3>
          <li>
            We will review and update this privacy policy from time to time. This may be to reflect a change in the products or services we offer or to our internal procedures or it may be to
            reflect a change in the law.
          </li>
          <li>
            The easiest way to check for updates is by looking for the latest version of this policy on Our Website or you can contact us
            by emailing <a className="email" href="mailto:cnsprivacy@cnsonline.net">cnsprivacy@cnsonline.net</a>.
          </li>
          <li>
            Each time we update our privacy policy we will update the policy version number shown at the end of the privacy policy and the date on which that version of the privacy policy came into force.
          </li>
        </ol>

        <div align="right">
          Last Updated: 26/03/2019
        </div>

        <div className='ie_gap'/>

      </Section>
    </div>;
  }
};
