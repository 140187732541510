import React from 'react';
import {Button} from '..';

export default class ContainerCardToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      currentItem: 'Sort by'
    };
  }

  className() {
    const classNames = ['container-table__actions'];
    if (this.state.active) {
      classNames.push('container-table__actions_active');
    }
    return classNames.join(' ');
  }

  showMenu() {
    this.setState({active: true});
  }

  hideMenu() {
    this.setState({active: false});
  }

  toggle(action) {
    this.props.onToggle(action);
    this.setState({active: false});
  }

  render() {
    return <div className='container-table_card-toolbar'>
      {this.props.isPaidUser && <React.Fragment>
        <div className='container-table__card-toolbar-first-line-controls'>
          <Button disabled={!this.props.selectedContainers || this.props.selectedContainers.length===0 || this.props.setAlertDisabled}
                                          onClick={() => this.props.onApplyTemplateButtonClick()}
                                          className='container-table-toolbar-item' color='light-blue'>Apply Template Alerts</Button>
          <span className="info info-apply-template-remove-container"
                data-apply-template-remove-container='Containers need to be selected via a check box before applying alerts or removing containers'
                data-apply-template-remove-container-at='bottom'>i</span>
           <Button disabled={!this.props.selectedContainers || this.props.selectedContainers.length===0}
              onClick={() => this.props.showRemoveContainerPopUp()}
              className='container-table-toolbar-item' color='light-blue'>Remove Containers</Button>
        </div>

        <div className='container-table__card-toolbar-second-line-controls'>
          <Button
              disabled={this.props.context.state.foundContainers.length >= 20}
              onClick={() => this.props.showAddContainerPopUp()}
              className='container-table-toolbar-item' color='light-blue'>Add
            Containers</Button>

          <Button
              onClick={() => this.props.toggleStatusMode()}
              className='container-table-toolbar-item' color='light-blue'>{this.props.allStatusesMode ? 'Current Status Only' : 'All Statuses'}</Button>
        </div>

      </React.Fragment>
      }
       <div className='container-table__card-toolbar-second-line-controls'>
       <Button
                className="container-table-toolbar-item"
                color="light-blue"
                onClick={()=>{this.props.history.push('/saved-lists')}}>
                  Go to Saved Lists</Button>

       
      <div className={this.className() +' container-table__sortby-button'} onMouseOver={e => this.showMenu(e)}
           onMouseOut={e => this.hideMenu(e)}>
        <Button color="blue" icon="arrow" >{this.state.currentItem || 'Sort by'}</Button>

        <ul className="container-table__card-sorting-menu">
          <li onClick={() => {
            this.setState({currentItem: 'Number A-Z'});
            this.hideMenu();
            this.props.onSortingSelect('container', 'desc');
          }}>Number A-Z
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Number Z-A'});
            this.hideMenu();
            this.props.onSortingSelect('container', 'asc')
          }}>Number Z-A
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Port A-Z'});
            this.hideMenu();
            this.props.onSortingSelect('port', 'desc')
          }}>Port A-Z
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Port Z-A'});
            this.hideMenu();
            this.props.onSortingSelect('port', 'asc')
          }}>Port Z-A
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Activity A-Z'});
            this.hideMenu();
            this.props.onSortingSelect('activity', 'desc')
          }}>Activity A-Z
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Activity Z-A'});
            this.hideMenu();
            this.props.onSortingSelect('activity', 'asc')
          }}>Activity Z-A
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Status A-Z'});
            this.hideMenu();
            this.props.onSortingSelect('status', 'desc')
          }}>Status A-Z
          </li>
          <li onClick={() => {
            this.setState({currentItem: 'Status Z-A'});
            this.hideMenu();
            this.props.onSortingSelect('status', 'asc')
          }}>Status Z-A
          </li>
        </ul>
      </div>
      </div>
    </div>
  }
};
