import React from 'react';

import statusCellBuilder, {getContainerAdditionalActions} from './status-cell/status-additional-info';
import statusCellIconName from './status-cell/status-icon-info';

export function getVesselString(vesselObject, isHistoryTranshipmentStatus){
  if(!vesselObject) {
    return null;
  }
  if(vesselObject.vessel !== null) {
    return vesselObject.vessel;
  }
  else if(isHistoryTranshipmentStatus){
    return `${vesselObject.inVessel === null ? '' : vesselObject.inVessel + ' (in)'} \r\n ${vesselObject.outVessel === null ? '' : vesselObject.outVessel + ' (out)'}`;
  }
  else {
    return `${vesselObject.outVessel === null ? '' : vesselObject.outVessel + ' (out)'}  \r\n ${vesselObject.inVessel === null ? '' : vesselObject.inVessel + ' (in)'}`;
  }
}

export function getContainerStatusName(containerStatusesEntry) {
  return (containerStatusesEntry === null || containerStatusesEntry.status === null) ? null : containerStatusesEntry.status.statusType
}

function getStatusCell(containerStatusesEntry, isPaidUser, maxLengthOfContainerStatusName, onAction, isCurrentJourney, actionsList, additionalActionsList, portName, allStatusesMode) {
  let status = getContainerStatusName(containerStatusesEntry);
  const statusIconInfo = statusCellIconName[status];
  return statusIconInfo ? statusCellBuilder({ entry: containerStatusesEntry, onAction: onAction, isCurrentJourney: isCurrentJourney}, maxLengthOfContainerStatusName, statusIconInfo, actionsList, additionalActionsList, portName, allStatusesMode, isPaidUser)
      : <td colSpan={isPaidUser ? 2 : 1}>{status}</td>;
}

function getContainerInfo(props) {
  const containerCheckBox = props.isPaidUser && <input type='checkbox' checked={props.isSelected} onChange={props.onSelect}/>;
  if(props.entry.containerStatuses !== null){
    return props.entry.containerStatuses.map((node, i) =>{
      return props.isPaidUser && !props.allStatusesMode && i > 0 ? null : <tr key={props.entry.container+i} className={props.hasChanges ? 'changed-container-row' : ''}>
        {i===0 && props.isPaidUser && <td rowSpan={props.entry.containerStatuses === null ? 1
            : props.entry.containerStatuses.length} className='container-table__cell container-table__cell_type_select'>{containerCheckBox}</td>}
          {i===0 && <td className="container-table__cell container-table__cell_type_container"
              rowSpan={props.entry.containerStatuses === null ? 1
                  : props.entry.containerStatuses.length}>{props.entry.container}</td>}
        <td className="container-table__cell container-table__cell_type_port">{node.port}</td>
        <td className="container-table__cell container-table__cell_type_activity">{node.activity}</td>
        <td className="container-table__cell container-table__cell_type_vessel">{getVesselString(node.vessel)}</td>
        { getStatusCell(node
            , props.isPaidUser
            , props.maxLengthOfContainerStatusName
            , props.onAction
            , i === 0
            , i === 0 ? props.entry.actions : null
            , getContainerAdditionalActions(i===0, props.isPaidUser, props, (props.entry.containerStatuses ? props.entry.containerStatuses[0].activity : null))
            , node.port
            , props.allStatusesMode)}
        </tr>
    })
  }
  else {
    return <tr className={props.hasChanges ? 'changed-container-row' : ''}>
      {props.isPaidUser && <td className='container-table__cell container-table__cell_type_select'>{containerCheckBox}</td>}
        <td className="container-table__cell container-table__cell_type_container">{props.entry.container}</td>
      <td className="container-table__cell container-table__cell_type_port"/>
      <td className="container-table__cell container-table__cell_type_activity"/>
      <td className="container-table__cell container-table__cell_type_vessel"/>
      { getStatusCell(null, props.isPaidUser, 0, props.onAction)}
      </tr>
  }

}

export default function containerTableRow(props) {

  return <React.Fragment>

      {getContainerInfo(props)}

  </React.Fragment>
};