import React from "react";
import { LayoutContext } from "../app/context/layout-context";
import FrameSection from "../frame-section/frame-section";
import "./advanced-features.css";
import "./advanced-features.mobile.css";
import Button from "./../button/button";
import { Redirect } from "react-router-dom";
import ReactPlayer from "react-player";

class AdvancedFeatures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectTo: ""
    };
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const historyBox = (
      <FrameSection title="HISTORY" borderless titleColor="yellow">
        <p>You can look up the history of a container going back 6 months.</p>
        <p>
          The history of a container can be viewed for either a particular day
          or a time period. You can further refine your search by specifying the
          port the container arrived at and the activity it was engaged in (eg
          Import, export etc).
        </p>
        <p>
          It will show you the activity and all the statuses that the container
          went through as well as any alerts that were sent, including the email
          address and phone number they were sent to.
        </p>
      </FrameSection>
    );

    const savedListBox = (
      <FrameSection title="SAVED LISTS" borderless titleColor="yellow">
        <p>
          This feature allows you to save up to 10 lists of containers. You can
          select a list and select search to see the details on the containers.
        </p>
      </FrameSection>
    );

    const moreDetailsBox = (
      <FrameSection title="MORE CONTAINER DETAILS" borderless titleColor="blue">
        <p>
          The container details page will show all the statuses on a container.
          Statuses are colour coded to show what is past, current or yet to
          come.
        </p>
        <p>
          {" "}
          Also on this page, you can view what alerts have already been set. You
          can set alerts on a single container and have them sent to a different
          email address or phone number. You can have up to two email or SMS
          alerts sent on the one container.
        </p>
        <p>You can also add or remove containers whilst in this screen.</p>
      </FrameSection>
    );

    const alertsBox = (
      <FrameSection
        title="SMS & EMAIL ALERTS"
        borderless
        titleColor="dark-blue"
      >
        <p>
          For the free trial period, users will be able to set up to 30 SMS
          alerts and 30 email alerts over a 30 day period.
        </p>
        <p>
          When you are getting near your SMS or email limit, we will advise you
          on screen and via SMS or email about how many alerts you have left.
        </p>
        <p>
          If you run over your alert limit within the 30 days, we will suspend
          any pending alerts.
        </p>
      </FrameSection>
    );

    const alertTemplatesBox = (
      <FrameSection title="ALERT TEMPLATES" borderless titleColor="dark-blue">
        <p>
          The alerts can be set to occur when a container reaches a particular
          status. Additionally, you can set up a template to specify that an
          alert be sent on particular statuses for all or some of your
          containers.
        </p>
        <p>
          If you have a standard set of alerts for particular statuses on your
          containers, you can change the alerts for individual containers to
          have less or more alerts and also change the phone number and/or email
          address that the alert is sent to.
        </p>
      </FrameSection>
    );

    const videoBox = (
      <div className="advanced_feature-video-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://vimeo.com/342006804"
          width="100%"
          height="100%"
        />
      </div>
    );

    return (
      <div className="main_wrap advanced_feature_page_id">
        <div className="advanced_feature_header">
          <h2>Advanced Features</h2>
          <h3>
            Our advanced features will provide cargo owners with even more
            visibility; enabling users to plan, adapt and drive efficiencies in
            their supply chains. Push notifications will also enable real-time
            decision making, helping users to react quickly to potential
            business challenges.
          </h3>
        </div>
        <div className="advanced_feature_content">
          <div>
            {this.props.layoutContext.state.isMobile && videoBox}
            <FrameSection
              title="WITH ADVANCED FEATURES, USERS CAN:"
              borderless
              uncollpsible
              titleColor="pink"
            >
              <ul className="advanced_feature_important_content">
                <li>
                  Set SMS and email alerts for real-time notification of a
                  container’s status
                </li>
                <li>Access more detailed container journey information</li>
                <li>Save up to 10 lists for regular searching</li>
                <li>
                  Search the history of a container’s journey to audit and
                  verify cargo
                </li>
              </ul>
            </FrameSection>
            {!this.props.layoutContext.state.isMobile && alertTemplatesBox}
            {!this.props.layoutContext.state.isMobile && alertsBox}
            {!this.props.layoutContext.state.isMobile && historyBox}
          </div>
          <div>
            {!this.props.layoutContext.state.isMobile && videoBox}
            {!this.props.layoutContext.state.isMobile && savedListBox}

            {/*mobile layout*/}
            {this.props.layoutContext.state.isMobile && alertTemplatesBox}
            {this.props.layoutContext.state.isMobile && alertsBox}
            {this.props.layoutContext.state.isMobile && moreDetailsBox}
            {this.props.layoutContext.state.isMobile && historyBox}
            {this.props.layoutContext.state.isMobile && savedListBox}

            {!this.props.layoutContext.state.isMobile && moreDetailsBox}
            <div className="back-to-account-div">
              <div>
                To try our new advanced features, select the 'Trial Advanced
                Features' button on your My Account screen
              </div>
              <br />
              <div className="advanced-feature-page-back-button-wrapper">
                <Button
                  color="blue"
                  icon="arrow"
                  onClick={() => this.setState({ redirectTo: "/account" })}
                >
                  <span>
                    Back to
                    <br />
                    My Account
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => (
  <LayoutContext.Consumer>
    {layoutContext => (
      <AdvancedFeatures {...props} layoutContext={layoutContext} />
    )}
  </LayoutContext.Consumer>
);
