import React from "react";
import ReactModal from "react-modal";
import { api } from "../../services";
import { Button } from "..";
import "./delete-account.css";
import "./delete-account.mobile.css";
import { logout } from "../../services/";

export default class DeleteAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isConnectionProblem: false };
  }


  setRequestForDeleteAccount() {
    api(this.props.layoutContext, "/api/customers", "DELETE").then(res => {
      if (res.status === 200) {
        this.props.onClose();
        logout();
      } else {
        this.setState({ isConnectionProblem: true });
      }
    });
  }

  showConnectionPropblem() {
    if (this.state.isConnectionProblem)
      return (
        <div className="connection-error">
          Oops, something went wrong. Please try again. If the problem persists,
          please call our helpdesk on{" "}
          <a className="phone-number-clickable-link" href="tel:08000469648">
            08000 469 648
          </a>
        </div>
      );
  }

  render() {
    const root = document.querySelector("react-app");
    const isOpen = this.props.isOpen;
    const close = e => {
      e.preventDefault();
      this.props.onClose();
    };

    return (
      <ReactModal appElement={root} isOpen={isOpen} className="app-modal">
        <div className="delete-account">
          <h4>We’re sorry you want to leave us but just before you go ……</h4>
          {this.props.invoices ? (
            <React.Fragment>
              <p>
                By cancelling this account, you will no longer be able to access
                our information on your containers in our Southampton and London
                Gateway ports.
              </p>
              <p>
                As you have previous invoices with us, we are required to keep
                this information available to you for 7 years.
              </p>
              <p>
                So that we can identify you if you contact us regarding an
                invoice, we will retain enough information to identify you.
                After 7 years have passed, we will delete all information.
              </p>
            </React.Fragment>
          ) : this.props.layoutContext.state.isFreeAdvancedUser ? (
            <React.Fragment>
              <p>
                If you delete your account now, your access to all standard and
                advanced Where’s my container features will be removed. Any
                alerts still pending on this account will be deleted and
                therefore not sent.
              </p>
              <p>
                If you are wanting instead to only use our free standard
                features, simply wait until the end of this current period and
                you will revert back to free user status.
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>
                By cancelling this account, you will no longer be able to access
                our information on your containers in our Southampton and London
                Gateway ports.
              </p>
            </React.Fragment>
          )}

          <p>
            If you still wish to leave, simply click on the Goodbye button
            below.
          </p>
          <p>If you have decided to stay, just click on Cancel</p>

          <p>
            <Button color="blue" onClick={close}>
              Cancel
            </Button>
            <Button
              color="pink"
              onClick={() =>
                this.setState({ isConnectionProblem: false }, () => {
                  this.setRequestForDeleteAccount();
                })
              }
            >
              Goodbye
            </Button>
          </p>
        </div>
        {this.showConnectionPropblem()}
      </ReactModal>
    );
  }
}
