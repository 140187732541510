import React from 'react';
import { Redirect } from 'react-router-dom';
import { ContainerTable, Section, Button } from '..';
import { Context } from '../app/context/context';
import moment from 'moment';
import {LayoutContext} from "../app/context/layout-context";
import './containers.css';
import './containers.mobile.css';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import spinner from './../../static/images/Spinner.gif';
import CriticalAlertComponent from './../dashboard/critical-alert-component';

const ReactHint = ReactHintFactory(React);

export class Containers extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: ''
    };
  }

  componentDidMount(){
    this.props.layoutContext.getCmsComponent(['Sign In', 'Critical Alerts (DPWS)', 'Critical Alerts (LGPL)'], this);
  }

  componentWillUnmount(){
    this.props.context.cleanSearchResults();
  }

  newSearch() {
    this.setState({ redirectTo: '/dashboard' });
  }

  getLegendHint = () => {
    return <div className="react-hint__content">
          <p>SOU - Southampton Port</p>
          <p>LGP - London Gateway Port</p>
    </div>
  };

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }

    const legendDiv = this.props.layoutContext.state.isMobile ? <div className="table__legend">
      <ReactHint
          attribute='legend-hint-data'
          persist
          events={{click: true}}
                 onRenderContent={this.getLegendHint}/>
      <p>Last refreshed at: <b>{moment().format('HH:mm')}</b></p><p legend-hint-data='' legend-hint-data-at='top'>SOU/LGP</p>
    </div> :
        <div className="table__legend">
          <div>
            <p>SOU - Southampton Port</p>
            <p>LGP - London Gateway Port</p>
          </div>
          <p>Last refreshed at: <b>{moment().format('HH:mm')}</b></p>
        </div>;

    const containerControls = <div className='container-list-controls'>
      <Button id='containers-page_refresh-button' color="blue" icon="reload" onClick = {e => this.props.context.refreshSearch(this.props.layoutContext, this.props.layoutContext.state.isPaidUser || this.props.layoutContext.state.isFreeAdvancedUser)}>Refresh</Button>
      <Button id='containers-page_new-search-button' color="pink" icon="arrow" onClick={e => this.newSearch()}>New search</Button>
    </div>;

    return <div className="main_wrap page_id_containers" onClick={()=>{}}>

      <div className='main_wrap-header'>
        <div className="container-page_critical_alert_wrapper">
          <CriticalAlertComponent sou={this.state.criticalalertsdpws}
                                  lgp={this.state.criticalalertslgpl}/>
        </div>
        <h2>Container Details
          {(this.props.layoutContext.state.isPaidUser || this.props.layoutContext.state.isFreeAdvancedUser) && <React.Fragment>
              <span
                  className="page_id_containers-found-containers-amount">({this.props.context.state.foundContainers.length})

                <span className="info info-containers-amount" data-container-amount='This is the number of containers currently being viewed in this screen' data-container-amount-at={this.props.layoutContext.state.isMobile ? 'bottom':'right'}>
                  i</span>
              </span>
          </React.Fragment>}
        </h2>
      </div>
      {this.props.context.state.isLoading ?
                <div className='spinner-container'><img src={spinner} alt='Spinner'/></div> :
          <ContainerTable
            history={this.props.history}
          />}
      <Section>
        {this.props.layoutContext.state.isMobile && containerControls}
        {legendDiv}
        {!this.props.layoutContext.state.isMobile && containerControls}
      </Section>

      <span className="container-amount-hint-wrapper">
        <ReactHint persist
                   autoPosition={true}
                   attribute="data-container-amount"
                   events={{click: true, hover: true}}/>
      </span>
      <span className="apply-template-remove-container-hint-wrapper">
        <ReactHint persist
                   attribute="data-apply-template-remove-container"
                   events={{click: true, hover: true}}/>
      </span>
      <span className="container-amount-hint-wrapper">
        <ReactHint persist
                   className="custom-hint"
                   autoPosition={true}
                   attribute="data-alerts"
                   events={{click: true, hover: true}}/>
      </span>
      <span className="container-amount-hint-wrapper">
        <ReactHint persist
                   className="custom-hint-small"
                   autoPosition={true}
                   attribute="data-alerts-small"
                   events={{click: true, hover: true}}/>
      </span>
    </div>;
  }
};

export default props => (
    <LayoutContext.Consumer>
      {layoutContext =>
          <Context.Consumer>
            {context => <Containers {...props}
                                    context={context}
                                    layoutContext={layoutContext}/>}
          </Context.Consumer>
      }
    </LayoutContext.Consumer>
);
