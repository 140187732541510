import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import Section from "../section/section";
import {Redirect} from 'react-router-dom';
import './administration-search.css';
import {Arrow} from '../button/icon';
import {api} from '../../services';
import {atLeastOneOfFields} from '../../validations';
import AdministrationSearchTable from './administration-search_table';
import { isValidForm } from '../../services';

export default class AdministrationSearch extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: '',
      foundUsers: null,
      pageNumber: 1,
      totalPages: 0
    };
    this.searchNextPageUsers = this.searchNextPageUsers.bind(this);
  };

  searchNextPageUsers() {
    this.setState({pageNumber: this.state.pageNumber + 1},
        this.doSearch(this.state.pageNumber + 1));
  }

  searchUsers() {
    if(isValidForm(this.form)){
      this.setState({pageNumber: 1}, this.doSearch(1));
    }
  }

  doSearch(pageNumber) {
    const values = this.form.getValues();
    const data = {
      "page": pageNumber,
      "firstName": values['first-name'],
      "lastName": values['last-name'],
      "companyName": values['company'],
      "email": values['email-address']
    };

    api(this.props.layoutContext, '/api/help-desk/customers?' + Object.keys(data)
    .filter((key) => {
      return data[key] !== "";
    })
    .map(
        (key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
        })
    .join('&'))
    .then(response => {
      if (response.status === 200) {
        response.json().then(responseBody => {
          this.setState({
            totalPages: responseBody.allPages,
            foundUsers: pageNumber === 1
                ? responseBody.searchHelpDeskAccountDtos
                : this.state.foundUsers.concat(responseBody.searchHelpDeskAccountDtos)
          });
        })
      }
    });
  }

  clearSearchForm() {
    const labels = Object.keys(this.form.state["byId"]);
    labels.forEach(element => {
      this.form.state["byId"][element].value = '';
    });
    this.setState({foundUsers: null});
    this.form.validateAll();
    this.forceUpdate();
  }

  render() {

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }

    return <div className="page_id_administration_search">
      <h1>Administration Search</h1>
      <Section color="white">
        <Form
            ref={node => {
              this.form = node;
            }}
            onKeyDown={event => {
              if (event.key === 'Enter'){
                event.preventDefault();
                this.form.validateAll();
                this.searchUsers();
              }
            }}
            onSubmit={(e) => e.preventDefault()
            }>
          <fieldset>
            <div>
              <label>First Name:</label>
              <Input
                  validations={[atLeastOneOfFields]}
                  name="first-name"
                  maxLength={200}/>
            </div>
            <div>
              <label>Last Name:</label>
              <Input
                  validations={[atLeastOneOfFields]}
                  name="last-name"
                  maxLength={200}/>
            </div>
          </fieldset>
          <fieldset>
            <div>
              <label>Company:</label>
              <Input
                  validations={[atLeastOneOfFields]}
                  name="company"
                  maxLength={200}/>
            </div>
            <div>
              <label>Email Address:</label>
              <Input
                  validations={[atLeastOneOfFields]}
                  name="email-address"
                  maxLength={200}/>
            </div>
          </fieldset>
          <div className="administration_search_buttons">
            <Button className="button button_color_blue" onClick={() => {
              this.clearSearchForm();
            }}>Clear</Button>
            <button
                className="button button_color_blue"
                onClick={() => this.setState({redirectTo: '/dashboard'})}>
              Return to Dashboard
            </button>
            <Button
                className="button button_color_pink administration_search_submit_button"
                onClick={() => {
                  this.searchUsers()
                }}>
              Search
              {Arrow()}
            </Button>
          </div>
        </Form>
        {this.state.foundUsers && <AdministrationSearchTable
            data={this.state.foundUsers !== null ? this.state.foundUsers : []}
            nextPageHandler={this.searchNextPageUsers}
            totalPages={this.state.totalPages}
            currentPage={this.state.pageNumber}
        />}
      </Section>
    </div>;
  }
}
