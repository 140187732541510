import React from 'react';
import {Link} from 'react-router-dom';
import {Arrow} from '../button/icon'
import { Section} from '..';

export function faqDashboardSection(componentContent){
  return <Section
      mobileAdaptive={true}
      color={componentContent.customField1}
      title={componentContent.title}
      backgroundColorCode={componentContent.customField1}
      textColorCode={componentContent.customField2}
      titleMode={componentContent.region.name ==='Text' && componentContent.customField3}
      imagePosition={componentContent.customField5}
      imageFileName={componentContent.customField4}
      opacity={componentContent.customField8 || 1}
      displayMode={componentContent.contentType.name}
      link={componentContent.customField6}
      mediaTitle={componentContent.region.name !== 'Text' && componentContent.customField3}
      innerContent={componentContent.content !== 'null' && <span className='dashboard_faq-section-content' dangerouslySetInnerHTML={{__html: componentContent.content}}/>}
      className="dashboard_faq-section">

      <span className='faq-dashboard-section-button-wrapper'>
        <Link className="button button_color_pink button_icon" to="/faqs" onClick={(e)=> e.stopPropagation()}>
          {Arrow()}
        </Link>
      </span>

  </Section>
}


