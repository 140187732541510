import React from "react";
import Row from "./container-table__row.jsx";
import Card from "./container-table__card";
import { Context } from "../app/context/context";
import { LayoutContext } from "../app/context/layout-context";
import {
  hasAnyServiceType,
  splitContainersByModalColumns
} from "./../../services";
import "./container-table.css";
import "./container-table.mobile.css";
import ContainerCardToolbar from "./container-table_card_sorting";
import Button from "../button/button";
import _ from "lodash";
import {
  api,
  isValidForm,
  filterInput,
  dataValidationPatterns,
  areArraysEqual,
  splitContainerNumbers
} from "../../services/index";
import ReactModal from "react-modal";
import AlertTemplate from "./../activity-alert-template/activity-alert-template";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import {
  requiredAtLeastOneContainerNumber,
  numberOfContainers,
  searchContainerFormat
} from "../../validations";
import Collapsible from "react-collapsible";
import ArrowUp from "../collapsible-page-header/img/arrow-up";
import ArrowDown from "../collapsible-page-header/img/arrow-down";
import {
  required,
  alertPrimaryPhone,
  email,
  phoneOrEmpty,
  emailOrEmpty
} from "../../validations";
import ChangeAlertContacts from "./../change-alert-contacts/change-alert-contacts";

export class ContainerTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortingBy: "",
      sortingOrder: "desc",
      selectedContainers: [],
      setAlertDisabled: false,
      alertTemplateActivityNotFoundList: [],
      isAlertContactDetailsNotFoundPopUpOpened: false,
      isChangeAlertContactDetailsPopUpOpened: false,
      containerForSingleAlerts: {
        number: "",
        activity: "",
        gkey: ""
      },
      defaultPhoneNumber: "",
      initialPrimaryPhoneNumber: "",
      primaryPhoneNumber: "",
      secondaryPhoneNumber: "",
      initialSecondaryPhoneNumber: "",
      primaryEmail: "",
      initialPrimaryEmail: "",
      secondaryEmail: "",
      initialSecondaryEmail: "",
      isPrimaryPhoneEditDisabled: true,
      isPrimaryEmailEditDisabled: true,
      singleContainerAlertItems: [],
      initialSingleContainerAlertItems: [],
      containerPotentialStatusList: [],
      isNoPhoneErrorActive: false,
      isPrimaryPhoneNumberChanged: false,
      isSingleAlertSelectorSavedSuccessfully: false,
      isRemoveContainersPopUpOpen: false,
      isRemoveContainersAlertOpen: false,
      isAddContainerPopUpOpen: false,
      isChangeAlertContactPopUpOpen: false,
      containerWhichHasSingleAlertsOnApplyingTemplate: ""
    };
    this.selectSorting = this.selectSorting.bind(this);
    this.applyTemplateAlerts = this.applyTemplateAlerts.bind(this);
  }

  componentWillMount() {
    this.containerNumbers = window.location.search.replace("?numbers=", "");

    if (
      this.props.context.state.foundContainers.length === 0 &&
      this.containerNumbers
    ) {
      this.props.context.searchContainer(
        this.props.layoutContext,
        this.props.layoutContext.state.isPaidUser ||
          this.props.layoutContext.state.isFreeAdvancedUser,
        { "container-numbers": decodeURIComponent(this.containerNumbers) }
      );
    }
  }

  selectSorting(name, order) {
    this.setState({
      sortingBy: name,
      sortingOrder: order
    });
  }

  changeSorting(name) {
    let order = "desc";

    if (this.state.sortingBy === name) {
      order = this.state.sortingOrder === "asc" ? "desc" : "asc";
    }

    this.setState({
      sortingBy: name,
      sortingOrder: order
    });
  }

  getVesselValue(vessel) {
    if (vessel.vessel) {
      return vessel.vessel;
    } else if (vessel.outVessel) {
      return vessel.outVessel;
    } else if (vessel.inVessel) {
      return vessel.inVessel;
    } else {
      return "";
    }
  }

  sortData() {
    if (this.state.sortingBy === "") {
      return this.props.context.state.foundContainers;
    }

    const secondarySorting = (a, b) => {
      return a["container"].localeCompare(b["container"]);
    };

    const key = this.state.sortingBy;

    let sorted;
    switch (key) {
      case "status":
        sorted = this.props.context.state.foundContainers.sort(
          (a, b) =>
            (a.containerStatuses === null
              ? ""
              : a.containerStatuses[0][key] === null
              ? ""
              : a.containerStatuses[0][key]["statusType"] === null
              ? ""
              : a.containerStatuses[0][key]["statusType"]
            ).localeCompare(
              b.containerStatuses === null
                ? ""
                : b[key] === null
                ? ""
                : b.containerStatuses[0][key]["statusType"] === null
                ? ""
                : b.containerStatuses[0][key]["statusType"]
            ) || secondarySorting(a, b)
        );
        break;
      case "vessel":
        sorted = this.props.context.state.foundContainers.sort(
          (a, b) =>
            (a.containerStatuses === null
              ? ""
              : a.containerStatuses[0][key] === null
              ? ""
              : this.getVesselValue(a.containerStatuses[0][key])
            ).localeCompare(
              b.containerStatuses === null
                ? ""
                : b.containerStatuses[0][key] === null
                ? ""
                : this.getVesselValue(b.containerStatuses[0][key])
            ) || secondarySorting(a, b)
        );
        break;
      case "activity":
      case "port":
        sorted = this.props.context.state.foundContainers.sort(
          (a, b) =>
            (a.containerStatuses === null
              ? ""
              : a.containerStatuses[0][key] === null
              ? ""
              : a.containerStatuses[0][key]
            ).localeCompare(
              b.containerStatuses === null
                ? ""
                : b.containerStatuses[0][key] === null
                ? ""
                : b.containerStatuses[0][key]
            ) || secondarySorting(a, b)
        );
        break;
      default:
        sorted = this.props.context.state.foundContainers.sort(
          (a, b) =>
            (a[key] || "").localeCompare(b[key] || "") || secondarySorting(a, b)
        );
    }

    return this.state.sortingOrder === "desc" ? sorted : sorted.reverse();
  }

  className(name) {
    if (this.state.sortingBy === name) {
      return this.state.sortingOrder === "desc"
        ? "sorting-order_desc"
        : "sorting-order_asc";
    }
  }

  omitAlertFieldsOfStatusObject(containerStatus) {
    containerStatus.status = this.omitAlertFields(containerStatus.status);
    if (containerStatus.pastStatuses) {
      containerStatus.pastStatuses = containerStatus.pastStatuses.map(
        pastStatus => {
          return this.omitAlertFields(pastStatus);
        }
      );
    }
    if (containerStatus.futureStatuses) {
      containerStatus.futureStatuses = containerStatus.futureStatuses.map(
        futureStatus => {
          return this.omitAlertFields(futureStatus);
        }
      );
    }
  }

  omitAlertFields(field) {
    if (field) {
      return _.omit(field, ["smsAlertStatus", "emailAlertStatus"]);
    }
  }

  isContainerChanged(container) {
    if (this.props.context.state.recentFoundContainers.length !== 0) {
      let previousFoundContainerClone = _.cloneDeep(
        this.props.context.state.recentFoundContainers.find(entry => {
          return entry.container === container.container;
        })
      );

      let currentContainerClone = _.cloneDeep(container);

      //newly added containers has no changes
      if (!previousFoundContainerClone) {
        return false;
      }

      if (previousFoundContainerClone.containerStatuses) {
        previousFoundContainerClone.containerStatuses.map(containerStatus =>
          this.omitAlertFieldsOfStatusObject(containerStatus)
        );
      }
      if (currentContainerClone.containerStatuses) {
        currentContainerClone.containerStatuses.map(
          containerStatus =>
            containerStatus &&
            this.omitAlertFieldsOfStatusObject(containerStatus)
        );
      }

      return (
        JSON.stringify(previousFoundContainerClone) !==
        JSON.stringify(currentContainerClone)
      );
    } else {
      return false;
    }
  }

  toggleSelectionAllContainers(action) {
    action.target.checked
      ? this.setState({
          selectedContainers: this.props.context.state.foundContainers.map(
            item => item.container
          )
        })
      : this.setState({ selectedContainers: [] });
  }

  toggleContainerSelection(action, containerObject) {
    action.target.checked
      ? this.setState({
          selectedContainers: [
            ...this.state.selectedContainers,
            containerObject.container
          ]
        })
      : this.removeContainerSelection(containerObject.container);
  }

  removeContainerSelection(containerToRemove) {
    const indexToRemove = this.state.selectedContainers.indexOf(
      containerToRemove
    );
    return this.setState({
      selectedContainers: [
        ...(this.state.selectedContainers.splice(indexToRemove, 1) &&
          this.state.selectedContainers)
      ]
    });
  }

  isContainerSelected(containerObject) {
    return (
      this.state.selectedContainers.indexOf(containerObject.container) !== -1
    );
  }

  isContainerSettingAlertsDisabled(container) {
    return (
      !container.containerStatuses ||
      container.containerStatuses[0].status.statusType ===
        "Status is unavailable"
    );
  }

  isAllContainerSelected() {
    return (
      this.props.context.state.foundContainers &&
      this.props.context.state.foundContainers.filter(
        item => this.state.selectedContainers.indexOf(item.container) !== -1
      ).length === this.props.context.state.foundContainers.length
    );
  }

  applyTemplateAlerts() {
    this.setState({ setAlertDisabled: true });

    let containersToApply = this.props.context.state.foundContainers
      .filter(
        container =>
          this.state.selectedContainers.includes(container.container) &&
          !this.isContainerSettingAlertsDisabled(container)
      )
      .map(containerToApply => {
        return {
          containerNumber: containerToApply.container,
          gkey: containerToApply.containerStatuses[0].gkey,
          activityType: containerToApply.containerStatuses[0].activity
        };
      });

    api(
      this.props.layoutContext,
      "/api/alert-subscription",
      "POST",
      containersToApply
    ).then(res => {
      this.setState({ setAlertDisabled: false });
      if (res.status === 200) {
        this.setState({
          selectedContainers: []
        });
        this.props.context.refreshSearch(
          this.props.layoutContext,
          this.props.layoutContext.state.isPaidUser ||
            this.props.layoutContext.state.isFreeAdvancedUser
        );
      } else if (res.status === 403) {
        res.json().then(response => {
          if (
            response.code === "CSC_04" &&
            response.subCode === "ALERT_TEMPLATE_ACTIVITY_NOT_FOUND"
          ) {
            this.setState({
              alertTemplateActivityNotFoundList: response.message
            });
          }
          if (
            response.code === "CSC_04" &&
            response.subCode === "ALERT_CONTACT_DETAIL_PHONE_NOT_FOUND"
          ) {
            this.setState({ isAlertContactDetailsNotFoundPopUpOpened: true });
          }
          if (
            response.code === "CSC_04" &&
            response.subCode === "ALERT_TEMPLATE_HAS_SINGLE_ALERT_SETTINGS"
          ) {
            this.setState({
              containerWhichHasSingleAlertsOnApplyingTemplate: response.message
            });
          }
        });
      }
    });
  }

  setContainerNumberForSingleAlertSelection(containerEntry) {
    api(
      this.props.layoutContext,
      "api/alert-templates/single?containerNumber=" +
        encodeURIComponent(containerEntry.container) +
        "" +
        "&gkey=" +
        encodeURIComponent(containerEntry.containerStatuses[0].gkey) +
        "&activityType=" +
        encodeURIComponent(containerEntry.containerStatuses[0].activity)
    ).then(res => {
      if (res.status === 200) {
        res.json().then(response =>
          this.setState({
            defaultPhoneNumber: response.defaultPhoneNumber,
            primaryPhoneNumber: response.firstContactDetail
              ? response.firstContactDetail.phone
              : "",
            initialPrimaryPhoneNumber: response.firstContactDetail
              ? response.firstContactDetail.phone
              : "",
            secondaryPhoneNumber: response.secondContactDetail
              ? response.secondContactDetail.phone
              : "",
            initialSecondaryPhoneNumber: response.secondContactDetail
              ? response.secondContactDetail.phone
              : "",
            primaryEmail: response.firstContactDetail
              ? response.firstContactDetail.email
              : "",
            initialPrimaryEmail: response.firstContactDetail
              ? response.firstContactDetail.email
              : "",
            secondaryEmail: response.secondContactDetail
              ? response.secondContactDetail.email
              : "",
            initialSecondaryEmail: response.secondContactDetail
              ? response.secondContactDetail.email
              : "",
            containerPotentialStatusList: response.potentialStatuses,

            singleContainerAlertItems: response.items,
            initialSingleContainerAlertItems: [...response.items],

            containerForSingleAlerts: {
              number: containerEntry.container,
              activity: containerEntry.containerStatuses[0].activity,
              gkey: containerEntry.containerStatuses[0].gkey
            }
          })
        );
      }
    });
  }

  alertTemplateOnClick(activityType, statusName, alertType, action) {
    if (this.state.isNoPhoneErrorActive) {
      this.setState({ isNoPhoneErrorActive: false });
    }
    const changedAlert = {
      activityType: activityType,
      containerStatusType: statusName,
      alertType: alertType
    };
    action.target.checked
      ? this.setState({
          singleContainerAlertItems: [
            ...this.state.singleContainerAlertItems,
            changedAlert
          ]
        })
      : this.removeAlertFromAlertTemplates(changedAlert);
  }

  removeAlertFromAlertTemplates(changedAlert) {
    const indexToRemove = _.findIndex(
      this.state.singleContainerAlertItems,
      changedAlert
    );
    return this.setState({
      singleContainerAlertItems: [
        ...(this.state.singleContainerAlertItems.splice(indexToRemove, 1) &&
          this.state.singleContainerAlertItems)
      ]
    });
  }

  saveSingleAlertSelection() {
    if (
      _.find(this.state.singleContainerAlertItems, { alertType: "SMS" }) &&
      (!this.state.primaryPhoneNumber ||
        this.state.primaryPhoneNumber === "" ||
        (this.state.isPrimaryPhoneEditDisabled &&
          !this.state.initialPrimaryPhoneNumber))
    ) {
      this.form.validateAll();
      this.setState({ isNoPhoneErrorActive: true });
      return;
    }

    this.setState({ isLoginInProgress: true });
    let body = {
      activityType: this.state.containerForSingleAlerts.activity,
      gkey: this.state.containerForSingleAlerts.gkey,
      containerNumber: this.state.containerForSingleAlerts.number,
      alertTemplateDto: {
        items:
          !this.state.defaultPhoneNumber || this.state.defaultPhoneNumber === ""
            ? this.state.singleContainerAlertItems.filter(
                item => item.alertType !== "SMS"
              )
            : this.state.singleContainerAlertItems
      },
      firstContactDetail: {
        email:
          this.state.isPrimaryEmailEditDisabled ||
          this.state.primaryEmail === ""
            ? this.state.initialPrimaryEmail
            : this.state.primaryEmail,
        phone:
          this.state.isPrimaryPhoneEditDisabled ||
          this.state.primaryPhoneNumber === ""
            ? this.state.initialPrimaryPhoneNumber
            : this.state.primaryPhoneNumber
      },
      secondContactDetail: {
        email:
          this.state.secondaryEmail === "" ? null : this.state.secondaryEmail,
        phone:
          this.state.secondaryPhoneNumber === ""
            ? null
            : this.state.secondaryPhoneNumber
      }
    };
    api(
      this.props.layoutContext,
      "/api/alert-subscription/single",
      "POST",
      body
    ).then(response => {
      if (response.status === 200) {
        this.closeSingleAlertSelectionPopUp();
        this.setState({ isSingleAlertSelectorSavedSuccessfully: true });
      }
    });
  }

  isSingleAlertSavingDisabled() {
    if (
      this.state.secondaryEmail !== this.state.initialSecondaryEmail ||
      this.state.secondaryPhoneNumber !== this.state.initialSecondaryPhoneNumber
    ) {
      return false;
    } else if (
      (!this.state.isPrimaryPhoneEditDisabled &&
        this.state.primaryPhoneNumber !==
          this.state.initialPrimaryPhoneNumber) ||
      (!this.state.isPrimaryEmailEditDisabled &&
        this.state.primaryEmail !== this.state.initialPrimaryEmail)
    ) {
      return false;
    } else if (
      areArraysEqual(
        this.state.initialSingleContainerAlertItems,
        this.state.singleContainerAlertItems
      )
    ) {
      return true;
    }
  }

  closeSingleAlertSelectionPopUp() {
    this.setState({
      isPrimaryPhoneEditDisabled: true,
      isPrimaryEmailEditDisabled: true,
      containerForSingleAlerts: {
        number: "",
        activity: "",
        gkey: ""
      }
    });
  }

  findMaxLengthOfContainerStatusName() {
    let maxLength = 0;
    this.props.context.state.foundContainers.forEach(container => {
      if (container.containerStatuses) {
        container.containerStatuses.forEach(containerStatus => {
          if (
            containerStatus.status &&
            containerStatus.status &&
            containerStatus.status.statusType.length > maxLength
          ) {
            maxLength = containerStatus.status.statusType.length;
            if (
              containerStatus.status.estimateArrivalTime &&
              "Estimated Arrival Time:".length > maxLength
            ) {
              maxLength = "Estimated Arrival Time:".length;
            }
            if (
              containerStatus.status.estimateDepartureTime &&
              "Estimated Departure Time:".length > maxLength
            ) {
              maxLength = "Estimated Departure Time:".length;
            }
            if (
              containerStatus.status.estimateDischargeTime &&
              "Estimated Discharge Time:".length > maxLength
            ) {
              maxLength = "Estimated Discharge Time:".length;
            }
          }
          if (containerStatus.futureStatuses) {
            containerStatus.futureStatuses.forEach(futureStatus => {
              if (futureStatus.statusType.length > maxLength) {
                maxLength = futureStatus.statusType.length;
              }
            });
          }
          if (containerStatus.pastStatuses) {
            containerStatus.pastStatuses.forEach(pastStatus => {
              if (pastStatus.statusType.length > maxLength) {
                maxLength = pastStatus.statusType.length;
              }
            });
          }
        });
      }
    });
    return maxLength;
  }

  showRemoveContainerPopUp() {
    this.isAllContainerSelected()
      ? this.setState({ isRemoveContainersAlertOpen: true })
      : this.setState({ isRemoveContainersPopUpOpen: true });
  }

  addContainerToScreen() {
    if (isValidForm(this.newContainerForm)) {
      let containerNumbersToAdd = splitContainerNumbers(
        this.newContainerForm.getValues()["new-container-numbers"]
      );
      containerNumbersToAdd = containerNumbersToAdd.filter(
        item =>
          _.find(this.props.context.state.foundContainers, {
            container: item.toUpperCase()
          }) === undefined
      );

      if (containerNumbersToAdd.length > 0) {
        this.props.context.addContainers(
          this.props.layoutContext,
          this.props.layoutContext.state.isPaidUser ||
            this.props.layoutContext.state.isFreeAdvancedUser,
          containerNumbersToAdd
        );
        this.props.history.push({
          pathname: "/containers",
          search:
            this.props.history.location.search +
            encodeURIComponent(" ") +
            encodeURIComponent(containerNumbersToAdd)
        });
      }
      this.setState({ isAddContainerPopUpOpen: false });
    }
  }

  removeSelectedContainersFromList() {
    const containerNumbers = this.props.context.state.foundContainers
      .filter(item => !this.state.selectedContainers.includes(item.container))
      .map(container => container.container);

    this.props.context.removeContainers(this.state.selectedContainers);

    this.props.history.push({
      pathname: "/containers",
      search:
        "?" + new URLSearchParams({ numbers: containerNumbers }).toString()
    });
    this.setState({
      isRemoveContainersPopUpOpen: false,
      selectedContainers: []
    });
  }

  showAddContainerPopUp() {
    this.setState({ isAddContainerPopUpOpen: true });
  }

  toggleStatusMode() {
    this.props.layoutContext.toggleContainerTableViewMode();
  }

  render() {
    const maxLengthOfContainerStatusName = this.findMaxLengthOfContainerStatusName();

    const root = document.querySelector("react-app");
    const collapsibleTrigger = isOpen => (
      <div className="single-alert-popup-trigger-wrapper">
        <h3>
          Add second alert contact details
          <div>{isOpen ? <ArrowDown /> : <ArrowUp />}</div>
        </h3>
      </div>
    );

    const containersHasSingleAlertsOnApplyingTemplatePopUp = (
      <ReactModal
        appElement={document.querySelector("react-app")}
        isOpen={
          this.state.containerWhichHasSingleAlertsOnApplyingTemplate !== ""
        }
        className="app-modal"
      >
        <Button
          id="close-modal"
          onClick={() =>
            this.setState({
              containerWhichHasSingleAlertsOnApplyingTemplate: ""
            })
          }
        >
          ×
        </Button>
        <div className="single-alert-successfully-saved-popup">
          <h3 className="app-modal-header">
            Your alerts have been applied except for the following containers
            which have special alerts set. You will need to change these using
            Set Alert in the Actions drop-down box.
            <div className="container-table-modal-container-list">
              {splitContainersByModalColumns(
                this.state.containerWhichHasSingleAlertsOnApplyingTemplate.split(
                  ","
                )
              )}
            </div>
          </h3>
          <Button
            color="blue"
            onClick={() => {
              this.setState({
                containerWhichHasSingleAlertsOnApplyingTemplate: ""
              });
              this.props.context.refreshSearch(
                this.props.layoutContext,
                this.props.layoutContext.state.isPaidUser ||
                  this.props.layoutContext.state.isFreeAdvancedUser
              );
            }}
          >
            OK
          </Button>
        </div>
      </ReactModal>
    );

    const singleAlertSuccessfullyPopUp = (
      <ReactModal
        appElement={document.querySelector("react-app")}
        isOpen={this.state.isSingleAlertSelectorSavedSuccessfully}
        className="app-modal single-alert-successfully-saved-modal"
      >
        <Button
          id="close-modal"
          onClick={() =>
            this.setState({ isSingleAlertSelectorSavedSuccessfully: false })
          }
        >
          ×
        </Button>
        <div className="single-alert-successfully-saved-popup">
          <h2>
            <p>Thank you for setting new alert details for this container.</p>
            <p>
              You will only see the new mobile numbers and/or email addresses in
              this page or later on in History.
            </p>
            If the email or mobile number you have added has not been used
            before, we will now send you a test email and/or SMS to ensure that
            they are valid.
            <p>
              This test will not count as part of your alert allowance.
            </p>
            If you do not receive anything please contact our helpdesk on 08000
            469 648
          </h2>
          <Button
            color="blue"
            onClick={() => {
              this.setState({ isSingleAlertSelectorSavedSuccessfully: false });
              this.props.context.refreshSearch(
                this.props.layoutContext,
                this.props.layoutContext.state.isPaidUser ||
                  this.props.layoutContext.state.isFreeAdvancedUser
              );
            }}
          >
            OK
          </Button>
        </div>
      </ReactModal>
    );

    const alertTemplateActivityNotFoundPopUp = (
      <ReactModal
        appElement={root}
        isOpen={this.state.alertTemplateActivityNotFoundList.length > 0}
        className="app-modal"
      >
        <div className="container-table-apply-template-alerts-error-popup">
          <h2>
            You have not yet set up your{" "}
            <a href="/alert-templates">Alert Template</a> for{" "}
            {this.state.alertTemplateActivityNotFoundList.length > 0 &&
              this.state.alertTemplateActivityNotFoundList.split(/[[\]]/)}
            . Please set this up first and then apply to your containers.
            Nothing has been applied
          </h2>
          <Button
            color="blue"
            onClick={() =>
              this.setState({ alertTemplateActivityNotFoundList: [] })
            }
          >
            OK
          </Button>
        </div>
      </ReactModal>
    );

    const alertContactDetailsNotFoundPopUp = (
      <React.Fragment>
        <ReactModal
          appElement={root}
          isOpen={this.state.isAlertContactDetailsNotFoundPopUpOpened}
          className="app-modal"
        >
          <div className="container-table-apply-template-alerts-error-popup">
            <h2>
              You must nominate a mobile number before SMS alerts can be
              applied. Please go to the{" "}
              <span
                className="container-table-change-alert-contacts-link"
                onClick={() => {
                  this.setState({
                    isAlertContactDetailsNotFoundPopUpOpened: false,
                    isChangeAlertContactDetailsPopUpOpened: true
                  });
                }}
              >
                Change Alert Email and SMS
              </span>{" "}
              page.
            </h2>
            <Button
              color="blue"
              onClick={() =>
                this.setState({
                  isAlertContactDetailsNotFoundPopUpOpened: false
                })
              }
            >
              OK
            </Button>
          </div>
        </ReactModal>

        <ChangeAlertContacts
          isOpen={this.state.isChangeAlertContactDetailsPopUpOpened}
          onClose={() => {
            this.setState({ isChangeAlertContactDetailsPopUpOpened: false });
          }}
          layoutContext={this.props.layoutContext}
        />
      </React.Fragment>
    );

    const isPaidUser = hasAnyServiceType(["Paid", "Free-advanced", 'Internal']);
    const singleAlertPopUp = (
      <ReactModal
        appElement={root}
        isOpen={this.state.containerForSingleAlerts.number !== ""}
        className="app-modal single-alert-selector-modal"
      >
        <Button
          id="close-modal"
          onClick={() => this.closeSingleAlertSelectionPopUp()}
        >
          ×
        </Button>
        <div className="single-alert-popup">
          <div className="single-alert-popup-header">
            <h2>{this.state.containerForSingleAlerts.number}</h2>
            <h3>
              Alert preferences can only be changed before the status has been
              reached
            </h3>
          </div>

          {(!this.state.defaultPhoneNumber ||
            this.state.defaultPhoneNumber === "") && (
            <div className="form-error is-visible">
              You must{" "}
              <span
                onClick={e => {
                  e.preventDefault();
                  this.closeSingleAlertSelectionPopUp();
                  this.setState({ isChangeAlertContactPopUpOpen: true });
                }}
              >
                nominate your default mobile number
              </span>{" "}
              before SMS alerts can be applied. Only Email alerts can be
              applied.
            </div>
          )}
          <div className="single-alret-popup-container">
            <div  className={"single-alert-popup-alert-template-wrapper "+ this.state.containerForSingleAlerts.activity}>
              <AlertTemplate
                isSingleAlert={true}
                defaultPhoneNumber={this.state.defaultPhoneNumber}
                availableStatusList={this.state.containerPotentialStatusList}
                isMobile={this.props.layoutContext.state.isMobile}
                activityType={this.state.containerForSingleAlerts.activity}
                currentAlertTemplates={this.state.singleContainerAlertItems}
                onclick={this.alertTemplateOnClick.bind(this)}
              />
            </div>
            <div className="single-alert-popup-contact-details">
              <Form
                ref={node => {
                  this.form = node;
                }}
                onSubmit={event => {
                  event.preventDefault();
                }}
              >
                <div className="single-alert-popup-contact-details single-alert-popup-contact-details-primary-contact">
                  <h3>Alert contact details for this container</h3>
                  <div className="single-alert-popup-contact-details-contact-cell">
                    <label>SMS</label>
                    <span className="single-alert-popup-contact-details-primary-input">
                      <Input
                        name="alert-primary-phone-number"
                        type="text"
                        value={this.state.primaryPhoneNumber || ""}
                        onChange={e => {
                          this.setState({ isNoPhoneErrorActive: false });
                          filterInput.call(
                            this,
                            e,
                            "primaryPhoneNumber",
                            dataValidationPatterns.phone
                          );
                        }}
                        validations={[alertPrimaryPhone]}
                        onBlur={() => {
                          this.setState({ isPrimaryPhoneNumberChanged: true });
                          this.form.validate("alert-primary-phone-number");
                        }}
                        maxLength={20}
                        minLength={6}
                        disabled={
                          !this.state.defaultPhoneNumber ||
                          this.state.defaultPhoneNumber === "" ||
                          this.state.isPrimaryPhoneEditDisabled
                        }
                        hasChanges={this.state.isPrimaryPhoneNumberChanged}
                      />
                      <Input
                        name="alert-primary-phone-number-disabled"
                        className="enable-editing-checkbox"
                        type="checkbox"
                        checked={!this.state.isPrimaryPhoneEditDisabled}
                        disabled={
                          !this.state.defaultPhoneNumber ||
                          this.state.defaultPhoneNumber === ""
                        }
                        onChange={event =>
                          this.setState({
                            isPrimaryPhoneEditDisabled: !event.target.checked
                          })
                        }
                      />
                      <Input
                        name="phone_error"
                        hidden={true}
                        value={this.state.isNoPhoneErrorActive}
                      />
                      <label>Change</label>
                    </span>
                  </div>
                  <div className="single-alert-popup-contact-details-contact-cell">
                    <label>Email</label>
                    <span className="single-alert-popup-contact-details-primary-input">
                      <Input
                        name="alert-primary-email"
                        type="text"
                        value={this.state.primaryEmail}
                        onChange={e =>
                          filterInput.call(
                            this,
                            e,
                            "primaryEmail",
                            dataValidationPatterns.email
                          )
                        }
                        validations={[required, email]}
                        onBlur={() => this.form.validate("alert-primary-email")}
                        maxLength={200}
                        disabled={this.state.isPrimaryEmailEditDisabled}
                      />
                      <Input
                        className="enable-editing-checkbox"
                        type="checkbox"
                        checked={!this.state.isPrimaryEmailEditDisabled}
                        onChange={event =>
                          this.setState({
                            isPrimaryEmailEditDisabled: !event.target.checked
                          })
                        }
                      />
                      <label>Change</label>
                    </span>
                  </div>
                </div>
                <Collapsible
                  trigger={collapsibleTrigger(false)}
                  triggerWhenOpen={collapsibleTrigger(true)}
                  triggerTagName="div"
                  triggerClassName="alert-templates-page-header-trigger"
                  triggerOpenedClassName=""
                >
                  <div className="single-alert-popup-contact-details single-alert-popup-contact-details-secondary-contact">
                    <h3>
                      <p>
                        Adding contact details here will mean that 2 alert<br/>
                        emails or SMS will be sent for each ticked checkbox
                      </p>
                    </h3>
                    <div className='single-alert-popup-secondary-contact'>
                      <div className="single-alert-popup-contact-details-contact-cell">
                      <label>SMS</label>
                      <Input
                        name="alert-secondary-phone-number"
                        placeholder="Optional"
                        type="text"
                        value={this.state.secondaryPhoneNumber}
                        onChange={e =>
                          filterInput.call(
                            this,
                            e,
                            "secondaryPhoneNumber",
                            dataValidationPatterns.phone
                          )
                        }
                        validations={[phoneOrEmpty]}
                        onBlur={() =>
                          this.form.validate("alert-secondary-phone-number")
                        }
                        disabled={
                          !this.state.defaultPhoneNumber ||
                          this.state.defaultPhoneNumber === ""
                        }
                        maxLength={20}
                        minLength={6}
                      />
                    </div>
                      <div className="single-alert-popup-contact-details-contact-cell">
                      <label>Email</label>
                      <Input
                        name="alert-secondary-email"
                        type="text"
                        placeholder="Optional"
                        value={this.state.secondaryEmail}
                        onChange={e =>
                          filterInput.call(
                            this,
                            e,
                            "secondaryEmail",
                            dataValidationPatterns.email
                          )
                        }
                        validations={[emailOrEmpty]}
                        onBlur={() =>
                          this.form.validate("alert-secondary-email")
                        }
                        maxLength={200}
                      />
                    </div>
                    </div>
                  </div>
                </Collapsible>
                <div className="single-alert-popup-contact-detail-controls">
                  <Button
                    color="blue"
                    onClick={e => {
                      e.preventDefault();
                      this.closeSingleAlertSelectionPopUp();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    isFormValidationRequired={true}
                    disabled={this.isSingleAlertSavingDisabled()}
                    color="pink"
                    onClick={() => {
                      this.saveSingleAlertSelection();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ReactModal>
    );

    const removeContainerAlertOpen = (
      <ReactModal
        appElement={root}
        isOpen={this.state.isRemoveContainersAlertOpen}
        className="app-modal"
      >
        <div className="container-table-remove-containers-from-list">
          <h3>
            You cannot remove all containers. There must be at least one
            container number on the screen
          </h3>
          <div>
            <Button
              color="blue"
              onClick={() =>
                this.setState({ isRemoveContainersAlertOpen: false })
              }
            >
              Ok
            </Button>
          </div>
        </div>
      </ReactModal>
    );

    const addContainerPopUp = (
      <ReactModal
        appElement={root}
        isOpen={this.state.isAddContainerPopUpOpen}
        className="app-modal"
      >
        <div>
          <Button
            id="close-modal"
            onClick={() => this.setState({ isAddContainerPopUpOpen: false })}
          >
            ×
          </Button>
          <h2 className="app-modal-header">Add container numbers</h2>
          <div className="container-table-add-containers-to-list">
            <Form
              ref={node => {
                this.newContainerForm = node;
              }}
              onSubmit={event => {
                event.preventDefault();
              }}
            >
              <Textarea
                id="container-numbers-text-area"
                name="new-container-numbers"
                className="search-numbers"
                placeholder="Maximum number of containers that can be displayed is 20"
                currentcontainernumber={
                  this.props.context.state.foundContainers.length
                }
                validations={[
                  requiredAtLeastOneContainerNumber,
                  numberOfContainers,
                  searchContainerFormat
                ]}
                maxcontainernumbers={20}
                containernumberformat={dataValidationPatterns.containerNumber}
              />

              <div className="container-table-add-containers-to-list-controls">
                <Button
                  color="blue"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ isAddContainerPopUpOpen: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="pink"
                  onClick={e => {
                    e.preventDefault();
                    this.newContainerForm.validateAll();
                    this.addContainerToScreen();
                  }}
                >
                  Add to screen
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </ReactModal>
    );

    const removeContainerModal = (
      <ReactModal
        appElement={root}
        isOpen={this.state.isRemoveContainersPopUpOpen}
        className="app-modal remove-containers-modal"
      >
        <div className="container-table-remove-containers-from-list">
          <Button
            id="close-modal"
            onClick={() =>
              this.setState({ isRemoveContainersPopUpOpen: false })
            }
          >
            ×
          </Button>
          <h3 className="app-modal-header">
            You are removing the following containers from the list:
            <div className="container-table-modal-container-list">
              {splitContainersByModalColumns(this.state.selectedContainers)}
            </div>
          </h3>
          <div className="container-table-remove-containers-from-list-controls">
            <span>
              <Button
                color="blue"
                onClick={() =>
                  this.setState({ isRemoveContainersPopUpOpen: false })
                }
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                color="pink"
                onClick={() => this.removeSelectedContainersFromList()}
              >
                Remove from Screen
              </Button>
            </span>
          </div>
        </div>
      </ReactModal>
    );

    const alertSuspensionNotificationMessage = (
      <>
        {this.props.context.state.isSmsAlertsSuspended &&
          !this.props.context.state.isEmailAlertsSuspended && (
            <span className="container-table_email-sms-alerts-suspended">
              SMS Alerts are currently suspended
            </span>
          )}
        {this.props.context.state.isEmailAlertsSuspended &&
          !this.props.context.state.isSmsAlertsSuspended && (
            <span className="container-table_email-sms-alerts-suspended">
              Email Alerts are currently suspended
            </span>
          )}
        {this.props.context.state.isEmailAlertsSuspended &&
          this.props.context.state.isSmsAlertsSuspended && (
            <span className="container-table_email-sms-alerts-suspended">
              All Alerts are currently suspended
            </span>
          )}
      </>
    );

    if (this.props.layoutContext.state.isMobile) {
      return (
        <React.Fragment>
          <ContainerCardToolbar
            context={this.props.context}
            toggleStatusMode={this.toggleStatusMode.bind(this)}
            allStatusesMode={this.props.layoutContext.state.allStatusesMode}
            showAddContainerPopUp={this.showAddContainerPopUp.bind(this)}
            showRemoveContainerPopUp={this.showRemoveContainerPopUp.bind(this)}
            selectedContainers={this.state.selectedContainers}
            isPaidUser={isPaidUser}
            onSortingSelect={this.selectSorting}
            onApplyTemplateButtonClick={this.applyTemplateAlerts}
            setAlertDisabled={this.state.setAlertDisabled}
            {...this.props}
          />
          {alertSuspensionNotificationMessage}
          <div className="container-table-cards">
            {this.sortData().map(entry => (
              <Card
                allStatusesMode={this.props.layoutContext.state.allStatusesMode}
                isPaidUser={isPaidUser}
                hasChanges={this.isContainerChanged(entry)}
                key={entry.container}
                entry={entry}
                isAvailableForSettingAlerts={
                  !this.isContainerSettingAlertsDisabled(entry)
                }
                isSelected={this.isContainerSelected(entry)}
                onSelect={action =>
                  this.toggleContainerSelection(action, entry)
                }
                onAction={action =>
                  this.props.onAction(entry.container, action)
                }
                setContainerNumberForSingleAlertSelection={this.setContainerNumberForSingleAlertSelection.bind(
                  this
                )}
              />
            ))}
          </div>
          {singleAlertPopUp}
          {alertTemplateActivityNotFoundPopUp}
          {singleAlertSuccessfullyPopUp}
          {alertContactDetailsNotFoundPopUp}
          {removeContainerModal}
          {removeContainerAlertOpen}
          {addContainerPopUp}
          {containersHasSingleAlertsOnApplyingTemplatePopUp}
          <ChangeAlertContacts
            isOpen={this.state.isChangeAlertContactPopUpOpen}
            onClose={() => {
              this.setState({ isChangeAlertContactPopUpOpen: false });
            }}
            layoutContext={this.props.layoutContext}
          />
        </React.Fragment>
      );
    } else {
      return (
        <div className="container-table-wrapper">
          {alertSuspensionNotificationMessage}
          {isPaidUser && (
            <div className="container-table-toolbar">
              <div>
              <Button
                disabled={
                  !this.state.selectedContainers ||
                  this.state.selectedContainers.length === 0 ||
                  this.state.setAlertDisabled
                }
                onClick={() => this.applyTemplateAlerts()}
                className="container-table-toolbar-item"
                color="light-blue"
              >
                Apply Template Alerts
              </Button>
              <span
                className="info info-apply-template-remove-container"
                data-apply-template-remove-container="Containers need to be selected via a check box before applying alerts or removing containers"
                data-apply-template-remove-container-at="top"
              >
                i
              </span>
              <Button
                disabled={
                  !this.state.selectedContainers ||
                  this.state.selectedContainers.length === 0
                }
                onClick={() => this.showRemoveContainerPopUp()}
                className="container-table-toolbar-item"
                color="light-blue"
              >
                Remove Containers
              </Button>
              <Button
                onClick={() => this.toggleStatusMode()}
                className="container-table-toolbar-item"
                color="light-blue"
              >
                {this.props.layoutContext.state.allStatusesMode
                  ? "Current Status Only"
                  : "All Statuses"}
              </Button>
              <Button
                disabled={this.props.context.state.foundContainers.length >= 20}
                onClick={() => this.showAddContainerPopUp()}
                className="container-table-toolbar-item"
                color="light-blue"
              >
                Add Containers
              </Button>
              </div>
              <div>
                <Button
                className="container-table-toolbar-item"
                color="light-blue"
                onClick={()=>{this.props.history.push('/saved-lists')}}>
                  Go to Saved Lists</Button>
              </div>
            </div>
          )}
          <table className="container-table">
            <thead>
              <tr>
                {isPaidUser && (
                  <th className="table-row-selection-column">
                    {this.props.context.state.foundContainers.length > 0 && (
                      <input
                        type="checkbox"
                        checked={this.isAllContainerSelected()}
                        onChange={action => {
                          this.toggleSelectionAllContainers(action);
                        }}
                      />
                    )}
                  </th>
                )}
                <th
                  className={this.className("container")}
                  onClick={() => this.changeSorting("container")}
                >
                  Container
                </th>
                <th
                  className={this.className("port")}
                  onClick={() => this.changeSorting("port")}
                >
                  Port
                </th>
                <th
                  className={this.className("activity")}
                  onClick={() => this.changeSorting("activity")}
                >
                  Activity
                </th>
                <th
                  className={this.className("vessel")}
                  onClick={() => this.changeSorting("vessel")}
                >
                  Vessel
                </th>
                {/*hardcoded width to align alert header above values*/}
                <th
                  className={
                    this.className("status") + " table-row-status-column"
                  }
                  style={
                    isPaidUser
                      ? {
                          width: maxLengthOfContainerStatusName * 7 + 85 + "px",
                          minWidth:
                            maxLengthOfContainerStatusName * 7 + 85 + "px"
                        }
                      : {}
                  }
                  onClick={() => this.changeSorting("status")}
                >
                  Status
                </th>
                {isPaidUser && (
                  <th className="table-row-alerts-column">
                    {isPaidUser && (
                      <span className="container-table__status-cell_advanced-mode-alert-header">
                        <span>Alerts</span>
                        <span>Email</span>
                        <span>SMS</span>
                      </span>
                    )}
                  </th>
                )}
              </tr>
            </thead>
            {this.sortData().map((entry, i) => (
              <tbody key={entry.container + i}>
                <Row
                  maxLengthOfContainerStatusName={
                    maxLengthOfContainerStatusName
                  }
                  allStatusesMode={
                    this.props.layoutContext.state.allStatusesMode
                  }
                  isPaidUser={
                    this.props.layoutContext.state.isPaidUser ||
                    this.props.layoutContext.state.isFreeAdvancedUser
                  }
                  hasChanges={this.isContainerChanged(entry)}
                  setContainerNumberForSingleAlertSelection={this.setContainerNumberForSingleAlertSelection.bind(
                    this
                  )}
                  key={entry.container}
                  entry={entry}
                  onAction={action =>
                    this.props.onAction(entry.container, action)
                  }
                  isAvailableForSettingAlerts={
                    !this.isContainerSettingAlertsDisabled(entry)
                  }
                  isSelected={this.isContainerSelected(entry)}
                  onSelect={action =>
                    this.toggleContainerSelection(action, entry)
                  }
                />
              </tbody>
            ))}
          </table>
          {alertTemplateActivityNotFoundPopUp}
          {singleAlertSuccessfullyPopUp}
          {alertContactDetailsNotFoundPopUp}
          {singleAlertPopUp}
          {removeContainerModal}
          {removeContainerAlertOpen}
          {addContainerPopUp}
          {containersHasSingleAlertsOnApplyingTemplatePopUp}
          <ChangeAlertContacts
            isOpen={this.state.isChangeAlertContactPopUpOpen}
            onClose={() => {
              this.setState({ isChangeAlertContactPopUpOpen: false });
            }}
            layoutContext={this.props.layoutContext}
          />
        </div>
      );
    }
  }
}

export default props => (
  <LayoutContext.Consumer>
    {layoutContext => (
      <Context.Consumer>
        {context => (
          <ContainerTable
            {...props}
            context={context}
            layoutContext={layoutContext}
          />
        )}
      </Context.Consumer>
    )}
  </LayoutContext.Consumer>
);
