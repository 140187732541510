import React from 'react';
import { Section} from '..';

export function dashboardComingSoonSection(componentContent){
  return <Section
            color={componentContent.customField1}
            title={componentContent.title}
            backgroundColorCode={componentContent.customField1}
            textColorCode={componentContent.customField2}
            titleMode={componentContent.customField3}
            imagePosition={componentContent.customField5}
            imageFileName={componentContent.customField4}
            opacity={componentContent.customField8 || 1}
            displayMode={componentContent.contentType.name}
            size={6}
            className="dashboard_coming-soon-section"
            mediaTitle={componentContent.customField3}
            link={componentContent.customField6}
            innerContent={componentContent.content !== 'null' && <span className='dashboard_faq-section-content' dangerouslySetInnerHTML={{__html: componentContent.content}}/>}/>
}