import {api} from "./index";
import _ from 'lodash';
import { getCmsComponentByLocation } from '../components/cms-integration-components/get-cms-component';
import localForage from "localforage";

const componentsNeedToBeFilledAccordingRegionLimit = ['Sign In'];
const componentsNeedToBeCachedEvenNotPresentInCMS = ['Sign In'];

export const getCmsContentFromCache = () => {
  return localForage.getItem('cmsContentCache');
};

export const getCmsMediaFromCache = async () => {
  return localForage.getItem('cmsMediaContent');
};

export const getCmsMediaFromCacheByName = (fileName, context) => {
  if(context && (!context.state.cmsImage || context.state.cmsImage.fileName!== fileName)){
    getCmsMediaFromCache().then(cache => {
      context.setState({cmsImage: _.find(cache, {fileName: fileName})});
    });
  }
};

export const removeFromCmsMediaContentFromCache = (fileNamesToDelete) => {
  if(fileNamesToDelete.length > 0){
    getCmsMediaFromCache().then((cachedValues)=>{
    cachedValues = cachedValues.filter(item => _.find(fileNamesToDelete, {fileName: item.fileName}) === undefined);
    localForage.setItem('cmsMediaContent',cachedValues);})
  }
};

export const checkCmsCache = (response, previousCache) => {
  let previousCachedComponentsToAdd = [];
  response.forEach(item => {
    let index = componentsNeedToBeCachedEvenNotPresentInCMS.indexOf(item.region.name);
    if(index !== -1){
      const componentsAmount = response.filter(responseItem => responseItem.region.name === item.region.name).length;
      if (componentsAmount < item.region.itemLimit) {
        if (previousCache) {
          previousCachedComponentsToAdd = previousCachedComponentsToAdd.concat(
              previousCache.cmsContentCache.content
              .filter(previousCacheItem =>
                  _.find(response, {published: previousCacheItem.published, region: {name: previousCacheItem.region.name}}) === undefined
                  && _.find(response, {region: {name: previousCacheItem.region.name}}) !== undefined
              ))
        }
      }
    }
  });
  return previousCachedComponentsToAdd;
};


export const cacheMediaContentFromResponse = (response, context, initialContext, regionNames, updateCacheFunction) => {
  const allFileNames = [...new Set(response
  .filter(item => (item.contentType.name === 'Text+Image'
      || item.contentType.name === 'Image') && item.customField4 !== null)
  .map(item => item.customField4))];

  if (allFileNames.length > 0) {
    return getCmsMediaFromCache().then((cachedCmsMediaContent)=>{

    //file names to get from remote(if doesn't exist in cache)
    const fileNamesToGetFormRemote = allFileNames.filter(
        newItem => _.find(cachedCmsMediaContent, {fileName: newItem})
            === undefined);

    if (fileNamesToGetFormRemote.length > 0) {
      Promise.all(fileNamesToGetFormRemote.map(item => api(context,
          'content-media/'
          + item)))
      .then(result => Promise.all(result.map(v => v.blob()))
          .then(images => {
              Promise.all(images.map( (image, imageOrder) => {
                return new Promise((resolve, reject) => {
                  let reader = new FileReader();
                  reader.readAsDataURL(image);
                  reader.onloadend = () => {
                    resolve({ content: reader.result, fileName: fileNamesToGetFormRemote[imageOrder] });
                  };
                });
              })).then((results) => {
                cachedCmsMediaContent = filterUnusedMediaContent(allFileNames ,cachedCmsMediaContent);
                if(cachedCmsMediaContent){
                  results = results.concat(cachedCmsMediaContent);
                }
                Promise.all([localForage.setItem('cmsMediaContent', results), updateCacheFunction()]).then(()=> {
                    context.getCmsComponent(regionNames, initialContext);
                  })
              });
          })
      )
    }
    else updateCacheFunction().then(() => context.getCmsComponent(regionNames, initialContext));

    if (cachedCmsMediaContent !== null && cachedCmsMediaContent.length > 0) {
      const filesToDelete = filterUsedMediaContent(allFileNames, cachedCmsMediaContent);
      removeFromCmsMediaContentFromCache(filesToDelete);
    }});
  }
  //if no media in response then clear media cache and update cms cache
  else {
    Promise.all([localForage.removeItem('cmsMediaContent'), updateCacheFunction()]).then(()=>{
      context.getCmsComponent(regionNames, initialContext);
    })
  }
};

const filterUnusedMediaContent = (usedFileNames, cachedCmsMediaContent) => {
  return cachedCmsMediaContent ? cachedCmsMediaContent.filter(
      item => usedFileNames.indexOf(item.fileName) !== -1) : [];
};

const filterUsedMediaContent = (usedFileNames, cachedCmsMediaContent) => {
  return cachedCmsMediaContent ? cachedCmsMediaContent.filter(
      item => usedFileNames.indexOf(item.fileName) === -1) : [];
};

export const sortCmsContent = (contentArray) => {
  return contentArray.sort((a, b) => {
    if (a.displayOrder > b.displayOrder) {
      return 1;
    }
    if (a.displayOrder < b.displayOrder) {
      return -1;
    }
    if (a.published > b.published) {
      return -1;
    }
    if (a.published < b.published) {
      return 1;
    }
    return 0;
  })
};

export const buildComponentsFromCmsInfo = (cmsContent, regionNames) => {

  return regionNames.map(regionName => {
    const regionContent = cmsContent.cmsContentCache.content.filter(
        cachedItem => regionName.indexOf(cachedItem.region.name) !== -1)
    .map(cmsItem => {
      return {
        ...getCmsComponentByLocation(cmsItem.region.name, cmsItem)
      }
    });
    return {regionName: regionName, content: regionContent}
  });
};

export const loadCmsContent = (layoutContext, initialContext, regionNames) => {
  let that = layoutContext;
  api(layoutContext, '/content')
  .then(res => {
    if (res.status === 200) {
      res.json().then(
          //TODO check published date to decide whether we need update content

          response => {
            //get cms cache
            getCmsContentFromCache().then((previousCache) => {

              //check component in previous cache
              let previousCachedComponentsToAdd = checkCmsCache(response, previousCache);
              if(previousCachedComponentsToAdd.length > 0) {
                response = response.concat(previousCachedComponentsToAdd);
              }

              //if region was removed from CMS and present in cache
              if (previousCache) {
                const cachedRegionNames = [...new Set(
                    previousCache.cmsContentCache.content
                    .map(item => item.region.name)
                    .filter(
                        cachedRegion => componentsNeedToBeFilledAccordingRegionLimit.indexOf(
                            cachedRegion) !== -1
                            && _.find(response, {region: {name: cachedRegion}})
                            === undefined))];
                if (cachedRegionNames.length > 0) {
                  response = response.concat(
                      previousCache.cmsContentCache.content.filter(
                          previousCacheItem => cachedRegionNames.indexOf(
                              previousCacheItem.region.name) !== -1));
                }
              }

              let responseWithoutDups = _.map(
                  _.uniq(
                      _.map(response, function (obj) {
                        return JSON.stringify(obj);
                      })
                  ), function (obj) {
                    return JSON.parse(obj);
                  }
              );

              //cache result
              cacheMediaContentFromResponse(response, that, initialContext,
                  regionNames, ()=> localForage.setItem('cmsContentCache', {
                    cmsContentCache: {
                      createdDate: new Date(),
                      content: responseWithoutDups
                    }
                  }));
            });
          }
      ).catch (()=>{
        console.log("Error during saving CMS content to cache");
      })
    }
  });
};