import React from 'react';
import validator from 'validator';
import { splitContainerNumbers } from '../services';
import requiredValidationMessages from './required-validation-messages';
import validationMessages from './validation-messages';
import confirmationValidationMessage from './confirmation-validation-messages';

export const required = (value, props) => {
  if (!value ||
    (props.isUsed && !value) ||
    (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">{requiredValidationMessages[props.name]}</span>;
  }
};

export const requiredHelpDesk = (value, props) => {
  if (!value ||
      (props.isUsed && !value) ||
      (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">This field cannot be empty</span>;
  }
};

export const atLeastOneOfFields = (value, props, allValues) => {
  return !(allValues["company"][0].value || allValues["email-address"][0].value
      || allValues["first-name"][0].value || allValues["last-name"][0].value) ? <span/> : null;
};

export const requiredDynamicSubType = (value, props, allValues) => {
  if (['Importer', 'Exporter', 'Importer and Exporter'].includes(allValues["business-type"][0].value) && !allValues["business-subtype"][0].value) {
    return <span className="form-error is-visible">{requiredValidationMessages[props.name]}</span>;
  }
};

export const requiredNewPasswordConfirm = (value, props) => {
  if (!value ||
      (props.isUsed && !value) ||
      (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">Please confirm your new password</span>;
  }
};

export const requiredNewPassword = (value, props) => {
  if (!value ||
      (props.isUsed && !value) ||
      (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">Please enter your new password</span>;
  }
};

export const requiredAtLeastOneContainerNumber = (value, props) => {
  if (splitContainerNumbers(value).length < 1 || (props.isCheckable && !props.checked)) {
    return <span className="form-error is-visible">You must provide at least one container number</span>;
  }
};

export const postcode = (value, props) => {
  if (
      props.isUsed && props.isUsed &&
      props.isChanged && props.isChanged &&
      value.match(/^([A-Za-z\d][A-Za-z\d\s-]+[A-Za-z\d])$/g) === null
  ) {
    return <span className="form-error is-visible">Please enter the postcode</span>;
  }
};

export const alertPrimaryPhone = (value, props, components) => {
  if((components.phone_error && components.phone_error[0].value === true)
       || (props.hasChanges && phone(value, props))
  ){
    return <span className="form-error is-visible">{validationMessages[props.name]}</span>;
  }
};

export const phone = (value, props) => {
  if (
      props.isUsed && props.isUsed &&
      props.isChanged && props.isChanged &&
      value.match(/[\d]{6,}/g) === null
  ) {
    return <span className="form-error is-visible">{validationMessages[props.name]}</span>;
  }
};

export const phoneCheck = (value, props) => {
  if (
      props.isUsed && props.isUsed &&
      props.isChanged && props.isChanged &&
      value.match(/[\d]{6,}/g) === null
  ) {
    return <span className="form-error is-visible">Invalid phone number</span>;
  }
};

export const phoneOrEmpty = (value, props) => {
  if(value){
    return phone(value, props);
  }
};

export const password = (value, props) => {
  if(
    props.isUsed && props.isUsed &&
    props.isChanged && props.isChanged &&
    (
      !value.match(/[A-Z]/g) ||
      !value.match(/[\d]/g) ||
      value.length < 8 ||
      value.length > 50)
  ) {
    return <span className="form-error is-visible">Password must contain at least 8 characters including at least 1 number and 1 uppercase letter.</span>
  }
};

export const passwordsEqual = (value, props, components) => {
  const password = components.password[0];
  const confirmPassword = components['confirm-password'][0];

  if (
    password.isUsed && confirmPassword.isUsed &&
    password.isChanged && confirmPassword.isChanged &&
    password.value !== confirmPassword.value
  ) {
    return <span className="form-error is-visible">The passwords you have entered do not match</span>;
  }
};

export const passwordLogin = (value, props) => {
  if (!value || value === '') {
    return <span className="form-error is-visible"/>;
  }
};

export const passwordsNotEqual = (value, props, components) => {
  const oldPassword = components['current-password'][0];
  const newPassword = components['password'][0];

  if (
    oldPassword.isUsed && newPassword.isUsed &&
    oldPassword.isChanged && newPassword.isChanged &&
    oldPassword.value === newPassword.value
  ) {
    return <span className="form-error is-visible">Old and new passwords are the same, please create a new password.</span>;
  }
};

export const email = (value, props) => {
  if (!validator.isEmail(value) || value === '') {
    return <span className="form-error is-visible">Invalid Email</span>;
  }
};

export const emailOrEmpty = (value, props) => {
  if(value){
    return email(value, props);
  }
};

export const emailsEqual = (value, props, components) => {
  const email = components.email[0];
  const confirmEmail = components['confirm-email'][0];

  if (
    email.isUsed && confirmEmail.isUsed &&
    email.isChanged && confirmEmail.isChanged &&
    email.value !== confirmEmail.value
  ) {
    return <span className="form-error is-visible">Email addresses do not match</span>;
  }
};

export const emailExist = (value, props) => {
  if (
    props.isUsed && props.isUsed &&
    props.isChanged && props.isChanged &&
    !props.isemailexist
  ) {
    return <span className="form-error is-visible">This username does not exist in the system. Please <a href="/register">register</a> to proceed</span>;
  }
};

export const emailNotExist = (value, props) => {
  if (
    props.isUsed && props.isUsed &&
    props.isChanged && props.isChanged &&
    props.isemailexist
  ) {
    return <span className="form-error is-visible">This Email already exists</span>;
  }
};

export const numberOfContainers = (value, props) => {
  let containerNumber = splitContainerNumbers(value).length;
  if (containerNumber > props.maxcontainernumbers
      || (props.currentcontainernumber && (props.maxcontainernumbers - props.currentcontainernumber < containerNumber))) {
    return <span className="form-error is-visible">{validationMessages[props.name]}
    </span>;
  }
};

export const searchContainerFormat = (value, props) => {
  let invalidNumbers = [];
  splitContainerNumbers(value).forEach((containerNumber) => {
    if (!props.containernumberformat.test(containerNumber)) {
      invalidNumbers.push(containerNumber);
    }
  });
  if (invalidNumbers.length > 0) {
    return <span className="form-error is-visible">Invalid format of container number {invalidNumbers.join(
        ', ')}</span>;
  }
};

export const hasCorrectConfirmation = (value, props, components) => {
  const originalValue = components[props.name][0];
  const isOriginalValueIsConfirmation = props.name.includes('-confirmation');
  const confirmation = isOriginalValueIsConfirmation ? components[props.name.replace('-confirmation', '')][0] : components[props.name+'-confirmation'][0];

  if(isOriginalValueIsConfirmation && !originalValue.isUsed && originalValue.value !== confirmation.value ){
    return <span className="form-error invisible"/>;
  }
  if (
      confirmation.isUsed && originalValue.isUsed &&
      originalValue.isChanged && confirmation.isChanged &&
      originalValue.value !== confirmation.value
  ) {
    return <span className="form-error is-visible">{confirmationValidationMessage[isOriginalValueIsConfirmation ? props.name.replace('-confirmation', '') : props.name]}</span>;
  }
};

export const notEqualsToCurrentValue = (value, props, components) => {
  const currentValue = components[props.name + '-current-value'][0];
  const newValue = components[props.name][0];

  if (
      props.isUsed && props.isUsed &&
      props.isChanged && props.isChanged &&
      currentValue.value &&
      currentValue.value.toLowerCase() === newValue.value.toLowerCase()
  ) {
    return <span className="form-error is-visible">This is already your current information</span>;
  }
};

export const atLeastOneOfContactUpdateFields = (value, props, allValues) => {
  return !(allValues["alert-template-page_new_email"][0].value || allValues["alert-template-page_new_sms_number"][0].value) ? <span/> : null;
};

export const listNameAlreadyExists = (value, props) => {
  for(let existingName of props.existingListNames){
    if(value.toUpperCase() === existingName.toUpperCase()) {
      return <span className="form-error is-visible">This list name already exists</span>;
    }
  }
};