import React from 'react';
import {api, filterInput, dataValidationPatterns, splitContainersByModalColumns, isValidForm} from '../../services';
import {Button} from '..';
import {default  as ValidationButton} from 'react-validation/build/button';
import {
  hasCorrectConfirmation,
  phoneOrEmpty,
  emailOrEmpty,
  notEqualsToCurrentValue,
  atLeastOneOfContactUpdateFields
} from '../../validations';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import ReactModal from 'react-modal';

import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css'
import './change-alert-contacts.css';

const ReactHint = ReactHintFactory(React);

export default class ChangeAlertContacts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentEmail: null,
      currentSMSNumber: null,
      newEmail: '',
      newEmailConfirmation: '',
      newSMSNumber: '',
      newSMSNumberConfirmation: '',
      affectedSASContainers: []
    };
  }

  updateAlertEmailSMSNumber() {
    if(isValidForm(this.form)){
      const values = this.form.getValues();
      const newEmail = values['alert-template-page_new_email'] === '' ? null
          : values['alert-template-page_new_email'];
      const smsNumber = values['alert-template-page_new_sms_number'] === '' ? null
          : values['alert-template-page_new_sms_number'];

      const body = {
        'email': newEmail,
        'phone': smsNumber
      };

      api(this.props.layoutContext, 'api/alert-contact-detail', 'POST', body)
      .then(res => {
        if (res.status === 200) {
          res.json().then((response) => {
            if(response && response.length > 0){
              this.setState({affectedSASContainers: response})
            } else {
              this.onContactsChanged();
            }
          });
        } else {
          this.props.layoutContext.setConnectionProblems(true);
        }
      });
    }
  }

  onContactsChanged() {
    if (this.props.onSuccessfullUpdate) {
      this.props.onSuccessfullUpdate();
    }
    this.setDefaultContactsAndClosePopUp();
  }

  setDefaultContactsAndClosePopUp(){
    this.setState({
      newEmail: '',
      newEmailConfirmation: '',
      newSMSNumber: '',
      newSMSNumberConfirmation: '',
      affectedSASContainers: []
    });
    this.props.onClose();
  }

/*  static getDerivedStateFromProps(props, state) {
    if(props.isOpen){
      return {
        currentEmail: null,
        currentSMSNumber: null,
        newEmail: '',
        newEmailConfirmation: '',
        newSMSNumber: '',
        newSMSNumberConfirmation: ''
      };
    }
  }*/

  componentDidUpdate(prevProps, prevState)  {
    if(this.props.isOpen && !prevProps.isOpen){
      api(this.props.layoutContext, '/api/alert-contact-detail')
      .then(res => {
        if (res.status === 200) {
          res.json().then(response => {
            this.setState({
              currentEmail: response.email,
              currentSMSNumber: response.phone
            })
          })
        }
      });
    }
  }

  render() {

    const contactChangeInfo = 'If the email or mobile number you have added has not been\n'
        + 'used before, we will now send you a test email and/or SMS to\n'
        + 'ensure that they are valid. This test will not count as part\n'
        + 'of your alert allowance. If you do not receive\n'
        + 'anything please contact our helpdesk on 08000 469 648';

    return <>
    <ReactModal
        appElement={document.querySelector('react-app')}
        isOpen={this.props.isOpen}
        className='app-modal alert-template-change-sms-email-popup'>
      <div className='alert-template-change-sms-email-popup'>
        <div className='alert-template-change-sms-email-popup-header'>
          <h4>Change Alert Email Address or SMS Number</h4>
          <p>The below email address and mobile number are the defaults for your container alerts. <br/> When you change or update these details, they will immediately apply to<br/> pending alerts that have been applied via the Alert Templates.<br/> Please enter mobile numbers with no spaces or other characters</p>
        </div>
        <div className='alert-template-change-sms-email-popup-content'>
          <Form
              className='alert-template-change-sms-email-popup-form'
              ref={node => {
                this.form = node;
              }}
              onKeyDown={event => {
                if (event.key === 'Enter'){
                  this.form.validateAll();
                  this.updateAlertEmailSMSNumber();
                }
              }}
              onSubmit={event => {
                event.preventDefault();
              }}
          >
            <div className='update-email-section'>
              <h3>Update Email Address:</h3>
              <div className='update-section-field'>
                <label>Current Email:</label>
                <Input
                    className='read-only-input'
                    readOnly={true}
                    name="alert-template-page_new_email-current-value"
                    value={this.state.currentEmail || 'Not set'}/>
              </div>
              <div className='update-section-field'>
                <label>Change to:</label>
                <Input
                    name="alert-template-page_new_email"
                    value={this.state.newEmail}
                    onChange={(e) => filterInput.call(this, e, 'newEmail', dataValidationPatterns.email)}
                    onBlur={() => this.form.validate(
                        'alert-template-page_new_email')}
                    validations={[emailOrEmpty, hasCorrectConfirmation,
                      notEqualsToCurrentValue,
                      atLeastOneOfContactUpdateFields]}
                    maxLength={200}/>
              </div>

              <div className='update-section-field'>
                <label>Confirm:</label>
                <Input
                    name="alert-template-page_new_email-confirmation"
                    onBlur={() => this.form.validate(
                        'alert-template-page_new_email-confirmation')}
                    validations={[emailOrEmpty, hasCorrectConfirmation]}
                    maxLength={200}/>
              </div>
            </div>


            <div className='update-sms-section'>
              <h3>Update Mobile Number:</h3>
              <div className='update-section-field'>
                <label>Current SMS number:</label>
                <Input
                    className='read-only-input'
                    readOnly={true}
                    name="alert-template-page_new_sms_number-current-value"
                    value={this.state.currentSMSNumber || 'Not Set'}/>
              </div>
              <div className='update-section-field'>
                <label>Change to:</label>
                <Input
                    name='alert-template-page_new_sms_number'
                    validations={[phoneOrEmpty, hasCorrectConfirmation,
                      notEqualsToCurrentValue,
                      atLeastOneOfContactUpdateFields]}
                    value={this.state.newSMSNumber}
                    onChange={(e) => filterInput.call(this, e, 'newSMSNumber', dataValidationPatterns.phone)}
                    onBlur={() => this.form.validate(
                        'alert-template-page_new_sms_number')}
                    minLength={6}
                    maxLength={20}

                />
              </div>

              <div className='update-section-field'>
                <label>Confirm:</label>
                <Input
                    name='alert-template-page_new_sms_number-confirmation'
                    onBlur={() => this.form.validate(
                        'alert-template-page_new_sms_number-confirmation')}
                    validations={[phoneOrEmpty, hasCorrectConfirmation]}
                    minLength={6}
                    maxLength={20}
                />
              </div>
            </div>
            <div className='alert-template-change-sms-email-popup-footer'>
              {!this.props.layoutContext.state.isMobile && <div
                  className='alert-template-change-sms-email-popup-footer-info'>
                {contactChangeInfo}
              </div>}
              <div
                  className='alert-template-change-sms-email-popup-footer-controls'>
                <Button color='blue' onClick={(e) => {
                  this.setDefaultContactsAndClosePopUp();
                  e.preventDefault();
                }}>Cancel</Button>

                <ValidationButton
                    className='alert-template-contacts-update-button'
                    onClick={() => {
                      this.form.validateAll();
                      this.updateAlertEmailSMSNumber()
                    }}>Update</ValidationButton>
              </div>
              <ReactHint persist events={{click: true}}/>
              {this.props.layoutContext.state.isMobile && <div
                  data-rh={contactChangeInfo}
                  data-rh-at='top'
                  className='alert-template-change-sms-email-popup-more-info'>
                More information</div>}
            </div>
          </Form>
        </div>
      </div>
    </ReactModal>
    <ReactModal
        appElement={document.querySelector('react-app')}
        isOpen={this.state.affectedSASContainers.length > 0}
        className="app-modal">
      <Button id="close-modal" onClick={() => this.onContactsChanged()}>×</Button>
      <div className='change-alert-contacts_affected-sas-containers-popup'>
        <h3>Please note that these changes will not affect alerts set up for the below containers. You will need to check these via the Actions drop down box on the Container Details screen.
          <div className='container-table-modal-change-alert-contacts'>
            {splitContainersByModalColumns(this.state.affectedSASContainers)}
          </div>
        </h3>
        <Button color='blue'
                onClick={() => this.onContactsChanged()}>OK</Button>
      </div>
    </ReactModal>
    </>
  }
}