import React from 'react';
import './header.css'
import './header.mobile.css'
import {NavLink} from 'react-router-dom';
import {
  Dashboard,
  Document,
  Settings,
  AdministrationSearch,
  Alert,
  History,
  UserGuide
} from "../logged-in-layout/icon";
import {
  showIfHasAuthorities,
  hasAnyServiceType,
  isAuthorized
} from '../../services/';
import {slide as Menu} from 'react-burger-menu'
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import dpWorldLogo from './../../static/images/dp-world.svg';
import wmcLogo from './../../static/images/logo.svg';

const ReactHint = ReactHintFactory(React);

export default class Header extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      menuOpen: false
    }
  }

  closeMenu () {
    this.setState({menuOpen: false})
  }

  toggleMenu () {
    this.setState({menuOpen: !this.state.menuOpen})
  }

  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen})
  }


  getClassName(path) {
    return window.location.pathname === path ? 'menu-item_active' : 'menu-item';
  }

  isActivePage(location, match) {
    return location.pathname === match;
  }

  render() {

    const isAuthorizedValue = isAuthorized();
    const isPaidUser = hasAnyServiceType(['Paid']);
    const isFreeAdvancedUser = hasAnyServiceType(['Free-advanced', 'Internal']);

    return <span className='header' id='react-app-header'>

      <div className='dp-world-link'>
        <a href="/dashboard">
          <img src={dpWorldLogo} alt="DP World"/>
        </a>
      </div>
      <div className='menu-header-wrapper' style={isAuthorizedValue ? {} : {
        paddingLeft: '0',
        minWidth: '235px'
      }}>
      {this.props.isMobile && isAuthorizedValue &&

      <Menu left width={200} className={"slide-menu"} isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)}  >
        <ul>
          <li>
            <NavLink
                to="/dashboard"
                onClick={this.closeMenu.bind(this)}
                isActive={(match, location) => this.isActivePage(location,
                    "/dashboard")}
                className="menu-item"
                activeClassName="menu-item_active">
              <Dashboard/> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
                to="/account"
                onClick={this.closeMenu.bind(this)}
                isActive={(match, location) => this.isActivePage(location,
                    "/account")}
                className="menu-item"
                activeClassName="menu-item_active">
              <Settings/>Account
            </NavLink>
          </li>
          <li>
            {!isPaidUser && !isFreeAdvancedUser && <div
                className='alert-template-feature-hint-wrapper'>
              <ReactHint attribute='free-menu-hint-data' persist events={{click: true}}/>
            </div>}

            <NavLink
                free-menu-hint-data="This is an advanced feature. For access to advanced features, check your My Account page"
                free-menu-hint-data-at="right"
                to="/alert-templates"
                isActive={(match, location) => this.isActivePage(location,
                    "/alert-templates")}
                onClick={(e) => {
                  if (!isPaidUser && !isFreeAdvancedUser) {
                    e.preventDefault();
                  }
                  else {
                   this.closeMenu();
                  }
                }}
                className={this.getClassName('/alert-templates')
                + (isPaidUser || isFreeAdvancedUser ? '' : ' menu-item_disabled')}
                activeClassName={this.getClassName('/alert-templates')
                + (isPaidUser || isFreeAdvancedUser ? '' : ' menu-item_disabled')}>
              <Alert/>Alerts
            </NavLink>
          </li>

          <li>
            {!isPaidUser && !isFreeAdvancedUser&& <div
                className='alert-template-feature-hint-wrapper'>
              <ReactHint persist events={{click: true}}/>
            </div>}

            <NavLink
                data-rh={isPaidUser || isFreeAdvancedUser
                    ? ''
                    : "This is an advanced feature. For access to advanced features, check your My Account page"}
                data-rh-at="right"
                to="/history-search"
                isActive={(match, location) => this.isActivePage(location,
                    "/history-search")}
                onClick={(e) => {
                  if (!isPaidUser && !isFreeAdvancedUser) {
                    e.preventDefault();
                  }
                  else {
                    this.closeMenu();
                  }
                }}
                className={this.getClassName('/history-search')
                + (isPaidUser || isFreeAdvancedUser ? '' : ' menu-item_disabled')}
                activeClassName={this.getClassName('/history-search')
                + (isPaidUser || isFreeAdvancedUser ? '' : ' menu-item_disabled')}>
              <History/>History
            </NavLink>
          </li>
          <li>

            <NavLink
                to="/faqs"
                onClick={this.closeMenu.bind(this)}
                isActive={(match, location) => this.isActivePage(location,
                    "/faqs")}
                className="menu-item"
                activeClassName="menu-item_active">
              <Document/>FAQs
            </NavLink>
          </li>
          {(isFreeAdvancedUser || isPaidUser) && <li>
            <NavLink to="/User_Guide_WMC_V1.4.pdf"
                     target="_blank"
                     className="menu-item"
                     activeClassName="menu-item_active">
              <UserGuide/> User Guide </NavLink>
          </li>}
          {showIfHasAuthorities(<li>
            <NavLink
                to="/administration-search"
                onClick={this.closeMenu.bind(this)}
                isActive={(match, location) => this.isActivePage(location,
                    "/administration-search")}
                className="menu-item"
                activeClassName="menu-item_active">
              <AdministrationSearch/>Admin
            </NavLink>

          </li>, ['helpdesk'])}
        </ul>
      </Menu>
      }
        <div className='wmc-logo'>
        <img src={wmcLogo} alt="Where’s my container"
             style={isAuthorizedValue ? {} : {paddingLeft: '0', margin: '0'}}/>
        </div>
      </div>
    </span>
  }
};
