import React from "react";
import {
  importTemplate,
  exportTemplate,
  returnToShipperTemplate,
  transhipmentTemplate,
  IMPORT_ACTIVITY_TYPE,
  EXPORT_ACTIVITY_TYPE,
  TRANSHIPMENT_ACTIVITY_TYPE,
  RETURN_TO_SHIPPER_ACTIVITY_TYPE
} from "./templates/activityTemplates";
import _ from "lodash";
import { Button } from "..";
import Collapsible from "react-collapsible";
import ArrowUp from "../collapsible-page-header/img/arrow-up";
import ArrowDown from "../collapsible-page-header/img/arrow-down";

import "./activity-alert-template.css";
import "./activity-alert-template.mobile.css";

export default class AlertTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  isAlertTypeChecked(activityType, statusName, alertType) {
    return _.find(this.props.currentAlertTemplates, {
      activityType: activityType,
      containerStatusType: statusName,
      alertType: alertType
    });
  }

  getClassName(activityName) {
    return (
      "activity_alert_template-header activity_alert_template-" +
      activityName.toLowerCase().replace(/ /g, "-")
    );
  }

  replaceSpaces(value) {
    return value.replace(" ", "_");
  }

  hasAnyAlertOfActivityType(activityType) {
    return _.find(this.props.currentAlertTemplates, {
      activityType: activityType
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextProps !== this.props;
  }

  getDesktopVersion(activityTemplate) {
    return (
      <div className='activity_alert_template'>
        
        <table width="100%" className='activity_alert_template-table'>
          <thead className={this.getClassName(this.props.activityType)}>
            <tr>
              <td className="activity_alert_template-lcol">
                {this.props.activityType.toUpperCase() + " STATUSES"}
              </td>
              <td>EMAIL</td>
              <td>SMS</td>
            </tr>
          </thead>
          <tbody>
            {activityTemplate.map((item, i) => (
              <tr key={i}>
                <td className="activity_alert_template-lcol alert-template-status-name">
                    {item.statusLabel}
                </td>
                {item.labels.map((label, i) => (
                  <td key={i}>
                    <input
                      disabled={
                        (this.props.availableStatusList !== undefined
                          ? !this.props.availableStatusList.includes(
                              item.statusType
                            )
                          : false) ||
                        ((!this.props.defaultPhoneNumber ||
                          this.props.defaultPhoneNumber === "") &&
                          this.props.isSingleAlert &&
                          label === "SMS")
                      }
                      type="checkbox"
                      checked={
                        this.isAlertTypeChecked(
                          this.props.activityType,
                          item.statusType,
                          label
                        ) || ""
                      }
                      onChange={action =>
                        this.props.onclick(
                          this.props.activityType,
                          item.statusType,
                          label,
                          action
                        )
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {!this.props.isSingleAlert && (
          <div className='activity_alert_template-buttonRow'>
            <Button
              disabled={
                !this.hasAnyAlertOfActivityType(this.props.activityType)
              }
              onClick={e => {
                e.preventDefault();
                this.props.removeAlertTemplateByActivityType(
                  this.props.activityType
                );
              }}
              color="blue"
            >
              Clear
            </Button>
            </div>
          )}
      </div>
    );
  }

  getMobileVersion(activityTemplate) {
    const collapsibleTrigger = isOpen => (
      <div className="alert-templates-collapsible-trigger-wrapper">
        <div className="alert-templates-collapsible-trigger">
          {this.props.activityType.toUpperCase() + " STATUSES"}
        </div>
        {isOpen ? <ArrowDown /> : <ArrowUp />}
      </div>
    );

    const mobileAlertSelection = (
      <div className="collapsible-content">
        {this.props.isSingleAlert && (
          <b>{this.props.activityType.toUpperCase() + " STATUSES"}</b>
        )}
        <div className="alert-template-mobile-row">
          <div className="alert-template-status-name" />
          <div className="alert-template-value-cell">Email</div>
          <div className="alert-template-value-cell">SMS</div>
        </div>
        {activityTemplate.map(item => (
          <div key={item.statusLabel} className="alert-template-mobile-row">
            <div className="alert-template-status-name">
              <h4 className="alert-template-status-name">{item.statusLabel}</h4>
            </div>
            {item.labels.map(label => (
              <div
                key={item.statusLabel + label}
                className="alert-template-value-cell"
              >
                <input
                  disabled={
                    (this.props.availableStatusList !== undefined
                      ? !this.props.availableStatusList.includes(
                          item.statusType
                        )
                      : false) ||
                    ((!this.props.defaultPhoneNumber ||
                      this.props.defaultPhoneNumber === "") &&
                      this.props.isSingleAlert &&
                      label === "SMS")
                  }
                  type="checkbox"
                  checked={
                    this.isAlertTypeChecked(
                      this.props.activityType,
                      item.statusType,
                      label
                    ) || ""
                  }
                  onChange={action =>
                    this.props.onclick(
                      this.props.activityType,
                      item.statusType,
                      label,
                      action
                    )
                  }
                />
              </div>
            ))}
          </div>
        ))}
        {!this.props.isSingleAlert && (
          <Button
            disabled={!this.hasAnyAlertOfActivityType(this.props.activityType)}
            onClick={e => {
              e.preventDefault();
              this.props.removeAlertTemplateByActivityType(
                this.props.activityType
              );
            }}
            color="blue"
          >
            Clear
          </Button>
        )}
      </div>
    );

    return this.props.isSingleAlert ? (
      mobileAlertSelection
    ) : (
      <Collapsible
        trigger={collapsibleTrigger(false)}
        triggerWhenOpen={collapsibleTrigger(true)}
        triggerTagName="div"
        triggerClassName={this.getClassName(this.props.activityType)}
        triggerOpenedClassName={this.getClassName(this.props.activityType)}
      >
        {mobileAlertSelection}
      </Collapsible>
    );
  }

  render() {
    let activityTemplate;
    switch (this.props.activityType) {
      case IMPORT_ACTIVITY_TYPE:
        activityTemplate = importTemplate;
        break;
      case EXPORT_ACTIVITY_TYPE:
        activityTemplate = exportTemplate;
        break;
      case TRANSHIPMENT_ACTIVITY_TYPE:
        activityTemplate = transhipmentTemplate;
        break;
      case RETURN_TO_SHIPPER_ACTIVITY_TYPE:
        activityTemplate = returnToShipperTemplate;
        break;
      default:
        activityTemplate = null;
    }

    return this.props.isMobile
      ? this.getMobileVersion(activityTemplate)
      : this.getDesktopVersion(activityTemplate);
  }
}
