import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button'
import ReactModal from 'react-modal';
import { api, isValidForm } from '../../services';
import { Button as CustomButton} from '..';
import {
  required,
  password,
  passwordsEqual,
  passwordsNotEqual,
  requiredNewPassword,
  requiredNewPasswordConfirm
} from '../../validations';
import './change-password.css';
import './change-password.mobile.css';

export default class ChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isConnectionProblem: false,
      isOldPasswordInvalid: false
    }
  }

  setRequestForChangePassword(){

    if (!isValidForm(this.form)) return false;

    const values = this.form.getValues();
    const body = {
      newPassword: values["password"],
      oldPassword: values["current-password"]
    };

    api(this.props.layoutContext, '/api/customers/account-details/password/update', 'PUT', body)
      .then(res => {
        res.status === 200
          ? this.props.onSuccessfullClose()
          : res.json().then(response => response.code === 'CSC_02' ? this.setState({ isOldPasswordInvalid: true }) : this.setState({ isConnectionProblem: true }))
      });
  }

  showOldPasswordInvalid(){
    if (this.state.isOldPasswordInvalid) return <span className="form-error is-visible">Password is incorrect</span>
  }

  showConnectionPropblem() {
    if (this.state.isConnectionProblem) return <div className="connection-error">
      Oops, something went wrong.  Please try again.  If the problem persists, please call our helpdesk on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a>
    </div>
  }

  updatePassword(){
    this.setState({ isConnectionProblem: false }, () => {
      this.form.validateAll();
      this.setRequestForChangePassword();
    })
  }

  render() {
    const root = document.querySelector('react-app');
    const isOpen = this.props.isOpen;
    const close = e => {
      e.preventDefault();
      this.setState({isOldPasswordInvalid : false});
      this.props.onClose();
    };

    return <ReactModal appElement={root} isOpen={isOpen} className="app-modal">
      <div className='change-password'>
        {/*<Button id="close-modal" onClick={close}>×</Button>*/}
        <h4>Change your password</h4>
        <div>Please use the form below to change your password.</div>
        <Form
          ref={node => {
            this.form = node;
          }}
          onKeyDown={event => {
            if (event.key === 'Enter'){
              this.form.validateAll();
            }
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
        >
          <div>
            <label>Current password</label>
            <Input
              onChange = {()=>this.setState({isOldPasswordInvalid: false})}
              type="password"
              name="current-password"
              onBlur={() => this.form.validate('current-password')}
              validations={[required, passwordsNotEqual]}
            />
            {this.state.isOldPasswordInvalid && this.showOldPasswordInvalid()}
          </div>

          <div>
            <label>New password</label>
            <Input
              type="password"
              name="password"
              onBlur={() => this.form.validate('password')}
              validations={[requiredNewPassword, password, passwordsNotEqual]}
            />
          </div>
          <div>
            <label>Re-enter new password</label>
            <Input
              type="password"
              name="confirm-password"
              onBlur={() => this.form.validate('confirm-password')}
              validations={[requiredNewPasswordConfirm, password, passwordsEqual]}
            />
          </div>
          <div>
            <CustomButton buttonType='button' color="blue" onClick={close}>Cancel</CustomButton>
            <Button
              className='change-password__submit_button'
              color="pink"
              onClick={() => {
               this.updatePassword();
              }}
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
      {this.showConnectionPropblem()}
    </ReactModal>;
  }
};
