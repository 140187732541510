export default {
  'alert-primary-phone-number': 'Please enter the alert phone number',
  'alert-secondary-phone-number': 'Please enter the alert phone number',
  'alert-primary-email': 'Please enter the alert email address',
  'new-container-numbers': 'Maximum number of containers is 20, please remove some containers from the list before adding more',
  'container-numbers': 'Please enter a maximum of 20 container numbers',
  'alert-template-page_new_sms_number': 'Invalid phone number',
  'alert-template-page_new_sms_number-confirmation': 'Invalid phone number',
  'contact-number': 'Invalid phone number',
  'phone': 'Invalid phone number',
  'new-saved-list-content': 'Please enter a maximum of 20 container numbers',
  'history-search-container-numbers': 'Please enter a maximum of 20 container numbers'
};