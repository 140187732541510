import React from 'react';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import { PasswordReset, Section} from '..';
import Button from "./../button/button.jsx";
import { setUserTokens, api, isValidForm } from '../../services';
import { required, email, passwordLogin } from '../../validations';
import './login.css';
import './login.mobile.css';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { Arrow } from '../button/icon';
import CookieBanner from 'react-cookie-banner';
import ReactGA from 'react-ga';
import Collapsible from 'react-collapsible';
import CriticalAlertComponent from './../dashboard/critical-alert-component';
import { GoogleReCaptcha } from "react-google-recaptcha-v3";


export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordResetOpen: false,
      errorMessage: '',
      isSuspendModalOpen: false,
      isLoginAvailable: false,
      password: '',
      login: '',
      isFormValid: false,
      width: window.innerWidth,
      isLoginInProgress: false,
      captchaToken: '',
      currentAttempt: 1,
      recaptchaKey: ''
    };
  }

  componentDidMount(){
    this.props.layoutContext.getCmsComponent(['Sign In', 'Critical Alerts (DPWS)', 'Critical Alerts (LGPL)'], this);
  }

  showPasswordReset(e) {
    e.preventDefault();
    this.setState({ passwordResetOpen: true });
    ReactGA.set({ location: window.location.href});
    ReactGA.set({ page: "/password-reset"});
    ReactGA.pageview("/password-reset");
    ReactGA.event({
      category: 'Login',
      action: 'Clicked \'Forgot Password\'',
      label: 'Reset Password'
    })

  }
  loginEvent(){
    ReactGA.event({
      category:"Login",
      action :'Logged In',
      label:"User Logged In"
    })
  }
  linkedInClickLogin(){
    var img = document.createElement("img");
    img.setAttribute("src","https://px.ads.linkedin.com/collect/?pid=3333801&conversionId=4679729&fmt=gif");
    document.body.appendChild(img);
  }
  linkedInClickRegister(){
    var img = document.createElement("img");
    img.setAttribute("src","https://px.ads.linkedin.com/collect/?pid=3333801&conversionId=4679721&fmt=gif");
    document.body.appendChild(img);
  }
  hidePasswordReset() {
    this.setState({ passwordResetOpen: false });
  }

  login(){

    this.form.validateAll();
    if(!isValidForm(this.form)) return false;

    this.setState({isLoginInProgress: true});
    const values = this.form.getValues();
    const url = '/api/oauth/token';
    const body = new URLSearchParams();
    body.set('username', values["username"]);
    body.set('password', values["password"] );
    body.set('grant_type', 'password');
    body.set('scope', 'scope');
    body.set('recaptchaToken', this.state.captchaToken);

    api(this.props.layoutContext, url, 'POST', body)
    .then((res) => res.json())
    .then((response) => {
      if(response.access_token !== undefined){
        localStorage.setItem('didMarketingPreferencesModalShown', false)
        setUserTokens(response);
        this.props.layoutContext.checkServiceType();
        this.props.refreshToken();
        this.loginEvent();
        this.linkedInClickLogin();
      }
      else{
        this.setState({isSuspendModalOpen: response.description === 'User account is locked'});
        this.setState({ errorMessage: response.description, isLoginInProgress: false, currentAttempt: this.state.currentAttempt+1 })
      }
    })
    .catch(()=>this.setState({isLoginInProgress: false}))
  }

  showErrorMessage() {
    if(this.state.errorMessage.indexOf('Customer not found') !== -1) {
      return <React.Fragment>
        This username does not exist in the system. Please <Link to="/register">register</Link> to proceed
      </React.Fragment>
    } else if (this.state.errorMessage === 'Customer credential not verified') {
      return <React.Fragment>
        You have not yet confirmed your email address. Please check your inbox (or Junk folder) for our confirmation email and follow the instructions. You will be unable to access Where's my container until you have performed these actions
      </React.Fragment>
    } else if (this.state.errorMessage.indexOf('Bad credential') !== -1) {
      if (this.state.errorMessage.indexOf('[1]') !== -1){
        return<React.Fragment>
          You have one sign-in attempt left. If you have forgotten your password, select ‘Forgot Password’ instead
        </React.Fragment>
      }else if (this.state.errorMessage.indexOf('[0]') !== -1) {
        return <React.Fragment>
          In line with our security requirements we have suspended your account.  Please contact us by calling  <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a> or email us at WMCsupport@dpworld.com so that we can assist you.
        </React.Fragment>
      }
      return <React.Fragment>
        Password is incorrect
      </React.Fragment>
    } else if (this.state.errorMessage === 'User account is locked') {
      return;
    } else if(this.state.errorMessage === 'User account has expired'){
      return <React.Fragment>
        Password is expired
      </React.Fragment>
    } else {
      return <React.Fragment>
        Oops, something went wrong. Please try again. If the problem persists, please call our helpdesk on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a>
      </React.Fragment>
    }
  }

  removeValidationErrorMessage(){
    this.setState({errorMessage:''})
  }
  notRegisteredLink(){
    ReactGA.event({
      category: 'Login',
      action: 'Clicked \'Not Registered \'',
      label :"New User - Register"
    })
    this.linkedInClickRegister();
  }
  render() {

    let bannerStyle,
    signInSection = <Section color="white" title={this.props.layoutContext.state.isMobile ? '' : 'Sign-in'}>
      <div className="login__error_user_doesnt_exist">
        {this.state.errorMessage && this.showErrorMessage()}
      </div>

      <Form
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
          onKeyDown={event => {
            if (event.key === 'Enter' && this.state.login
                && this.state.password
                && this.state.isFormValid) this.login()
          }}>
        <div>
          <label>Username</label>
          <Input
              id="login_page_email"
              onBlur={() => this.form.validate('username')}
              name="username"
              validations={[required, email]}
              placeholder='Enter email address'
              onChange={(e) => {
                this.removeValidationErrorMessage(this);
                this.setState({login: e.target.value})
              }}/>
        </div>
        <div>
          <label>Password</label>
          <Input
              id="login_page_password"
              type="Password"
              name="password"
              validations={[passwordLogin]}
              placeholder='Enter password'
              onChange={(e) => this.setState(
                  {password: e.target.value})}/>
          <GoogleReCaptcha
            onVerify={(token)=>{this.setState({captchaToken: token})}}
         />
        </div>



        <div className='not_registered_and_forgot_password'>
          <span className='not-registered-link-wrapper'>
            <Link onClick={() =>this.notRegisteredLink()} to="/register">Not registered?</Link>
          </span>
          <span className='forgot-password-link-wrapper'>
          <Link  id='login-page_forgot-password-link' to="#" onClick={e => this.showPasswordReset(e)}>Forgot
            password?</Link>
          </span>

          <Button
              disabled={this.state.isLoginInProgress || (this.state.currentAttempt>3 && !this.state.captchaToken)}
              isFormValidationRequired={true}
              id="login_page_login_button"
              className='login__button_sign_in'
              onClick={() => this.setState({errorMessage: ''}, () => {
                this.login();
                this.props.layoutContext.checkServiceType();
              })
              }>
            {Arrow()}
          </Button>
        </div>
      </Form>

      <PasswordReset
          isOpen={this.state.passwordResetOpen}
          onClose={() => this.hidePasswordReset()}
          layoutContext={this.props.layoutContext}
      />
      {this.state.isSuspendModalOpen && <ReactModal
          appElement={document.querySelector('react-app')}
          isOpen={this.state.isSuspendModalOpen} className="app-modal">
        <div className="account-suspend-modal">
          <h2>Your account has been suspended. Please contact our Helpdesk
            on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a> for assistance.</h2>
          <Button color='blue'
                        onClick={() => this.setState(
                            {isSuspendModalOpen: false})}>OK</Button>
        </div>
      </ReactModal>}
    </Section>;

    if (!this.props.layoutContext.state.isMobile) {
      bannerStyle = {
        banner: {
          backgroundColor: '#173A64',
          textAlign: 'left',
          height: '70px',
          padding: '0',
          msGridColumn: '1',
          gridColumn: '1',
          gridColumnEnd: '12',
          msGridColumnSpan: '11',
          gridRow: '1',
          msGridRow: '1',
          overflow: 'hidden'
        },
        button: {
          color: 'white',
          backgroundColor: '#173A64',
          border: '1px solid white',
          right: '60px',
          fontSize: '14pt'
        },
        message: {
          paddingLeft: '30px',
          lineHeight: '1.3',
          paddingTop: '25px',
          display: 'inline-block',
          width: '78%',
        }
      }
    }
    else{
      bannerStyle = {
        banner: {
          backgroundColor: '#173A64',
          textAlign: 'left',
          height: 'auto',
          padding: '0',
          gridColumn: '1',
          gridColumnEnd: '3',
          gridRow: '1',
        },
        button: {
          color: 'white',
          backgroundColor: '#173A64',
          border: '1px solid white',
          right: '10px',
          fontSize: '16pt'
        },
        message: {
          display: 'inline-block',
          width: '75%',
          lineHeight: '1.3',
          paddingLeft: '10px'
        }
      };
      signInSection = <Collapsible trigger="Sign-in">{signInSection}</Collapsible>
    }

      return <div className="login_wrapper">


        <div className='login-page_notification-content-wrapper'>
          {/*<CookieBanner*/}
          {/*    styles={bannerStyle}*/}
          {/*    message='This website uses cookies to ensure you get the best experience on our website.'*/}
          {/*    link={<a href='/cookie-policy'>Learn more</a>}*/}
          {/*    buttonMessage='Got it!'*/}
          {/*    dismissOnScroll={false}*/}
          {/*/>*/}

          <div className="login-page_critical_alert_wrapper">
            <CriticalAlertComponent sou={this.state.criticalalertsdpws}
                                    lgp={this.state.criticalalertslgpl}/>
          </div>
          <div className="main_wrap page_id_login">

              {this.props.layoutContext.state.isMobile && signInSection}

              {this.state.signin && Array.isArray(this.state.signin) && this.state.signin[0] && this.state.signin[0].content}
              {this.state.signin && Array.isArray(this.state.signin) && this.state.signin[1] && this.state.signin[1].content}

              {!this.props.layoutContext.state.isMobile && signInSection}
          </div>
        </div>
      </div>
  }
};
