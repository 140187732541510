import React from 'react';
import { Button } from '..';

export default class ContainerTableActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  className() {
    const classNames = ['container-table__actions'];

    if (this.state.active) {
      classNames.push('container-table__actions_active');
    }

    return classNames.join(' ');
  }

  showMenu(e) {
    this.setState({ active: true });
  }

  hideMenu(e) {
    this.setState({ active: false });
  }

  toggle(action) {
    this.props.onToggle(action);
    this.setState({ active: false });
  }

  showVehicleBooking(){
    const vehicleBooking = this.props.actions===null ? null : this.props.actions.vehicleBooking;
      return <li>
        <a className={vehicleBooking === null ? 'disabled-link' : 'enabled-link'} href={vehicleBooking} target="_blank" rel="noopener noreferrer">Vehicle booking</a>
      </li>
  }

  showVesselSchedule(){
    const vesselSchedule = this.props.actions === null ? null : this.props.actions.vesselSchedule;
      return <li>
        <a className={vesselSchedule === null ? 'disabled-link' : 'enabled-link'} href={vesselSchedule} target="_blank" rel="noopener noreferrer">Vessel schedule</a>
      </li>
  }

  showRailSchedule(){
    const railSchedule = this.props.actions ===null ? null : this.props.actions.railSchedule;
      return <li onClick={e => this.toggle(this.props.actions.railSchedule)}>
        <a className={railSchedule === null ? 'disabled-link' : 'enabled-link'} href={railSchedule} target="_blank" rel="noopener noreferrer">Rail schedule</a>
      </li>
  }

  showAdditionalActions(){
    let additionalActionsToShow = [];
    if(this.props.additionalActions){
      this.props.additionalActions.forEach((item)=>{
        additionalActionsToShow.push(<li key={item.label} onClick={()=> item.action()}><span className='enabled-link' target="_blank" rel="noopener noreferrer">{item.label}</span></li>)
      })
    }
    return additionalActionsToShow
  }

  render() {
    return <div className={this.className()} onMouseOver={e => this.showMenu(e)} onMouseOut={e => this.hideMenu(e)}>
      <Button color="pink" icon="arrow">Actions</Button>
      <ul className="container-table__actions-menu">
        {this.showVehicleBooking()}
        {this.showVesselSchedule()}
        {this.props.port === 'LGP' && this.showRailSchedule()}
        {this.props.additionalActions && this.showAdditionalActions()}
      </ul>
    </div>;
  } 
};
