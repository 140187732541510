import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components';
import ReactGA from 'react-ga';
import LayoutProvider from './components/app/context/layout-context';
import {LayoutContext} from './components/app/context/layout-context';
import 'react-app-polyfill/ie9';

const serviceWorker = {};
{
  const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      window.location.hostname === '[::1]' ||
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  serviceWorker.register = function() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
      if (publicUrl.origin !== window.location.origin) {
        return;
      }

      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

        if (isLocalhost) {
          checkValidServiceWorker(swUrl);

          navigator.serviceWorker.ready.then(() => {
            console.log(
              'This web app is being served cache-first by a service ' +
                'worker. To learn more, visit https://goo.gl/SC7cgQ'
            );
          });
        } else {
          registerValidSW(swUrl);
        }
      });
    }
  };

  function registerValidSW(swUrl) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                console.log('New content is available; please refresh.');
                var preventDevToolsReloadLoop;
                navigator.serviceWorker.addEventListener('controllerchange', function(event) {
                  if (preventDevToolsReloadLoop) return;
                  preventDevToolsReloadLoop = true;
                  console.log('Controller loaded');
                  window.location.reload();
                });
              } else {
                console.log('Content is cached for offline use.');
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error during service worker registration:', error);
      });
  }

  function checkValidServiceWorker(swUrl) {
    fetch(swUrl)
      .then(response => {
        if (
          response.status === 404 ||
          response.headers.get('content-type').indexOf('javascript') === -1
        ) {
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          registerValidSW(swUrl);
        }
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  }

  serviceWorker.unregister = function() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  };

  serviceWorker.unregisterAll = function() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }
}

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
//ReactGA.initialize("UA-121578066-1");

ReactDOM.render(<LayoutProvider><LayoutContext.Consumer>
  { layoutContext => (
      <App layoutContext={layoutContext}/>)
  }</LayoutContext.Consumer></LayoutProvider>, document.querySelector('react-app'));
//serviceWorker.register();
// disabling SW for now since even in CRA v3.0 it is not possible to
// configure skipWaiting and clientsClaim properties of workbox plugin without ejecting
// https://github.com/facebook/create-react-app/issues/5359
// https://github.com/facebook/create-react-app/pull/5369
serviceWorker.unregisterAll();
