import React from "react";
import { Section } from "..";

import "./terms-of-use-policy.css";

export default class TermsOfUsePolicy extends React.Component {
  render() {
    return (
      <div className="policy-page terms_of_use_policy_main_wrap page_terms_of_use_policy">
        <h1>WEBSITE TERMS OF USE POLICY</h1>
        <Section color="white">
          <h2>
            <ol>
              PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR
              WEBSITE
            </ol>
          </h2>

          <ol className="withoutPoint" type="1" start={1}>
            <h3 className="marginMainPoints">
              <li className="withoutPoint">WHAT'S IN THESE TERMS?</li>
            </h3>
            <li>
              These Terms of Use (the "<b>Terms</b>") tell you the rules for
              using our website{" "}
              <a className="site" href="https://www.wheresmycontainer.co.uk">
                www.wheresmycontainer.co.uk
              </a>{" "}
              ("<b>Our Website</b>").
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">WHO WE ARE AND HOW TO CONTACT US</li>
            </h3>
            <li>
              <a className="site" href="https://www.wheresmycontainer.co.uk">
                www.wheresmycontainer.co.uk
              </a>{" "}
              is a website operated by Community Network Services Ltd ("
              <b>we</b>" or "<b>us</b>", or the “<b>Company</b>”). Community
              Network Services Ltd, a company registered in England No: 2084279.
              Our registered address is 16 Palace Street, London, SW1E 5JQ. Our
              main trading address is Western Docks, Southampton, SO15 1DA. Our
              VAT number is 458998463.
            </li>
            <li>
              To contact us, please email{" "}
              <a className="email" href="mailto:cnsprivacy@cnsonline.net">
                cnsprivacy@cnsonline.net
              </a>
              .
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                BY USING OUR WEBSITE YOU ACCEPT THESE TERMS
              </li>
            </h3>
            <li>
              By using Our Website, you confirm that you accept these Terms and
              that you agree to comply with them.
            </li>
            <li>
              If you do not agree to these Terms, you must not use Our Website.
            </li>
            <li>
              We recommend that you print a copy of these Terms for future
              reference.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                THERE ARE OTHER TERMS THAT MAY APPLY TO YOU
              </li>
            </h3>
            <li>
              These Terms refer to the following additional terms, which also
              apply to your use of Our Website:
            </li>
            <ul type="disc">
              <li>
                <a className="site" href="/privacy-policy">
                  Our Privacy Policy
                </a>
                , which sets out the terms on which we process any personal data
                we collect from you, or that you provide to us, including,
                without limitation as part of using any of our Interactive
                Services. By using Our Website, you consent to such processing
                and you warrant that all data provided by you is accurate.
              </li>
              <li>
                <a className="site" href="/acceptable-use-policy">
                  Our Acceptable Use Policy
                </a>
                , which sets out the permitted uses and prohibited uses of Our
                Website. When using Our Website, you must comply with this
                Acceptable Use Policy.
              </li>
              <li>
                <a className="site" href="/cookie-policy">
                  Our Cookie Policy
                </a>
                , which sets out information about the cookies on Our Website.
              </li>
            </ul>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                WE MAY MAKE CHANGES TO THESE TERMS
              </li>
            </h3>
            <li>
              We amend these Terms from time to time. Every time you wish to use
              Our Website, please check these Terms to ensure you understand the
              terms that apply at that time.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                WE MAY MAKE CHANGES TO OUR WEBSITE
              </li>
            </h3>
            <li>
              We may update and change Our Website from time to time. We will
              try to give you reasonable notice of any major changes.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                WE MAY SUSPEND OR WITHDRAW OUR WEBSITE
              </li>
            </h3>
            <li>Our Website is made available free of charge.</li>
            <li>
              We do not guarantee that Our Website, or any content on it, will
              always be available or be uninterrupted. We may suspend or
              withdraw or restrict the availability of all or any part of Our
              Website for business and operational reasons. We will try to give
              you reasonable notice of any suspension or withdrawal.
            </li>
            <li>
              You are also responsible for ensuring that all persons who access
              Our Website through your internet connection are aware of these
              Terms and other applicable terms and conditions, and that they
              comply with them.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                OUR WEBSITE IS ONLY FOR USERS IN THE UK
              </li>
            </h3>
            <li>
              Our Website is directed to people residing in the United Kingdom.
              We do not represent that content available on or through Our
              Website is appropriate for use or available in other locations.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE
              </li>
            </h3>
            <li>
              If you choose, or you are provided with, a user identification
              code, password or any other piece of information as part of our
              security procedures, you must treat such information as
              confidential. You must not disclose it to any third party.
            </li>
            <li>
              We have the right to disable any user identification code or
              password, whether chosen by you or allocated by us, at any time,
              if in our reasonable opinion you have failed to comply with any of
              the provisions of these Terms. We can terminate your account or
              suspend your account in order to protect you or us from identity
              theft or other fraudulent activity.
            </li>
            <li>
              If you know or suspect that anyone other than you knows your user
              identification code or password, you must promptly notify us by
              email to{" "}
              <a className="email" href="mailto:cnsprivacy@cnsonline.net">
                cnsprivacy@cnsonline.net
              </a>
              .
            </li>

            <span id="section-10" />
            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                HOW YOU MAY USE MATERIAL ON OUR WEBSITE
              </li>
            </h3>
            <li>
              We are the owner or the licensee of all intellectual property
              rights in Our Website, and in the material published on it. Those
              works are protected by copyright laws and treaties around the
              world. All such rights are reserved.
            </li>
            <li>
              You may print off one copy, and may download extracts, of any
              page(s) from Our Website for your personal use and you may draw
              the attention of others within your organisation to content posted
              on Our Website.
            </li>
            <li>
              You must not modify the paper or digital copies of any materials
              you have printed off or downloaded in any way, and you must not
              use any illustrations, photographs, video or audio sequences or
              any graphics separately from any accompanying text.
            </li>
            <li>
              Our status (and that of any identified contributors) as the
              authors of content on Our Website must always be acknowledged.
            </li>
            <li>
              You must not use any part of the content on Our Website for
              commercial purposes without obtaining a licence to do so from us
              or our licensors.
            </li>
            <li>
              If you print off, copy or download any part of Our Website in
              breach of these Terms, your right to use Our Website will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">FREE TRIALS</li>
            </h3>
            <li>
              You may start using our advanced features as a free trial. This
              free trial will last for 30 days and is intended to allow users to
              trial the service.
            </li>
            <li>
              Free trial eligibility is determined by CNS at our sole discretion
              and we may limit eligibility or duration to prevent free trial
              abuse. We reserve the right to revoke the free trial if we
              determine that you are not eligible. We may use previously gained
              information to determine eligibility.
            </li>
            <li>
              At the end of the trial period, you will automatically revert to
              our free standard service. To view the end date of your free trial
              period, visit ‘My Account’ on the Where’s My Container website and
              check the Package Details.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">BILLING AND CANCELLATIONS</li>
            </h3>
            <li>
              Your subscription fee for our advanced features and any other
              charges such as taxes, will be charged on a 30 day basis. Your
              next payment date can be viewed in ‘My Account’ page in the
              ‘Payment Details’.
            </li>
            <li>
              To use our Advanced Features, you must have a valid credit or debit
              card. If you update your card details, you authorise us to
              continue to charge the applicable card. If a payment is not
              successfully settled due to expiration, insufficient funds or
              otherwise we may suspend your advanced feature access until we
              have successfully charged a valid card.
            </li>
            <li>
              Cancellation of access to advanced features is done by not
              purchasing another 30-day access. When your current access period
              expires, you will automatically be reverted to the free standard
              access. To see when this will be, go to ‘My Account’ and check
              ‘Package Details’.
            </li>
            <li>
              Payments are non-refundable and we do not provide refunds or
              credits for any partial month paid periods.{" "}
            </li>
            <li>
              From time to time, we will review our charging structures and
              reserve the right to change the subscription amount. You will be
              contacted by us in advance of any changes.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                DO NOT RELY ON INFORMATION ON THIS WEBSITE
              </li>
            </h3>
            <li>
              The content on Our Website is provided for general information
              only. It is not intended to amount to advice on which you should
              rely. You must obtain professional or specialist advice before
              taking, or refraining from, any action on the basis of the content
              on Our Website.
            </li>
            <li>
              Although we make reasonable efforts to update the information on
              Our Website, we make no representations, warranties or guarantees,
              whether express or implied, that the content on Our Website is
              accurate, complete or up to date.
            </li>
            <li>
              Information supplied to you as part of an Application service is
              provided by us as an estimate only. The user of an Application
              acknowledges and agrees that whilst this information and service
              is provided in good faith, no representation or warranty, express
              or implied, is or will be made and no responsibility or liability
              (subject to paragraph 13.1 below) is or will be accepted by us, or
              a member of our Group, or by any of our or our Group's respective
              officers, employees or agents in relation to the accuracy or
              completeness of this information or any other written or oral
              information made available to any interested party or its advisers
              and any such liability is expressly disclaimed.
            </li>
            <li>
              For the purposes of this document, "<b>Group</b>" means the
              Company, any subsidiary or any holding company of the Company and
              any other subsidiary from time to time of a holding company of the
              Company. A reference to a "holding company" or a "subsidiary"
              means a holding company or a subsidiary (as the case may be) as
              defined in section 1159 of the Companies Act 2006.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO
              </li>
            </h3>
            <li>
              Where Our Website contains links to other websites and resources
              provided by third parties, these links are provided for your
              information only. Such links should not be interpreted as approval
              by us of those linked websites or information you may obtain from
              them.
            </li>
            <li>
              We have no control over the contents of those sites or resources.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
              </li>
            </h3>
            <div>
              <ol className="withPointAndNextNumber" start={13}>
                <li className="withPointAndNextNumber">
                  Whether you are a consumer or a business user:
                </li>
                <ul type="disc">
                  <li>
                    We do not exclude or limit in any way our liability to you
                    where it would be unlawful to do so. This includes liability
                    for death or personal injury caused by our negligence or the
                    negligence of our employees, agents or subcontractors and
                    for fraud or fraudulent misrepresentation.
                  </li>
                </ul>
                <li className="withPointAndNextNumber">
                  If you are a business user:
                </li>
                <ul type="disc">
                  <li>
                    We exclude all implied conditions, warranties,
                    representations or other terms that may apply to Our Website
                    or any content on it.
                  </li>
                  <li>
                    We will not be liable to you for any loss or damage, whether
                    in contract, tort (including negligence), breach of
                    statutory duty, or otherwise, even if foreseeable, arising
                    under or in connection with:
                  </li>
                  <ul type="circle">
                    <li>use of, or inability to use, Our Website; or</li>
                    <li>
                      use of or reliance on any content displayed on Our
                      Website.
                    </li>
                  </ul>
                  <li>In particular, we will not be liable for:</li>
                  <ul type="circle">
                    <li>loss of profits, sales, business, or revenue;</li>
                    <li>business interruption;</li>
                    <li>loss of anticipated savings;</li>
                    <li>
                      loss of business opportunity, goodwill or reputation; or
                    </li>
                    <li>any indirect or consequential loss or damage.</li>
                  </ul>
                </ul>
                <li className="withPointAndNextNumber">
                  If you are a consumer user:
                </li>
                <ul type="disc">
                  <li>
                    Please note that we only provide Our Website for domestic
                    and private use. You agree not to use Our Website for any
                    commercial or business purposes, and we have no liability to
                    you for any loss of profit, loss of business, business
                    interruption, or loss of business opportunity.
                  </li>
                  <li>
                    If defective digital content that we have supplied, damages
                    a device or digital content belonging to you and this is
                    caused by our failure to use reasonable care and skill, we
                    will either repair the damage or pay you compensation.
                    However, we will not be liable for damage that you could
                    have avoided by following our advice to apply an update
                    offered to you free of charge or for damage that was caused
                    by you failing to correctly follow installation instructions
                    or to have in place the minimum system requirements advised
                    by us.
                  </li>
                </ul>
              </ol>
            </div>
            <h3 className="marginMainPoints">
              <li className="withoutPoint">UPLOADING CONTENT TO OUR WEBSITE</li>
            </h3>
            <li>
              Whenever you make use of a feature that allows you to upload
              content to Our Website, or to make contact with other users of Our
              Website, you must comply with the content standards set out in our{" "}
              <a className="site" href="/acceptable-use-policy">
                Acceptable Use Policy
              </a>
              .
            </li>
            <li>
              You warrant that any such contribution does comply with those
              standards, and you will be liable to us and indemnify us for any
              breach of that warranty. This means you will be responsible for
              any loss or damage we suffer as a result of your breach of
              warranty.
            </li>
            <li>
              Any content you upload to Our Website will be considered
              non-confidential and non-proprietary. You retain all of your
              ownership rights in your content, but you are required to grant us
              and other users of Our Website a limited licence to use, store and
              copy that content and to distribute and make it available to third
              parties. The rights you license to us are described in{" "}
              <a className="site" href="#section-15">
                Rights you are giving us to use material you upload
              </a>
              .
            </li>
            <li>
              We also have the right to disclose your identity to any third
              party who is claiming that any content posted or uploaded by you
              to Our Website constitutes a violation of their intellectual
              property rights, or of their right to privacy.
            </li>
            <li>
              We have the right to remove any posting you make on Our Website
              if, in our opinion, your post does not comply with the content
              standards set out in our{" "}
              <a className="site" href="/acceptable-use-policy">
                Acceptable Use Policy
              </a>
              .
            </li>
            <li>
              You are solely responsible for securing and backing up your
              content.
            </li>

            <span id="section-15" />
            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                RIGHTS YOU ARE GIVING US TO USE MATERIAL YOU UPLOAD
              </li>
            </h3>
            <li>
              When you upload or post content to Our Website, you grant us,
              third parties and any other users of the Website a perpetual,
              worldwide, non-exclusive, royalty free, transferable licence to
              use, reproduce, distribute, prepare derivative works or, display
              and perform that user generated content.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE
                THEM
              </li>
            </h3>
            <li>
              We do not guarantee that Our Website will be secure or free from
              bugs or viruses.
            </li>
            <li>
              You are responsible for configuring your information technology,
              computer programmes and platform to access Our Website. You should
              use your own virus protection software.
            </li>
            <li>
              You must not misuse Our Website by knowingly introducing viruses,
              trojans, worms, logic bombs or other material that is malicious or
              technologically harmful. You must not attempt to gain unauthorised
              access to Our Website, the server on which Our Website is stored
              or any server, computer or database connected to Our Website. You
              must not attack Our Website via a denial-of-service attack or a
              distributed denial-of service attack. By breaching this provision,
              you would commit a criminal offence under the Computer Misuse Act
              1990. We will report any such breach to the relevant law
              enforcement authorities and we will co-operate with those
              authorities by disclosing your identity to them. In the event of
              such a breach, your right to use Our Website will cease
              immediately.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                RULES ABOUT LINKING TO OUR WEBSITE
              </li>
            </h3>
            <li>
              You may link to our home page, provided you do so in a way that is
              fair and legal and does not damage our reputation or take
              advantage of it.
            </li>
            <li>
              You must not establish a link in such a way as to suggest any form
              of association, approval or endorsement on our part where none
              exists.
            </li>
            <li>
              You must not establish a link to Our Website in any website that
              is not owned by you.
            </li>
            <li>
              Our Website must not be framed on any other website, nor may you
              create a link to any part of Our Website other than the home page.
            </li>
            <li>
              We reserve the right to withdraw linking permission without
              notice.
            </li>
            <li>
              The website in which you are linking must comply in all respects
              with the content standards set out in our{" "}
              <a className="site" href="/acceptable-use-policy">
                Acceptable Use Policy
              </a>
              .
            </li>
            <li>
              If you wish to link to or make any use of content on Our Website
              other than that set out above, please contact{" "}
              <a className="email" href="mailto:cnsprivacy@cnsonline.net">
                cnsprivacy@cnsonline.net
              </a>
              .
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">
                WHICH COUNTRY'S LAWS APPLY TO ANY DISPUTES?
              </li>
            </h3>
            <li>
              If you are a consumer, please note that these Terms, their subject
              matter and their formation, are governed by English law. You and
              we both agree that the courts of England and Wales will have
              exclusive jurisdiction except that if you are a resident of
              Northern Ireland you may also bring proceedings in Northern
              Ireland, and if you are resident of Scotland, you may also bring
              proceedings in Scotland.
            </li>
            <li>
              If you are a business, these Terms, their subject matter and their
              formation (and any non-contractual disputes or claims) are
              governed by English law. We both agree to the exclusive
              jurisdiction of the courts of England and Wales.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">SURVIVAL</li>
            </h3>
            <li>
              If any provision or provisions of these Terms of Use shall be held
              to be invalid, illegal or unenforceable, the validity, legality
              and enforceability of the remaining provisions shall remain in
              full force and effect.
            </li>

            <h3 className="marginMainPoints">
              <li className="withoutPoint">OUR TRADEMARKS ARE REGISTERED?</li>
            </h3>
            <li>
              CNS, and other marks are trademarks of DP World and other
              companies within the DP World group and are registered in various
              countries. You are not permitted to use them without our approval,
              unless they are part of material you are using as permitted under{" "}
              <a className="site" href="#section-10">
                How you may use material on Our Website
              </a>
              .
            </li>
          </ol>

          <div align="right">Last Updated: 23/07/2019</div>

          <div className="ie_gap" />
        </Section>
      </div>
    );
  }
}
