import React from 'react';
import ReactModal from 'react-modal';
import './account-suspended.css';

export default class AccountSuspended extends React.Component {
  render() {
    const root = document.querySelector('react-app');
    const { isOpen, className } = this.props;

    return <ReactModal appElement={root} isOpen={isOpen} className={className}>
      <h4>Your account has been suspended. Please contact our helpdesk on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a> for assistance</h4>
    </ReactModal>;
  }
};
