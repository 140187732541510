import React from 'react';
import { removeCookies } from '../../services';
import { Redirect } from 'react-router-dom';

export default class SignOut extends React.Component {

  constructor(props) {
    super();
    removeCookies();
    props.checkIsAuthorized();
  }

  render() {
    return <Redirect to='/login'/>
  };
}
