import React from 'react';
import CustomerGeneralInformation from './customer-general-information/customer-general-information';
import AccountNotes from './account-notes/account-notes'
import SuspendAccount from './suspend-account/suspend-account'
import {api} from "../../services/index";
import {Button} from '..';
import { Redirect } from 'react-router-dom';

import './administration-customer-update.css';
import CustomerSecretQuestion from "./customer-secret-question/customer-secret-question";
import PasswordReset from "./password-reset/password-reset";
import DeleteAccountButton from "./delete-account/delete-account-button";

export default class AdministrationCustomerUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: '',
      generalInformation: '',
      roles: [],
      secretQuestionTypes: [],
      secretQuestionAnswers: '',
      customerUpdated: false,
      serviceType: ''
    };
  }

  componentDidMount() {
    this.getCustomerDetails();
    this.getSecretQuestions();
    this.getRoleTypes();
  }

  onCustomerUpdate(){
    this.setState({customerUpdated: true});
  }

  getCustomerDetails() {
    const customerId = window.location.search.replace('?customerId=', '');
    this.setState({customerId: customerId});
    api(this.props.layoutContext, `/api/help-desk/customers/${customerId}`)
    .then(res => {
      if (res.status === 200) {
        res.json().then(responseBody =>
            this.setState({
              generalInformation: responseBody.details,
              statusType: responseBody.statusType,
              customerId: responseBody.customerId,
              secretQuestionAnswers: responseBody.secretQuestions,
              isDataOwner: responseBody.isDataOwner, 
              serviceType: responseBody.serviceType,
              hasInvoiceHistory: responseBody.hasInvoiceHistory
            })
        );
      }
    })
  }

  getRoleTypes() {
    api(this.props.layoutContext, '/api/type/role-types')
    .then(res => {
      res.status === 200
          ? res.json().then(response => this.setState({roles: response}))
          : setTimeout(() => this.getOptionsByType(this.getRoleTypes()), 5000);
    });
  }

  getSecretQuestions() {
    api(this.props.layoutContext, '/api/type/secret-question-types')
    .then(res => {
      res.status === 200
          ? res.json().then(response => this.setState({secretQuestionTypes: response}))
          : setTimeout(() => this.getOptionsByType(this.getSecretQuestions()), 5000);
    });
  }

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }

    return <div className="page_id_helpdesk_account">
      <div className="administration-title">
          <h1>Administration</h1>
        <h2>Customer Account Preferences</h2>
        {this.state.customerUpdated && <h4 className='administration-customer-updated'>Changes have been saved</h4>}
      </div>
      <section>
        <CustomerGeneralInformation
            generalInformation={this.state.generalInformation}
            roleTypes={this.state.roles}
            customerId={this.state.customerId}
            statusType={this.state.statusType}
            onCustomerUpdate={this.onCustomerUpdate.bind(this)}
            layoutContext={this.props.layoutContext}

        />
      </section>
      <section>
        <CustomerSecretQuestion
          secretQuestionTypes={this.state.secretQuestionTypes}
          secretQuestionAnswers={this.state.secretQuestionAnswers}
          customerId={this.state.customerId}
          statusType={this.state.statusType}
          layoutContext={this.props.layoutContext}
        />
      </section>

      <div className="suspend_account_temp_pass_container">
          <PasswordReset
              customerId={this.state.customerId}
              statusType={this.state.statusType}
              isDataOwner={this.state.isDataOwner}
              layoutContext={this.props.layoutContext}
          />

        {this.state !== undefined && this.state.customerId !== undefined ?
              <SuspendAccount
                  statusType={this.state.statusType}
                  customerId={this.state.customerId}
                  isDataOwner={this.state.isDataOwner}
                  refreshCustomerDetails={() => this.getCustomerDetails()}
                  layoutContext={this.props.layoutContext}
              />
            : <div/>
        }
      </div>
      <div className="delete_account">
        <DeleteAccountButton
            layoutContext={this.props.layoutContext}
            customerId={this.state.customerId}
            isDataOwner={this.state.isDataOwner}
            serviceType={this.state.serviceType}
            invoices={this.state.hasInvoiceHistory}
        />
      </div>
      <div className="returnToSearch">
        <Button color="pink"
                icon="arrow"
                onClick={() => this.setState({redirectTo: '/administration-search'})}>
          Return to Search
        </Button>
      </div>
      <div className="account_notes">
        {this.state !== undefined && this.state.customerId !== undefined ?
            <AccountNotes
                layoutContext={this.props.layoutContext}
                customerId={this.state.customerId}
                statusType={this.state.statusType} /> : <div/>}
      </div>
      <div className='ie_gap' style={{height: '170px'}}/>
    </div>
  }
}