import React from 'react';
import ReactModal from 'react-modal';
import { Redirect } from 'react-router-dom';
import { api } from '../../services';
import { Button } from '..';
import './email-confirmation.css';

export default class EmailConfirmation extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      isEmailConfirmed: false,
      isAlreadyConfirmed: false,
      isConnectionProblem: false,
      isLinkExpired: false,
      isOpen: true,
      redirectTo: ''
    };
  }

  componentDidMount(){
    this.setRequestForEmailConfirmation();
  }

  setRequestForEmailConfirmation(){
    const query = window.location.search;
    const cid = (query.match(/cid=(.*)/g) || '').toString().replace(/^cid=/, '')
    const token = (query.match(/token=(.*)&cid/g) || '').toString().replace(/^token=/, '').replace(/&cid$/, '');
    const url = '/api/emails/confirmation?cid=' + encodeURIComponent(cid) + '&token=' + encodeURIComponent(token);

    api(this.props.layoutContext, url, 'POST', {})
      .then(res => {
        if (res.status === 200) {
          this.setEmailConfirmed();
        } else if (res.status === 202) {
          this.setAlreadyConfirmed();
        } else if (res.status === 400) {
          res.json().then(response => {if(response.code === 'CSC_02') this.setLinkExpired()});
        } else if (res.status === 500) {
          this.setConnectionProblem();
        }
      });
  }

  setEmailConfirmed(){
    this.setState({ isEmailConfirmed: true });
  }

  setAlreadyConfirmed(){
    this.setEmailConfirmed();
    this.setState({ isAlreadyConfirmed: true });
  }

  setConnectionProblem() {
    this.setState({ isConnectionProblem: true });
  }

  setLinkExpired(){
    this.setState({isLinkExpired: true});
  }

  closeModal(){
    this.setState({isOpen: false})
  }

  showSuccessMessage(){
    return <React.Fragment>
      <h4>Your email has been successfully confirmed.</h4>
      <Button color='blue' onClick={() => this.closeModal()}>Continue</Button>
    </React.Fragment>
  }

  showConnectionProblem(){
    return <React.Fragment>
      <h4>Oops, something went wrong.  Please try again.  If the problem persists, please call our helpdesk on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a></h4>
    </React.Fragment>
  }

  showAlreadyConfirmed() {
    return <React.Fragment>
      <h4>You have already confirmed your email address. Please sign in.</h4>
    </React.Fragment>
  }

  showLinkExpired(){
    return <React.Fragment>
      <h4>The confirmation link in this email has expired. You will need to register again.</h4>
    </React.Fragment>
  }

  showErrorMessage(){
    return <React.Fragment>
      <h4>This link has been expired. Please call our helpdesk on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a></h4>
    </React.Fragment>
  }

  showMessage() {
    if (!this.state.isEmailConfirmed && this.state.isConnectionProblem) {
      return this.showErrorMessage()
    } else if (this.state.isEmailConfirmed && !this.state.isAlreadyConfirmed) {
      return this.showSuccessMessage()
    } else if (this.state.isEmailConfirmed && this.state.isAlreadyConfirmed) {
      return this.showAlreadyConfirmed()
    } else if(this.state.isLinkExpired) {
      return this.showLinkExpired();
    } else if(this.state.isConnectionProblem) {
      return this.showConnectionProblem();
    }
  }

  render(){
    const root = document.querySelector('react-app');

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />
    }

    return <ReactModal
      appElement={root}
      isOpen={this.state.isOpen}
      className='app-modal'
    >
      <Button id="close-modal" onClick={() => this.closeModal()}>×</Button>
      <div className='email-confirmation'>
        {this.showMessage()}
      </div>
    </ReactModal>
  }
}
