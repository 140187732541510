import React from 'react';
import {Section} from '..';

export function getDefaultCmsComponentByLocation(componentLocation) {
  switch (componentLocation) {
    case 'Sign In':
      return [
        {
          displayOrder: 1,
          regionName: 'Sign In',
          regionItemLimit: 2,
          content: <Section color="light-blue" title="Welcome">
            <span className='section-content-wrapper'>
              <p>Welcome to the new-look <i><strong>Where’s my container? </strong></i>
                tool
                for DP World’s UK ports at London Gateway and Southampton.</p>
              <p>Helping our customers and cargo owners to track and trace their
                containers.</p>
              <p>From ship to shore, out of the port gate and back again. Here you
                will find all the key information you need on the status of your
                container as it moves through one of our UK ports.</p>
            </span>
          </Section>
        }
        ,
        {
          displayOrder: 2,
          regionName: 'Sign In',
          regionItemLimit: 2,
          content: <Section backgroundColorCode='#D90348'>
          </Section>
        }
      ];
    case 'Port Status':
      return [{
        displayOrder: 1,
        regionName: 'Port Status',
        regionItemLimit: 1,
        content: <Section className="dashboard_port-status-notifications-section" color="blue" size="6"/>
        }
      ];
    case 'Critical Alerts (LGPL)':
    case 'Critical Alerts (DPWS)':
      return [{
        title: '',
        regionName: componentLocation
      }];
    case 'Coming Soon':
      return [{
        displayOrder: 1,
        regionName: 'Coming Soon',
        regionItemLimit: 1,
        content: <Section className="dashboard_coming-soon-section" color="pink" size="6"/>
        }
      ];
    case 'FAQ Dashboard':
      return [{
        displayOrder: 1,
        regionName: 'FAQ Dashboard',
        regionItemLimit: 1,
        content: <Section className="dashboard_faq-section"
                          color="light-blue" size="3" mobileAdaptive={true}/>
      }];
    case 'FAQ Item':
    default: return [];
  }
}