import React from 'react';
import ReactModal from 'react-modal';
import {Button} from '../..';

import "./not-allowed-operation-modal.css"

export default class NotAllowedOperationModal extends React.Component {

  render() {
    return <ReactModal appElement={document.querySelector('react-app')}
                       isOpen={this.props.isOpen} className="app-modal">
      <div className="not-allowed-operation-modal">
        <h4>You cannot perform this task</h4>
          <Button color="blue" onClick={() => {
            this.props.closeModal();
          }}>OK</Button>
      </div>
    </ReactModal>
  }

}