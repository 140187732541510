import React from 'react';
import {Redirect} from 'react-router-dom';

export default class AdministrationSearchTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: ''
    };
  }

  onClickAction(customerId){
    this.setState({redirectTo: `/customer-update?customerId=${customerId}`})
  }

  render() {

    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo}/>
    }

    return <React.Fragment>{this.props.data.length > 0 ?
        <div className="administration-search-table-wrapper">
          <table className="administration-search-table">
          <thead>
          <tr>
            <th className='administration_search_table_cell administration_search_table_cell_last_name'>Last Name</th>
            <th className='administration_search_table_cell administration_search_table_cell_first_name'>First Name</th>
            <th className='administration_search_table_cell administration_search_table_cell_company'>Company</th>
            <th className='administration_search_table_cell administration_search_table_cell_email'>Email/Username</th>
            <th className='administration_search_table_cell administration_search_table_cell_phone'>Contact Phone</th>
            <th className='administration_search_table_cell administration_search_table_cell_service_type'>Service Type</th>
            <th className='administration_search_table_cell administration_search_table_cell_status'>Status</th>
          </tr>
          </thead>

          <tbody>
          {this.props.data.map(entry =>
              <tr key={entry.customerId} onClick={()=>{
              this.onClickAction(entry.customerId)}
              }>
                <td>{entry.lastName}</td>
                <td>{entry.firstName}</td>
                <td>{entry.companyName}</td>
                <td>{entry.email}</td>
                <td>{entry.phone}</td>
                <td>{entry.serviceType}</td>
                <td>{entry.statusType}</td>
              </tr>)
          }
          </tbody>
        </table></div> : <div className="no_records_found">No records found</div>}
      {this.props.totalPages > this.props.currentPage && <div
          className="show_more">
        <button className="button button_color_blue"
                onClick={this.props.nextPageHandler}>
          Show more results
        </button>
      </div>
      }
    </React.Fragment>
  }
}