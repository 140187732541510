import React from "react";
import PropTypes from "prop-types";
import { Context } from "../app/context/context";
import { LayoutContext } from "../app/context/layout-context";
import Button from "../button/button";
import FrameSection from "../frame-section/frame-section";
import {
  api,
  formatDate,
  formatFullDate,
  formatShortDate
} from "../../services";
import style from "./style.module.scss";

class ReceiptsHistory extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      more: false,
      nextPage: 1
    };
  }
  componentDidMount() {
    this.showMore();
  }

  showMore = () => {
    api(LayoutContext, "/api/payment/history?page=" + this.state.nextPage)
      .then(response => {
        return response.json();
      })
      .then(data => {
        var payments = this.state.payments,
          nextPage = this.state.nextPage +1;
        payments = payments.concat(data.histories);
        if (data.currentPage === data.allPages)
          this.setState({
            payments: payments,
            nextPage: nextPage,
            more: false
          });
        else
          this.setState({
            payments: payments,
            nextPage: nextPage,
            more: true
          });
      })
      .catch(err => {
        console.log(err);
      });
  };

  render() {
    const DesktopLayout = (
      <table className={style.table}>
        <thead className={style.tableHeader}>
          <tr>
            <td className={style.dateColumn}>Subscription Period</td>
            <td>Actual Payment Date</td>
            <td>Subscription Amount</td>
            <td>VAT</td>
            <td>Total Cost</td>
            <td>Invoice Number</td>
            <td>Purchase Order Number</td>
            <td />
          </tr>
        </thead>
        <tbody className={style.tableContent}>
          {this.state.payments.map((payment, i) => {
            return (
              <tr key={i}>
                <td className={style.dateColumn}>
                  {formatFullDate(payment.startDate)} -{" "}
                  {formatFullDate(payment.endDate)}
                </td>
                <td>{formatShortDate(payment.paymentDate)}</td>
                <td>£{parseFloat(payment.subscriptionAmount).toFixed(2)}</td>
                <td>£{parseFloat(payment.vat).toFixed(2)}</td>
                <td>£{parseFloat(payment.totalCost).toFixed(2)}</td>
                <td>{payment.invoiceNumber}</td>
                <td>{payment.purchaseOrderNumber}</td>
                <td>
                <a href={payment.link} target='_blank' rel="noopener noreferrer"><Button className={style.buttonView}>View</Button></a>
                </td>
              </tr>
            );
          })}
          {this.state.more && (
            <tr>
              <td colSpan="8" className={style.showMoreRow}>
                <Button className={style.showMoreRow} type={"link"} onClick={this.showMore}>
                  show more
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
    const MobileLayout = (
      <div>
        {this.state.payments.map((payment, i) => {
          return (
            <FrameSection
              key={i}
              className={style.frame}
              title={
                <div className={style.title}>
                  <div>
                    Period
                  </div>
                  <div>
                    {formatDate(payment.startDate)} -{" "}
                    {formatDate(payment.endDate)}
                  </div>
                </div>
              }
              titleColor={"yellow"}
            >
              <div className={style.sectionRow}>
                <b>Actual Payment Date</b>
                <br />
                <span>{formatShortDate(payment.paymentDate)}</span>
              </div>
              <div className={style.sectionRow}>
                <b>Subscription Amount</b>
                <br />
                <span>
                  £{parseFloat(payment.subscriptionAmount).toFixed(2)}
                </span>
              </div>
              <div className={style.sectionRow}>
                <b>VAT</b>
                <br />
                <span>£{parseFloat(payment.vat).toFixed(2)}</span>
              </div>
              <div className={style.sectionRow}>
                <b>Total Cost</b>
                <br />
                <span>£{parseFloat(payment.totalCost).toFixed(2)}</span>
              </div>
              <div className={style.sectionRow + " " + style.flexRow}>
                <div>
                  <b>Invoice Number</b>
                  <br />
                  <span>{payment.invoiceNumber}</span>
                </div>
                <a href={payment.link} target='_blank' rel="noopener noreferrer"><Button className={style.buttonView}>View</Button></a>
              </div>
              <div className={style.sectionRow}>
                <b>Purchase Order Number</b>
                <br />
                <span>{payment.purchaseOrderNumber}</span>
              </div>
            </FrameSection>
          );
        })}
        {this.state.more && (
          <div className={style.showMoreRow}>
            <Button className={style.showMoreRow} type={"link"} onClick={this.showMore}>
              show more
            </Button>
          </div>
        )}
      </div>
    );
    return (
      <div className="dashboard_wrap">
        <div className={style.container}>
          <h2 className={style.header}>Receipted Invoice History</h2>
          {this.props.layoutContext.state.isMobile
            ? MobileLayout
            : DesktopLayout}
          <div className={style.buttonRow}>
            <Button color={"blue"} onClick={()=>this.context.router.history.push('/account')}>Back to My Account</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default props => (
  <LayoutContext.Consumer>
    {layoutContext => (
      <Context.Consumer>
        {context => (
          <ReceiptsHistory
            {...props}
            context={context}
            layoutContext={layoutContext}
          />
        )}
      </Context.Consumer>
    )}
  </LayoutContext.Consumer>
);
