import React from 'react';
import ContainerHistoryTable from './../container-history-list/container-history-table';
import { Context } from '../app/context/context';
import {LayoutContext} from "../app/context/layout-context";
import spinner from "./../../static/images/Spinner.gif";
import {Redirect} from 'react-router-dom';

import './container-history.css';
import Button from '../button/button';

export class ContainerHistory extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: ''
    };
  }

  componentWillUnmount(){
    this.props.context.cleanSearchResults();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.location.search !== nextProps.location.search
        || this.props.context.state.isLoading !== nextProps.context.state.isLoading
        || this.state !== nextState;
  }

  searchAgain(){
    this.props.context.cleanSearchResults();
    this.setState({redirectTo: `/history-search${this.props.location.search}`});
  }

  render() {

    if(this.state.redirectTo){
      return <Redirect to={this.state.redirectTo}/>
    }

    return <div className="main_wrap page_id_containers-history">
      <h2>History Details
      </h2>
      {this.props.context.state.isLoading ?
          <div className='spinner-container'><img src={spinner} alt='Spinner'/></div> :
          <>
            <ContainerHistoryTable
                searchParams={this.props.location.search}
            />
            <div className='page_id_containers-history-controls'>
              <Button color="pink" icon="arrow" onClick={e => this.searchAgain()}>Search again</Button>
            </div>
          </>
      }
    </div>;
  }
}

export default props => (
    <LayoutContext.Consumer>
      {layoutContext =>
          <Context.Consumer>
            {context => <ContainerHistory {...props}
                                          context={context}
                                          layoutContext={layoutContext}/>}
          </Context.Consumer>
      }
    </LayoutContext.Consumer>
);
