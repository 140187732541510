import React from 'react';
import './critical-alert-component.css';

export default class CriticalAlertComponent extends React.Component {

  static getContent(alerts) {
    if (alerts.length <= 0) {
      return [];
    }

    let content = alerts.length === 1
        ? <span className="critical-alert-content">{alerts[0]}</span>
        : <span className="critical-alert-content">{alerts[0]}<br/>{alerts[1]}</span>;
    return <div className="critical-alert-wrapper">
      <div className="critical-alert-container">{content}</div>
    </div>;
  }

  getCmsCriticalAlerts() {
    if (this.props.sou && this.props.lgp) {
      let result = [this.props.sou[0], this.props.lgp[0]]
      .filter(e => e.title !== '')
      .sort((e1, e2) => {
        if (e1.published > e2.published) {
          return -1;
        } else if (e1.published < e2.published) {
          return 1;
        }
        return 0;
      })
      .map(e => e.content);

      return CriticalAlertComponent.getContent(result);
    } else {
      return '';
    }
  }

  render() {
    return this.getCmsCriticalAlerts();
  }

}
