import React from "react";
import ReactModal from "react-modal";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";

import Button from "../button/button";
import { LayoutContext } from "../app/context/layout-context";
import { required, email } from "../../validations";
import { api } from "../../services";

import "./footer.css";
import "./footer.mobile.css";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sitekey: null,
      isSendButtonDisable: false,
      contactUsShown: false,
      firstName: "",
      lastName: "",
      email: "",
      subject: "",
      message: "",
      recaptchaToken: ""
    };
  }

  filterInput(e, key, pattern) {
    this.setState({
      [key]: e.target.value.replace(pattern, "")
    });
  }

  handleChange = () => {
    api(LayoutContext, "/api/contact-us", "POST", {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      message: this.state.message,
      subject: this.state.subject,
      recaptchaToken: this.state.recaptchaToken
    })
      .then(() => {
        this.setState({
          contactUsShown: false,
          isSendButtonDisable: false,
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: ""
        });
      })
      .catch(() => {
        this.setState({
          contactUsShown: false,
          isSendButtonDisable: false,
          firstName: "",
          lastName: "",
          email: "",
          subject: "",
          message: ""
        });
      });
  };

  render() {
    const link_one = (
      <React.Fragment>
        <div>
          <a href="/terms-of-use-policy" target="_blank">
            Terms of Use Policy
          </a>
        </div>
        <div>
          <a href="/privacy-policy" target="_blank">
            Privacy Policy
          </a>
        </div>
      </React.Fragment>
    );
    const link_two = (
      <React.Fragment>
        <div>
          <a href="/cookie-policy" target="_blank">
            Cookie Policy
          </a>
        </div>
        <div>
          <a href="/acceptable-use-policy" target="_blank">
            Acceptable Use Policy
          </a>
        </div>
      </React.Fragment>
    );
    return (
      <div className="footer__container">
        <div className="footer__contact-info">
          <span className="footer__company-name">
            Community Network Services Ltd.
          </span>
          <br /> 16 Palace Street,
          <br /> London England SW1E 5JQ
          <br /> Reg. Number: 02084279 England
          <br /> VAT Reg: 458998463 <br />
          As agent for the DP World ports London Gateway and Southampton
        </div>
        <div className="footer__helpdesk">
          If you are experiencing system problems, please contact our Helpdesk
          on {!this.props.isMobile && <br />}
          08000&nbsp;469&nbsp;648 or email us at{" "}
          <a href="mailto:wmcsupport@dpworld.com">WMCsupport@dpworld.com</a>
          <br />
          <Button
            className="footer__contact-us"
            type="link"
            onClick={() => this.setState({ contactUsShown: true })}
          >
            Contact Us
          </Button>
        </div>
        <div className="footer__links">
          {this.props.isMobile ? (
            <React.Fragment>
              <div className="footer__links_row">{link_one}</div>
              <div className="footer__links_row">{link_two}</div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {link_one}
              {link_two}
            </React.Fragment>
          )}
        </div>
        <ReactModal
          appElement={document.querySelector("react-app")}
          isOpen={this.state.contactUsShown}
          className="app-modal"
        >
          <Button
            id="close-modal"
            onClick={() => this.setState({ contactUsShown: false })}
          >
            ×
          </Button>
          <h2>Contact Us</h2>
          <Form
            ref={node => {
              this.form = node;
            }}
            onSubmit={e => {
              this.setState({ isSendButtonDisable: true });
              this.handleChange();
              e.preventDefault();
            }}
            onKeyDown={event => {
              if (event.keyCode === 13) this.toOrder(event);
            }}
          >
            <div className="contact-us__form-item">
              <span>First Name:</span>
              <Input
                onBlur={() => this.form.validate("firstName")}
                name="firstName"
                value={this.state.firstName || ""}
                onChange={e => {
                  this.filterInput(e, "firstName", /[^A-Za-z\s-']/g);
                }}
                validations={[required]}
                maxLength={200}
              />
            </div>
            <div className="contact-us__form-item">
              <span>Last Name:</span>
              <Input
                onBlur={() => this.form.validate("lastName")}
                name="lastName"
                value={this.state.lastName || ""}
                onChange={e => {
                  this.filterInput(e, "lastName", /[^A-Za-z\s-']/g);
                }}
                validations={[required]}
                maxLength={200}
              />
            </div>
            <div className="contact-us__form-item">
              <span>Email:</span>
              <Input
                onBlur={() => this.form.validate("email")}
                name="email"
                value={this.state.email || ""}
                onChange={e => {
                  this.filterInput(e, "email", /[^\w@\-._]/g);
                }}
                validations={[required, email]}
                maxLength={200}
              />
            </div>
            <div className="contact-us__form-item">
              <span>Subject:</span>
              <Input
                onBlur={() => this.form.validate("subject")}
                name="subject"
                value={this.state.subject || ""}
                onChange={e => {
                  this.filterInput(
                    e,
                    "subject",
                    /[^A-Za-zÀ-ÖØ-öø-ÿ\d\s-'@.,&;:"'()$£!%+{}\\/?<>]/g
                  );
                }}
                validations={[required]}
                maxLength={70}
              />
            </div>
            <div className="contact-us__form-item">
              <span>Message:</span>
              <Textarea
                placeholder="Maximum 2500 characters"
                onBlur={() => this.form.validate("message")}
                name="message"
                value={this.state.message || ""}
                onChange={e => {
                  this.filterInput(e, "message", /[^A-Za-zÀ-ÖØ-öø-ÿ\s\d'(){}[\]@#$£+=<>?!%*&\\/,.-]/g);
                }}
                validations={[required]}
                maxLength={2500}
              />
            </div>
            <GoogleReCaptcha
              onVerify={token => this.setState({ recaptchaToken: token })}
            />
            <div className="contact-us__row-button">
              <Button color="gray" onClick={() => this.setState({ contactUsShown: false })}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="blue"
                isFormValidationRequired
                disabled={this.state.isSendButtonDisable}
              >
                Send
              </Button>
            </div>
          </Form>
        </ReactModal>
      </div>
    );
  }
}