import React from "react";
import Collapsible from "react-collapsible";
import { formatTimeAndFullDate } from "../../services";
import ArrowUp from "../collapsible-page-header/img/arrow-up";
import ArrowDown from "../collapsible-page-header/img/arrow-down";
import FrameSection from "../frame-section/frame-section"
import "./pending-alerts-page.css";
import "./pending-alerts-page.mobile.css";
import {api} from "../../services/index"

const collapsibleTrigger = (isOpen, title, color, param, layout, hasPendingAlerts) => (
  <div className={titleClassName(color)}>
    <div>{title}</div>
    <div className="alerts-page_collapse-desc">
      <span>{!layout && param}</span>
      {hasPendingAlerts && (isOpen ? <ArrowDown /> : <ArrowUp />)}
    </div>
  </div>
);

const titleClassName = titleColor => {
  const classNames = ["frame-section_title", "alerts-page_collapse-title"];

  if (titleColor) {
    classNames.push(`frame-section_title-color_${titleColor}`);
  }

  return classNames.join(" ");
};

const CollapsibleBlock = props => {
  const header = props.title + ` (${props.data ? props.data.totalPending : "-"  })`;
  const hasPendingAlerts = props.data && props.data.containers.length > 0;
  return (
    <Collapsible
      trigger={collapsibleTrigger(
        false,
        header,
        props.color,
        props.data && props.data.containers.length <= 0
          ? "No pending alerts"
          : "Expand to see details",
        props.layout,
        hasPendingAlerts
      )}
      triggerWhenOpen={collapsibleTrigger(
        true,
        header,
        props.color,
          hasPendingAlerts ? "Collapse" : "No pending alerts",
        props.layout,
        hasPendingAlerts
      )}
      triggerTagName="div"
      triggerClassName=""
      triggerOpenedClassName=""
      className={"alerts-page_collapse"}
      openedClassName={"alerts-page_collapse"}
    >
      {props.data && props.data.containers.length > 0 && (
        <div className={"alerts-page_collapse-content"}>
          {!props.layout && (
            <table className={"alerts-page_content-table"}>
              <thead>
                <tr>
                  <th>CONTAINER</th>
                  <th>APPLIED BY</th>
                  <th>EMAIL</th>
                  <th>SMS</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {props.data &&
                  props.data.containers.map(item => (
                    <tr key={item.number}>
                      <td>{item.number}</td>
                      <td>
                        {item.fromTemplate
                          ? "Alert Template"
                          : "Single Alert Selector"}
                      </td>
                      <td>{item.emailRecipients.join("; ")}</td>
                      <td>{item.smsRecipients.join("; ")}</td>
                      <td>
                        {item.statuses.map((status, index) => (
                          <p style={{ margin: "8px", marginLeft: "0px" }} key={"status" + index}>
                            {status}
                          </p>
                        ))}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {props.layout && <div>{props.data && props.data.containers.map(item => 
            <div className={"alerts-page_mobile-alert-item"} key={item.number}>
            <FrameSection title={item.number}>
              <div>{item.fromTemplate
                          ? "Alert Template"
                          : "Single Alert Selector"}</div>
                          <div>{item.emailRecipients.join("; ")}</div>
                          <div>{item.smsRecipients.join("; ")}</div>
            </FrameSection>
            {item.statuses.map((status, index) => (
                          <p style={{ margin: "8px", marginLeft: "0px" }} key={"status" + index}>
                            {status}
                          </p>
                        ))}
            </div>
          )}</div>}
          {props.data.currentPage < props.data.allPages && (
            <p
              style={{ textAlign: "center", cursor: "pointer", textDecoration: "underlined" }}
              onClick={props.showMore}
            >
              Show more
            </p>
          )}
        </div>
      )}
    </Collapsible>
  );
};

export default class PendingAlerts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pendingSms: 0,
      pendingEmail: 0,
      availableSms: 300, 
      availableEmail: 300, 
      alertsInfo: {}
    };
  }

  componentDidMount() {
    let alerts = {};
    api(
      this.props.layoutContext,
      "/api/alert-subscription/pending"
    ).then(res => {
      if (res.status === 200) 
        return res.json();
    }).then(data=>{
      data.activities.map(item => (alerts[item.activity] = { ...item }));
      this.setState({ alertsInfo: alerts, ...data.alertPackageInfo });
    })
  }

  showMore(type) {
    api(
      this.props.layoutContext,
      `/api/alert-subscription/pending/activity?activity=${type}&page=${this.state.alertsInfo[type].currentPage+1}`
    ).then(res=>{
      if (res.status === 200) 
        return res.json();
    }).then(data=>{
      let alertsInfo = this.state.alertsInfo;
      alertsInfo[type].containers.push(...data.containers);
      alertsInfo[type].currentPage=data.currentPage;
      this.setState({alertsInfo})
    })
  }

  render() {
    let { alertsInfo } = this.state;
    return (
      <div className="main_wrap page_id_history">
        <h2>Pending Alerts</h2>
        <div className={"alerts-page_main-content"}>
          <span>as at {formatTimeAndFullDate(new Date())}</span>
          <div className={"alerts-page_summary-row"}>
            <div>
              You have {this.state.pendingEmail + this.state.pendingSms} pending
              alerts: {this.state.pendingEmail} email and{" "}
              {this.state.pendingSms} SMS
            </div>
            <div style={{ color: "red" }}>
              {!this.state.availableSms && !this.state.availableEmail && "All Alerts are currently suspended"}
              {!this.state.availableSms && !!this.state.availableEmail && "SMS Alerts are currently suspended"}
              {!!this.state.availableSms && !this.state.availableEmail && "Email Alerts are currently suspended"}
            </div>
          </div>
          <div>
            <CollapsibleBlock
              title="IMPORT"
              color="blue"
              data={alertsInfo["Import"]}
              showMore={() => this.showMore("Import")}
              layout={this.props.layoutContext.state.isMobile}
            />
            <CollapsibleBlock
              title="EXPORT"
              color="pink"
              data={alertsInfo["Export"]}
              showMore={() => this.showMore("Export")}
              layout={this.props.layoutContext.state.isMobile}
            />
            <CollapsibleBlock
              title="TRANSHIPMENT"
              color="yellow"
              data={alertsInfo["Transhipment"]}
              showMore={() => this.showMore("Transhipment")}
              layout={this.props.layoutContext.state.isMobile}
            />
            <CollapsibleBlock
              title="RETURN TO SHIPPER"
              color="dark-blue"
              data={alertsInfo["Return to Shipper"]}
              showMore={() => this.showMore("Return to Shipper")}
              layout={this.props.layoutContext.state.isMobile}
            />
          </div>
        </div>
      </div>
    );
  }
}
