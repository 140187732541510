import React from 'react';

import {api, formatDate, formatTime, isValidForm} from "../../../services";
import Textarea from 'react-validation/build/textarea';
import Form from 'react-validation/build/form';
import { Button } from '../..';

import './account-notes.css'
import {required} from "../../../validations";

export default class AccountNotes extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      noteTextarea: '',
      notes: null,
      pageNumber: 1,
      totalPages: 0
    };
  }

  componentDidMount(){
    this.getAccountNotes();
  };

  searchNextPageAccountNotes() {
    this.setState({pageNumber: this.state.pageNumber + 1},
        this.loadAccountNotes(this.state.pageNumber + 1));
  }

  getAccountNotes() {
    this.setState({pageNumber: 1}, this.loadAccountNotes());
  }

  loadAccountNotes(pageNumber = 1) {
    const data = {
      "page": pageNumber
    };

    api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/notes?` + Object.keys(data)
        .filter((key) => {
          return data[key] !== "";
        })
        .map(
            (key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(data[key]);
            })
        .join('&'))
    .then(res => {
      if (res.status === 200) {
        res.json().then(responseBody =>
        {
          this.setState({
            totalPages: responseBody.allPages,
            notes: pageNumber === 1
                ? responseBody.notes
                : this.state.notes.concat(responseBody.notes)

          });
        }
        );
      }
    });
  }

  addNote(){
    this.form.validateAll();
    if (isValidForm(this.form)) {
      const values = this.form.getValues();
      const note = values['new-note'];
      const data = {
        note: note,
        customerId: this.props.customerId
      };

      this.setState({isAdding: true});
      api(this.props.layoutContext, `/api/help-desk/customers/${this.props.customerId}/notes`, 'POST',
          data)
      .then(response => {
            if (response.status === 200) {
              this.setState({ isAdding: false });
              this.getAccountNotes();
              this.clearNote();
            }
          }
      );
    }
  }

  clearNote = () => {
    this.setState({noteTextarea: ''})
  };

  handleNoteChange(e) {
    this.setState({noteTextarea: e.target.value})
  };

  render() {

    let isUnverified = this.props.statusType !== 'Suspended' && this.props.statusType !== 'Live';
    if (isUnverified) {
      return <React.Fragment><div/></React.Fragment>;
    }

    return <React.Fragment>
      <h4 className="account-notes-title">Account Notes</h4>
      <Form
          className="add-note-form"
          ref={node => {
            this.form = node;
          }}
          onSubmit={event => {
            event.preventDefault();
          }}
      >
        <div className="new-note-textarea-wrapper">
          <Textarea
              name="new-note"
              className="new-note"
              placeholder="Type here to add Account Notes"
              validations={[required]}
              maxLength={500}
              value={this.state.noteTextarea}
              onChange={(evt) => this.handleNoteChange(evt)}
          />
        </div>
        <div className="add-note-button-wrapper">
          <Button
              disabled={this.state.noteTextarea === ''}
              onClick={e => {
                e.preventDefault();
                this.addNote();
              }}
              color="pink"
              icon="arrow"
          >
            Add Note
          </Button>
        </div>
      </Form>
      {
        this.state.notes != null ? (
        this.state.notes.length > 0 ?
          <div className="account-notes-table-wrapper">
            <table className="account-notes-table">
              <tbody>
              {this.state.notes.map(entry =>
                  <tr key={entry.createdDate}>
                    <td>{formatDate(entry.createdDate)}</td>
                    <td>{formatTime(entry.createdDate)}</td>
                    <td>{entry.note}</td>
                  </tr>)
              }
              </tbody>
            </table></div>
            :
            <div className="no_records_yet">No records yet</div>
        ) : <div/>
      }
      {
        this.state.totalPages > this.state.pageNumber && <div className="show_more">
          <button className="button button_color_blue"
                  onClick={() => this.searchNextPageAccountNotes()}>
            Show more notes
          </button>
        </div>
      }

    </React.Fragment>;

  }
}