import React from 'react';
import {hasAnyServiceType} from '../../../services';
import { getCmsComponentByLocation } from '../../cms-integration-components/get-cms-component';
import {buildComponentsFromCmsInfo, loadCmsContent, getCmsContentFromCache} from '../../../services/cms-integration';
import _ from 'lodash';

export const LayoutContext = React.createContext();

//const CACHE_VALIDITY_TIME = 300000;
const CACHE_VALIDITY_TIME = 5000;

export default class LayoutProvider extends React.Component {

  constructor() {
    super();

    this.state = {
      isMobile: this.isMobile(),
      isPaidUser: hasAnyServiceType(['Paid']),
      isFreeAdvancedUser: hasAnyServiceType(['Free-advanced', 'Internal']),
      isInternalUser: hasAnyServiceType(['Internal']),
      isFreeUser: hasAnyServiceType(['Free']),
      hasConnectionProblems: false,
      allStatusesMode: hasAnyServiceType(['Paid', 'Free-advanced', 'Internal'])
    };
  }

  getCmsComponent(regionNames, context) {
    if (context) {
      getCmsContentFromCache().then((cachedCmsResponse) => {
        let result = [];
        if ((!cachedCmsResponse
            || (new Date() - Date.parse(cachedCmsResponse.cmsContentCache.createdDate)) > CACHE_VALIDITY_TIME)) {
          loadCmsContent(this, context, regionNames);
        }

        if (cachedCmsResponse) {
          result = buildComponentsFromCmsInfo(cachedCmsResponse, regionNames)
        }

        regionNames.forEach(regionName => {
              const regionNameContent = _.find(result, {regionName: regionName});
              if (!cachedCmsResponse || !regionNameContent
                  || regionNameContent.content.length === 0) {
                result.push({
                  regionName: regionName,
                  content: getCmsComponentByLocation(regionName, null)
                })
              }
            }
        );

        //check if result is completed
        result.forEach(resultItem =>{
          if (resultItem.content.length === 0 || resultItem.content[0].regionItemLimit > result.length) {
            const componentsToAdd = getCmsComponentByLocation(resultItem.regionName, null)
            .map(item => {
              return {regionName: regionNames, ...item};
            });
            resultItem.content.push(...(componentsToAdd.slice(resultItem.content.length)))
          }
        });

        result = Object.assign({}, ...result.map(item => {
          return {[item.regionName.toLowerCase().replace(/[\s()]+/g, '')]: item.content}
        }));
        context.setState({...result});
      });
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  isMobile() {
    const {innerWidth} = window;
    return innerWidth < 500;
  }

  checkServiceType(){
    const isPaid = hasAnyServiceType(['Paid']);
    const isFreeAdvanced = hasAnyServiceType(['Free-advanced', 'Internal']);
    const isInternal =  hasAnyServiceType(['Internal']);
    const isFree =  hasAnyServiceType(['Free']);
    this.setState({
      isFreeAdvancedUser:isFreeAdvanced,
      isPaidUser: isPaid,
      isInternalUser: isInternal,
      isFreeUser: isFree,
      allStatusesMode: isPaid || isFreeAdvanced
    })
  }

  handleWindowSizeChange = () => {
    this.isMobile() ? this.setState({isMobile: true}) : this.setState({isMobile: false});
  };

  shouldComponentUpdate(nextProps, nextState){
    return this.props !== nextProps || this.state !==nextState;
  }

  render() {
    return (
        <LayoutContext.Provider value={{
          state: this.state,
          toggleContainerTableViewMode: () => { this.setState({allStatusesMode: !this.state.allStatusesMode})} ,
          checkServiceType: () => this.checkServiceType(),
          getCmsComponent: (contentRegions, context) => this.getCmsComponent(contentRegions, context),
          setConnectionProblems: (hasConnectionProblems)=>{this.setState({hasConnectionProblems: hasConnectionProblems});}
        }}>
          {this.props.children}
        </LayoutContext.Provider>
    )
  }
}
