import React from 'react';
import Cookies from 'browser-cookies';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import {
  LoggedInLayout,
  Containers,
  Login,
  Dashboard,
  MyAccount,
  Registration,
  Faqs,
  AccountSuspended,
  EmailConfirmation,
  PageNotFound,
  Notification,
  PrivacyPolicy,
  CookiePolicy,
  AcceptableUsePolicy,
  TermsOfUsePolicy,
} from '..';
import SignOut from '../sign-out/sign-out';
import { setUserTokens,toggleServiceTypeMode,  logout, api, isAuthorized, trackLocation } from '../../services';
import Provider from './context/context';
import "./app.css";
import "./app.mobile.css";
import AdministrationSearch from "../administration-search/administration-search";
import AlertTemplates from "../alert-templates/alert-templates";
import AdministrationCustomerUpdate from "../administration-customer-update/administration-customer-update.jsx";
import LoggedOutLayout from "../logged-out-layout/logged-out-layout";
import AuthorizationWrapper from "../Authorization/AuthorizationWrapper";
import HistorySearch from "../history-search/history-search";
import SavedLists from '../saved-list/saved-lists';
import ContainerHistory from '../container-history/container-history';
import ScrollToTop from './scroll-to-top';
import Payment from '../payment/Payment'
import AdvancedFeature from '../advanced-feature/advanced-features';
import PaidFeature from '../paid-feature/paid-features';
import ReceiptsHistory from '../receipts-hisory/ReceiptsHistory';
import PendingAlertsPage from "../pending-alerts-page/PendingAlertsPage"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sitekey: '',
      accountSuspended: false,
      isConnectionProblem: false,
      notificationMessage: '',
      isAuthorized: isAuthorized(),
      isTemporaryPasswordUsed: false
    };
  }

  checkIsAuthorized(){
    this.setState({isAuthorized: isAuthorized()});
  }

  async componentDidMount(){
    await this.refreshToken();
    await api(this.props.layoutContext, '/api/captcha/site-key' )
    .then((res) => res.text())
    .then((response) => {
      this.setState({sitekey: response})
    })
    .catch(err=>console.log(err))
  }

  setAccountSuspended(){
    this.setState({ accountSuspended: true });
  }

  setRequestToRefreshToken(layoutContext, refreshToken){
    const url = '/api/oauth/token?grant_type=refresh_token&refresh_token=' + refreshToken;

    return api(layoutContext, url, 'POST', {});
  }

  setConnectionProblems() {
    this.setState({
      notificationMessage: 'Oops, something went wrong.  Please try again.  If the problem persists, please call our helpdesk on 08000 469 648',
      isConnectionProblem: true
    })
  }

  onChangeTemporaryPassword(){
    this.setState({isTemporaryPasswordUsed: false});
  }

  refreshToken(onSuccess) {
    return new Promise((resolve, reject)=>{
      const refreshToken = Cookies.get('refresh_token');
      if (refreshToken) {
         this.setRequestToRefreshToken(this.props.layoutContext, refreshToken)
          .then(res => {
            if (res.status === 200) {
              res.json().then(res => {
                setUserTokens(res);
                toggleServiceTypeMode(this.props.layoutContext, res.service_type);
                this.setState({ isAuthorized: isAuthorized(), isTemporaryPasswordUsed: Number(res.resetRequest) !== 0 });
                setTimeout(() => this.refreshToken(), res.expires_in * 900);
                resolve()
                if(onSuccess){
                  onSuccess();
                }
              })
            } else if (res.status === 500) {
              this.setConnectionProblems();
            } else {
              logout();
            }
          })
      }else resolve();
    })
    
  }

  closeNotification() {
    this.setState({ isConnectionProblem: false, notificationMessage: '' })
  }

  showEmailConfirmation() {
    if (window.location.search.startsWith('?token=')) {
      return <EmailConfirmation layoutContext={this.props.layoutContext}/>
    }
  }

  showLoggedInLayout(){
    return <Provider>

        <LoggedInLayout
            layoutContext={this.props.layoutContext}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/payment" render={()=>(
              <Payment refreshToken={()=> this.refreshToken()}/>
            )}/>
            <Route path="/receipts" render={() => (
                <AuthorizationWrapper allowedServiceTypes={['Free', 'Paid']}>
                  <ReceiptsHistory />
                </AuthorizationWrapper>
            )}/>
             <Route path="/pending-alerts" render={() => (
                <AuthorizationWrapper allowedServiceTypes={['Free-advanced', 'Paid', 'Internal']}>
                  <PendingAlertsPage layoutContext={this.props.layoutContext} />
                </AuthorizationWrapper>
            )}/>
            <Route path="/login" render={() => (<Redirect to={this.state.isTemporaryPasswordUsed ? '/account' : '/dashboard'} />)} />
            <Route path="/register" render={() => (<Redirect to='/' />)} />
            <Route path="/history-search" render={(props)=>(
              <AuthorizationWrapper allowedServiceTypes={['Paid', 'Free-advanced', 'Internal']}>
                <HistorySearch {...props} searchParams={props.location.search} layoutContext={this.props.layoutContext} />
              </AuthorizationWrapper>)}/>
            <Route path="/containers-history" render={(props)=>(
                <AuthorizationWrapper allowedServiceTypes={['Paid', 'Free-advanced', 'Internal']}>
                  <ContainerHistory {...props} layoutContext={this.props.layoutContext} />
                </AuthorizationWrapper>)}/>
            <Route path="/sign-out" render={()=>(
                <SignOut
                    checkIsAuthorized={this.checkIsAuthorized.bind(this)}
                />
            )}/>
            <Route path="/account" render={() => (
              <MyAccount
                setAccountSuspended={() => this.setAccountSuspended()}
                refreshToken={(onSuccess) => this.refreshToken(onSuccess)}
                setConnectionProblems={() => this.setConnectionProblems()}
                isChangePasswordOpen = {this.state.isTemporaryPasswordUsed}
                onChangeTemporaryPassword = {this.onChangeTemporaryPassword.bind(this)}
                layoutContext = {this.props.layoutContext}
              />
            )} />
            <Route path="/containers" component={Containers} />
            <Route path="/faqs" render={() => (<Faqs layoutContext={this.props.layoutContext}/>)} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/cookie-policy" component={CookiePolicy} />
            <Route path="/acceptable-use-policy" component={AcceptableUsePolicy} />
            <Route path="/terms-of-use-policy" component={TermsOfUsePolicy} />
            <Route path="/administration-search"
                   render={() => (
                     <AuthorizationWrapper allowedAuthorities={['helpdesk']}>
                       <AdministrationSearch
                           layoutContext={this.props.layoutContext}/>
                     </AuthorizationWrapper>
                   )}/>
            <Route path="/advanced-features"
                   render={() => (
                       <AuthorizationWrapper allowedServiceTypes={['Free']}>
                         <AdvancedFeature/>
                       </AuthorizationWrapper>
                   )}/>
            <Route path="/paid-features"
                   render={() => (
                       <AuthorizationWrapper allowedServiceTypes={['Free', 'Free-advanced', 'Internal']}>
                         <PaidFeature/>
                       </AuthorizationWrapper>
                   )}/>
            <Route path="/customer-update"
                   render={() => (
                     <AuthorizationWrapper allowedAuthorities={['helpdesk']}>
                       <AdministrationCustomerUpdate
                           layoutContext={this.props.layoutContext}/>
                     </AuthorizationWrapper>
                   )}/>
            <Route path="/alert-templates" render={() => (
                <AuthorizationWrapper allowedServiceTypes={['Paid', 'Free-advanced', 'Internal']}>
                  <AlertTemplates layoutContext={this.props.layoutContext}/>
                </AuthorizationWrapper>
            )} />
            <Route path="/saved-lists"
                   render={() => (
                       <AuthorizationWrapper allowedServiceTypes={['Paid', 'Free-advanced', 'Internal']}>
                         <SavedLists
                             layoutContext={this.props.layoutContext}/>
                       </AuthorizationWrapper>
                   )}/>
            <Route component={PageNotFound}/>
          </Switch>
          <AccountSuspended
            isOpen={this.state.accountSuspended}
            className='app-modal app-modal__type_warning' />
        </LoggedInLayout>
    </Provider>
  }

  showNotLoggedInLayout(){
    return <LoggedOutLayout layoutContext={this.props.layoutContext}>
          <Switch>
            <Route exact path="/" render={() => (
                <Login layoutContext={this.props.layoutContext} refreshToken={() => this.refreshToken()}/>
            )}/>
            <Route path="/login" render={() => (
                <Login layoutContext={this.props.layoutContext} refreshToken={() => this.refreshToken()}/>
            )}/>
            <Route path="/register" render={() => (
                <Registration layoutContext={this.props.layoutContext}
                    setConnectionProblems={() => this.setConnectionProblems()}/>
            )}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/cookie-policy" component={CookiePolicy}/>
            <Route path="/acceptable-use-policy"
                   component={AcceptableUsePolicy}/>
            <Route path="/terms-of-use-policy" component={TermsOfUsePolicy}/>
            <Route render={() => (<Redirect to='/login'/>)}/>
          </Switch>
          < AccountSuspended isOpen={this.state.accountSuspended} />
        {this.showEmailConfirmation()}
            </LoggedOutLayout>
  }

  shouldComponentUpdate(nextProps, nextState){
    return this.props !== nextProps || this.state !== nextState;
  }

  render() {
    if(this.state.sitekey)
    return  <GoogleReCaptchaProvider
    reCaptchaKey={this.state.sitekey}>
    <BrowserRouter>
      <React.Fragment>
        <ScrollToTop>
          <Route path="/" component={trackLocation} />

          <Notification
              isOpen={this.props.layoutContext.state.hasConnectionProblems}
              text={<p className='app-modal__type_warning'>Oops, something went wrong.  Please try again.  If the problem persists, please call our helpdesk on <a className='phone-number-clickable-link' href="tel:08000469648">08000 469 648</a></p>}
              onClose={() => this.props.layoutContext.setConnectionProblems(false)}
          />
            {
              this.state.isAuthorized
                ? this.showLoggedInLayout()
                : this.showNotLoggedInLayout()
            }
        </ScrollToTop>
      </React.Fragment>
    </BrowserRouter>
    </GoogleReCaptchaProvider>;
    return null;
  }
};
